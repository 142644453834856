import React, { useCallback, useEffect, useState } from "react";
import { Typography, Box, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import { useStyles } from "./styles";
import Helper from "../../Utils/Helper";
import { ALERT_EFFECT,CURRENCIES_SYMBOLS } from "../../Utils/Constants";
import ArrowUp from "../../Assets/images/menu-icons/arrowUp";

const ListItem = ({
  data,
  index,
  onMouseEnter,
  onMouseLeave,
  selected,
  // selectedIndex,
  previousIndex,
  hoveredIndex,
  onClick,
  headers,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const npsColors = useSelector((state) => state.app.mapping_data.nps_color);
  const npsScoreChange = useSelector(
    (state) => state.app.mapping_data.nps_score_change
  );
  const fieldMapping = useSelector(
    (state) => state.app.mapping_data.field_mapping
  );
  // const fieldMapping={fin_value:{is_enabled:false}}

  const getNpsColor = (data) => {
    if (data.nps_score_change) return npsScoreChange[data.nps_score_change];
    if (data.nps_class)
      return data.nps_class ? npsColors[data.nps_class.toLowerCase()] : "";
  };

  const handleOnClick = (data, index) => {
    onClick && onClick(data, index);
  };

  const handleHover = (index) => {
    onMouseEnter && onMouseEnter(index);
  };
  const handleMouseLeave = (index) => {
    onMouseLeave && onMouseLeave(index);
  };

  const conv = window.conv;

  let prevHoverElemInd;
  if (hoveredIndex > 0) {
    prevHoverElemInd = hoveredIndex - 1;
  }

  return (
    <Grid
      mr={conv(16)}
      className={clsx(
        classes.tableRow,
        prevHoverElemInd === index && classes.noBorder,
        previousIndex === index && classes.noBorder,
        selected && classes.noBorder
      )}
      onClick={() => handleOnClick(data, index)}
      onMouseEnter={() => {
        handleHover(index);
      }}
      onMouseLeave={() => handleMouseLeave(-1)}
    >
      <Grid container>
        <Grid item xs={headers[0].size}>
          <Typography color={theme.palette.text.tableRowTitle} variant="h6" mr={conv(5)} style={{wordBreak:"break-word"}}>
            {data.name}
          </Typography>
        </Grid>
        {fieldMapping.fin_value.is_enabled && (
          <Grid item xs={headers[1].size}>
            <Typography variant="h6">
              {CURRENCIES_SYMBOLS[data.fin_currency_type] || ""}
              {data?.fin_value===0?"-":Helper.AbbreviateNumber(data.fin_value)}
            </Typography>
          </Grid>
        )}
        <Grid item xs={headers[2].size}>
          <Typography fontWeight={400} color={getNpsColor(data)} variant="h6">
            {data[headers[2].field]}
          </Typography>
        </Grid>
        <Grid  item
          xs={headers[3].size}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {data[headers[headers.length - 1].field] == ALERT_EFFECT.UP && (
            <ArrowUp fill={theme.palette.icon.changeArrow} />
          )}
          {data[headers[headers.length - 1].field] == ALERT_EFFECT.DOWN && (
            <Box
              display="flex"
              justifyContent="flex-end"
              sx={{ transform: "rotate(180deg)" }}
            >
              <ArrowUp fill={theme.palette.icon.changeArrow} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(ListItem);
