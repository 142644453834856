import React, { useCallback, useState, useEffect } from "react";
import { Box, Grid, Typography, Stack } from "@mui/material";
import InfinitePaginatedScroll from "../../Shared/InfinitePaginatedScroll";
import CircularProgress from "../../Shared/CircularProgress";
import Checkbox from "../../Shared/Checkbox";
import GUID from "../../../Utils/Guid";
import { useTheme } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getImageUrl } from "../../../Utils/ImageHelper";
import { portfolioActions } from "../../../Services/Redux/actionCreators";
import NoDataView from "../../NoDataView";
import MemberInfo from "./MemberInfo";

function AccountMembers({ getValue, onChange }) {
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const imageCookie = useSelector((state) => state.app.cloud_cookie);

  const memberData=useSelector((state)=>state.portfolio.account_members)
  let meta,members;
  if(memberData&&memberData.items)
  {
    members=memberData.items;
    meta=memberData.meta
  }

  const loadMembers = useCallback(
    async (id) => {
      setLoading(true);
      dispatch(portfolioActions.getAccountTeamMembers(id, page));
      setLoading(false);
    },
    [page]
  );

  const handleChange = (val) => {
    onChange && onChange(val);
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (params.id) loadMembers(params.id);
  }, [page, params.id]);

  if(!members||!meta||members?.length===0)
  {
    return <Box flex="1" height="300px"><NoDataView/></Box>;
  }

  return (
    <InfinitePaginatedScroll
      totalRecords={meta.total_records}
      onNext={handleNext}
      height="400px"
      padding={0}
      loader={<CircularProgress show={loading} />}
    >
      {members.map((item) => (
        <MemberInfo
          key={GUID()}
          image={getImageUrl(item.user.avatar, imageCookie)}
          title={item.user.display_name}
          description={item.role.name}
          addOn={
            <Checkbox
              checked={getValue(item.user.user_id)}
              onClick={() =>
                handleChange({
                  key: item.user.user_id,
                  value: item,
                })
              }
            />
          }
        />
      ))}
    </InfinitePaginatedScroll>
  );
}

export default React.memo(AccountMembers);