import React from "react";
import LoginContainer from "../Components/Shared/LoginContainer";
const withLoginBackground = Component => {
  return props => (
    <LoginContainer>
      <Component {...props} />
    </LoginContainer>
  );
};

export default withLoginBackground;
