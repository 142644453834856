import * as React from "react";
import { Box, Grid, Stack, Typography, IconButton } from "@mui/material";
import { Close, MoreHoriz } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import Readmore from "./Readmore";
function isOverflown(element) {
  if (element)
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
}

const Tag = (props) => {
  const theme = useTheme();
  const tagRef = React.useRef();

  const [isHidden, setIsHidden] = React.useState(true);
  const [overflow, setOverflow] = React.useState(false);

  React.useEffect(() => {
    if (tagRef?.current) {
      setOverflow(isOverflown(tagRef?.current));
    }
  }, [tagRef?.current]);

  let {
    background = theme.palette.background.tagBg,
    borderRadius,
    border,
    spacing,
    marginTop,
    fontSize = "1rem",
    selectedItemStyle,
    padding,
    fontWeight,
    color,
    onClose,
    rows = 2,
  } = props;
  const useStyles = makeStyles((theme) => ({
    hidden: {
      display: "-webkit-box",
      WebkitLineClamp: rows,
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      color: "transparent",
    },
  }));
  const classes = useStyles();
  if (!selectedItemStyle) {
    selectedItemStyle = {
      background: theme.palette.background.selectedTagBg,
      color: theme.palette.text.tagSelectedColor,
    };
  }
  if (!border) border = "1px solid " + theme.palette.border.scorecard_border;
  if (!color) color = theme.palette.text.tagColor;

  return (
    <Box display="flex" position={"relative"} >
      <Box ref={tagRef} className={isHidden ? classes.hidden : null}>
        {props.data &&
          props.data.map((item, index) => {
            let defaultStyle = {
              display: "inline-block",
              color: item.color || color,
              background: background,
              borderRadius: borderRadius,
              border: border,
              marginTop: marginTop || spacing,
              marginBottom: spacing,
              marginRight: spacing,
              fontSize: fontSize,
              padding: padding,
              fontWeight: fontWeight,
              ...item.style,
            };
            return (
              <Typography
                variant="span"
                key={index}
                style={
                  item.selected
                    ? { ...defaultStyle, ...selectedItemStyle }
                    : defaultStyle
                }
              >
                {item.text}
                {item.badge && ` ${item.badge} `}
                {props.deletable && (
                  <IconButton
                    sx={{ padding: 0, color: "text.primary" }}
                    onClick={() => item.onDelete?.(item.key)}
                  >
                    <Close />
                  </IconButton>
                )}
              </Typography>
            );
          })}
        {overflow && (
          <Box width="30px" style={{ position: "absolute",right:0,bottom:0}}>
            {" "}
            <IconButton
              sx={{ paddingTop: "15px", color: "text.primary" }}
              onClick={() => setIsHidden(!isHidden)}
            >
              <MoreHoriz />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Tag;
Tag.defaultProps = {
  data: [
    {
      text: "CLI",
      selected: false,
      // color: "#fff",
      style: {},
      badge: "",
      onClick: () => {},
      onDelete: () => {},
    },
  ],
  borderRadius: "8px",
  spacing: "4px",
  fontSize: "1rem",
  fontWeight: "400",
  padding: "8px 12px",
  deletable: false,
  clickable: false,
};
