import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector,useDispatch } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import PortfolioView from "../PortfolioView";
import AccountView from "../AccountView";
import JTPView from "../JTPView";
import { useStyles } from "./styles";
import {
  CURRENCIES_SYMBOLS,
  FEEDSUBTYPES,
  FEEDTYPES,
} from "../../../Utils/Constants";
import Helpers from "../../../Utils/Helper";
import Animator from "../../Animator";
import UIHelper from "../../../Utils/UIHelper";
const conv=UIHelper.convertPxToRem;



const FeedCard = ({ selected, onClick, data, key ,filter,loaded,id}) => {
  const classes = useStyles();
  const dispatch=useDispatch();
  const npsColors = useSelector((state) => state.app.mapping_data.nps_color);
  const nrrColors = useSelector(
    (state) => state.app.mapping_data.nrr_state_color
  );

  const titles = useSelector((state) => state.app.mapping_data.title);

  const handleClick = (e) => {
    onClick(data);
  };
  if (!data) return;

  let childComponent = (
    <Typography variant="h6" textAlign="center">
      No Data
    </Typography>
  );

  let isPortfolio=false;
  if (
    (data.feed_type === FEEDTYPES.ACCOUNT ||
      data.feed_type === FEEDTYPES.PORTFOLIO) &&
    data.feed_sub_type === FEEDSUBTYPES.JTP
  ) {
    let sourceData = data.feed_data.source_data;

    if (sourceData) {
      let data = sourceData.journey_score;
      childComponent = (
        <JTPView
          title={sourceData.name}
          currency={CURRENCIES_SYMBOLS[sourceData.fin_currency_type] || ""}
          amount={Helpers.AbbreviateNumber(sourceData.fin_value)}
          date={Helpers.DateToMMYYYY(sourceData.last_updated_dt)}
          data={data}
        />
      );
    }
  } else if (data.feed_type === FEEDTYPES.PORTFOLIO) {
    let sourceData = data.feed_data.source_data;
    isPortfolio=true;
    if (sourceData) {
      let predictiveData = sourceData.predictive_nps;
      let valueData = [];
      let labelData = [];
      let goalLine;
      if (
        predictiveData &&
        predictiveData.trends &&
        predictiveData.trends.length
      ) {

        goalLine = predictiveData.target_nps_value;
      }
      childComponent = (
        <PortfolioView
          title={sourceData.name}
          currency={CURRENCIES_SYMBOLS[sourceData.fin_currency_type] || ""}
          amount={Helpers.AbbreviateNumber(sourceData.fin_value)}
          npsScore={predictiveData?.nps_score}
          npsValue={predictiveData?.nps_diff}
          npsLabel={titles ? titles.nps_title : ""}
          color={predictiveData.nps_diff_color_code}
          date={Helpers.DateToMMYYYY(sourceData.last_updated_dt)}
          data={predictiveData.trends}
          goalLineValue={goalLine}
          value={sourceData?.last_updated_dt}
        />
      );
    }
  } else if (data.feed_type === FEEDTYPES.ACCOUNT) {
    let sourceData = data.feed_data.source_data;

    if (sourceData) {
      let trendData = sourceData.nps_class_trends;
      childComponent = (
        <AccountView
          selected={selected}
          currency={CURRENCIES_SYMBOLS[sourceData.fin_currency_type] || ""}
          amount={Helpers.AbbreviateNumber(sourceData.fin_value)}
          title={sourceData.name}
          text={sourceData.nps_class}
          date={Helpers.DateToMMYYYY(sourceData.last_updated_dt)}
          value={sourceData.last_updated_dt}
          npsLabel={titles ? titles.nps_title : ""}
          nrrLabel={titles ? titles.nrr_state_title : ""}
          data={trendData}
        />
      );
    }
  }
  return (
    <Animator animation="slideUp">
      <Box mt={conv(14)} mr={conv(21)} 
        id={key}
        onClick={handleClick}
        className={clsx(classes.box, selected && classes.selectedBox)}
      >
        {childComponent}
      </Box>
    </Animator>
  );
};

FeedCard.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  filter:PropTypes.string,
  children: PropTypes.object,
};

FeedCard.defaultProps = {
  selected: true,
  filter:"all",
  onClick: () => {},
};
export default React.memo(FeedCard);
