import { customListActions } from "../actionCreators";
import { SORT_ORDER } from "../../../Utils/Constants";
let defaultSortData={
  sort_by: "name",
  sort_order: SORT_ORDER.ASCENDING,
}
const initialState = {
  customListData: {
    action: [],
    account: [],
    portfolio: [],
    portfolio_accounts:[]
  },
  customListMetaData: {
    action: null,
    account: null,
    portfolio: null,
    portfolio_accounts:null,    
  },
  sortData:{
    account: defaultSortData,
    portfolio: defaultSortData,
    portfolio_accounts:defaultSortData,    
  },
  searchData:{
    action: null,
    account: null,
    portfolio: null,
    portfolio_accounts:null, 
  },
  pf_acclist_source:null
};

const customListReducer = (state = initialState, action) => {
  switch (action.type) {
  case customListActions.SET_LIST_DATA: {
    let customListData = {...state.customListData};
    customListData[action.payload.apiName] = [...action.payload.data.items];

    let customListMetaData = {...state.customListMetaData};
    customListMetaData[action.payload.apiName] = action.payload.data.meta;

    return {
      ...state,
      customListData,
      customListMetaData
    };
  }
  case customListActions.APPEND_LIST_DATA: {
    let customListData = {...state.customListData};
    let customListMetaData = {...state.customListMetaData};
    customListData[action.payload.apiName] = [...customListData[action.payload.apiName], ...action.payload.data.items];
    customListMetaData[action.payload.apiName] = action.payload.data.meta;
    return {
      ...state,
      customListData,
      customListMetaData
    };
  }
  case customListActions.SET_SORT_DATA: {
    let sortData = {...state.sortData};
    sortData[action.payload.apiName] = action.payload.data;
    return {
      ...state,
      sortData
    };
  }
  case customListActions.SET_SEARCH_TEXT: {
    let searchData = {...state.searchData};
    searchData[action.payload.apiName] = action.payload.data;
    return {
      ...state,
      searchData
    };
  }
  case customListActions.RESET_SORT_DATA: {
    let sortData = {...state.sortData};
    sortData[action.payload.apiName] = defaultSortData;
    return {
      ...state,
      sortData
    };
  }
  case customListActions.SET_CHANGED: {
    let sortData = {...state.sortData};
    let currSortData={...sortData[action.payload.apiName]}
    sortData[action.payload.apiName] = {...currSortData,changed:action.payload.changed};
    return {
      ...state,
      sortData
    };
  }
  case customListActions.SET_PF_ACC_LIST_SOURCE: {   
    return {
      ...state,
      pf_acclist_source:action.payload
    };
  }
  case customListActions.CLEAR_LIST_DATA: {
    return {
      ...initialState
    };
  }
  default:
    return state;
  }
};

export default customListReducer;
