import React, { useEffect, useRef, useState, forwardRef } from "react";
const Tooltip = forwardRef((props, ref) => {
  const { value, className, show,position=0 } = props;

  return (
    <p
      ref={ref}
      className={className}
      style={{
        display: show ? "block" : "none",
        position: "absolute",
        left:position,
        textOverflow:"no-wrap"
      }}
    >
      {value}
    </p>
  );
});
export default Tooltip;
