import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((MUITheme) => ({
  box: {
    flex: 1,
    // marginTop: "25px",   
  },
  box_header: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: "right",
  },
  distribution_box: {

    "& h6": {
      color: MUITheme.palette.text.lightGrey,
      marginBottom:"0.625rem",
      fontSize:"1.125rem",
      lineHeight:"1.687rem",
      fontWeight:400
    },
    "& h5": {
      color: MUITheme.palette.text.mirage,
      fontWeight: 400,
      fontSize:"1.125rem"
    },
    "& .totalCount":{
      fontSize:"1.125rem",
      lineHeight:"1.687rem",
      fontWeight:300
    }
  },
  header:{
    borderBottom:"1px solid "+MUITheme.palette.border.primary,
  }
}));
