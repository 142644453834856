import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((MUITheme) => ({
  container: {
    "& h6": {
      color: MUITheme.palette.text.secondary + " !important",
    },
  },
  scroll: {
    height: "calc(100vh - 320px)",
    overflow: "auto",
  },
  scroll_detail: {
    height: "calc(100vh - 360px)",
    overflow: "auto",
  },
  box_header: {
    flex: "1",
    marginTop: "10px !important",
    marginBottom: "10px !important",
  },
  buttonApply: {
    marginTop: 15,
    lineHeight: "2.3rem !important",
    fontSize: "0.925rem !important",
    textAlign: "right",
    // marginLeft: "20%",
    // marginRight: "20%",
    // marginBottom: "20px",
    fontWeight: 600,
  },
  rounded_label: {
    color: MUITheme.palette.text.secondary,
    background: MUITheme.palette.background.secondary,
    borderRadius: "4px",
    padding: "5px 15px",
    display: "inline-block",
  },
}));
