import React from "react";
import { CheckedColor, UncheckedColor } from ".";

const Chat = ({stroke="text.lighterGrey",width=20,height=18,...props}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 3.75H13V2.25H11V3.75ZM2.75 17V12H1.25V17H2.75ZM13 20.25H6V21.75H13V20.25ZM1.25 17C1.25 19.6234 3.37665 21.75 6 21.75V20.25C4.20507 20.25 2.75 18.7949 2.75 17H1.25ZM21.25 12C21.25 16.5563 17.5563 20.25 13 20.25V21.75C18.3848 21.75 22.75 17.3848 22.75 12H21.25ZM13 3.75C17.5563 3.75 21.25 7.44365 21.25 12H22.75C22.75 6.61522 18.3848 2.25 13 2.25V3.75ZM11 2.25C5.61522 2.25 1.25 6.61522 1.25 12H2.75C2.75 7.44365 6.44365 3.75 11 3.75V2.25Z"
        fill={stroke}
      />
      <circle cx="12" cy="12" r="1" fill={stroke} />
      <circle cx="16" cy="12" r="1" fill={stroke} />
      <circle cx="8" cy="12" r="1" fill={stroke} />
    </svg>
  );
};

export default Chat;
