import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Typography, Stack, Checkbox } from "@mui/material";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  accountActions,
  portfolioActions,
} from "../../Services/Redux/actionCreators";
import GUID from "../../Utils/Guid";
import { useTranslate } from "../../Hooks/useTranslate";
import Button from "../Shared/Button";
import LoadingView from "../LoadingView";
import NoDataView from "../NoDataView";
import AlertSettingItem from "./AlertSettingItem";
import AlertDetail from "./AlertDetail";
import SidebarHeader from "../SidebarHeader";
import MemberDetail from "../AccountTeam/MemberDetail";
import { FEEDTYPES } from "../../Utils/Constants";
import UIHelper from "../../Utils/UIHelper";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import { EVENT_TYPE, VIEW_EVENT_TYPE } from "../../Utils/Constants";
const conv = UIHelper.convertPxToRem;

function AlertSettings() {
  const classes = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [adminId, setAdminId] = useState();
  const [selectedData, setSelectedData] = useState();
  const [pinPointAttr, setPinpointAttr] = useState();
  const accAlerts = useSelector((state) => state.account.alerts);
  const pfAlerts = useSelector((state) => state.portfolio.alerts);
  const accData = useSelector((state) => state.account.data);
  const pfData = useSelector((state) => state.portfolio.data);
  let alerts;

  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  if (selectedFeed) {
    if (selectedFeed.feed_type === FEEDTYPES.ACCOUNT) alerts = accAlerts;
    else if (selectedFeed.feed_type === FEEDTYPES.PORTFOLIO) alerts = pfAlerts;
  }

  const loadAlertSettings = useCallback(async (id) => {
    setLoading(true);
    if (selectedFeed?.feed_type === FEEDTYPES.ACCOUNT)
      await dispatch(accountActions.getAlerts(id, FEEDTYPES.ACCOUNT));
    else if (selectedFeed?.feed_type === FEEDTYPES.PORTFOLIO)
      await dispatch(portfolioActions.getAlerts(id, FEEDTYPES.PORTFOLIO));

    setLoading(false);
  }, []);

  useEffect(() => {
    let { id } = params;
    loadAlertSettings(id);
  }, [params.id, selectedFeed]);

  if (!loading && (!alerts || alerts?.alerts.length == 0))
    return <NoDataView mt="20%" />;

  const handleContactAdmin = () => {
    if (alerts.contact) {
      setAdminId(alerts.contact);
    }
  };

  const handleCloseAdminDetail = () => {
    setAdminId(null);
  };

  const handleAlertDetailClick = (data) => {
    setSelectedData(data);
  };
  const handleCloseDetail = () => {
    setSelectedData(null);
  };

  let attr = {};
  if (selectedFeed?.feed_type === FEEDTYPES.ACCOUNT) {
    let name = "";
    let pf_name = "";
    let sfdc_id = "";
    name = accData?.name ? accData.name : "";
    pf_name = accData?.portfolio_name ? accData.portfolio_name : "";
    sfdc_id = accData?.external_account_id ? accData.external_account_id : "";
    attr = { name, portfolio_name: pf_name, sfdc_id };
  } else if (selectedFeed?.feed_type === FEEDTYPES.PORTFOLIO) {
    let name = "";
    name = pfData?.name ? pfData.name : "";
    attr = { name: name };
  }
  if (selectedData)
    return (
      <Box
        display={"flex"}
        p={conv(13, 28)}
        flex="1"
        flexDirection={"column"}
        className={classes.container}
      >
        <AlertDetail
          data={selectedData}
          onClose={handleCloseDetail}
          pinPointData={{
            source: "ALERT_MANAGER",
            feed_type: selectedFeed?.feed_type,
            ...attr,
          }}
        />
      </Box>
    );

  if (adminId) {
    return (
      <Box display={"flex"} p={conv(13, 28)} flex="1" flexDirection={"column"}>
        <MemberDetail
          id={adminId}
          onClose={handleCloseAdminDetail}
          data={{
            source: "ALERT_ADMIN",
            feed_type: selectedFeed?.feed_type,
            ...attr,
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      display={"flex"}
      p={conv(13, 28)}
      flex="1"
      flexDirection={"column"}
      className={classes.container}
    >
      <SidebarHeader title={t("alert_settings")} />
      {loading ? (
        <LoadingView mt={"20%"} />
      ) : (
        <>
          <Typography variant="body2" mt={conv(30)}>
            {t("alert_settings_description")}
          </Typography>

          <div
            style={{
              display: "flex",
              flex: "1 auto",
              flexDirection: "column",
              overflowY: "auto",
              height: 0,
              paddingRight: "0.875rem",
              marginTop: conv(27),
            }}
          >
            {alerts?.alerts?.map((item) => {
              return (
                <AlertSettingItem
                  onClick={handleAlertDetailClick}
                  key={GUID("alert_setting")}
                  title={item.alert_title}
                  data={item}
                />
              );
            })}
          </div>
          <Box m={conv(39, 42)}>
            <Button
              color="primary"
              className={classes.buttonApply}
              onClick={handleContactAdmin}
              disableRipple
              variant="contained"
              children={t("contact_administrator")}
              type="submit"
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default React.memo(AlertSettings);
