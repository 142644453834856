import React, { useCallback, useEffect, useState, useRef } from "react";
import { Box, Grid, Typography } from "@mui/material";
import PortfolioDetail from "./PortfolioDetail/index";
import LoadingView from "../LoadingView";
import { useStyles } from "./styles";
import NpsDistribution from "./NpsDistribution";
import {
  portfolioActions,
  feedActions,
  appActions,
  scoreCardActions,
} from "../../Services/Redux/actionCreators";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { FEEDTYPES, FILTER_TYPE, RIGHTMENUS } from "../../Utils/Constants";
import ScoreCard from "../ScoreCard";
import { useTranslate } from "../../Hooks/useTranslate";
import NoDataView from "../NoDataView";
import PortfolioJourney from "../CustomerJourney/PortfolioJourney";
import KPIDrillDownOperations from "../KPIDrillDownOperations";
import PortfolioTitle from "./PortfolioTitle";
import DetailAnimation from "../CustomAnimations/DetailAnimation";
import DetailAnimation2 from "../CustomAnimations/DetailAnimation2";
import { MoreVert } from "@mui/icons-material";
import CustomerJourneyAnimation from "../CustomAnimations/CustomerJourneyAnimation";
import UIHelper from "../../Utils/UIHelper";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import { EVENT_TYPE, VIEW_EVENT_TYPE } from "../../Utils/Constants";
import Helpers from "../../Utils/Helper";
const conv = UIHelper.convertPxToRem;

const PortfolioAnalytics = () => {
  const classes = useStyles();
  const t = useTranslate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const pfDetailRef = useRef();
  const topRef = useRef();
  const divRef = useRef();
  //animation states
  const [start, setStart] = useState(false);
  const [graphLoaded, setGraphLoaded] = useState(false);
  const loadingJTP = useSelector((state) => state.portfolio.loading_jtp);
  const [opDriverId, setOpDriverId] = useState();
  const [tempJTPData, setTempJTPData] = useState();
  const params = useParams();
  const npsColors = useSelector((state) => state.app.mapping_data.nps_color);
  let operationalDrivers = useSelector(
    (state) => state.portfolio.operationalDrivers
  );

  let portfolioValue = useSelector((state) => state.portfolio.data);
  let kpi_metrics = useSelector((state) => state.portfolio.kpi_metrics);
  let portfolioTrend = useSelector((state) => state.portfolio.trendData);
  let jtpData = useSelector((state) => state.portfolio.jtpData);

  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  let driver = useSelector((state) => state.portfolio.selected_op_driver);
  const deepLinkData = useSelector((state) => state.portfolio.link_data);
  let jtp = useSelector((state) => state.portfolio.selected_jtp);
  const loadingKPI = useSelector((state) => state.portfolio.loading_kpi);
  const loadValueData = useCallback(async () => {
    const { id, date } = params;
    if (!id) return;
    let timestamp = null;
    if (date && !(date === "date")) {
      timestamp = date;
    }
    setLoading(true);
    resetAnimState();
    setTempJTPData(null);
    setGraphLoaded(false);
    dispatch(portfolioActions.clearAllData());
    dispatch(scoreCardActions.clearAllData());
    dispatch(portfolioActions.setLoaded(false));
    await Promise.allSettled([
      await dispatch(portfolioActions.portfolioValue(id, timestamp)),
      dispatch(portfolioActions.portfolioTrend(id, timestamp)),
      dispatch(portfolioActions.portfolioJtp(id, timestamp)),
      dispatch(portfolioActions.analyticsReactions(id, FEEDTYPES.PORTFOLIO)),
      dispatch(portfolioActions.getIsAlertConfigured(id)),
      dispatch(portfolioActions.analyticsStatus(id, FEEDTYPES.PORTFOLIO)),
    ]);
    if (location?.state) {
      const { jtp, kpi_corelation_id, kpi_metric_id } = location.state;
      setTempJTPData({ jtp_id: jtp?.id, kpi_corelation_id, kpi_metric_id });
    }

    if (deepLinkData) {
      dispatch(
        feedActions.setSelectedFeed({
          feed_id: null,
          feed_type: FEEDTYPES.PORTFOLIO,
          source_id: deepLinkData.source_id,
          source_name: deepLinkData.source_name,
        })
      );
      dispatch(portfolioActions.setTimestamp(deepLinkData.timestamp));
      setTempJTPData({
        jtp_id: deepLinkData.jtp_id,
        kpi_corelation_id: deepLinkData.kpi_corelation_id,
      });

      let filters = deepLinkData.filter;
      if (filters && filters.length > 0) {
        let selectedFilters = {};
        filters.forEach((element) => {
          if (element.type === FILTER_TYPE.CHECKBOX) {
            if (!selectedFilters[element.key]) {
              selectedFilters[element.key] = {
                title: element.groupName,
                type: element.type,
                values: { [element.data]: element.text },
              };
            } else {
              selectedFilters[element.key]["values"][element.data] =
                element.text;
            }
          }
          if (element.type === FILTER_TYPE.RANGE) {
            selectedFilters[element.key] = {
              group_name: element.groupName,
              title: element.text,
              type: element.type,
              values: [element.data.min, element.data.max],
            };
          }
          if (element.type === FILTER_TYPE.CHECKBOX_PROFILE) {
            let valueData = element.data;
            valueData["user"]["display_name"] = element.text;
            if (!selectedFilters[element.key]) {
              selectedFilters[element.key] = {
                title: element.groupName,
                type: element.type,
                values: { [element.data.user.user_id]: valueData },
              };
            } else {
              selectedFilters[element.key]["values"][
                element.data.user.user_id
              ] = valueData;
            }
          }
        });
        dispatch(portfolioActions.setSelectedFilters(selectedFilters));
        dispatch(portfolioActions.setApplyFilters(true));
      }
    }
    recordEvent();
    setLoading(false);
  }, [selectedFeed, params.feed_id, params.id, params.date]);

  useEffect(() => {
    loadValueData();
  }, [params.feed_id, params.id, params.date]);

  useEffect(() => {
    if (!selectedFeed && portfolioValue) {
      const { feed_id } = params;
      dispatch(
        feedActions.setSelectedFeed({
          feed_id: feed_id,
          feed_type: FEEDTYPES.PORTFOLIO,
          source_id: portfolioValue?.id,
          source_name: portfolioValue?.name,
        })
      );
    }
  }, [selectedFeed, portfolioValue, params.id]);

  function recordEvent() {
    if (portfolioValue?.name) {
      if (location?.state?.jtp?.id) {
        return;
      }
      if (deepLinkData?.jtp_id) {
        return;
      }
      Pinpoint.sendEventData(
        EVENT_TYPE.VIEW,
        [VIEW_EVENT_TYPE.PORTFOLIO_ANALYTICS],
        [portfolioValue?.name]
      );
    }
  }

  useEffect(() => {
    if (!jtp) {
      topRef?.current?.scrollIntoView();
      setTempJTPData(null);
    }
  }, [jtp]);

  const selectJTP_OP_KPI = async () => {
    if (tempJTPData) {
      const { jtp_id, kpi_corelation_id, kpi_metric_id } = tempJTPData;
      if (jtp_id) {
        dispatch(portfolioActions.selectJTP(jtp_id));
      }
      if (kpi_corelation_id) {
        setOpDriverId(kpi_corelation_id);
        dispatch(portfolioActions.selectOperationalDriver(kpi_corelation_id));
      }
      if (kpi_metric_id) {
        dispatch(portfolioActions.selectKPI(kpi_metric_id));
      }
    }
    setStart(true);
  };

  const handleKPINavigate = (val) => {
    if (!opDriverId) {
      dispatch(portfolioActions.selectOperationalDriver(val));
    } else setOpDriverId(null);
  };

  const handleGraphLoaded = async () => {
    setGraphLoaded(true);
    dispatch(portfolioActions.setLoaded(true));
  };

  useEffect(() => {
    if (graphLoaded) {
      selectJTP_OP_KPI();
    }
  }, [graphLoaded, tempJTPData]);

  const resetAnimState = () => {
    setStart(false);
  };

  const handleViewAccountClick = () => {
    dispatch(appActions.toggleRightMenu(RIGHTMENUS.ACCOUNTS));
  };

  const pfDetailView = (
    <div ref={divRef} className="border">
      <div ref={pfDetailRef} className="border">
        <Box className={classes.box} pb={conv(30)} mb={conv(50)}>
          <DetailAnimation
            value={pfDetailRef?.current?.offsetHeight}
            trigger={jtp}
            stopAnimation={tempJTPData?.jtp_id}
          >
            {portfolioTrend && (
              <>
                <Box mt={conv(-5)}>
                  <PortfolioDetail
                    data={portfolioTrend}
                    onLoading={(loading) => {
                      setLoading(loading);
                    }}
                    value={portfolioValue?.last_updated_dt}
                    onLoaded={handleGraphLoaded}
                    loaded={graphLoaded}
                  />
                </Box>
                {graphLoaded && (
                  <Box mt={conv(60)}>
                    <NpsDistribution
                      disCount={portfolioTrend.distribution_count}
                      disDetails={portfolioTrend.distribution_details}
                      colors={npsColors}
                    />
                  </Box>
                )}
              </>
            )}
          </DetailAnimation>
        </Box>{" "}
      </div>

      <Box>
        <CustomerJourneyAnimation
          value={pfDetailRef?.current?.offsetHeight}
          trigger={jtp}
        >
          <PortfolioJourney />
        </CustomerJourneyAnimation>
      </Box>
    </div>
  );

  if (!loading && !portfolioValue)
    return (
      <Box flex="1" display={"flex"}>
        <NoDataView />
      </Box>
    );
  return (
    <Box flex="1" position={"relative"} display="flex" flexDirection={"column"}>
      <Box
        flex="1"
        position={"absolute"}
        display={loading || !graphLoaded ? "block" : "none"}
        zIndex="1000"
        width="100%"
        height="100%"
        bgcolor="background.container"
      >
        {" "}
        <Box flex="1" display={"flex"} mt="20%">
          <LoadingView />
        </Box>
      </Box>
      <div ref={topRef}></div>
      {graphLoaded && portfolioValue && (
        <Grid container pl={conv(39)} pb={conv(19)} className={classes.header}>
          <Grid item md={10} sm={8} pt={conv(37)}>
            <PortfolioTitle
              name={portfolioValue?.name}
              currency={portfolioValue.fin_currency_type}
              value={portfolioValue.fin_value}
            />
          </Grid>
          <Grid item md={2} sm={4} pt={conv(37)}>
            {portfolioTrend && graphLoaded && (
              <Box className={classes.box_header} mt={conv(5)}>
                <Typography
                  variant="h4"
                  color="text.heading"
                  fontSize={conv(29)}
                  lineHeight={conv(36)}
                  fontWeight={300}
                  // mr={conv(20)}
                  textAlign="right"
                  className="pointer"
                  onClick={handleViewAccountClick}
                >
                  {portfolioTrend.distribution_count}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.heading"
                  fontSize={conv(32)}
                  lineHeight={conv(36)}
                  fontWeight={300}
                  marginTop={conv(5)}
                >
                  <MoreVert fontSize={conv(29)} />
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      )}

      <Grid
        flex="1"
        marginTop={"0px"}
        p={conv(39)}
        pr={"1rem"}
        pt={0}
        container
        spacing="2rem"
        overflow={"auto"}
      >
        <Grid item md={6} sm={12}>
          {!location?.state?.jtp ? (
            <DetailAnimation2
              value={divRef?.current?.offsetHeight}
              stopAnimation={tempJTPData?.jtp_id}
              trigger={start}
            >
              {pfDetailView}
            </DetailAnimation2>
          ) : (
            pfDetailView
          )}
        </Grid>
        <Grid item md={6} sm={12}>
          {graphLoaded && (
            <>
              {!tempJTPData?.jtp_id && jtpData && (
                <ScoreCard
                  data={jtpData}
                  animationTrigger={!jtp}
                  type={FEEDTYPES.PORTFOLIO}
                />
              )}
              {driver && operationalDrivers?.length && (
                <KPIDrillDownOperations
                  onNavigate={handleKPINavigate}
                  drivers={operationalDrivers}
                  selectedDriver={driver}
                  data={kpi_metrics}
                  loading={loadingKPI}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default React.memo(PortfolioAnalytics);
