import React, { useEffect, useState } from "react";
import NoInternetModal from "./NoInternetModal";
import NoInternetLight from "../../Assets/images/no-int-light.png";
import NoInternetDark from "../../Assets/images/no-int-dark.png";
import { useSelector } from "react-redux";
import { useTranslate } from "../../Hooks/useTranslate";

const NoInternet = () => {
  const t = useTranslate();
  const theme = useSelector((state) => state.app.theme);
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const { isLoggedIn } = useSelector((state) => state.user);

  const checkInternet = (pingInterval=2000,continuous=false) => {
    const webPing = setInterval(() => {      
      fetch("//google.com", {
        mode: "no-cors",
      })
        .then(() => {
          setNetwork(true);
          if(!continuous)
            return clearInterval(webPing);
        })
        .catch(() => setNetwork(false));
    }, pingInterval);
    return webPing;
  };

  useEffect(()=>{
    let timer= checkInternet(10000,true);
    return ()=>{
      clearInterval(timer);
    }
  },[])
  const updateNetwork = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      checkInternet();
      return;
    }

    return setNetwork(false);
  };

  const handleRetry = () => {
    updateNetwork();
  };

  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  },[]);

  let icon = theme.toLowerCase() === "light" ? NoInternetLight : NoInternetDark;
  let title = t("offline_header");
  let desc = t("offline_content");
  if(!isLoggedIn)
    desc=t("offline_content_common");
  return (
    <NoInternetModal
      open={!isOnline}
      icon={icon}
      title={title}
      description={desc}
      onConfirm={handleRetry}
    />
  );
};

export default NoInternet;
