import React, { useCallback, useEffect, useId, useState } from "react";
import { Box } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "../CircularProgress";
import { useStyles } from "./styles";

import PropTypes from "prop-types";

const PaginatedScrollBox = props => {
  const id = useId();
  const classes=useStyles();
  const { totalRecords, onNext, endMessage, loader, children ,padding=0,dataLength} = props;
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = useCallback(() => {
    if (dataLength >= totalRecords) {
      setHasMore(false);
      return;
    }   
    onNext();
  },[onNext,dataLength,totalRecords]);
  
  return (
    <Box 
      id={id}
      padding={padding}
      overflow="auto"
      height={props.height}
      className={[classes.root, props.clsx]}
      sx={{boxSizing: "border-box;"}}
    >
      <InfiniteScroll 
        dataLength={children.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={loader}
        endMessage={endMessage}
        scrollableTarget={id}
      >

        {children}
      </InfiniteScroll>
    </Box>
  );
};

PaginatedScrollBox.propTypes = {
  totalRecords: PropTypes.number,
  dataLength:PropTypes.number,
  onNext: PropTypes.func,
  children: PropTypes.array,
  endMessage: PropTypes.object,
  loader: PropTypes.object,
};

PaginatedScrollBox.defaultProps = {
  totalRecords: 0,
  onNext: () => {},
  children: [],
  endMessage: <></>,
  loader: <CircularProgress />,
};
export default PaginatedScrollBox;
