import React, { useCallback, useEffect, useState } from "react";
import InfinitePaginatedScroll from "../Shared/InfinitePaginatedScroll";
import CircularProgress from "../Shared/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  feedActions,
  favoriteActions,
} from "../../Services/Redux/actionCreators";
import FavoriteItem from "./FavoriteItem";
import { FEEDTYPES, ACTION_EVENT_SUBTYPE } from "../../Utils/Constants";
import { Typography, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import LoadingView from "../LoadingView";
import NoDataView from "../NoDataView";
import { PAGE_SIZE } from "../../Utils/Constants";
import { useTranslate } from "../../Hooks/useTranslate";
import SearchInput from "../Shared/SearchInput";
import DeleteModal from "./DeleteModal";
import Search from "../../Assets/images/search";
import FavNoData from "../../Assets/images/fav_no_data";
import UIHelper from "../../Utils/UIHelper";

import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import { EVENT_TYPE, VIEW_EVENT_TYPE } from "../../Utils/Constants";
const conv = UIHelper.convertPxToRem;
const FavoritesList = () => {
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [listLoading, setListLoading] = useState(false);
  const [query, setQuery] = useState(null);
  const dispatch = useDispatch();
  const t = useTranslate();
  let favData = useSelector((state) => state.favorites?.data);
  let searchText=useSelector((state)=>state.favorites.search_text)
  let favList = favData?.items;
  const favMeta = favData?.meta;
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);

  const navigate = useNavigate();

  const loadFavorites = async (offset) => {
    if (offset === 0) {
      setLoading(true);
      await dispatch(favoriteActions.getFavorites(offset, PAGE_SIZE, query));
      setLoading(false);
      Pinpoint.sendEventData(EVENT_TYPE.VIEW, [VIEW_EVENT_TYPE.FAVORITES]);
      return;
    }

    setListLoading(true);
    await dispatch(favoriteActions.getFavorites(offset, PAGE_SIZE, query));
    setListLoading(false);
  };

  useEffect(() => {
    if (!favMeta&&query===null) {
      loadFavorites(0);
    }
  }, [favMeta,query]);

  useEffect(() => {
    if (query != null) {
      loadFavorites(0);
    }
  }, [query]);

  useEffect(() => {
    if (searchText) {
      loadFavorites(0);
      dispatch(favoriteActions.setSearchText(""));
    }
  }, []);

  useEffect(() => {
    if (offset) {
      loadFavorites(offset);
    }
  }, [offset]);

  const handleFavoriteClick = useCallback((data) => {
    let basepath;
    let feedPayload;
    if (data.favorite_type === FEEDTYPES.PORTFOLIO) {
      feedPayload = {
        feed_id: data.id,
        source_id: data.id,
        feed_type: data.favorite_type,
        source_name: data.name,
      };

      basepath = "portfolio";
    } else if (data.favorite_type === FEEDTYPES.ACCOUNT) {
      feedPayload = {
        feed_id: data.id,
        source_id: data.id,
        feed_type: data.favorite_type,
        portfolio_id: data.portfolio_id,
        source_name: data.name,
      };

      basepath = "account";
    }
    if (basepath && feedPayload) {
      let navPath = `${basepath}/feed_id/${data.id}/date`;
      if (data.favorite_type === FEEDTYPES.ACCOUNT) {
        if (data.portfolio_id) {
          navPath = `${navPath}/${data.portfolio_id}`;
        }
      }
      dispatch(feedActions.setSelectedFeed(feedPayload));
      navigate(navPath);
    }
  }, []);

  const handleNext = () => {
    if (favMeta && favMeta.offset + PAGE_SIZE < favMeta.total_records) {
      setOffset(favMeta.offset + PAGE_SIZE);
    }
  };

  const handleSearch = async (e) => {
    setQuery(e);
    dispatch(favoriteActions.setSearchText(e));
    Pinpoint.sendEventData(EVENT_TYPE.ACTION, [
      ACTION_EVENT_SUBTYPE.FAVORITE,
      ACTION_EVENT_SUBTYPE.SEARCH,
    ]);
  };

  const handleClear = () => {
    setQuery("");
    dispatch(favoriteActions.setSearchText(""));
  };

  return (
    <Box position={"relative"} flex="1">
      <Box ml={conv(23)} mr={conv(12)}>
        <Typography variant="h4" mt={conv(45)} color="text.heading">
          {t("title_favorites")}
        </Typography>
        <Box mt={conv(26)} mb={conv(45)} mr={conv(24)}>
          <SearchInput disabled={!favMeta}
            onKeyUp={handleSearch}
            onClear={handleClear}
            placeholder={t("hint_search_by_name")}
          />
        </Box>

        {loading && !favList ? (
          <Box mt="50%" flex="1">
            <LoadingView />
          </Box>
        ) : (favList && favList.length === 0) || !favMeta ? (
          <Box mt="50%" flex="1">
            {query ? (
              <NoDataView
                text={t("no_data_search_main")}
                description={t("no_data_search_sub")}
                icon={<Search />}
              />
            ) : (
              <NoDataView
                text={t("no_data_favorite_main")}
                description={t("no_data_favorite_sub")}
                icon={<FavNoData />}
              />
            )}
          </Box>
        ) : (
          <Box>
            <InfinitePaginatedScroll
              totalRecords={favMeta.total_records}
              onNext={handleNext}
              height={`calc(100vh - ${window.conv(300)})`}
              loader={<CircularProgress show={listLoading} />}
            >
              {favList &&
                favList.map((favItem, i) => {
                  let isSelected = false;
                  if (
                    selectedFeed &&
                    favItem.favorite_type === FEEDTYPES.PORTFOLIO &&
                    selectedFeed.feed_id === favItem.id
                  ) {
                    isSelected = true;
                  } else if (
                    selectedFeed &&
                    favItem.favorite_type === FEEDTYPES.ACCOUNT &&
                    selectedFeed.feed_id === favItem.id &&
                    selectedFeed.portfolio_id === favItem.portfolio_id
                  ) {
                    isSelected = true;
                  }
                  return (
                    <FavoriteItem
                      key={favItem.id + " " + favItem.portfolio_id}
                      data={favItem}
                      selected={isSelected}
                      onClick={handleFavoriteClick}
                    />
                  );
                })}
            </InfinitePaginatedScroll>
          </Box>
        )}
      </Box>
      <DeleteModal />
    </Box>
  );
};

export default React.memo(FavoritesList);
