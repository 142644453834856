import React, { useCallback, useState, useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material";
import InfinitePaginatedScroll from "../Shared/InfinitePaginatedScroll";
import CircularProgress from "../Shared/CircularProgress";
import GUID from "../../Utils/Guid";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getImageUrl } from "../../Utils/ImageHelper";
import { accountActions } from "../../Services/Redux/actionCreators";
import { useTranslate } from "../../Hooks/useTranslate";
import NoDataView from "../NoDataView";
import LoadingView from "../LoadingView";
import MemberInfo from "../PortfolioFilters/AccountMember/MemberInfo";
import MemberDetail from "./MemberDetail";
import SidebarHeader from "../SidebarHeader";
import UIHelper from "../../Utils/UIHelper";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import { EVENT_TYPE, VIEW_EVENT_TYPE } from "../../Utils/Constants";
import { FEEDTYPES } from "../../Utils/Constants";
const conv = UIHelper.convertPxToRem;

function AccountTeam({ getValue, onChange }) {
  const dispatch = useDispatch();
  const t = useTranslate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const [page, setPage] = useState(1);
  const imageCookie = useSelector((state) => state.app.cloud_cookie);

  const memberData = useSelector((state) => state.account.team_list);
  const accData = useSelector((state) => state.account.data);
  let meta, members;
  if (memberData && memberData.items) {
    members = memberData.items;
    meta = memberData.meta;
  }

  const loadMembers = useCallback(
    async (id) => {
      setLoading(true);
      await dispatch(accountActions.getAccountTeamList(id, page));
      setLoading(false);
    },
    [page]
  );

  useEffect(() => {
    let name = "";
    let pf_name = "";
    let sfdc_id = "";
    name = accData?.name ? accData.name : "";
    pf_name = accData?.portfolio_name ? accData.portfolio_name : "";
    sfdc_id = accData?.external_account_id ? accData.external_account_id : "";
    Pinpoint.sendEventData(
      EVENT_TYPE.VIEW,
      [VIEW_EVENT_TYPE.ACCOUNT_TEAM_LIST],
      [name],
      [pf_name, sfdc_id]
    );
  }, [accData]);

  const handleMemberDetailClick = (data) => {
    if (data?.user?.user_id) setSelectedId(data.user.user_id);
   
  };
  const handleCloseDetail = () => {
    setSelectedId(null);
  };

  const handleNext = () => {
    if (meta?.page_count > page) setPage(page + 1);
  };

  useEffect(() => {
    if (params.id && (!meta || meta?.page_count > page)) {
      loadMembers(params.id);
    }
  }, [page, params.id]);

  if (loading) return <LoadingView mt="15%" />;
  if (!members || !meta || members.length === 0) {
    return <NoDataView />;
  }
  const accountTeam = [];
  const followingTeam = [];
  members &&
    members.forEach((element) => {
      if (element.is_follower) followingTeam.push(element);
      else accountTeam.push(element);
    });
  return (
    <Box display="flex" flex="1" flexDirection="column" p={conv(13, 28)}>
      {selectedId ? (
        <MemberDetail id={selectedId} onClose={handleCloseDetail} data={{source:"ACCOUNT_TEAM",name:accData?.name,portfolio_name:accData?.portfolio_name,sfdc_id:accData?.external_account_id}} />
      ) : (
        <>
          <SidebarHeader
            title={t("team")}
            rightComponent={
              <Typography variant="h4" color="text.neutral">
                {meta.total_records}
              </Typography>
            }
          />
          <InfinitePaginatedScroll
            totalRecords={meta.total_records}
            onNext={handleNext}
            height="calc(100vh - 140px)"
            padding={0}
            loader={<CircularProgress show={loading} />}
          >
            {accountTeam.length > 0 && (
              <Typography
                variant="h6"
                fontWeight={700}
                mt={conv(30)}
                mb={conv(22)}
              >
                {t("account_team")}
              </Typography>
            )}
            {accountTeam.map((item) => (
              <MemberInfo
                key={GUID()}
                onClick={handleMemberDetailClick}
                data={item}
                image={getImageUrl(item.user.avatar, imageCookie)}
                title={item.user.display_name}
                description={item.role.name}
              />
            ))}
            {followingTeam.length > 0 && (
              <Typography
                variant="h6"
                fontWeight={700}
                mt={conv(30)}
                mb={conv(22)}
              >
                {t("following")}
              </Typography>
            )}
            {followingTeam.map((item) => (
              <MemberInfo
                key={GUID()}
                onClick={handleMemberDetailClick}
                data={item}
                image={getImageUrl(item.user.avatar, imageCookie)}
                title={item.user.display_name}
                description={item.role.name}
              />
            ))}
          </InfinitePaginatedScroll>
        </>
      )}
    </Box>
  );
}

export default React.memo(AccountTeam);
