import React from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import AppLogo from "../AppLogo";
import Footer from "../Footer";
import UIHelper from "../../../Utils/UIHelper";
const conv=UIHelper.convertPxToRem;
const LoginContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, classes.background)}>
      <div className={classes.logoContainer}>
        <AppLogo width={conv(298)} />
      </div>
      <div className={classes.children}>{children}</div>
      <div className={classes.footerContainer}>
        <Footer />
      </div>
    </div>
  );
};

export default LoginContainer;
