import { convertLength } from "@mui/material/styles/cssUtils";
import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  container: {
    "& h6": {
      color: MUITheme.palette.text.secondary + " !important",
    },
  },
  shareItem: {
    border: "1px solid",
    borderColor: MUITheme.palette.text.lightGrey,
    borderRadius: "50%",
    cursor: "pointer",
    position: "relative",
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  copyLink: {
    fontSize: conv(20) + "!important",
    lineHeight: conv(35) + "!important",
    fontWeight: 500,
    color: MUITheme.palette.text.secondary,
  },
  buttonApply: {
    marginTop: conv(20)+" !important",
    fontWeight: 600,
    textTransform: "capitalize !important",
    borderRadius: conv(12) + " !important",
    paddingTop:conv(17)+" !important",
    paddingBottom:conv(17)+" !important"
  },
  linkBorder: {
    border: "1px solid " + MUITheme.palette.border.primary,
    borderRadius: conv(12),
  },
}));
