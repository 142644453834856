export const useStyles = (MUITheme) => ({
  styledTextField: {
    margin: window.conv(10,0) + " !important",

    "& div": {
      color: `${MUITheme.palette.text.primary} !important`,
      border: window.conv(1) + ` solid ${MUITheme.palette.border.primary}`,
      borderRadius: window.conv(12),
      "&:hover": {
        borderColor: MUITheme.palette.border.primary,
      },
    },
    "& .MuiInputBase-root": {
      background: MUITheme.palette.text.darkGrey,
      "&:hover": {
        background: MUITheme.palette.text.darkGrey,
      },
    },

    "& .MuiOutlinedInput-root": {
      fontSize: window.conv(12),
      borderRadius: window.conv(12),
      "& fieldset": {
        borderColor: MUITheme.palette.border.primary,
      },
      "&:hover fieldset": {
        borderColor: MUITheme.palette.border.primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: MUITheme.palette.border.primary,
      },
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      fontSize: window.conv(12),
    },
    "& .MuiFormHelperText-contained": { color: "rgba(0,0,0,.6)" },

    "& input": {
      color: MUITheme.palette.text.lightGrey,
      background: MUITheme.palette.text.darkGrey,
      padding: window.conv(18, 20),
      fontSize: window.conv(16),
      letterSpacing: 0.15,
      fontWeight: 400,
      borderRadius: window.conv(12),
      lineHeight: window.conv(24),
      "&:disabled": { color: "#999" },
      "&::placeholder": {
        color: MUITheme.palette.text.lighterGrey,
        marginLeft:window.conv(-3),
        
      },
    },
    "& .MuiFormLabel-root.Mui-disabled": { color: "#999" },
    "& .Mui-disabled": {
      "& fieldset": {
        borderColor: `${MUITheme.palette.border.secondary} !important`,
      },
    },
    "& .MuiInputLabel-root": {
      fontSize: window.conv(15),
      marginTop: window.conv(-3),      
      marginLeft:window.conv(3),
      fontWeight:400,
      transition:"all ease 0.2s",
      color: `${MUITheme.palette.text.lighterGrey} !important`
    },
    "& .MuiFormLabel-root.Mui-focused": {
      fontSize: window.conv(15),
      marginTop: window.conv(-3),
      marginLeft:window.conv(3),
      color: `${MUITheme.palette.text.lighterGrey} !important`,
    },
    "& .MuiInputAdornment-root": {
      border: "none",
    },
  },
});
