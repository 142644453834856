import { makeStyles } from "@mui/styles";
import Background from "../../Assets/images/mobile-bg.png";
export const useStyles = makeStyles(MUITheme => ({
  container: {
    backgroundColor: MUITheme.palette.background.mainContent,
    height: "100vh",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center top",
    display: "flex",
    flexDirection: "column",
  },

  logoContainer: {
    paddingTop: "50px",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  downloadBox: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  storeLogo: {
    // width: "100%",
    height:"60px"
  },
}));
