import React, { Suspense, lazy } from "react";
import {
  Navigate,
  BrowserRouter as ReactRouter,
  Route,
  Routes,
} from "react-router-dom";
import PageNotFound from "../Components/PageNotFound";
import { connect } from "react-redux";
// import { LinearProgress } from "@mui/material";
import SuspenseView from "../Components/SuspenseView";
import "animate.css";
import { setupRouteAuthentications } from "./Routes";
import { loginActions } from "../Services/Redux/actionCreators/userActions";
import PrivateRoute from "../Components/PrivateRoute";
import { FEEDTYPES } from "../Utils/Constants";
import AlertDialog from "../Components/Shared/CustomAlert";
import { withTranslation } from "react-i18next";
import {
  accountActions,
  appActions,
  portfolioActions,
} from "../Services/Redux/actionCreators";
import PortfolioAnalytics from "../Components/PortfolioAnalytics";
import AccountAnalytics from "../Components/AccountAnalytics";
import NoAnalytics from "../Pages/Dashboard/NoAnalytics";
import UserProfile from "../Pages/UserProfile";
import ActionDetail from "../Components/ActionList/ActionDetail";

const Branch = window.branch;
class GlobalRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav_path: null,
      loaded: false,
      show_error: false,
    };
  }

  readDeepLinkData() {
    const { dispatch } = this.props;
    // init Branch
    Branch.init(process.env.REACT_APP_BRANCH_IO_KEY, async (err, data) => {
      if (!err) {
        let parsed_data = data.data_parsed;
        await dispatch(loginActions.initializeApplication);
        if (!this.props.isLoggedIn) {
          this.setState({
            loaded: true,
            nav_path: data.data_parsed["~referring_link"],
          });
          return;
        }
        if (parsed_data && parsed_data.enterprise_id) {
          let nav_path;
          // parsed_data.enterprise_id="omnicell";
          // parsed_data.theme="LIGHT";
          if (parsed_data.enterprise_id !== this.props.enterprise_id) {
            this.setState({ loaded: true, show_error: true });
            return;
          }
          if (parsed_data.source_type === FEEDTYPES.PORTFOLIO) {
            nav_path = `/dashboard/portfolio/feed_id/${parsed_data.source_id}/${parsed_data.timestamp}`;
            dispatch(portfolioActions.setDeeplinkData(parsed_data));
          }
          if (parsed_data.source_type === FEEDTYPES.ACCOUNT) {
            nav_path = `/dashboard/account/feed_id/${parsed_data.source_id}/${parsed_data.timestamp}/${parsed_data.portfolio_id}`;
            dispatch(accountActions.setDeeplinkData(parsed_data));
          }
          if (parsed_data.theme === "DARK" || parsed_data.theme === "LIGHT")
            dispatch(appActions.setAppTheme(parsed_data.theme.toLowerCase()));

          if (nav_path) {
            this.setState({ nav_path });
          }
        }
      }
      this.setState({ loaded: true });
      console.log("BB BIO Parsed Data ", data.data_parsed);
    });
  }

  async componentDidMount() {
    const { dispatch } = this.props;

    const query = new URL(window.location.href);
    if (
      query?.search &&
      query.search.includes("_branch_match_id") &&
      query.search.includes("_branch_referrer")
    ) {
      this.readDeepLinkData();
    } else {
      await dispatch(loginActions.initializeApplication);
      this.setState({ loaded: true });
    }
  }

  handleCloseError() {
    this.setState({ show_error: false });
  }
  render() {
    if (!this.props.isInitialized || !this.state.loaded) {
      return <SuspenseView />;
    }

    const routes = setupRouteAuthentications();

    let loginPath = routes.LOGIN.path;
    if (this.state.nav_path) {
      loginPath = `${loginPath}?ref_link=${this.state.nav_path}`;
    }

    const { t } = this.props;
    return (
      <ReactRouter>
        <Suspense fallback={<SuspenseView />}>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to={
                    this.props.isLoggedIn
                      ? this.state.nav_path || routes.LANDING.path
                      : loginPath
                  }
                />
              }
            />
            {Object.values(routes).map((route) => {
              if (route.redirectTo) {
                return (
                  <Route
                    key={route.name}
                    path={route.path}
                    element={
                      <PrivateRoute
                        element={() => route.component}
                        isAllowed={route.isAllowed}
                        redirectTo={route.redirectTo}
                      />
                    }
                  >
                    {route.name === routes.LANDING.name && (
                      <>
                        <Route index element={<NoAnalytics />} />
                        <Route
                          path="portfolio/:feed_id/:id/:date"
                          element={<PortfolioAnalytics />}
                        />
                        <Route
                          path="account/:feed_id/:id/:date/:portfolio_id"
                          element={<AccountAnalytics />}
                        />
                        <Route path="profile" element={<UserProfile />} />
                        <Route path="action/:id" element={<ActionDetail />} />)
                      </>
                    )}
                  </Route>
                );
              }
              return (
                <Route
                  key={route.name}
                  path={route.path}
                  element={route.component}
                />
              );
            })}
            <Route path="*" element={<PageNotFound homePath="/" />} />
          </Routes>
          <AlertDialog
            open={this.state.show_error}
            onClose={this.handleCloseError.bind(this)}
            msg={t("deeplink_invalid_content")}
            title={t("deeplink_invalid_title")}
          />
        </Suspense>
      </ReactRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  isInitialized: state.user.isInitialized,
  isLoggedIn: state.user.isLoggedIn,
  enterprise_id: state.user.enterprise_id,
});

export default withTranslation()(connect(mapStateToProps)(GlobalRouter));
