import MobilePage from "./MobilePage";
let type = new window.MobileDetect(window.navigator.userAgent);

export const MobileView = ({ children }) => {
  let mobile = false;
  let mobileOS;
  if (type.os() === "iOS") {
    mobile = true;
    mobileOS = "iOS";
  } else if (type.os() === "AndroidOS") {
    mobile = true;
    mobileOS = "androidOS";
  }

  return mobile ? <MobilePage os={mobileOS} /> : children;
};

export default MobileView;
