import { makeStyles } from "@mui/styles";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((theme) => ({

  container: {
    "& .MuiPaper-root": {
      padding: conv(26,0)+" !important",
      borderRadius:conv(12),
      width: conv(400),
      backgroundColor: `${theme.palette.background.alertBoxBg} !important`,

      "& h2": {
        color: `${theme.palette.text.white} !important`,
        fontSize: conv(22),
        fontWeight: 500,
        textAlign:"center"
      },
      "& p": {
        color: `${theme.palette.text.lightGrey} !important`,
        fontSize: conv(16),
        lineHeight:conv(22),
        fontWeight: 400,
        textAlign:"center"
      },

      "& button": {
        color: `${theme.palette.text.white} !important`,
        // backgroundColor: theme.palette.background.grey,
        fontSize: "1.125rem !important",
        fontWeight: 500,
        // width: 200,
        // padding: conv(16,0)+" !important",
      },
    },
  },
  content: {
    color: `${theme.palette.text.red} !important`,
    fontSize: conv(16) + "!important",
    fontWeight: 400,
    textAlign:"center"
  },
}));
