import React, { useEffect, useState } from "react";
import Slider, { Range, Handle } from "rc-slider";
import "rc-slider/assets/index.css";
import { useStyles } from "../styles";
import { Stack, Typography, Box } from "@mui/material";
import TextField from "./NumberInput";
import Dragger from "./Dragger";
import UIHelper from "../../../Utils/UIHelper";
import { useTheme } from "@mui/styles";
const conv=UIHelper.convertPxToRem;

const Rangebar = ({ min, max, value, label, onChange }) => {
  const theme=useTheme();
  const classes = useStyles();
  const onLowerBoundChange = (e) => {
    if (value.length === 2) handleChange([+e.target.value, value[1]]);
  };

  const onUpperBoundChange = (e) => {
    if (value.length === 2) handleChange([value[0], +e.target.value]);
  };

  const onSliderChange = (data) => {
    handleChange(data);
  };

  const handleChange = (data) => {
    onChange && onChange(data);
  };

  return (
    <div style={{ marginTop:conv(-20),paddingBottom:conv(16) }} className={classes.slider}>
      <Stack direction="row" mb={conv(16)} display="flex" alignItems="center">
        <Typography component="p" p={conv(10)} color="text.grey_chateau">
          {label}
        </Typography>
        <TextField
          defaultValue={min}
          value={value[0]}
          onChange={onLowerBoundChange}
          minWidth={conv(50)}
        />
        <Typography component="p" p={conv(10)} width={conv(60)} color="text.grey_chateau">
         - {label}
        </Typography>
        <TextField
          defaultValue={max}
          value={value[1]}
          onChange={onUpperBoundChange}
          minWidth={conv(50)}
        />
      </Stack>
      <Box pl={conv(15)} pr={conv(15)} >
        <Range
          allowCross={false}
          value={value}
          min={Math.round(min)}
          max={Math.round(max)}
          onChange={onSliderChange}
          railStyle={{ backgroundColor: "#454E56", height: conv(2) }}
          handle={(handleProps) => {
            return (
              <Handle
                {...handleProps}
                style={{
                  background: "none",
                  border:"none",
                  marginTop: conv(-12),
                  marginLeft: conv(-5),
                  opacity: 1,
                }}
              >
                <Dragger fill={theme.palette.background.mainContent} stroke={theme.palette.text.heading}/>
              </Handle>
            );
          }}
        />
      </Box>
    </div>
  );
};

export default Rangebar;
