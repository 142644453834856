import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { KPI_CONDITION_FLAGS } from "../../../Utils/Constants";
import clsx from "clsx";
import { useStyles } from "../styles";
import { useTranslate } from "../../../Hooks/useTranslate";
import { useResizeDetector } from "react-resize-detector";
import { useWindowResize } from "../../../Hooks/useWindowResize";
import Helpers from "../../../Utils/Helper";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

const KPIDrillDownView = (props) => {
  const classes = useStyles();
  const containerRef = useRef();
  const tooltipRef = useRef();
  const [data, setData] = useState();
  const [valid, setValid] = useState(false);
  const resizing = useWindowResize();
  const t = useTranslate();
  if (!props.data) return;

  useEffect(() => {
    let data = props.data;
    let title = "";
    if (data.name) title = data.name;
    if (data.measurement_unit) {
      title += ` (${data.measurement_unit})`;
    }
    let bgColor1, bgColor2;
    let textColor = classes.greenText;
    if (data.condition_flag.toLowerCase() === KPI_CONDITION_FLAGS.DIRECT) {
      bgColor1 = classes.redBackground;
      bgColor2 = classes.greenBackground;
      if (data.threshold_value > data.actual_value) {
        textColor = classes.redText;
      } else textColor = classes.greenText;
    }
    if (data.condition_flag.toLowerCase() === KPI_CONDITION_FLAGS.INVERSE) {
      bgColor1 = classes.greenBackground;
      bgColor2 = classes.redBackground;

      if (data.threshold_value > data.actual_value) {
        textColor = classes.greenText;
      } else textColor = classes.redText;
     
    }
    if (!data.actual_type) {
      textColor = classes.greyText;
    }
    let upper_bound = data.upper_bound || 0;
    let lower_bound = data.lower_bound || 0;
    let threshold_value = data.threshold_value || 0;
    let actual_value = data.actual_value || 0;

    let difference = upper_bound - lower_bound;
    let progressBarWidth = containerRef?.current?.clientWidth;
    let leftBarWidth = "50%";
    let rightBarWidth = "50%";

    let dotPosition = 0;
    if (
      difference > 0 &&
      upper_bound >= threshold_value &&
      upper_bound >= actual_value &&
      lower_bound <= actual_value
    ) {
      leftBarWidth = (threshold_value / upper_bound) * progressBarWidth;
      rightBarWidth = progressBarWidth - leftBarWidth;

      leftBarWidth = `${(leftBarWidth / progressBarWidth) * 100}%`;
      rightBarWidth = `${(rightBarWidth / progressBarWidth) * 100}%`;
      dotPosition =
        (actual_value - lower_bound) * (progressBarWidth / difference);
      setValid(true);
    } else {
      dotPosition = progressBarWidth / 2;
      bgColor1 = classes.grayBackground;
      bgColor2 = classes.grayBackground;
      textColor = classes.greyText;
     
      setValid(false);
    }

    if (lower_bound === threshold_value) {
      rightBarWidth="100%";
      leftBarWidth = 0;
    } else if (upper_bound === threshold_value) {
      rightBarWidth = 0;
      leftBarWidth = "100%";
    }
    if (dotPosition > progressBarWidth - 6) {
      dotPosition = progressBarWidth - 6;
    }
    let result = {
      ...data,
      title,
      bgColor1,
      bgColor2,
      dotPosition,
      leftBarWidth,
      rightBarWidth,
      upper_bound: Helpers.FormatKPIValue(upper_bound),
      lower_bound: Helpers.FormatKPIValue(lower_bound),
      threshold_value: Helpers.FormatKPIValue(threshold_value),
      actual_value: Helpers.FormatKPIValue(actual_value),
      textColor,
    };
    setData(result);
  }, [props.data, resizing, containerRef, tooltipRef]);

  useEffect(() => {
    if (tooltipRef && containerRef && data) {
      let totalWidth = containerRef?.current?.offsetWidth;
      if (tooltipRef?.current) {
        let tooltip = tooltipRef.current;
        let tooltipWidth = tooltip.offsetWidth;
        let dotPosition = data.dotPosition;
        let halfWidth = tooltipWidth / 2;
        let left = dotPosition - halfWidth;

        if (dotPosition < halfWidth) {
          left = 0;
        } else if (dotPosition > totalWidth - halfWidth) {
          left = totalWidth - tooltipWidth;
        }
        if(data?.rightBarWidth!=0)
        {
          let ub=props.data.upper_bound;
          let av=props.data.actual_value;
          if(ub===av)
          {
            left=totalWidth-tooltipWidth-2;
          }
          // if(Math.round(ub)===Math.round(av)&&(ub-av>0))
          // {
          //   left=totalWidth-tooltipWidth-2;
          // }
        }

        tooltipRef.current.style.left = `${left}px`;
      }
    }
  }, [data, tooltipRef, containerRef]);

  return (
    <>
      <div className={classes.drilldownBoxContainer}>
        {data && (
          <Typography
            variant="h6"
            pl="0px"
            lineHeight="1.32rem"
            color="text.neutral"
          >
            {data.title}
          </Typography>
        )}
        <div className={classes.drilldownBox}>
          <div
            style={{
              position: "relative",
              marginLeft: conv(44),
              marginRight: conv(44),
            }}
          >
            <div className={classes.tooltipTextBox} ref={tooltipRef}>
              {data && (
                <Typography
                  variant="p"
                  textOverflow={"no-wrap"}
                  className={clsx(
                    classes.rounded_label,
                    // data.textColor,
                    (!valid||!data.actual_type) && classes.greyText
                  )}
                >
                  <b className={data.textColor}>{data.actual_value}</b> /{" "}
                  {data.actual_type ? data.actual_type : t("none")}
                </Typography>
              )}
            </div>
          </div>
          <Box mt="15px" display="flex" flexDirection="row">
            <div className={classes.boundValue}>
              <Typography
                component="p"
                pr={conv(8)}
                textAlign={"right"}
                className={classes.neutral_label}
              >
                {data?.lower_bound}
              </Typography>
            </div>
            <div className={classes.barContainer} ref={containerRef}>
              <div className="tooltipContainer">
                <div
                  className="tooltipBox"
                  style={{ left: data?.dotPosition }}
                ></div>
              </div>
              <div
                className={clsx(
                  classes.metricsBar,
                  classes.leftBar,
                  data?.bgColor1,
                  data?.rightBarWidth === 0 && classes.border
                )}
                style={{ width: data?.leftBarWidth }}
              ></div>
              <div
                className={clsx(
                  classes.metricsBar,
                  data?.bgColor2,
                  classes.rightBar,
                  data?.leftBarWidth === 0 && classes.border
                )}
                style={{ width: data?.rightBarWidth }}
              >
                <div className={classes.tooltipBottomText}>
                  <Typography
                    variant="p"
                    // fontSize={"1rem"}
                    className={classes.neutral_label}
                  >
                    {data?.threshold_value}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={clsx(classes.boundValue, classes.textRight)}>
              <Typography
                component="p"
                textAlign={"left"}
                pl={conv(5)}
                className={classes.neutral_label}
              >
                {data?.upper_bound}
              </Typography>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};
export default KPIDrillDownView;
