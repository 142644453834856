import React from "react";
import { Box, Typography } from "@mui/material";
import GUID from "../../../Utils/Guid";
import Avatar from "@mui/material/Avatar";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

function MemberInfo({ image, title, description, addOn, onClick, data }) {
  const handleClick = () => {
    onClick && onClick(data);
  };
  return (
    <Box
      display="flex"
      key={GUID()}
      mb={conv(20)}
      sx={{ "&:hover": { cursor: "pointer" } }}
      alignItems={"center"}
      justifyContent="space-between"
      onClick={handleClick}
    >
      <Box width={conv(80)}>
        {" "}
        <Avatar
          sx={{ height: conv(59), width: conv(59), border: "none" }}
          alt={title}
          src={image}
        />
      </Box>

      <Box flex="1">
        <Typography variant="h6" color="text.neutral" fontSize={conv(14.77)} fontWeight={400} lineHeight={conv(22)}>
          {description}
        </Typography>
        <Typography variant="h6" fontSize={conv(14.77)} fontWeight={700} lineHeight={conv(22)}>
          {title}
        </Typography>
      </Box>
      <Box width={conv(50)} textAlign="right">
        {addOn}
      </Box>
    </Box>
  );
}

export default React.memo(MemberInfo);
