import * as React from "react";
import { Stack, Box } from "@mui/material";
import PropTypes from "prop-types";
import ProgressBar from "../../Shared/ProgressBar";
import { COLOR_CODES } from "../../../Utils/Constants";

const JTPBars = (props) => {
  const { data, trackColor, readOnly=false, onClick, value,lg=false } = props;

  const handleClick = (id, value) => {
    onClick && onClick(id, value);
  };

  return (
    <Stack
      flex={1}
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      mt="5px"
    >
      {data &&
        data.map((item) => {
          let barValue =
            item.sat_score_value < 0 ? 0 : (item.sat_score_value + 1) * 33.3; //Converting [0,1,2,3] to percentage 25%,50%,75%,100% respectively,
          let clickable = value === item.id ? true : item.clickable;

          if (item.color === COLOR_CODES.GREY || item.sat_score_value < 0) {
            barValue = 0;
            // clickable = false;
          }
          return (
            <ProgressBar
              key={item.id}
              id={item.id}
              label={item.name}
              value={barValue}
              lg={lg}
              onClick={(id) => !readOnly && handleClick(id, barValue)}
              clickable={clickable}
              readOnly
              canClick={readOnly ? item.clickable : true}
              color={
                value ? value === item.id && item.color_code : item.color_code
              }
              selected={clickable}
              trackColor={trackColor}
            />
          );
        })}
    </Stack>
  );
};

JTPBars.propTypes = {
  data: PropTypes.array,
  readOnly: PropTypes.bool,
  trackColor: PropTypes.string,
};

JTPBars.defaultProps = {
  data: [],
  readOnly: true,
  trackColor: "background.mainContent",
};
export default JTPBars;


