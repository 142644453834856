import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((MUITheme) => ({
  slider: {
    "& .rc-slider-track": {
      backgroundColor: MUITheme.palette.text.grey_chateau,
      height: "2px !important",
    },
    "& .rc-slider-rail": {
      backgroundColor: "#454E56 !important",
    },
  },
  box_header: {
    display: "flex",
    flex: "1",
    marginTop: "10px !important",
    marginBottom: "10px !important",
  },
  buttonClear: {
    // marginTop: "20px",
    padding: "1rem 0px !important",
    lineHeight: "2.3rem !important",
    fontSize: "1rem !important",
    fontWeight: 500 + "!important",
    cursor: "pointer",
    color: `${MUITheme.palette.text.secondary} !important`,
    textAlign: "left",
    justifyContent: "start !important",
  },
  buttonApply: {
    marginTop: 15,
    lineHeight: "2.3rem !important",
    fontSize: "1rem !important",
    textAlign: "right",
  },
}));
