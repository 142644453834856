import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  box: {
    backgroundColor: MUITheme.palette.background.listItem,
    flex: 1,
    borderRadius: window.conv(20),
    padding: window.conv(20),
    marginTop:window.conv(14),
    marginRight:window.conv(21),
    cursor: "pointer",
    display: "flex",
    position: "relative",
  },
  selectedBox: {
    backgroundColor: MUITheme.palette.background.listItemSelected,
  },
  menuBox: {
    "& .MuiPaper-root": {
      backgroundColor: MUITheme.palette.background.secondary + " !important",
      borderRadius: window.conv(20),
      minWidth:window.conv(237)
    },
    "& .MuiList-root": {
      padding: window.conv(24,28)+" !important",
    },
    "& .MuiMenuItem-root":{
      paddingLeft:0,
      paddingRight:0,
      paddingTop:"5px"
    }
  },
  autoHeight: {
    height: `calc(100vh - ${conv(185)})`,
  },
  optionButton: {
    position: "absolute",
    borderRadius: "50%",
    height: window.conv(40),
    width: window.conv(40),
    textAlign: "center",
    verticalAlign: "middle",
    // display: "none",
    right: window.conv(30),
    color: MUITheme.palette.text.secondary,
    background: MUITheme.palette.background.secondary,
    filter: "drop-shadow(0.25rem 0.25rem 1.4rem rgba(0, 0, 0, 1))",
    "& svg": { marginTop: window.conv(8) },
  },
  commentBox:{
    color: "#ffffff",
    fontWeight: 300,
    fontSize: "1.125rem",
    lineHeight: "27px",
  }
}));
