import React from "react";
import { useStyles } from "./styles";
import { useTranslate } from "../../Hooks/useTranslate";
import AppLogo from "../Shared/AppLogo";
import DownloadButton from "./DownloadButton";
import { Stack, Typography } from "@mui/material";

const LoginContainer = ({ os }) => {
  const classes = useStyles();
  const t = useTranslate();
  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <AppLogo width="180" />
      </div>
      <div className={classes.downloadBox}>
        <Stack
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4" align="center">
            <Typography className="dot"></Typography> {t("you_are_mobile_text")}
          </Typography>
          <Typography variant="subtitle2" align="center">
            {t("download_our_app")}
          </Typography>
          <DownloadButton os={os} />
        </Stack>
      </div>
    </div>
  );
};

export default LoginContainer;
