import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Box, Typography, Stack } from "@mui/material";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
import PropTypes from "prop-types";

import { useStyles } from "./styles";

const AlertDialog = ({ open, title, msg, onClose,onConfirm, buttonText = "OK" }) => {
  const classes = useStyles();
  const handleConfirm=()=>{
    onConfirm&&onConfirm();
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose} className={classes.container}>  
      <Stack display="flex" alignItems="center" justifyContent="center" padding={conv(0,20)}>
        <Typography variant="h4" mt="1rem">
          {title}
        </Typography>
        <br/>
        <Typography variant="neutral" textAlign={"center"}>
          {msg}
        </Typography>
      </Stack>
      <DialogActions
        className={classes.content}
        style={{ justifyContent: "center" }}
      >
        <Button onClick={handleConfirm}>{buttonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
export default AlertDialog;
