import { feedActions } from "../actionCreators";

const initialState = {
  feeds: {},
  meta: null,
  share_link: null,
};

const feedReducer = (state = initialState, action) => {
  switch (action.type) {
  case feedActions.SET_FEEDS: {
    return {
      ...state,
      feeds: {
        ...state.feeds,
        [action.payload.filter]: {
          list: action.payload.feeds.items,
          meta: action.payload.feeds.meta
        },
      },
    };
  }
  case feedActions.APPEND_FEEDS: {
    return {
      ...state,
      feeds: {
        ...state.feeds,
        [action.payload.filter]: {
          list: [
            ...state.feeds[action.payload.filter].list,
            ...action.payload.feeds.items,
          
          ],
          meta: action.payload.feeds.meta
        },
      },
    };
  }
  case feedActions.SET_FEEDCARD_LOADED: {
    const { filter, feed_id } = action.payload;
    const list = [...state.feeds[filter].list];
    let ind = list.findIndex((item) => item.feed_id == feed_id);
   
    if (ind > -1) {
      list[ind] = { ...list[ind], loaded: true };
    }
    return {
      ...state,
      feeds: {
        ...state.feeds,
        [filter]: {
          ...state.feeds[filter],
          list: list,
        },
      },
    };
  }

  case feedActions.CLEAR_FEEDS: {
    return { ...state, ...initialState };
  }
  case feedActions.SELECT_FEED: {
    return { ...state, selectedFeed: action.payload };
  }
  case feedActions.GENERATE_SHARE_LINK: {
    return { ...state, share_link: action.payload };
  }
  case feedActions.CLEAR_FEED_DATA:
    return initialState;
  default:
    return state;
  }
};

export default feedReducer;
