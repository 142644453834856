import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { useStyles } from "./styles";

const Loader = ({ show,size=40 }) => {
  const classes = useStyles();
  if (!show) return;
  return (
    <div className={classes.circularProgressContainer}>
      <CircularProgress  className={classes.circularProgress} size={size} thickness={2} color="success" variant="indeterminate" />
    </div>
  );
};

Loader.propTypes = {
  show: PropTypes.bool,
};

Loader.defaultProps = {
  show: false,
};
export default Loader;
