//https://mui.com/material-ui/react-avatar/
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";

const UserGroup = ({ size = 4, total, data, onClick, style }) => {
  const classes = useStyles();
  const is_enabled = useSelector(
    (state) => state.app.mapping_data.field_mapping?.account_team?.is_enabled
  );
  if (!is_enabled) return;
  return (
    <AvatarGroup
      total={total}
      size={size}
      className={classes.root}
      onClick={onClick}
    >
      {data &&
        data.map((avatar, i) => (
          <Avatar
            key={i}
            sx={{ height: "40px", width: "40px", border: "none", ...style }}
            alt={avatar.name}
            src={avatar.src}
          />
        ))}
    </AvatarGroup>
  );
};
export default UserGroup;

UserGroup.propTypes = {
  size: PropTypes.number,
  total: PropTypes.number,
  data: PropTypes.array,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

UserGroup.defaultProps = {
  size: 3,
  total: 3,
  data: [],
  onClick: () => {},
};
