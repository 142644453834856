import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { appActions, userActions } from "../../Services/Redux/actionCreators";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../Hooks/useTranslate";
import { useStyles } from "./styles";
import Footer from "../../Components/Shared/Footer";
import MoonIcon from "../../Assets/images/sun-light.png";
import SunIcon from "../../Assets/images/sun.png";
import LogOutIcon from "../../Assets/images/logout.png";
import Switch from "../../Components/Shared/Switch";
import clsx from "clsx";
import { localStorageKeys, useLocalStorage } from "../../Hooks/useLocalStorage";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

const ProfileSetting = (props) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const navigate = useNavigate();

  const classes = useStyles();

  const handleLogout = async() => {
    props?.onLogout();
    await dispatch(userActions.loginActions.signout());
    dispatch(appActions.clearReduxData());
    dispatch(appActions.clearAllData());
    navigate("/login");
  };

  const [theme, setAppTheme] = useLocalStorage(
    localStorageKeys.APP_THEME,
    "dark"
  );
  useEffect(() => {
    dispatch(appActions.setAppTheme(theme));
  }, [dispatch, theme]);

  const handleThemeChange = () => {
    setAppTheme(theme === "light" ? "dark" : "light");
    props?.onThemeChanged();
  };

  return (
    <Box display="flex" flex={1} flexDirection={"column-reverse"}>
      <Box
        height={conv(270)}
        padding={conv(47)}
        paddingBottom={conv(55)}
        justifyContent={"center"}
        display="flex"
        flexDirection={"column"}
      >
        <Box className={classes.outlinedBox}>
          <Box width="auto" height={conv(24)}>
            <img style={{marginTop:conv(-3)}}
              src={theme === "light" ?  MoonIcon:SunIcon }
              alt="change-theme"
            />
          </Box>
          <Box flex="1">
            <Typography
              variant="h6"
              fontWeight={500}
              color="text.secondary"
              alignItems={"center"}
            >
              {t("light_mode") }
              {/* {theme === "light" ?  t("dark_mode"):t("light_mode") } */}
            </Typography>
          </Box>
          <Box width={conv(60)} height={conv(28)} mt={"-3px"} mr={conv(20)}>
            <Switch onChange={handleThemeChange} checked={theme === "light"} />
          </Box>
        </Box>
        <Box
          className={clsx(classes.outlinedBox, classes.pointer)}
          onClick={handleLogout}
        >
          {" "}
          <img src={LogOutIcon} alt="logout" />
          <Typography variant="h6" color="text.secondary" alignItems={"center"}  fontWeight={500}>
            {t("logout")}
          </Typography>
        </Box>
        <Box mt={conv(111)} mb={conv(30)}>
          <Footer forProfile />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileSetting;
