export const initializeAPIOptions = (
  token,
  enterprise_id,
  body,
  queryStringParameters,
  additionalHeaders
) => {
  const options = {
    headers: {
      Authorization: token,
      enterprise_id: enterprise_id,
      ...additionalHeaders,
    },
  };
  if (body) {
    options.body = body;
  }
  if (queryStringParameters) {
    options.queryStringParameters = queryStringParameters;
  }
  return options;
};
