import React from "react";
const NoData = ({ fill="#182027", stroke }) => {
  return (
    <svg
      width={window.conv(130)}
      height={window.conv(130)}
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        // opacity="0.25"
        cx="65"
        cy="65"
        r="65"
        fill="rgba(156, 172, 196, 0.2)"
      />
      <path d="M78 76.5664H36V77.9997H78V76.5664Z" fill="#4B545B" />
      <path
        d="M48.2 75.1331H55.2C55.5866 75.1331 55.9 74.8123 55.9 74.4165V61.5165C55.9 61.1207 55.5866 60.7998 55.2 60.7998H48.2C47.8134 60.7998 47.5 61.1207 47.5 61.5165V74.4165C47.5 74.8123 47.8134 75.1331 48.2 75.1331ZM48.9 62.2331H54.5V73.6998H48.9V62.2331Z"
        fill="#4B545B"
      />
      <path
        d="M58.3992 75.1335H65.3992C65.7858 75.1335 66.0992 74.8126 66.0992 74.4169V52.9169C66.0992 52.5211 65.7858 52.2002 65.3992 52.2002H58.3992C58.0126 52.2002 57.6992 52.5211 57.6992 52.9169V74.4169C57.6992 74.8126 58.0126 75.1335 58.3992 75.1335ZM59.0992 53.6335H64.6992V73.7002H59.0992V53.6335Z"
        fill="#4B545B"
      />
      <path
        d="M38.7996 75.1329H45.7996C46.1862 75.1329 46.4996 74.8121 46.4996 74.4163V44.3163C46.4996 43.9205 46.1862 43.5996 45.7996 43.5996H38.7996C38.413 43.5996 38.0996 43.9205 38.0996 44.3163V74.4163C38.0996 74.8121 38.413 75.1329 38.7996 75.1329ZM39.4996 45.0329H45.0996V73.6996H39.4996V45.0329Z"
        fill="#4B545B"
      />
      <path
        d="M68.6667 75H75.3333C75.7015 75 76 74.5059 76 73.8965V45.1035C76 44.4941 75.7015 44 75.3333 44H68.6667C68.2985 44 68 44.4941 68 45.1035V73.8965C68 74.5059 68.2985 75 68.6667 75ZM69.3333 46.207H74.6667V72.793H69.3333V46.207Z"
        fill="#4B545B"
      />
      {/* <circle
        cx="78"
        cy="73"
        r="13"
        transform="rotate(-180 78 73)"
        fill="#171F26"
      /> */}
      <circle cx="78" cy="73" r="13" fill={fill} />
      <path
        d="M91.7625 73C91.7625 80.5884 85.5884 86.7625 78 86.7625C70.4116 86.7625 64.2375 80.5884 64.2375 73C64.2375 65.4116 70.4117 59.2375 78 59.2375C85.5884 59.2375 91.7625 65.4116 91.7625 73ZM66.2625 73C66.2625 79.4939 71.5061 84.7375 78 84.7375C84.4939 84.7375 89.7375 79.4939 89.7375 73C89.7375 66.5061 84.4939 61.2625 78 61.2625C71.5061 61.2625 66.2625 66.5061 66.2625 73ZM76.9875 77.3L79.0125 77.3L79.0125 79.325L76.9875 79.325L76.9875 77.3ZM76.9875 66.675L79.0125 66.675L79.0125 75.075L76.9875 75.075L76.9875 66.675Z"
        fill="#515861"
        stroke="#171F26"
        strokeWidth="0.1"
      />
    </svg>
  );
};
export default NoData;
