import React, { useEffect } from "react";
import AnalyticsIcon from "../../Assets/images/no-analytics.png";
import { Stack, Typography, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { feedActions } from "../../Services/Redux/actionCreators";
import NPSGraph from "../../Components/NPSGraph"
import { useTranslate } from "../../Hooks/useTranslate";

const NoAnalytics = () => {
  const dispatch = useDispatch();
  const t=useTranslate();
  useEffect(() => {
    dispatch(feedActions.setSelectedFeed(null));
  }, []);
  return (
    <div style={{display:"flex",flex:1 }}>
      <Stack
        flex="1"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <img src={AnalyticsIcon} style={{width:"5.125rem",height:"7.187rem"}} alt="no analytics" />
        <Typography variant="h4" align="center" mt="10">
          {t("what_analytics_would_you_like_to_see")}
        </Typography>
        <Typography variant="neutral">
          {t("please_select_an_item_from_left_menu")}
        </Typography>
        {/* <NPSGraph/> */}
      </Stack>
    </div>
  );
};

export default NoAnalytics;
