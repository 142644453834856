import { API } from "aws-amplify";
import { loaderActions } from ".";
import { fetchAuthenticatedUser } from "./userActions/loginActions";
import { APIEndpoints, APIPaths } from "../../AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../Utils/API";
import { performanceActions } from ".";



export const getActionDetail = (id) => async dispatch => {
  try {
    let start=new Date();
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.ALERTS.name;
    const apiPath = APIPaths.ACTION_DETAIL(id);
    const apiOptions = initializeAPIOptions(token, enterprise_id);
    let apiResult = await API.get(apiName, apiPath, apiOptions).catch(err=>{

    });
    dispatch(
      performanceActions.log({
        name: "ACTIONS DETAIL",
        api: "ACTION DETAIL",
        start,
        apiName,
        apiPath,
        payload: { id},
      })
    );
    return apiResult;
  } catch (e) {
    throw e;
  }
};
