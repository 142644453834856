export const LoaderContent = {
  LOGIN: {
    title: "Logging in",
    content: "Just a moment. We are logging you in",
  },
  SIGN_OUT: {
    title: "Signing out",
    content: "Just a moment. We are signing you out",
  },
  FORGOT_PASSWORD: {
    title: "Forgot Password",
    content: "Sending you an email with the verification code",
  },
  LOADING: {
    title: "Loading",
    content: "Please wait while we are loading content...",
  },
  FORGOT_PASSWORD_SUBMIT: {
    title: "Please wait...",
    content: "Resetting your password",
  },
};
