import { useTransition, useSpring, animated } from "react-spring";
import ANIMATION_TYPES from "./AnimationTypes";
const Animation = ({
  trigger,
  type = ANIMATION_TYPES.TRANSITION,
  config,
  ...props
}) => {
  if (type === ANIMATION_TYPES.TRANSITION) {
    const transition = useTransition(trigger, config);
    return (
      <div>
        {transition((style, item) =>
          item ? (
            <animated.div style={style}>{props.children}</animated.div>
          ) : (
            ""
          )
        )}
      </div>
    );
  } else if (type === ANIMATION_TYPES.SPRING) {
    const style = useSpring(config);
    return (
      <animated.div
        style={style}
      >
        {props.children}
      </animated.div>
    );
  }
  return props.children;
};
export default Animation;
