import Animation from "./Animation";
import ANIMATION_TYPES from "./AnimationTypes";
const DetailAnimation = ({
  value,
  trigger,
  stopAnimation,
  ...props
}) => {
  let config = {
    from: { y: -(value ), opacity: 0 },
    to: {
      y: trigger ? -(value ) : 0,
      opacity: trigger ? 0 : 1,
    },
    config: { tension: 280, friction: 60 },
  };
  if (stopAnimation) {
    config = {
      from: { y: 0, opacity: 0 },
      to: {
        y: -(value ) ,
        opacity: trigger ? 0 : 1,
      },
      config: { tension: 600, friction: 60,duration:1 },
    };
  }

  return (
    <Animation
      type={ANIMATION_TYPES.SPRING}
      config={config}
      trigger={trigger}
      {...props}
    />
  );
};
export default DetailAnimation;
