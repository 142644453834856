import React from "react";
import CircularProgress from "../Shared/CircularProgress";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslate } from "../../Hooks/useTranslate";

const LoadingView = ({ text, description, mt = "20px" }) => {
  const t = useTranslate();
  if (!text) text = t("loading");
  if (!description) description = t("one_moment");
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      flex="1"
      alignItems="center"
      justifyContent="center"
      textAlign={"center"}
      mt={mt}
    >
      <Box
        display="flex"
        height="8.125rem"
        width="8.125rem"
        alignItems={"center"}
        justifyContent="center"
        borderRadius={"50%"}
        bgcolor="background.loaderBg"
      >
        <CircularProgress show size={60} />
      </Box>
      <Typography variant="h4" mt="1rem">
        {text}
      </Typography>
      <Typography variant="neutral">{description}</Typography>
    </Box>
  );
};

export default LoadingView;
