import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import LoadingView from "../LoadingView";
import { useStyles } from "./styles";
import {
  accountActions,
  feedActions,
  appActions,
  scoreCardActions,
} from "../../Services/Redux/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import AmountLabel from "../AmountLabel";
import {
  CURRENCIES_SYMBOLS,
  FEEDTYPES,
  RIGHTMENUS,
} from "../../Utils/Constants";
import Helpers from "../../Utils/Helper";
import AccountDetail from "./AccountDetail";
import ScoreCard from "../ScoreCard";
import Animator from "../Animator";
import UserGroup from "../UserGroup";
import { useTranslate } from "../../Hooks/useTranslate";
import { getImageUrl } from "../../Utils/ImageHelper";
import SFDCLabel from "./SFDCLabel";
import ProductLine from "./ProductLine";
import CustomerMarketFactors from "./CustomerMarketFactors";
import NoDataView from "../NoDataView";
import AccountJourney from "../CustomerJourney/AccountJourney";
import KPIDrillDownOperations from "../KPIDrillDownOperations";
import DetailAnimation from "../CustomAnimations/DetailAnimation";
import DetailAnimation2 from "../CustomAnimations/DetailAnimation2";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import { EVENT_TYPE, VIEW_EVENT_TYPE } from "../../Utils/Constants";
import CustomerJourneyAnimation from "../CustomAnimations/CustomerJourneyAnimation";
import UIHelper from "../../Utils/UIHelper";
import { useRef } from "react";
const conv = UIHelper.convertPxToRem;

const AccountAnalytics = () => {
  const classes = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const accDetailRef = useRef();
  const topRef = useRef();
  const divRef = useRef();
  //animation states
  const [start, setStart] = useState(false);
  let jtp = useSelector((state) => state.account.selected_jtp);
  const params = useParams();
  const location = useLocation();
  const imageCookie = useSelector((state) => state.app.cloud_cookie);
  const [jtpID, setJtpID] = useState();
  const [opDriverId, setOpDriverId] = useState();
  const [tempJTPData, setTempJTPData] = useState(null);
  const [kpiId, setKPIId] = useState();
  let kpi_metrics = useSelector((state) => state.account.kpi_metrics);
  let driver = useSelector((state) => state.account.selected_op_driver);
  let accountData = useSelector((state) => state.account.data);
  let jtpData = useSelector((state) => state.account.jtpData);
  let team = useSelector((state) => state.account.team);
  let footer_menu = useSelector((state) => state.app.footer_menu);
  let operationalDrivers = useSelector(
    (state) => state.account.operationalDrivers
  );
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  const deepLinkData = useSelector((state) => state.account.link_data);
  const loadingKPI = useSelector((state) => state.account.loading_kpi);

  const loadValueData = useCallback(async () => {
    const { id, date, portfolio_id } = params;
    if (!id) return;
    let timestamp = null;
    if (date && !(date === "date")) {
      timestamp = date;
    }
    setLoading(true);
    setLoaded(false);
    resetAnimState();
    setTempJTPData(null);
    dispatch(accountActions.clearAllData());
    dispatch(scoreCardActions.clearAllData());
    dispatch(accountActions.setLoaded(false));

    await Promise.allSettled([
      dispatch(accountActions.getAccountData(id, timestamp, portfolio_id)),
      dispatch(accountActions.getAccountJTP(id, timestamp, portfolio_id)),
      dispatch(accountActions.getAccountTeam(id)),
    ]);
    Promise.allSettled([
      dispatch(accountActions.getIsAlertConfigured(id)),
      dispatch(accountActions.getActionList(id)),
      dispatch(accountActions.analyticsReactions(id, FEEDTYPES.ACCOUNT)),
      dispatch(
        accountActions.analyticsStatus(id, FEEDTYPES.ACCOUNT, portfolio_id)
      ),
    ]);
    dispatch(accountActions.setLoaded(true));
    if (location?.state) {
      const { jtp, kpi_corelation_id, kpi_metric_id } = location.state;
      setTempJTPData({ jtp_id: jtp?.id, kpi_corelation_id, kpi_metric_id });
    }
    if (deepLinkData) {
      dispatch(
        feedActions.setSelectedFeed({
          feed_id: null,
          feed_type: FEEDTYPES.ACCOUNT,
          source_id: deepLinkData.source_id,
          source_name: deepLinkData.source_name,
          portfolio_id: deepLinkData.portfolio_id,
        })
      );

      dispatch(accountActions.setTimestamp(deepLinkData.timestamp));

      setTempJTPData({
        jtp_id: deepLinkData.jtp_id,
        kpi_corelation_id: deepLinkData.kpi_corelation_id,
      });
    }
    recordEvent();
    setLoading(false);
    return () => {
      if (tempJTPData?.jtp_id) {
        dispatch(accountActions.selectJTP(null));
      }
      setStart(false);
      dispatch(accountActions.setLoaded(false));
    };
  }, [
    selectedFeed,
    params.feed_id,
    params.id,
    params.date,
    params.portfolio_id,
  ]);

  function recordEvent() {
    if (accountData) {
      if (location?.state?.jtp?.id) {
        return;
      }
      if (deepLinkData?.jtp_id) {
        return;
      }
      let sfdc = "";
      if (accountData?.external_account_id) {
        sfdc = accountData?.external_account_id;
      }
      Pinpoint.sendEventData(
        EVENT_TYPE.VIEW,
        [VIEW_EVENT_TYPE.ACCOUNT_ANALYTICS],
        [accountData?.name],
        [accountData?.portfolio_name, sfdc]
      );
    }
  }

  useEffect(() => {
    if (!selectedFeed && accountData) {
      const { feed_id } = params;
      dispatch(
        feedActions.setSelectedFeed({
          feed_id: feed_id,
          feed_type: FEEDTYPES.ACCOUNT,
          source_id: accountData?.id,
          source_name: accountData?.name,
          portfolio_id: accountData?.portfolio_id,
        })
      );
    }
  }, [selectedFeed, accountData, params.id]);

  const handleJTPChange = (val) => {
    setJtpID(val);
  };

  const handleOnLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (!jtp) {
      topRef?.current?.scrollIntoView();
      setTempJTPData(null);
    }
  }, [jtp]);

  const selectJTP_OP_KPI = async () => {
    if (tempJTPData) {
      const { jtp_id, kpi_corelation_id, kpi_metric_id } = tempJTPData;

      if (jtp_id) {
        dispatch(accountActions.selectJTP(jtp_id));
      }
      if (kpi_corelation_id) {
        setOpDriverId(kpi_corelation_id);
        dispatch(accountActions.selectOperationalDriver(kpi_corelation_id));
      }
      if (kpi_metric_id) {
        dispatch(accountActions.selectKPI(kpi_metric_id));
      }
    }
    setStart(true);
  };

  const handleKPINavigate = (val) => {
    if (!opDriverId) {
      dispatch(accountActions.selectOperationalDriver(val));
    } else setOpDriverId(null);
  };
  useEffect(() => {
    loadValueData();
  }, [params.feed_id, params.id, params.date]);

  useEffect(() => {
    if (loaded && !loading) {
      selectJTP_OP_KPI();
    }
  }, [loaded, loading, tempJTPData]);

  const resetAnimState = () => {
    setStart(false);
  };
  const handleViewTeamClick = () => {
    dispatch(appActions.toggleRightMenu(RIGHTMENUS.TEAM));
  };

  if (!loading && !accountData)
    return (
      <Box flex="1" display={"flex"}>
        <NoDataView />
      </Box>
    );

  let accDetailView = (
    <div ref={divRef} className="border">
      <div ref={accDetailRef} className="border">
        <DetailAnimation
          value={accDetailRef?.current?.offsetHeight}
          trigger={jtp}
          stopAnimation={tempJTPData?.jtp_id}
        >
          {accountData && (
            <>
              <Typography variant="p" color="text.subheading" fontSize={"1rem"}>
                <SFDCLabel value={accountData.external_account_id} />
              </Typography>
              <Box mt={conv(17)}>
                <ProductLine
                  data={accountData.meta?.product_line_dimension}
                  selected={accountData.product_line}
                />
              </Box>

              <Box mt={conv(54)} mb={conv(63)}>
                <AccountDetail onLoad={handleOnLoad} data={accountData} />
              </Box>
            </>
          )}
        </DetailAnimation>
      </div>
      <Box>
        <CustomerJourneyAnimation
          value={accDetailRef?.current?.offsetHeight}
          trigger={jtp && start && !loading}
        >
          {accountData && (
            <>
              {" "}
              <AccountJourney />
              {!jtp && (
                <Box flex="1" mt={conv(56)}>
                  <CustomerMarketFactors
                    resource={accountData?.customer_market_factor}
                    data={accountData?.meta?.customer_market_fact_dimension}
                  />
                </Box>
              )}
            </>
          )}
        </CustomerJourneyAnimation>
      </Box>
    </div>
  );
  return (
    <Box position={"relative"} display="flex" flexDirection={"column"} flex="1">
      <Box
        flex="1"
        position={"absolute"}
        display={loading || !loaded ? "block" : "none"}
        zIndex="1000"
        width="100%"
        height="100%"
        bgcolor="background.container"
        // style={{opacity:0.4}}
      >
        {" "}
        <Box flex="1" display={"flex"} mt="20%">
          <LoadingView />
        </Box>
      </Box>
      <div ref={topRef}></div>
      {accountData && (
        <Grid pl={conv(39)} pb={conv(19)} container className={classes.header}>
          <Grid item md={9} sm={8} pt={conv(37)}>
            <Typography
              variant="h3"
              color="text.heading"
              fontWeight={700}
              lineHeight={conv(54)}
            >
              {accountData.name}
              {accountData?.fin_currency_type && (
                <AmountLabel>
                  <Typography
                    fontSize={conv(29)}
                    lineHeight={conv(36)}
                    fontWeight={500}
                    display="inline-block"
                    variant="h4"
                    color="text.neutral"
                    marginLeft={conv(21)}
                  >
                    {CURRENCIES_SYMBOLS[accountData.fin_currency_type] || ""}
                    {Helpers.AbbreviateNumber(accountData.fin_value)}
                  </Typography>
                </AmountLabel>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} sm={4} pt={conv(28)}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="right"
              mr={conv(16)}
            >
              {team && imageCookie && (
                <UserGroup
                  style={{ cursor: "pointer" }}
                  onClick={handleViewTeamClick}
                  data={team?.items?.map((item) => ({
                    name: item.user.display_name,
                    src: getImageUrl(item.user.avatar, imageCookie),
                  }))}
                  total={team.meta.total_records}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid
        flex="1"
        marginTop={"0px"}
        p={conv(39)}
        pr={"1rem"}
        pt={0}
        container
        spacing="2rem"
        overflow={"auto"}
      >
        <Grid item md={6} sm={12}>
          {!location?.state?.jtp ? (
            <DetailAnimation2
              value={divRef?.current?.offsetHeight}
              stopAnimation={tempJTPData?.jtp_id}
              trigger={start}
            >
              {accDetailView}
            </DetailAnimation2>
          ) : (
            accDetailView
          )}
        </Grid>
        <Grid item md={6} sm={12}>
          {loaded && (
            <>
              {!tempJTPData?.jtp_id && jtpData && (
                <ScoreCard
                  data={jtpData}
                  animationTrigger={!jtp}
                  type={FEEDTYPES.ACCOUNT}
                />
              )}
              {driver && (
                <KPIDrillDownOperations
                  drivers={operationalDrivers}
                  selectedDriver={driver}
                  data={kpi_metrics}
                  onNavigate={handleKPINavigate}
                  loading={loadingKPI}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default React.memo(AccountAnalytics);
