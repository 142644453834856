const smoothing = 0.15;
const map = (value, inMin, inMax, outMin, outMax) => {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

export const pointsPositionsCalc = (points, w, h, options) =>
  points.map((e) => {
    const x = map(e[0], options.xMin, options.xMax, 0, w);
    const y = map(e[1], options.yMin, options.yMax, h, 0);
    return [x, y];
  });

const line = (pointA, pointB) => {
  const lengthX = pointB[0] - pointA[0];
  const lengthY = pointB[1] - pointA[1];
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
};

const controlPoint = (line, smooth) => (current, previous, next, reverse) => {
  const p = previous || current;
  const n = next || current;
  const l = line(p, n);

  const angle = l.angle + (reverse ? Math.PI : 0);
  const length = l.length * smooth;
  const x = current[0] + Math.cos(angle) * length;
  const y = current[1] + Math.sin(angle) * length;
  return [x, y];
};

const bezierCommand =
  (controlPoint, closed = false) =>
    (point, i, a) => {
      const cps = controlPoint(a[i - 1], a[i - 2], point);
      const cpe = controlPoint(point, a[i - 1], a[i + 1], true);
      let close = "";
      if (closed) close = i === a.length - 1 ? " z" : "";

      return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}${close}`;
    };

const svgPath = (points, command, h) => {
  const d = points.reduce(
    (acc, e, i, a) =>
      i === 0
        ? `M${a[0][0]},${a[0][1]} L ${e[0]},${e[1]} L ${e[0]},${e[1]}`
        : `${acc} ${command(e, i, a)}`,
    ""
  );
  return d;
};

const distance = (p1, p2) => {
  if (p1 && p2 && p1.length >= 2 && p2.length >= 2)
    return Math.sqrt(
      (p1[0] - p2[0]) * (p1[0] - p2[0]) + (p1[1] - p2[1]) * (p1[1] - p2[1])
    );
};

const getSVGDataFromPoints = (points, w, h, options, closed = false) => {
  const pointsPositions = GraphJS.pointsPositionsCalc(points, w, h, options);
  let smooth = smoothing;
  if (points.length === 4) smooth = 0;
  // if (closed) {
  //   smooth = 0.015;
  // }
  const bezierCommandCalc = GraphJS.bezierCommand(
    GraphJS.controlPoint(GraphJS.line, smooth),
    closed
  );
  const path = GraphJS.svgPath(pointsPositions, bezierCommandCalc, h);
  return path;
};

function calculateNextPoint(p0Index, p1, p2Index, pointsArr) {
  let d1 = distance(p1, pointsArr[p0Index]);
  let d2 = distance(p1, pointsArr[p2Index]);
  let index = d1 < d2 ? p0Index : p2Index;
  return { index, point: pointsArr[index] };
}
const GraphJS = {
  distance,
  svgPath,
  bezierCommand,
  controlPoint,
  line,
  pointsPositionsCalc,
  map,
  getSVGDataFromPoints,
  calculateNextPoint,
};
export default GraphJS;
