import { Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { portfolioActions } from "../../Services/Redux/actionCreators";
import Tag from "../Tag";
import { FILTER_TYPE } from "../../Utils/Constants";
import { useTheme } from "@mui/styles";
import { useParams } from "react-router-dom";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;


export default function SelectedFilters() {
  const dispatch = useDispatch();
  const theme=useTheme();
  const params = useParams();
  const selected_filters = useSelector(
    (state) => state.portfolio.applied_filters
  );
  const reloadAnalytics = useSelector((state) => state.portfolio.reload);
  const handleRemoveFilter = (key) => {
    let filters = { ...selected_filters };
    if (filters[key]) {
      delete filters[key];
    }
    dispatch(portfolioActions.setSelectedFilters(filters));
    let applyFilters = true;
    if(Object.keys(filters).length === 0){
      applyFilters = false
      if (params) {
        const { id, date } = params;
        reloadAnalytics(id, date);
      }
    }
    dispatch(portfolioActions.setApplyFilters(applyFilters));

  };
  let filters = selected_filters&&Object.keys(selected_filters).map((filter) => {
    let curr_filter=selected_filters[filter];
    let valueKeys=Object.keys(curr_filter["values"]);
    let filter_len=valueKeys.length;
    if(curr_filter.type===FILTER_TYPE.RANGE)
      filter_len=null;
    let text= curr_filter["title"];
    if(filter_len===1)
    {
      text=curr_filter["values"][valueKeys[0]]
    }
    return {
      key: filter,
      text: text,
      badge: filter_len>1?filter_len:null,
      onDelete: handleRemoveFilter,
    };
  });

  return (
    <>
      <Tag data={filters} deletable={true} rows={10} border={"1.16834px solid "+theme.palette.border.scorecard_border} fontWeight={300} padding={conv(9,11)} borderRadius={conv(9.34)}></Tag>
    </>
  );
}
