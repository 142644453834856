import React, { useEffect, useState } from "react";
import Images from "../../Assets/images/menu-icons";
import { Typography } from "@mui/material";
import MenuButton from "../../Components/MenuButton";
import Feeds from "../../Components/Feeds";
import Alerts from "../../Components/Alerts";
import { useDispatch, useSelector } from "react-redux";
import { MAINMENUS } from "../../Utils/Constants";
import AccountList from "../../Components/AccountList";
import { useNavigate } from "react-router-dom";
import Favorites from "../../Components/Favorites";
import { alertActions } from "../../Services/Redux/actionCreators";
import CustomListMain from "../../Components/AccountList/CustomListMain";
import ActionList from "../../Components/ActionList";
export const useLeftBarOptions =()=>{
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const actionData = useSelector(
    state => state.app.mapping_data.field_mapping.action
  );
  const trainingData = useSelector(
    state => state.app.mapping_data.field_mapping.training
  );
  
  const alertsCount = useSelector(
    (state) => state.alerts.unread_alerts
  );
  const [data,setData]=useState([]);  
  const navigateOnClick = (url) => {
    navigate(url);
  }
  useEffect(()=>{
    dispatch(alertActions.getUnreadCount())
  },[])
  useEffect(()=>{
    const defaultData=[ 
      {
        name: MAINMENUS.FEEDS,
        icon: <MenuButton icon={Images.Feeds} />,
        checkedIcon: <MenuButton checked icon={Images.Feeds} />,
        component: <Feeds/>,
        show:true
      },
      {
        name: MAINMENUS.FAVORITES,
        icon: <MenuButton icon={Images.Favorites} />,
        checkedIcon: <MenuButton checked icon={Images.Favorites} />,
        component: <Favorites/>,
        show:true,
      },
      {
        name: MAINMENUS.NOTIFICATIONS,
        icon: <MenuButton icon={Images.Notification} badge={alertsCount}/>,
        checkedIcon: <MenuButton checked icon={Images.Notification}  badge={alertsCount}/>,
        component: <Alerts/>,
        show:true,
        
      },
      {
        name: MAINMENUS.PORTFOLIOS,
        icon: <MenuButton icon={Images.Portfolios} />,
        checkedIcon: <MenuButton checked icon={Images.Portfolios} />,
        component: <CustomListMain type="portfolio"/>,
        show:true
      },
      {
        name: MAINMENUS.ACCOUNTS,
        icon: <MenuButton icon={Images.Accounts} />,
        checkedIcon: <MenuButton checked icon={Images.Accounts} />,
        component: <AccountList type="account"/>,
        show:true
      },
  
      {
        name: MAINMENUS.ACTIONS,
        icon: <MenuButton icon={Images.Actions} />,
        checkedIcon: <MenuButton checked icon={Images.Actions} />,
        component: <ActionList type="action"/>,
        show: actionData?actionData.is_enabled:false,
      },
      {
        name: MAINMENUS.HELP,
        icon: <MenuButton icon={Images.Favorites} />,
        checkedIcon: <MenuButton checked icon={Images.Favorites} />,
        component: <Typography variant="h4">HELP</Typography>,
        // show: trainingData?trainingData.is_enabled:false,
        show:false
      },
    ];
    setData(defaultData);
  },[actionData?.is_enabled,trainingData?.is_enabled,alertsCount])
  return data;
}
