import React, { useCallback, useEffect, useState } from "react";
import { Typography,Box } from "@mui/material";
import Tabs from "../Shared/Tabs";
import FeedList from "./FeedList";
import { useTranslate } from "../../Hooks/useTranslate";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

const Feeds = () => {
  const t = useTranslate();
  const [tabIndex, setTabIndex] = useState(0);

  const TabOptions = [
    {
      name: t("feed_filters.all"),
      key: "all",
      component: <FeedList filter="all" />,
    },
    {
      name: t("feed_filters.my_follows"),
      key: "my_follows",
      component: <FeedList filter="my_follow" />,
    },
    {
      name: t("feed_filters.experiences"),
      key: "experiences",
      component: <FeedList filter="experience" />,
    },
    {
      name: t("feed_filters.changes"),
      key: "changes",
      component: <FeedList filter="changes" />,
    },
  ];

  const onTabChange = useCallback((tab) => {
    setTabIndex(tab);
  }, []);

  return (
    <Box ml={conv(23)} mr={conv(12)} flex="1">
      <Typography variant="h4"  color="text.heading" mt={conv(45)}>
        {t("feeds")}
      </Typography>
      <Tabs
        value={tabIndex}
        data={TabOptions}
        onChange={onTabChange}
      />
    </Box>
  );
};

export default React.memo(Feeds);
