export const ThemeOptions = {
  dark: {
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      type: "dark",
      text: {
        primary: "#666",
        secondary: "#fff",
        disabled: "#D6D6D6",
        heading:"#F6F7F9",
        subheading:"#9BA6AF",
        neutral: "#80878F",
        kpi_bound:"#CCCED1",
        tagColor:"#767E87",
        tagSelectedColor:"#FFFFFF",
        default:"#767E87",
        lightblue:"#CCCED1",
        red: "#D0021B",
        darkGrey: "#141C23",
        lightGrey: "#8895A4",
        lighterGrey: "#4B545B",
        footerText: "#FFFFE1",
        white: "#fff",
        black: "#000",
        mirage:"#9BA6AF",
        accordian_title_color:"#fff",
        grey_chateau:"#9FA5AC",
        calligraphy:"#596269", 
        hover: {
          blue: "#001e3c",
          red: "#D0021B",
          black: "#333",
        },
        body2: "#CCCED1",
        tableHeader: "#596269",
        tableRowTitle: "#FFFFFF",
        scorecard_noData:"#3E4650",
        navButton:"#FFFFFF",
        navButtonDisabled:"#596269"
      },
      primary: {
        main: "#25303C",
        dark: "#25303C",
      },
      secondary: {
        main: "#25303C",
        dark: "#25303C",
      },
      purple: {
        main: "#220D3A",
        light: "#412f55",
      },
      background: {
        disabled: { gray: "#252c35" },//"#29333C"
        hover: {
          blue: "#ecf3fe",
          red: "#D0021B",
          black: "#333",
        },
        grey: "#D6D6D6",
        mainContent: "#141C23",
        jtpBoxBg:"#141C23",
        jtpBarBg:"#1A232B",
        accSliderBg:"#29333C",
        accSliderSelectedBg:"#141c23",
        opDriverBg:"#141C23",
        opDriverDisabledBg:"#131C22",
        kpiMetricBg:"#141C23",
        replyCommentBg:"#141C23",
        container: "#1A232B",
        secondary: "#29333C",
        darkgrey: "#232C34",
        lightgrey:"#31404B",
        skyblue: "#5185EA",
        tagBg:"#1A232A",
        selectedTagBg:"#141C23",
        alertBoxBg:"#121A21",
        alertContainerBg:"rgba(38, 50, 55, 0.75)",
        deleteIconBg:"rgba(82, 104, 122, 0.1)",        
        loaderBg:"rgba(35, 44, 52,0.25)",
        camIconBg:"#232E3A",
        footer: "#211D24",
        iconFill:"#1A232B",
        suggestionHover:"#4B545B",
        white: "#fff",
        black: "#000000",
        red: "#FF5935",
        primaryRed:"#F45252",
        primaryGreen:"#71C782",
        succesBox: "#E7FFF8",
        alertBox: "#FDE5E8",
        warningBox: "#FDF3E5",
        infoBox: "#DEEAFF",
        mentionBox: "#0E151E",
        searchBackground: "transparent",
        tableRowSelected: "#29333C",
        scorecardBg:"#1A232A",
        scorecardNoDataBg:"#1A232A",
  
        rightPanelBg:"#141C23",
        userSettingBg:"#1A232B",
        listItem:"#1A232B",
        listItemSelected:"#29333C",
        noDataFill:"#182027",
        phoneInputBg:"#141C23",
      },
      border: {
        primary: "#333A43",
        light:"#1D262E",
        secondary: "#ccc",
        mainContent: "#141C23",
        alertBox: "#E67381",
        warningBox: "#F18D5A",
        grey: "#D6D6D6",
        white: "#F6F7F9",
        lightgrey:"#4B545B",
        filter_divider:"#1D262E",
        tableRowBorder:"#25292E",
        tableHeaderBorder: "#253039",
        userSettingBoxBorder:"rgba(255, 255, 255, 0.17)",
        primarySearch: "#333A43",
        scorecard_border:"#2C3740",
        scorecard_nodata_border:"#222A31",
        transparent:"transparent",
        avatarBorder:"black",
        camIconBorder:"#141C23"
      },
      success: { main: "#00C48C" },
      error: { main: "#B00020" },
      infoBoxLink: "#067AD7",
      warning: { main: "#F18D5A" },
      searchIcon: {
        primary: "#596269"
      },
      sortIcon: {
        primary: "#CED3E7"
      },
      boxShadow: {
        searchBox: "none"
      },
      icon: {
        changeArrow: "#FFFFFF"
      }
    },
    typography: {
      allVariants: {
        fontFamily: "Poppins",
      },

      h1: {
        fontSize: "4rem",
        fontWeight: 600,
        lineHeight: "96px",
      },
      h2: {
        fontSize: "2.62rem",
        fontWeight: 700,
        lineHeight: "63px",
      },
      h3: {
        color: "#212121",
        fontSize: "2.25rem",
        fontWeight: 600,
        letterSpacing: -0.5,
        lineHeight: "32px",
      },
      h4: {
        color: "#F6F7F9",
        fontSize: "1.75rem",
        fontWeight: 600,
        lineHeight: "2.65rem",
      },
      h5: {
        color: "#F6F7F9",
        fontSize: "1.2rem",
        fontWeight: 400,
        lineHeight: "2.18rem",
      },
      h6: {
        color: "#fafafa",
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "24px",
      },
      body1: {
        color: "#9b9b9b",
        fontSize: "1.5rem",
        lineHeight: "30px",
      },
      body2: {
        color: "#ffffff",
        fontWeight: 300,
        fontSize: "1.125rem",
        lineHeight: "27px",
      },
      subtitle1: {
        color: "#212121",
        fontSize: 18,
        fontWeight: "bold",
      },
      span: {
        color: "#212121",
        fontSize: 14,
      },

      a: {
        color: "#fff",
        fontSize: 14,
      },
      subtitle2: {
        color: "#fafafa",
        fontSize: 15,
        lineHeight: "25px",
        fontStyle: "normal",
        fontWeight: 400,
      },
      neutral: {
        color: "#80878F",
        fontWeight: 500,
        fontSize: ".875rem ",
        lineHeight:"21px",
      },
      span_footer: {
        color: "#25303C",
        fontSize: 14,
      },
      sidebar_subtitle: {
        color: "#fff",
        fontSize: 20,                
        lineHeight: "35px",
        fontWeight: 500,
      },
      tableCell: {
        color: "#fff",
        fontWeight: 500,
        fontSize: "0.9rem",
        lineHeight: "1.25rem",
        padding: "6px 0px"
      },
      login_title:{
        color: "#fff",
        fontWeight: 500,
        fontSize: "1.5rem",
        lineHeight: "2.25rem",
      }
    },
  },
  light: {
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      type: "light",
      text: {
        primary: "#767E87",
        secondary: "#1A232A",
        heading:"#080A0D",
        subheading:"#1A232A",
        kpi_bound:"#767E87",
        tagColor:"#80878F",
        tagSelectedColor:"#000000",
        disabled: "#D6D6D6",
        neutral: "#80878F",
        default:"#767E87",
        lightblue:"#80878F",
        red: "#D0021B",
        darkGrey: "#141C23",
        lightGrey: "#767E87",
        lighterGrey: "#4B545B",
        footerText: "#FFFFE1",
        // white: "#4B545B",
        white: "#1A232A",
        black: "#000",
        mirage:"#1A232A",
        calligraphy:"#767E87", 
        hover: {
          blue: "#001e3c",
          red: "#D0021B",
          black: "#333",
        },
        tableHeader: "#596269",
        tableRowTitle: "#080A0D",
        scorecard_noData:"#80878F",
        navButton:"#767E87",
        navButtonDisabled:"#CCCED1"
      },
      primary: {
        main: "#25303C",
        dark: "#25303C",
      },
      secondary: {
        main: "#25303C",
        dark: "#25303C",
      },
      purple: {
        main: "#220D3A",
        light: "#412f55",
      },
      background: {
        disabled: { gray: "#80878F" },
        hover: {
          blue: "#ecf3fe",
          red: "#D0021B",
          black: "#333",
        },
        grey: "#D6D6D6",

        mainContent: "#fff",
        container: "#fff",
        jtpBoxBg:"rgba(204, 206, 209,0.2)",
        jtpBarBg:"#E3E3E8",
        accSliderBg:"#E3E3E8",
        accSliderSelectedBg:"#E3E3E8",
        opDriverBg:"rgba(204, 206, 209,0.2)",
        opDriverDisabledBg:"#DDDEE1",
        kpiMetricBg:"rgba(204, 206, 209,0.2)",
        tagBg:"#F5F6FC",
        replyCommentBg:"#F9F9FB",
        selectedTagBg:"transparent",
        loaderBg:"rgba(156, 172, 196, 0.2)",
        suggestionHover:"#EBECF0",
        secondary: "#EBECF0",
        darkgrey: "#232C34",
        lightgrey:"#EBECF0",
        skyblue: "#5185EA",
        alertBoxBg:"#FFFFFF",
        mentionBox: "#F9F9FB",
        alertContainerBg:"rgba(67, 78, 89, 0.75)",
        deleteIconBg:"rgba(244, 82, 82, 0.1)",
        footer: "#211D24",
        iconFill:"#fff",
        white: "#fff",
        black: "#000000",
        red: "#FF5935",
        succesBox: "#E7FFF8",
        alertBox: "#FDE5E8",
        warningBox: "#FDF3E5",
        infoBox: "#DEEAFF",
        primaryRed:"#F45252",
        primaryGreen:"#71C782",
        searchBackground: "rgba(214,220,232, 0.2)",
        tableRowSelected: "#FFFFFF",
        scorecardBg:"#ffffff",
        scorecardNoDataBg:"#FAFAFA",
        listItem:"#F9F9FB",
        listItemSelected:"#EBECF0",
        rightPanelBg:"#F9F9FB",
        userSettingBg:"#F9F9FB",
        camIconBg:"#596269",
        noDataFill:"#F9F9FB",
        phoneInputBg:"#F9F9FB",
      },
      border: {
        primary: " #D6DCE8",
        secondary: "#ccc",
        mainContent: "#141C23",
        alertBox: "#E67381",
        warningBox: "#F18D5A",
        grey: "#D6D6D6",
        lightgrey:"#CCCED1",//"#4B545B",
        white: "#F6F7F9",
        light:"#D6DCE8",
        filter_divider:"#D6DCE8",
        primarySearch: "#CCCED1",
        scorecard_border:"#D6DCE8",
        scorecard_nodata_border:"#ECECEC",
        tableRowBorder:"#D6DCE8",
        tableHeaderBorder: "#D6DCE8",
        transparent:"#C8C8C8",
        userSettingBoxBorder:"rgba(0, 0, 0, 0.17)",
        avatarBorder:"transparent",
        camIconBorder:"#FFFFFF"
      },
      success: { main: "#00C48C" },
      error: { main: "#B00020" },
      infoBoxLink: "#067AD7",
      warning: { main: "#F18D5A" },
      searchIcon: {
        primary: "#596269"
      },
      sortIcon: {
        primary: "#CED3E7"
      },
      boxShadow: {
        searchBox: "0px 0px 12px rgb(121 145 174 / 25%)"
      },
      icon: {
        changeArrow: "#606B75"
      }
    },
    typography: {
      allVariants: {
        fontFamily: "Poppins",
      },

      h1: {
        fontSize: "4rem",
        fontWeight: 600,
        lineHeight: "96px",
      },
      h2: {
        fontSize: "2.62rem",
        fontWeight: 700,
        lineHeight: "63px",
      },
      h3: {
        color: "#212121",
        fontSize: "2.25rem",
        fontWeight: 600,
        letterSpacing: -0.5,
        lineHeight: "32px",
      },
      h4: {
        color: "#1A232A",      
        fontSize: "1.75rem",
        fontWeight: 600,
        lineHeight: "2.65rem",
      },
      h5: {
        color: "#1A232A",
        fontSize: "1.2rem",
        fontWeight: 400,
        lineHeight: "2.18rem",
      },
      h6: {
        color: "#80878F",
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "24px",
      },
      body1: {
        color: "#9b9b9b",
        fontSize: "1.5rem",
        lineHeight: "30px",
      },
      body2: {
        color: "#1A232A",
        fontWeight: 300,
        fontSize: "1.125rem",
        lineHeight: "27px",
      },
      subtitle1: {
        color: "#212121",
        fontSize: 18,
        fontWeight: "bold",
      },
      span: {
        color: "#212121",
        fontSize: 14,
      },

      a: {
        color: "#fff",
        fontSize: 14,
      },
      subtitle2: {
        color: "#fafafa",
        fontSize: 15,
        lineHeight: "25px",
        fontStyle: "normal",
        fontWeight: 400,
      },
      neutral: {
        color: "#80878F",
        fontWeight: 500,
        fontSize: ".875rem ",
        lineHeight:"21px",
      },
      span_footer: {
        color: "#25303C",
        fontSize: 14,
      },
      sidebar_subtitle: {
        color: "#fff",
        fontSize: 20,                
        lineHeight: "35px",
        fontWeight: 500,
      },
      tableCell: {
        color: "#fff",
        fontWeight: 500,
        fontSize: "0.9rem",
        lineHeight: "1.25rem",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        padding: "6px 8px"
      },
      login_title:{
        color: "#fff",
        fontWeight: 500,
        fontSize: "1.5rem",
        lineHeight: "2.25rem",
      }
    },
  },
};
