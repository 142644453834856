import * as React from "react";
import Switch from "react-switch";
import PropTypes from "prop-types";

export default function CustomSwitch({ checked, onChange, }) {
  return (
    <Switch
      checkedIcon={false}
      uncheckedIcon={false}
      checked={checked}
      onChange={onChange}
    />
  );
}
CustomSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
