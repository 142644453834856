import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Accordion from "../Shared/Accordion";
import { Box, Grid, Typography, Stack, Checkbox } from "@mui/material";
import { useStyles } from "./styles";
import { useTheme } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { portfolioActions } from "../../Services/Redux/actionCreators";
import GUID from "../../Utils/Guid";
import { useTranslate } from "../../Hooks/useTranslate";
import SelectedFilters from "./SelectedFilters";
import FilterItem from "./FilterItem";
import Button from "../Shared/Button";
import FilterCount from "./FilterCount";
import LoadingView from "../LoadingView";
import SidebarHeader from "../SidebarHeader";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

function PortfolioFilters() {
  const classes = useStyles();
  const theme = useTheme();
  const t = useTranslate();
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  const loadPortfolioFilters = useCallback(async (id) => {
    setLoading(true);
    await dispatch(portfolioActions.getPortfolioFilters(id));
    setLoading(false);
  }, []);

  useEffect(() => {
    if (params.id) loadPortfolioFilters(params.id);
  }, [params.id]);
  const isFilterApplied = useSelector((state) => state.portfolio.apply_filters);
 
  const reloadAnalytics = useSelector((state) => state.portfolio.reload);

  const filterData = useSelector(
    (state) => state.portfolio.filters.filter_page
  );
  
  let portfolioValue = useSelector((state) => state.portfolio.data);
  let timestamp=useSelector(state=>state.portfolio.timestamp);
  const handleClearAll = useCallback(() => {
    if (isFilterApplied) {
      dispatch(portfolioActions.setApplyFilters(false));
      if (params) {
        const { id } = params;
        let date=timestamp;
        if(!date)
          date=params.date;
        reloadAnalytics(id, date);
      }
    }
  }, [params,timestamp, isFilterApplied]);

  const handleApplyFilter = useCallback(() => {
    
    dispatch(portfolioActions.setApplyFilters(true));
    if(!portfolioValue)
    {
      if (params) {
        const { id } = params;
        let date=timestamp;
        if(!date)
          date=params.date;
        reloadAnalytics(id, date);
      }
    }
   
  }, [timestamp,params,portfolioValue]);

  return (
    <Box
      display={"flex"}
      mt={conv(13)}
      ml={conv(23)}
      mr={conv(12)}
      flex={1}
      flexDirection={"column"}
    >
      <SidebarHeader
        title={t("filters")}
        mr={"0.875rem"}
        rightComponent={
          <Typography variant="h4" color="text.neutral">
            {isFilterApplied && <FilterCount />}
          </Typography>
        }
      />
      {loading ? (
        <LoadingView mt={"20%"} />
      ) : (
        <>
          <div
            style={{
              minHeight: 0,
              margin: conv(25, 0),
            }}
          >
            {isFilterApplied && <SelectedFilters />}
          </div>
          <div
            style={{
              display: "flex",
              flex: "1 auto",
              flexDirection: "column",
              overflowY: "auto",
              height: 0,
              paddingRight: "0.875rem",
            }}
          >
            <div className={classes.root}>
              {filterData &&
                filterData.filter_options.map((item) => {
                  let filterItem = item.items[0];
                  return (
                    <Accordion key={GUID("filter")} title={item.group_name}>
                      <FilterItem title={item.group_name} data={filterItem} />
                    </Accordion>
                  );
                })}
            </div>
          </div>
          <div
            style={{
              flexDirection: "column",
              margin: conv(33, 15, 33, 0),
            }}
          >
            <Grid container spacing={0}>
              <Grid item sm={6} md={6}>
                <Typography
                  component="p"
                  className={classes.buttonClear}
                  onClick={handleClearAll}
                >
                  {t("clear_all")}
                </Typography>
              </Grid>
              <Grid item sm={6} md={6}>
                <Button
                  color="primary"
                  className={classes.buttonApply}
                  disableRipple
                  onClick={handleApplyFilter}
                  variant="contained"
                  children={t("apply")}
                  type="submit"
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </Box>
  );
}

export default React.memo(PortfolioFilters);
