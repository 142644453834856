import * as React from "react";
import { Typography,Box } from "@mui/material";
import PropTypes from "prop-types";
import { useStyles } from "../styles";
import JTPBars from "./JTPBars.js";
import AmountLabel from "../../AmountLabel";

const JTPView = props => {
  const { title, currency, amount, date, data } = props;
  const classes = useStyles();

  const [clickableItem,setClickableItem]=React.useState(null);

  React.useEffect(()=>{
    if(data)
    {
      let clickable=data.find(item=>item.clickable);
      setClickableItem(clickable);
    }
  },[data])

  return (
    <>
      <Typography variant="h4" className={classes.account_title}>
        {title} {clickableItem&&clickableItem.name}
        <AmountLabel>
          <Typography variant="span" className={classes.finLabel}>
            {currency}
            {amount}
          </Typography>
        </AmountLabel>
      </Typography>
      <div style={{ textAlign: "right" }}>
        <Typography  variant="neutral" className={classes.neutralLabel}>
          {date}
        </Typography>
      </div>
      <Box flex={1} className={classes.jtpBox}>
        <JTPBars data={data} />
      </Box>
    </>
  );
};

JTPView.propTypes = {
  title: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.array,
  date: PropTypes.string,
};

JTPView.defaultProps = {
  title: "",
  currency: "",
  data: [],
  date: "",
};
export default JTPView;
