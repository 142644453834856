import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import LoadingView from "../LoadingView";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import { useParams, useLocation } from "react-router-dom";
import UIHelper from "../../Utils/UIHelper";
import { getFullDate } from "../../Utils/Date";
import Button from "../Shared/Button";
import MemberDetail from "../AccountTeam/MemberDetail";
import ScoreCard from "../ScoreCard";
import Animator from "../Animator";
import { useTranslate } from "../../Hooks/useTranslate";
import {
  actionActions,
  feedActions,
  appActions,
} from "../../Services/Redux/actionCreators";
import Tags from "../Tag";
import NoDataView from "../NoDataView";
import { FEEDTYPES, MAINMENUS } from "../../Utils/Constants";
const conv = UIHelper.convertPxToRem;

const ActionDetail = () => {
  const classes = useStyles();
  const theme = useTheme();
  const t = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [detail, setDetail] = useState();
  const { active_menu } = useSelector((state) => state.app);

  const [adminId, setAdminId] = useState();

  const loadData = async (id) => {
    setLoading(true);
    let result = await dispatch(actionActions.getActionDetail(id));
    if (result?.data) {
      setDetail(result.data);
    }
    setLoading(false);
  };

  const handleContactAdmin = () => {
    if (detail.owner_id) {
      setAdminId(detail.owner_id);
    }
  };

  const handleCloseAdmin = () => {
    setAdminId(null);
  };

  useEffect(() => {
    const id = params.id;
    dispatch(feedActions.setSelectedFeed(null));
    if (active_menu !== MAINMENUS.ACTIONS) {
      dispatch(appActions.setMainMenu(MAINMENUS.ACTIONS));
    }
    loadData(id);
  }, [params.id]);

  if (loading)
    return (
      <Box flex="1" display="flex">
        <LoadingView />
      </Box>
    );
  if (!detail)
    return (
      <Box flex="1" display="flex">
        <NoDataView />
      </Box>
    );
  return (
    <Box
      display="flex"
      height="100%"
      flexDirection={"column"}
      flex="1"
      pl={conv(39)}
      // 
    >
      {!adminId && (
        <Grid container>
          <Grid item md={9} sm={8} pt={conv(37)}>
            <Typography
              variant="h3"
              color="text.heading"
              fontWeight={700}
              lineHeight={conv(54)}
            >
              {detail.title}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container mt="10px" flex={"1"}>
        <Grid
          item
          md={6}
          sm={12}

          pb={conv(19)}
          // p="2rem"
          display="flex"
          flexDirection={"column"}
        >
          {adminId ? (
            <Box display={"flex"} flex="1" flexDirection={"column"}>
              <MemberDetail id={adminId} onClose={handleCloseAdmin} data={{source:"ACTION_ADMIN",name:""}}/>
            </Box>
          ) : (
            <>
              <Box flex="1" mt={conv(25)}>
                <Typography variant="h6" color="text.default">
                  {t("created_at")}
                  &nbsp; {getFullDate(detail.created_at)}
                </Typography>
                <Box flex="1" mt={conv(17)}>
                  <Tags
                    data={detail?.status_options?.map((item) => ({
                      text: item,
                      color: theme.palette.text.default,
                      selected: item === detail.status,
                    }))}
                    selectedItemStyle={{
                      color: theme.palette.text.secondary,
                      background: theme.palette.background.mainContent,
                    }}
                  />
                </Box>
                <Typography
                  variant="h6"
                  color="text.grey_chateau"
                  fontSize={UIHelper.convertPxToRem("18px")}
                  mt={UIHelper.convertPxToRem(32)}
                  mb={UIHelper.convertPxToRem(15)}
                >
                  {t("managed_in")}
                </Typography>
                <img src={detail.system_logo} alt={detail.system_name} />
                <Typography
                  variant="h6"
                  fontSize={UIHelper.convertPxToRem("18px")}
                  color="text.lightblue"
                  textAlign={"justify"}
                  mt={UIHelper.convertPxToRem(48)}
                  lineHeight={UIHelper.convertPxToRem(32)}
                >
                  {detail.description}
                </Typography>
              </Box>
              <Box mt="2rem" width="300px">
                <Button
                  color="primary"
                  className={classes.buttonApply}
                  onClick={handleContactAdmin}
                  disableRipple
                  variant="contained"
                  children={t("contact_administrator")}
                  type="submit"
                />
              </Box>
            </>
          )}
        </Grid>
        <Grid item md={6} sm={12} p="2rem"></Grid>
      </Grid>
    </Box>
  );
};
export default React.memo(ActionDetail);
