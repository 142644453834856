import React from "react";
const Read = ({ color }) => {
  return (
    <svg
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5H17.7127L20.2062 8H17C15.8954 8 15 8.89543 15 10V11H13V10C13 8.89543 12.1046 8 11 8H7.79383L10.2873 5H12V3H9.81818H9.34903L9.04914 3.3608L5.19318 8H5C3.89543 8 3 8.89543 3 10V16C3 17.1046 3.89543 18 5 18H11C12.1046 18 13 17.1046 13 16V13H15V16C15 17.1046 15.8954 18 17 18H23C24.1046 18 25 17.1046 25 16V10C25 8.89543 24.1046 8 23 8H22.8068L18.9509 3.3608L18.651 3H18.1818H16V5ZM21.8685 10L23 11.3613V16H17V10H21.8685ZM11 10H6.13149L5 11.3613L5 16H11V10Z"
        fill={color}
      />
    </svg>
  );
};
export default Read;
