import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import { useTheme } from "@mui/styles";
import clsx from "clsx";
import Animator from "../Animator";
import FavAccount from "../../Assets/images/fav_account";
import FavPortfolio from "../../Assets/images/fav_portfolio";
import AlertMenu from "../Alerts/AlertMenu";

const FavoriteItem = ({ selected, onClick, data, key }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [showOption, setShowOption] = React.useState(false);
  const [hideMarkRead, setHideMarkRead] = React.useState(true);
  const handleClose = () => {
    setShowOption(false);
  };
  const handleMouseEnter = (e) => {
    setShowOption(true);
  };
  const handleMouseLeave = (e) => {
    setShowOption(false);
  };
  const handleClick = (e) => {
    onClick(data);
  };
  if (!data) return;
  let color = theme.palette.text.white;
  let icon =
    data.favorite_type === "ACCOUNT" ? (
      <FavAccount color={color} />
    ) : (
      <FavPortfolio color={color} />
    );
  return (
    <>
      <Animator animation="slideUp">
        <div
          id={key}
          className={clsx(classes.box, selected && classes.selectedBox)}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            pr={"1rem"}
            mt={data.favorite_type === "ACCOUNT" ? "3px" : "0px"}
          >
            {icon}
          </Box>
          <Box flex="1">
            <Typography variant="body2" color={color} fontWeight="500">
              {data?.name}
            </Typography>
          </Box>
          <AlertMenu
            showButton={showOption}
            data={data}
            onClose={handleClose}
            isMarkRead={hideMarkRead}
            isFromFav={true}
          />
        </div>
      </Animator>
    </>
  );
};

FavoriteItem.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  data: PropTypes.object,
};

FavoriteItem.defaultProps = {
  selected: false,
  data: null,
  onClick: () => {},
};
export default React.memo(FavoriteItem);
