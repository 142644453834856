import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useStyles } from "./styles";
import JTPBars from "../Feeds/JTPView/JTPBars";
import { accountActions } from "../../Services/Redux/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslate } from "../../Hooks/useTranslate";
import CustomerLifecycle from "../CustomerLifecycle";
import OperationalDrivers from "../OperationalDrivers";
import LoadingView from "../LoadingView";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  EVENT_TYPE,
  VIEW_EVENT_TYPE,
  ANALYTICS_EVENT_SUBTYPE,
} from "../../Utils/Constants";
import NoDataView from "../NoDataView";

const CustomerJourney = (props) => {
  const classes = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentJTPValue, setCurrentJTPValue] = useState(-1);
  const params = useParams();
  let accountData = useSelector((state) => state.account.data);
  let accountJtp = useSelector((state) => state.account.jtpData);
  let operationalDrivers = useSelector(
    (state) => state.account.operationalDrivers
  );
  let jtp = useSelector((state) => state.account.selected_jtp);
  let jtps = useSelector((state) => state.account.jtpData);
  let loading_jtp = useSelector((state) => state.account.loading_jtp);
  let driver = useSelector((state) => state.account.selected_op_driver);
  let timestamp = useSelector((state) => state.account.timestamp);
  const loadOperationalDrivers = async (jtpId) => {
    const { id, portfolio_id } = params;
    setLoading(true);
    await dispatch(
      accountActions.getOperationalDrivers(id, jtpId, timestamp, portfolio_id)
    );
    setLoading(false);
  };

  useEffect(() => {
    if (jtp) {
      let selectedJTP = jtps?.find((item) => item.id === jtp);
      Pinpoint.sendEventData(
        EVENT_TYPE.VIEW,
        [VIEW_EVENT_TYPE.ACCOUNT_ANALYTICS, ANALYTICS_EVENT_SUBTYPE.JOURNEY],
        [accountData?.name, selectedJTP?.name],
        [accountData?.portfolio_name, accountData?.external_account_id]
      );
    }
    if (jtp && (currentJTPValue === -1 || currentJTPValue > 0)) {
      loadOperationalDrivers(jtp);
    } else {
      dispatch(accountActions.selectOperationalDriver(null));
      dispatch(accountActions.setKPIMetrics(null));
      // dispatch(accountActions.setOperationalDrivers([]));
    }
  }, [jtp, currentJTPValue, jtps]);

  const handleChange = (id, value) => {
    setCurrentJTPValue(value);
    dispatch(accountActions.selectOperationalDriver(null));
    dispatch(accountActions.setKPIMetrics(null));
    // dispatch(accountActions.setOperationalDrivers(null));
    dispatch(accountActions.selectJTP(id == jtp ? null : id));
  };
  const handleAnimationEnd = () => {
    dispatch(accountActions.setOperationalDrivers([]));
  };

  const handleDriverSelect = (val) => {
    if (val) dispatch(accountActions.selectOperationalDriver(val));
  };

  useEffect(() => {
    const { id, portfolio_id } = params;
    if (id && driver && jtp) {
      dispatch(
        accountActions.getKPIMetrics(id, jtp, driver, timestamp, portfolio_id)
      );
      let selectedJTP = jtps?.find((item) => item.id === jtp);
      let selectedDriver = operationalDrivers?.find(
        (item) => item.id === driver
      );

      Pinpoint.sendEventData(
        EVENT_TYPE.VIEW,
        [
          VIEW_EVENT_TYPE.ACCOUNT_ANALYTICS,
          ANALYTICS_EVENT_SUBTYPE.JOURNEY,
          ANALYTICS_EVENT_SUBTYPE.OPDriverKPIs,
        ],
        [accountData?.name, selectedJTP?.name, selectedDriver?.display_name],
        [accountData?.portfolio_name, accountData?.external_account_id]
      );
    }
  }, [accountData, driver]);

  if (!loading_jtp && accountJtp?.length == 0) {
    return "";
  }

  return (
    <Box className={classes.box}>
      {accountJtp && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="text.subheading">
              {t("customer_journey")}
            </Typography>

            <CustomerLifecycle
              data={accountData?.meta?.customer_lifecycle_dimension}
              selected={accountData.customer_lifecycle}
            />
          </Stack>
          <Box className={classes.jtpBoxContainer}>
            {loading_jtp ? (
              <LoadingView />
            ) : accountJtp?.length > 0 ? (
              <Box className={classes.jtpBox}>
                <JTPBars
                  data={accountJtp}
                  lg={true}
                  readOnly={false}
                  onClick={handleChange}
                  value={jtp}
                  trackColor="background.jtpBarBg"
                />
              </Box>
            ) : (
              <NoDataView />
            )}
          </Box>
        </>
      )}
      {loading && <LoadingView />}
      {!loading && (
        <Box className={classes.opDriverBox}>
          <OperationalDrivers
            title={t("operational_drivers")}
            data={operationalDrivers}
            onClick={handleDriverSelect}
            onAnimationEnd={handleAnimationEnd}
            value={driver}
            jtp={jtp}
            trackColor="background.secondary"
          />
        </Box>
      )}
    </Box>
  );
};
export default React.memo(CustomerJourney);
