import { makeStyles } from "@mui/styles";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  box: {
    backgroundColor: MUITheme.palette.background.container,
    flex: 1,
    borderRadius: 20,
    padding: 15,
    paddingBottom: 0,
    marginTop: "10px",
  },
  valueLabel:{
    textOverflow:"ellipsis",
    overflow:"hidden",
    "-webkit-line-clamp": 1,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "overflowWrap":"anywhere"
  },
  selectedBox: {
    backgroundColor: MUITheme.palette.background.secondary,
  },
  neutralLabel: {
    color: `${MUITheme.palette.text.lighterGrey} !important`,
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
  },
  npsErrorLabel: {
    background: MUITheme.palette.background.primaryRed,
    color: "#F45252",
    position: "absolute",
    top: "3px",
    fontSize: "1rem",
    lineHeight: "22px",
    display: "inline-block",
    padding: "3px 8px",
    borderRadius: "5px",
    marginLeft: "10px !important",
  },
  countLabel: {
    color: `${MUITheme.palette.text.secondary} !important`,
    paddingRight: "15px",
  },
  tooltipStyle: {
    top: conv(-75),
    padding: conv(6, 8),
    lineHeight: 1,
    textAlign: "center",
    borderRadius: conv(6),
    color: MUITheme.palette.text.lightblue,
    fontSize: "1rem",
    fontWeight: 500,
    backgroundColor: MUITheme.palette.background.lightgrey,
  },

  jtpBox: {
    padding: "5px",
    whiteSpace: "nowrap",
    overflowY: "hidden",
    overflowX: "scroll",
    "-webkit-overflow-scrolling": "touch",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
