import React from "react";
import { CheckedColor, UncheckedColor } from ".";

const Accounts = ({ checked }) => {
  return (
    <svg
      width={window.conv(24)}
      height={window.conv(27)}
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8906 2.00014C17.8906 1.58593 17.5548 1.25014 17.1406 1.25014C16.7264 1.25014 16.3906 1.58592 16.3906 2.00014L17.8906 2.00014ZM16.3906 22.5C16.3906 22.9142 16.7264 23.25 17.1406 23.25C17.5548 23.25 17.8906 22.9142 17.8906 22.5L16.3906 22.5ZM16.3906 2.00014L16.3906 22.5L17.8906 22.5L17.8906 2.00014L16.3906 2.00014Z"
        fill={checked ? CheckedColor : UncheckedColor}
      />
      <path
        d="M17.1402 23.3027L16.6171 23.8402C16.9083 24.1235 17.3721 24.1235 17.6632 23.8402L17.1402 23.3027ZM14.2691 19.4622C13.9722 19.1733 13.4974 19.1798 13.2085 19.4767C12.9196 19.7735 12.9261 20.2483 13.223 20.5372L14.2691 19.4622ZM21.0573 20.5372C21.3542 20.2483 21.3606 19.7735 21.0717 19.4766C20.7829 19.1798 20.308 19.1733 20.0112 19.4622L21.0573 20.5372ZM17.6632 22.7652L14.2691 19.4622L13.223 20.5372L16.6171 23.8402L17.6632 22.7652ZM17.6632 23.8402L21.0573 20.5372L20.0112 19.4622L16.6171 22.7652L17.6632 23.8402Z"
        fill={checked ? CheckedColor : UncheckedColor}
      />
      <path
        d="M6.14465 23.3026C6.14465 23.7168 5.80887 24.0526 5.39465 24.0526C4.98044 24.0526 4.64465 23.7168 4.64465 23.3026L6.14465 23.3026ZM4.64465 2.80274C4.64465 2.38852 4.98044 2.05273 5.39465 2.05273C5.80887 2.05273 6.14465 2.38852 6.14465 2.80273L4.64465 2.80274ZM4.64465 23.3026L4.64465 2.80274L6.14465 2.80273L6.14465 23.3026L4.64465 23.3026Z"
        fill={checked ? CheckedColor : UncheckedColor}
      />
      <path
        d="M5.39419 2.00007L4.87113 1.46257C5.16228 1.17924 5.6261 1.17924 5.91725 1.46257L5.39419 2.00007ZM2.52311 5.84052C2.22626 6.1294 1.75143 6.12294 1.46255 5.82608C1.17367 5.52923 1.18014 5.0544 1.47699 4.76552L2.52311 5.84052ZM9.31134 4.76552C9.60819 5.0544 9.61465 5.52923 9.32577 5.82608C9.03689 6.12294 8.56206 6.1294 8.26521 5.84052L9.31134 4.76552ZM5.91725 2.53757L2.52311 5.84052L1.47699 4.76552L4.87113 1.46257L5.91725 2.53757ZM5.91725 1.46257L9.31134 4.76552L8.26521 5.84052L4.87112 2.53757L5.91725 1.46257Z"
        fill={checked ? CheckedColor : UncheckedColor}
      />
    </svg>
  );
};

export default Accounts;
