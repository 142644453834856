import { makeStyles } from "@mui/styles";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles(MUITheme => ({
  border: { borderBottom: `1px solid ${MUITheme.palette.border.primary}` },
  tabs: {
    "& .MuiTab-root": {
      textTransform: "none",
      minWidth: conv(50),
      fontWeight: 400,
      fontSize: conv(18),
      lineHeight: conv(27),
      color: MUITheme.palette.text.primary,
    },
    "& .MuiButtonBase-root.MuiTab-root": {
      padding: conv(11,5),
    },

    "& .MuiTab-root.Mui-selected": {
      color: MUITheme.palette.background.skyblue,
    },
    "& .MuiTabs-indicator": {
      background: MUITheme.palette.background.skyblue,
    },
  },
}));
