import React, { useState } from "react";
import { Box } from "@mui/material";
import InfinitePaginatedScroll from "../Shared/InfinitePaginatedScroll";
import { useTranslate } from "../../Hooks/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import LoadingView from "../LoadingView";
import { useTheme } from "@mui/styles";
import { useStyles } from "./styles";
import clsx from "clsx";
import CircularProgress from "../Shared/CircularProgress";
import { useNavigate } from "react-router-dom";
import { FEEDTYPES } from "../../Utils/Constants";
import { feedActions } from "../../Services/Redux/actionCreators";
import ListItem from "./ListItem";

const ScrollList = ({
  onNext,
  onClick,
  type,
  headers,
  loading,
  data,
  ...props
}) => {
  const classes = useStyles();

  const listData = data?.list;
  const listMetaData = data?.meta;

  const [selectedId, setSelectedId] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const handleNext = () => {
    onNext && onNext();
  };

  const navigateOnClick = async (data, index) => {
    setSelectedId(data.id);
    setSelectedIndex(index);
    onClick && onClick(data, index);
  };
  return (
    <InfinitePaginatedScroll
      totalRecords={listMetaData.total_records}
      onNext={handleNext}
      loader={<CircularProgress show={loading} />}
    >
      {listData.map((item, index) => {
        let previousItemIndex = selectedIndex > 0 && selectedIndex - 1;
        return (
          <Box
            pl={window.conv(11)}
            ml={window.conv(12)}
            mr={window.conv(6)}
            className={clsx(
              classes.box,
              selectedId == item.id && classes.selectedBox
            )}
            key={index}
          >
            <ListItem
              data={item}
              index={index}
              headers={headers}
              onClick={navigateOnClick}
              previousIndex={previousItemIndex}
              selected={selectedId == item.id}
              hoveredIndex={hoveredIndex}
              onMouseEnter={(ind) => setHoveredIndex(ind)}
              onMouseLeave={(ind) => setHoveredIndex(ind)}
            />
          </Box>
        );
      })}
    </InfinitePaginatedScroll>
  );
};

export default React.memo(ScrollList);
