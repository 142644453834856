import { createTheme } from "@mui/material";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import { ThemeOptions } from "../../Assets/ThemeOptions";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const MUIThemeProvidor = ({ children }) => {
  const theme = useSelector(state => state.app.theme);
  const themeOption = ThemeOptions[theme];
  useEffect(()=>{
    var r = document.querySelector(":root");
    var rs = getComputedStyle(r);
    let scroll_color,background;
    if(theme==="light")
    {
      scroll_color= rs.getPropertyValue("--scroll_color_light");
      background=rs.getPropertyValue("--background_light");
    }
    else{
      scroll_color= rs.getPropertyValue("--scroll_color_dark");
      background=rs.getPropertyValue("--background_dark");
    }
    r.style.setProperty("--scroll_color",scroll_color);
    r.style.setProperty("--background", background);

  },[theme])
  return (
    <MUIThemeProvider theme={createTheme(themeOption)}>
      {children}
    </MUIThemeProvider>
  );
};

export default MUIThemeProvidor;
