import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((MUITheme) => ({   
  box: {
    backgroundColor: MUITheme.palette.background.listItem, 
    borderRadius:window.conv(15),
    padding:window.conv(24,21,24,21),   
    cursor: "pointer",
    display:"flex",
    marginBottom:window.conv(15),
    marginRight:window.conv(16),
    fontSize:window.conv(18)+" !important",
    lineHeight:window.conv(27)+" !important",
    alignItems:"top"
  },
  selectedBox: {
    backgroundColor: MUITheme.palette.background.listItemSelected,
  },
}));
