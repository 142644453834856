import React from "react";

const ShareLink = ({color}) => {
  return (
    <svg width={window.conv(24)} height={window.conv(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6187 13.2403C11.0481 13.8144 11.596 14.2894 12.2252 14.6332C12.8544 14.977 13.5502 15.1814 14.2653 15.2326C14.9804 15.2838 15.6982 15.1806 16.37 14.93C17.0418 14.6795 17.6518 14.2873 18.1587 13.7803L21.1587 10.7803C22.0694 9.83725 22.5734 8.57424 22.562 7.26325C22.5506 5.95227 22.0248 4.69821 21.0977 3.77117C20.1707 2.84413 18.9166 2.31828 17.6057 2.30689C16.2947 2.2955 15.0317 2.79947 14.0887 3.71026L12.3687 5.42026" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.6186 11.2402C14.1892 10.6661 13.6413 10.191 13.0121 9.84728C12.3829 9.50352 11.6871 9.2991 10.972 9.24788C10.2568 9.19666 9.53902 9.29985 8.86726 9.55043C8.1955 9.80102 7.58549 10.1931 7.07861 10.7002L4.07861 13.7002C3.16782 14.6432 2.66385 15.9062 2.67524 17.2172C2.68663 18.5282 3.21247 19.7823 4.13952 20.7093C5.06656 21.6363 6.32062 22.1622 7.6316 22.1736C8.94259 22.185 10.2056 21.681 11.1486 20.7702L12.8586 19.0602" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
  );
};

export default ShareLink;

