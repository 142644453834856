import React from "react";
import { Box, Typography } from "@mui/material";
import NoScorecard from "../../Assets/images/no-scorecard.png";
import { useTranslate } from "../../Hooks/useTranslate";

const NoData = () => {
  const t = useTranslate();

  return (
    <Box
      height="50rem"
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent="center"
    >
      <Box
        display="flex"
        height="8.125rem"
        width="8.125rem"
        alignItems={"center"}
        justifyContent="center"
        borderRadius={"50%"}
      >
        <img src={NoScorecard} alt="no-scorecard" />
      </Box>
      <Typography variant="h4" mt="1rem" fontSize={"1.5rem"}>
        {t("what_scorecard_would_you_like_to_see")}
      </Typography>

      <Typography variant="neutral">
        {t("please_select_an_item_from_customer_journey_menu")}
      </Typography>
    </Box>
  );
};
export default NoData;
