import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles(theme => ({
  card: {
    paddingBottom: conv(26)+" !important",
    borderRadius:conv(12),
    width: conv(400),
    margin: "20% auto 0",
    background:`${theme.palette.background.mainContent} !important`,
    border:"none",
    textAlign: "center",
    "&:focus-visible":{
      outline:"none !important",
    },
    "& h2": {
      color: `${theme.palette.text.white} !important`,
      fontSize: conv(22),
      fontWeight: 500,
      textAlign:"center"
    },
    "& p": {
      color: `${theme.palette.text.lightGrey} !important`,
      fontSize: conv(16),
      marginTop:conv(16),
      lineHeight:conv(22),
      fontWeight: 400,
      textAlign:"center"
    },

  },
  circularProgressContainer: {
    // paddingTop: conv(20),
    textAlign: "center",
    "& div": { color: theme.palette.text.primary },
  },
  circularProgress: { display: "inline-block" },
}));
