import { makeStyles } from "@mui/styles";
import convertPxToRem from "../../Utils/UIHelper";
const conv = convertPxToRem.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  root: {
    border: `1px solid ${MUITheme.palette.border.lightgrey}`,
    borderRadius: conv(36),
    padding: "1.937rem",
    paddingTop:"2.312rem",
    marginLeft: conv(10),
    marginTop:conv(10),
    flex: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom:"2rem"
  },
  main_title: {
    color: MUITheme.palette.text.secondary + " !important",
    textAlign: "center",
    fontWeight:600+" !important"
  },
  classfication_box: {
    padding: "0.812rem",
    height:"9.437rem",
    maxHeight:"9.437rem",
    border: `1px solid ${MUITheme.palette.border.scorecard_border}`,
    background:  MUITheme.palette.background.scorecardBg,
    borderRadius: "2.25rem",
    textAlign:"center",
    alignItems:"center",
    justifyContent:"center",
    display:"flex",
    flexDirection:"column"
  },
  noDataBorder:{
    border: `1px solid ${MUITheme.palette.border.scorecard_nodata_border}`,    
    background:MUITheme.palette.background.scorecardNoDataBg + " !important",
  },
  sm_card:{
    // flex:1   
  },
  heading: {
    fontSize:  "1.875rem !important",
    lineHeight: "2.812rem !important",
    fontWeight: 700 + " !important",
    textOverflow:"ellipsis",
    overflow:"hidden",
    "-webkit-line-clamp": 1,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "overflowWrap":"anywhere"
  },
  noData: {
    fontSize:  "1.5rem !important",
    lineHeight: "2.25rem !important",
    fontWeight: 400 + " !important",
    textOverflow:"ellipsis",
    overflow:"hidden",
    "-webkit-line-clamp": 1,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "overflowWrap":"anywhere"
  },
  whiteText:{
    color:MUITheme.palette.text.secondary+" !important",
    "-webkit-line-clamp": 2+" !important",
  },
  heading_finance: {
    fontSize:  "1.875rem !important",
    lineHeight: "2.812rem !important",
    fontWeight: 400 + " !important",
    opacity: 0.8,
    textOverflow:"ellipsis",
    overflow:"hidden",
  },
  card_title: {
    color: MUITheme.palette.text.default + " !important",
    textAlign:"center",
    fontWeight:500 +"!important",
    lineHeight:"1.5rem !important",
    "-webkit-line-clamp": 2,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow:"hidden",
  },
  noDataColor:{
    color: MUITheme.palette.text.scorecard_noData + " !important",
  },
  card_value: {
    width:"100%"
  },
  card_subtitle: {
    fontWeight: 300,
    color: MUITheme.palette.text.secondary,
    textAlign:"center"
  },
  card_subvalue: {
    fontWeight: 700+" !important",
    color: MUITheme.palette.text.secondary,
    
  },
  greenText: { color: MUITheme.palette.background.primaryGreen },
  redText: { color: MUITheme.palette.background.primaryRed },
}));

