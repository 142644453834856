import Amplify, { Analytics, Logger } from "aws-amplify";

const recordEvent = async (name, data) => {
  try {
    // Logger.LOG_LEVEL = "DEBUG";   

    Analytics.record(name, data, null);
  } catch (err) {
    console.log("Error: ", err);
  }
};
export default { recordEvent };
