import React, { useCallback, useEffect, useState } from "react";
import { Typography, Box, Stack } from "@mui/material";
import { useTranslate } from "../../Hooks/useTranslate";
import CustomListHeader from "./CustomListHeader";
import SearchInput from "../Shared/SearchInput";
import {
  appActions,
  customListActions,
} from "../../Services/Redux/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import LoadingView from "../LoadingView";
import NoDataView from "../NoDataView";
import { useNavigate } from "react-router-dom";
import {
  ACTION_EVENT_TYPE,
  ANALYTICS_EVENT_SUBTYPE,
  FEEDTYPES,
} from "../../Utils/Constants";
import { feedActions } from "../../Services/Redux/actionCreators";
import ScrollList from "./ScrollList";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  EVENT_TYPE,
  ACTION_EVENT_SUBTYPE,
  VIEW_EVENT_TYPE,
} from "../../Utils/Constants";
import { SORT_ORDER, ANALYTICS_TYPE, PAGE_SIZE } from "../../Utils/Constants";

const AccountList = (props) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const portfolioTimestamp = useSelector((state) => state.portfolio.timestamp);
  const listData = useSelector(
    (state) => state.customListData.customListData[props.type]
  );
  const listMetaData = useSelector(
    (state) => state.customListData.customListMetaData[props.type]
  );
  const searchText = useSelector(
    (state) => state.customListData.searchData[props.type]
  );

  const pfListSource = useSelector(
    (state) => state.customListData.pf_acclist_source
  );
  const searchParams = useSelector(
    (state) => state.customListData.sortData[props.type]
  );
  const fieldMapping = useSelector(
    (state) => state.app.mapping_data.field_mapping
  );
  let headers = [
    {
      field: "name",
      is_enabled: true,
      display_value: t(props.type === "portfolio" ? "portfolio" : "account"),
      sort_direction: "ASC",
      size: fieldMapping.fin_value.is_enabled ? 5 : 7,
    },
    {
      ...fieldMapping.fin_value,
      display_value: fieldMapping.fin_value.display_value || t("acv"),
      field: "fin_value",
      sort_direction: "",
      size: 3,
    },
    {
      field: "nps_class",
      is_enabled: true,
      display_value: t("str_nps"),
      sort_direction: "",
      size: fieldMapping.fin_value.is_enabled ? 3 : 4,
    },
    {
      field: "nps_class_change",
      is_enabled: true,
      display_value: t("header_chg"),
      sort_direction: "",
      size: 1,
    },
  ];
  if (props.headers) headers = props.headers;

  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [query, setQuery] = useState(null);
  let title = "account";
  if (props.type === "portfolio") title = "portfolio";

  const loadFirstPage = async (page) => {
    if (page === 0) {
      setLoading(true);
      await loadData(1);
      setLoading(false);
    }
  };

  const loadData = async (page) => {
    setListLoading(true);

    const { sort_by, sort_order } = searchParams;
    const q = query;
    if (props.type === "portfolio_accounts") {
      if (q) {
        Pinpoint.sendEventData(EVENT_TYPE.ACTION, [
          ACTION_EVENT_TYPE.ACCOUNT,
          ACTION_EVENT_SUBTYPE.SEARCH,
        ]);
      }
      await dispatch(
        customListActions.loadData(
          "portfolio_accounts",
          page,
          PAGE_SIZE,
          sort_by,
          sort_order,
          q,
          "portfolio",
          props.source_id,
          portfolioTimestamp
        )
      );
    } else {
      if (q) {
        if (props.type == ANALYTICS_TYPE.PORTFOLIO) {
          Pinpoint.sendEventData(EVENT_TYPE.ACTION, [
            ACTION_EVENT_TYPE.PORTFOLIO,
            ACTION_EVENT_SUBTYPE.SEARCH,
          ]);
        } else if (props.type == ANALYTICS_TYPE.ACCOUNT) {
          Pinpoint.sendEventData(EVENT_TYPE.ACTION, [
            ACTION_EVENT_TYPE.ACCOUNT,
            ACTION_EVENT_SUBTYPE.SEARCH,
          ]);
        }
      }
      await dispatch(
        customListActions.loadData(
          props.type,
          page,
          PAGE_SIZE,
          sort_by,
          sort_order,
          q
        )
      );
    }
    setListLoading(false);
  };

  useEffect(() => {
    if (!listMetaData) {
      loadFirstPage(0);
    }
  }, [listMetaData]);

  const loadPFAccounts = () => {
    if (
      props.source_id &&
      props.type === "portfolio_accounts" &&
      portfolioTimestamp
    ) {
      loadFirstPage(0);
      dispatch(
        customListActions.setPfListSource({
          source_id: props.source_id,
          timestamp: portfolioTimestamp,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !pfListSource ||
      (pfListSource &&
        (pfListSource.source_id !== props.source_id ||
          pfListSource.timestamp !== portfolioTimestamp))
    ) {
      loadPFAccounts();
    }
  }, [props.type, props.source_id, portfolioTimestamp, pfListSource]);

  useEffect(() => {
    if (query != null) {
      loadFirstPage(0);
    }
  }, [query]);

  useEffect(() => {
    if (searchParams?.changed) {
      loadFirstPage(0);
    }
  }, [searchParams]);

  useEffect(()=>{
    if(searchText)
    {
      loadFirstPage(0);
      dispatch(customListActions.setSearchText("", props.type));
    }
  },[])
  useEffect(() => {
    return () => {
      dispatch(customListActions.setChanged(false, props.type));
    };
  }, []);

  useEffect(() => {
    if (props.type == ANALYTICS_TYPE.PORTFOLIO) {
      Pinpoint.sendEventData(EVENT_TYPE.VIEW, [VIEW_EVENT_TYPE.PORTFOLIO_LIST]);
    } else if (props.type === "portfolio_accounts") {
      Pinpoint.sendEventData(
        EVENT_TYPE.VIEW,
        [VIEW_EVENT_TYPE.ACCOUNT_LIST, ANALYTICS_EVENT_SUBTYPE.PORTFOLIO],
        [props.name]
      );
    } else if (props.type == ANALYTICS_TYPE.ACCOUNT)
      Pinpoint.sendEventData(EVENT_TYPE.VIEW, [VIEW_EVENT_TYPE.ACCOUNT_LIST]);
  }, [props.type]);

  const onSortData = (sort_by, sort_order) => {
    dispatch(
      customListActions.setSortData(
        { sort_by, sort_order, changed: true },
        props.type
      )
    );
  };

  const onSearchData = (q) => {
    dispatch(customListActions.setSearchText(q, props.type));
    setQuery(q);
  };
  const onClearSearch = () => {
    dispatch(customListActions.resetSortData(props.type));
    dispatch(customListActions.setSearchText("", props.type));
    setQuery("");
  };
  const handleNext = () => {
    if (listMetaData && listMetaData.page_number < listMetaData.page_count) {
      loadData(listMetaData.page_number + 1);
    }
  };

  const navigateOnClick = async (data, index) => {
    let basepath;
    let feedPayload;
    if (props.type == ANALYTICS_TYPE.PORTFOLIO) {
      feedPayload = {
        feed_id: data.id,
        source_id: data.id,
        feed_type: FEEDTYPES.PORTFOLIO,
        source_name: data.name,
      };

      basepath = ANALYTICS_TYPE.PORTFOLIO;
    } else {
      feedPayload = {
        feed_id: data.id,
        source_id: data.id,
        feed_type: FEEDTYPES.ACCOUNT,
        portfolio_id: data.portfolio_id,
        source_name: data.name,
      };

      basepath = ANALYTICS_TYPE.ACCOUNT;
    }
    if (props.type === "portfolio_accounts") {
      feedPayload["portfolio_id"] = props.source_id;

      dispatch(appActions.setLeftMenuOpen());
      dispatch(appActions.setRightMenuClose());
    }

    if (basepath && feedPayload) {
      let navPath = `${basepath}/feed_id/${data.id}/date`;
      if (feedPayload.portfolio_id) {
        navPath = `${navPath}/${feedPayload.portfolio_id}`;
      }
      await dispatch(feedActions.setSelectedFeed(feedPayload));
      navigate(navPath);
    }
  };
  const conv = window.conv;

  const getView = () => {
    let component = <></>;
    if (loading)
      component = (
        <Box
          height={"100%"}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <LoadingView />
        </Box>
      );
    else if ((!loading && listData && listData.length === 0) || !listMetaData) {
      component = (
        <Box
          height={"100%"}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <NoDataView />
        </Box>
      );
    } else
      component = (
        <ScrollList
          onNext={handleNext}
          type={props.type}
          headers={headers}
          loading={listLoading}
          onClick={navigateOnClick}
          data={{ list: listData, meta: listMetaData }}
        />
      );
    return component;
  };

  return (
    <Box>
      <Box ml={conv(23)} mr={conv(12)}>
        <Box
          display={"flex"}
          justifyContent="space-between"
          mt={conv(45)}
          mr={conv(24)}
        >
          <Typography variant="h4" color="text.heading">
            {t(title + "s")}
          </Typography>
          <Typography variant="h5" color="text.neutral">
            {!loading && listMetaData?.total_records}
          </Typography>
        </Box>

        <Box mt={conv(26)} mb={conv(32)} mr={conv(24)}>
          <SearchInput
            disabled={!listMetaData}
            onKeyUp={onSearchData}
            onClear={onClearSearch}
            placeholder={t("hint_search_by_name")}
          />
        </Box>
      </Box>
      <Box height="calc(100vh - 22rem)" mr={window.conv(8)}>
        <Box
          pl={window.conv(11)}
          pr={window.conv(11)}
          ml={window.conv(12)}
          mr={window.conv(16)}
        >
          {searchParams && (
            <CustomListHeader
              sortOptions={searchParams}
              headers={headers}
              onSortData={onSortData}
            />
          )}
        </Box>
        {getView()}
      </Box>
    </Box>
  );
};

export default React.memo(AccountList);
