import * as React from "react";
import Box from "@mui/material/Box";

const TabPanel = props => {
  const { children, value,padding=0, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      flex={1}
      padding={padding}
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box display="flex" flex="1" >{children}</Box>}
    </Box>
  );
};

export default TabPanel;
