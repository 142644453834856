import {useCallback, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import { withStyles } from "@mui/styles";
import { useStyles } from "./styles";
import { useTranslate } from "../../../Hooks/useTranslate";
import _, {debounce} from "lodash";


const SearchInput = ({disabled=false,...props}) => {
  const t = useTranslate();
  const [value, setValue] = useState("");

  const debouncedFilter = useCallback(debounce(e => {
    if(e.target.value.length>=3) props.onKeyUp(e.target.value);
  }, 400), []);

  const onInput = (e) => {
    debouncedFilter(e);
  }
  const onClear = () => {
    setValue("");
    props.onClear("");    
  }
  const onChange = (e) => {
    if(disabled)
      return;
    if(e.target.value==="")
      onClear();
    else
      setValue(e.target.value);
  }; 

  return (
    <Box className={clsx(props.classes.styledSearchField)} display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexGrow={1}>
      <Box display={"flex"} alignItems={"center"} justifyContent={"flex-start"} className={clsx()} flexGrow={1}>
        <IconButton aria-label="search">
          <SearchIcon />
        </IconButton>        
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={props.placeholder}
          inputProps={{ ...props.InputProps, "aria-label": props.placeholder }}
          {...props.restProps}
          onKeyUp={onInput}
          onChange={onChange} value={value}
        />      
      </Box>
      { value && value.length > 0 &&
        <Button onClick={onClear}>
          <CloseIcon />
        </Button>
      }
    </Box>
  );
}

export default withStyles(useStyles)(SearchInput);