import { API } from "aws-amplify";
import { fetchAuthenticatedUser } from "./userActions/loginActions";
import { APIEndpoints, APIPaths } from "../../AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../Utils/API";
import { performanceActions } from ".";

export const SET_FEEDS = "SET_FEEDS";

export const CLEAR_FEEDS = "CLEAR_FEEDS";

export const APPEND_FEEDS = "APPEND_FEEDS";

export const SELECT_FEED = "SELECT_FEED";

export const CLEAR_FEED_DATA="CLEAR_FEED_DATA";

export const SET_FEEDCARD_LOADED="SET_FEEDCARD_LOADED";

const setFeeds = (filter, feeds) => ({
  type: SET_FEEDS,
  payload: { feeds: feeds, filter: filter },
});

export const clearAllData=()=>({type:CLEAR_FEED_DATA});

const clearFeeds = () => ({ type: CLEAR_FEEDS });

const appendFeeds = (filter, feeds) => ({
  type: APPEND_FEEDS,
  payload: { feeds: feeds, filter: filter },
});

export const setSelectedFeed = (feed) => ({ type: SELECT_FEED, payload: feed });

export const setFeedCardLoaded = (data) => ({ type: SET_FEEDCARD_LOADED, payload: data });

export const GENERATE_SHARE_LINK = "GENERATE_SHARE_LINK";
export const loadFeeds =
  (pageNo = 1, filter = "all", pageSize = 15) =>
    async (dispatch) => {
      try {
        let start=new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.FEEDS(pageNo, pageSize, filter);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: "FEEDS",
            api: "LOAD FEEDS",
            start,
            apiName,
            apiPath
          })
        );
        if (pageNo === 1) dispatch(setFeeds(filter, apiResult.data));
        else dispatch(appendFeeds(filter, apiResult.data));

        return apiResult;
      } catch (e) {
        throw e;
      }
    };
export const setShareLink = (data) => ({
  type: GENERATE_SHARE_LINK,
  payload: data,
});
export const generateShareLink =
  (payload
   
  ) =>
    async (dispatch) => {
      try {
        let start=new Date();
        dispatch(setShareLink(null));
        
        let  { source_id ,
          source_name,
          source_type,
          timestamp,
          jtp_id,
          op_id,
          op_color,
          op_name,
          theme,
          description,
          filter,
          portfolio_id
        }=payload;
        let jsonShareData = {};
        let jsonData = {};
        if(!source_id)
          return;
        jsonData["source_id"] = source_id;

        if (source_name) {
          jsonShareData["source_name"] = source_name;
          jsonShareData["title"] = source_name;
        }

        if (source_type) {
          jsonData["source_type"] = source_type;
        }

        if (jtp_id) {
          jsonData["jtp_id"] = jtp_id;
        }
        if (op_id) {
          jsonData["kpi_corelation_id"] = op_id;
        }
        if (op_color) {
          jsonData["kpi_corelation_color"] = op_color;
        }
        if (op_name) {
          jsonData["kpi_corelation_name"] = op_name;
        }
        if (timestamp) {
          jsonData["timestamp"] = timestamp;
        }
        if(portfolio_id)
        {
          jsonData["portfolio_id"]=portfolio_id;
        }
        if(filter)
        {
          jsonData["filter"]=filter;
        }
        // jsonData["showScoreCard"]=true;
        jsonShareData["data"] = jsonData;
        if(description)
          jsonShareData["description"] =description;
        jsonShareData["theme"] = theme;
        
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.UTILITY.name;
        const apiPath = APIPaths.SHARE_API();
        const apiOptions = initializeAPIOptions(
          token,
          enterprise_id,
          jsonShareData
        );
        let apiResult = await API.post(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: "SHARE",
            api: "CREATE SHARE DEEPLINK",
            start,
            apiName,
            apiPath
          })
        );
        dispatch(setShareLink(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };
