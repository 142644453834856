import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  outlinedBox: {
    border: "1px solid "+MUITheme.palette.border.userSettingBoxBorder,
    color: MUITheme.palette.background.secondary,
    padding: conv(20,22),
    borderRadius: conv(15),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: conv(21),
    "& img": {
      paddingRight: "0.75rem",
    },
    "& h6": {
      fontWeight:500+" !important"
    },
  },
  pointer: {
    cursor: "pointer",
  },
}));
