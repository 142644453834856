import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useStyles } from "./styles";
import JTPBars from "../Feeds/JTPView/JTPBars";
import LoadingView from "../LoadingView";
import { portfolioActions } from "../../Services/Redux/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslate } from "../../Hooks/useTranslate";
import OperationalDrivers from "../OperationalDrivers";
import Helpers from "../../Utils/Helper";
import Animator from "../Animator";
import NoDataView from "../NoDataView";
import UIHelper from "../../Utils/UIHelper";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  EVENT_TYPE,
  VIEW_EVENT_TYPE,
  ANALYTICS_EVENT_SUBTYPE,
} from "../../Utils/Constants";
const conv = UIHelper.convertPxToRem;

const CustomerJourney = (props) => {
  const classes = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentJTPValue, setCurrentJTPValue] = useState(-1);
  const params = useParams();
  let portfolioData = useSelector((state) => state.portfolio.data);
  let portfolioJtp = useSelector((state) => state.portfolio.jtpData);
  let operationalDrivers = useSelector(
    (state) => state.portfolio.operationalDrivers
  );
  const filters = useSelector((state) => state.portfolio.applied_filters);
  let jtp = useSelector((state) => state.portfolio.selected_jtp);
  let jtps = useSelector((state) => state.account.jtpData);
  let driver = useSelector((state) => state.portfolio.selected_op_driver);
  let loading_jtp = useSelector((state) => state.portfolio.loading_jtp);

  let timestamp = useSelector((state) => state.portfolio.timestamp);
  const loadOperationalDrivers = async (jtpId) => {
    const { id } = params;
    setLoading(true);
    let filterData;
    if (filters) filterData = Helpers.formatFilterData(filters);
    await dispatch(
      portfolioActions.getOperationalDrivers(id, jtpId, timestamp, filterData)
    );
    setLoading(false);
  };

  useEffect(() => {
    if (jtp) {
      let selectedJTP = jtps?.find((item) => item.id === jtp);
      Pinpoint.sendEventData(
        EVENT_TYPE.VIEW,
        [VIEW_EVENT_TYPE.PORTFOLIO_ANALYTICS, ANALYTICS_EVENT_SUBTYPE.JOURNEY],
        [portfolioData?.name, selectedJTP?.name]
      );
    }
    if (jtp && (currentJTPValue === -1 || currentJTPValue > 0)) {
      loadOperationalDrivers(jtp);
    } else {
      dispatch(portfolioActions.selectOperationalDriver(null));
      dispatch(portfolioActions.setKPIMetrics(null));
      // dispatch(portfolioActions.setOperationalDrivers([]));
    }
  }, [jtp, currentJTPValue, filters, jtps]);

  const handleChange = (id, value) => {
    setCurrentJTPValue(value);
    dispatch(portfolioActions.selectOperationalDriver(null));
    dispatch(portfolioActions.setKPIMetrics(null));
    // dispatch(portfolioActions.setOperationalDrivers(null));
    dispatch(portfolioActions.selectJTP(id == jtp ? null : id));
  };
  const handleAnimationEnd = () => {
    dispatch(portfolioActions.setOperationalDrivers([]));
  };

  const handleDriverSelect = (val) => {
    if (val) dispatch(portfolioActions.selectOperationalDriver(val));
  };

  useEffect(() => {
    const { id } = params;
    if (id && driver) {
      let filterData;
      if (filters) filterData = Helpers.formatFilterData(filters);
      dispatch(
        portfolioActions.getKPIMetrics(id, jtp, driver, timestamp, filterData)
      );
      let selectedJTP = jtps?.find((item) => item.id === jtp);
      let selectedDriver = operationalDrivers?.find(
        (item) => item.id === driver
      );
      Pinpoint.sendEventData(
        EVENT_TYPE.VIEW,
        [
          VIEW_EVENT_TYPE.PORTFOLIO_ANALYTICS,
          ANALYTICS_EVENT_SUBTYPE.JOURNEY,
          ANALYTICS_EVENT_SUBTYPE.OPDriverKPIs,
        ],
        [portfolioData?.name, selectedJTP?.name, selectedDriver?.display_name]
      );
    }
  }, [driver, filters, portfolioData]);

  if (!loading_jtp && portfolioJtp?.length == 0) {
    return "";
  }

  return (
    <Box className={classes.box}>
      {portfolioJtp && (
        <>
          {/* <Animator animation="slideDown"> */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="text.subheading">
              {t("customer_journey")}
            </Typography>
          </Stack>
          <Box className={classes.jtpBoxContainer}>
            {loading_jtp ? (
              <LoadingView />
            ) : portfolioJtp?.length > 0 ? (
              <Box className={classes.jtpBox}>
                <JTPBars
                  data={portfolioJtp}
                  lg={true}
                  readOnly={false}
                  onClick={handleChange}
                  value={jtp}
                  trackColor="background.jtpBarBg"
                />
              </Box>
            ) : (
              <NoDataView />
            )}
          </Box>
          {/* //</Animator> */}
        </>
      )}
      {loading && <LoadingView />}
      {!loading && (
        // <Animator animation="slideUp">
        <Box className={classes.opDriverBox}>
          <OperationalDrivers
            title={t("operational_drivers")}
            data={operationalDrivers}
            onClick={handleDriverSelect}
            onAnimationEnd={handleAnimationEnd}
            value={driver}
            jtp={jtp}
            trackColor="background.secondary"
          />
        </Box>
        // </Animator>
      )}
    </Box>
  );
};
export default React.memo(CustomerJourney);
