import React, { useState, useEffect } from "react";
import { Typography, Grid, Box } from "@mui/material";
import {
  appActions,
  feedActions,
  userActions,
} from "../../Services/Redux/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { getImageUrl } from "../../Utils/ImageHelper";
import { useTranslate } from "../../Hooks/useTranslate";
import { useStyles } from "./styles";
import PhoneIcon from "../../Assets/images/phone-icon.png";
import EmailIcon from "../../Assets/images/email-icon.png";
import UserLineIcon from "../../Assets/images/user-line.png";
import LoadingView from "../../Components/LoadingView";
import ProfileSetting from "../../Components/ProfileSetting";
import FileUpload from "../../Components/Shared/FileUpload";
import CameraIcon from "../../Assets/images/camera.png";
import AlertDialog from "../../Components/Shared/CustomAlert";
import UIHelper from "../../Utils/UIHelper";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  ACTION_EVENT_SUBTYPE,
  EVENT_TYPE,
  VIEW_EVENT_TYPE,
} from "../../Utils/Constants";
const conv = UIHelper.convertPxToRem;

const UserProfile = () => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const [phone, setPhone] = useState();
  const [isEdited, setIsEdited] = useState(false);
  const [error, setError] = useState(undefined);
  const [open, setOpen] = useState(false);
  const imageCookie = useSelector((state) => state.app.cloud_cookie);
  const user = useSelector((state) => state.user.user);
  const {
    left_menu_open,
  } = useSelector((state) => state.app);
  const classes = useStyles();

  useEffect(() => {
    dispatch(appActions.setUserProfileLoaded(true));
    if (user) setPhone(user.phone_number);
  }, [user]);

  useEffect(() => {
    Pinpoint.sendEventData(EVENT_TYPE.VIEW, [VIEW_EVENT_TYPE.SETTINGS]);
    if(left_menu_open)
      dispatch(appActions.setLeftMenuClose());
    return () => {
      dispatch(appActions.setUserProfileLoaded(false));
    };
  }, []);


  const handlePhoneChange = (e) => {
    if (!isNaN(e.target.value)) {
      setPhone(e.target.value.trim());
      setIsEdited(true);
    }
  };
  const handleEditClick = async () => {
    if (isEdited) {
      if (phone && phone.length >= 10 && phone.indexOf("+") === 0) {
        setOpen(false);
        let payload = user;
        payload["phone_number"] = phone;
        await dispatch(userActions.loginActions.updateUser(payload));
        setIsEdited(false);
      } else {
        setOpen(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProfilePicUpload = async (e) => {
    const { value } = e.target;
    if (value && value.indexOf("fakepath") === -1) {
      let result = await dispatch(
        userActions.loginActions.updateProfilePhoto(value)
      );

      if (result?.data?.url) {
        let payload = user;
        payload["avatar"] = result.data.url;
        dispatch(userActions.loginActions.updateUser(payload));
      }
    }
  };

  const handleLogout = () => {
    Pinpoint.sendEventData(EVENT_TYPE.ACTION, [ACTION_EVENT_SUBTYPE.LOGOUT]);
  };

  const handleThemeChanged = () => {
    Pinpoint.sendEventData(EVENT_TYPE.ACTION, [
      ACTION_EVENT_SUBTYPE.LIGHT_MODE_TOGGLE,
    ]);
  };

  if (!user) return <LoadingView />;

  return (
    <Grid container flex="1" height="100%" bgcolor={"background.mainContent"}>
      <Grid item md={8} sm={8}>
        <Box p={conv(44, 24)} pr={conv(50)}>
          <Typography variant="h4">{t("settings")}</Typography>
          <Box mt={conv(42)}>
            <Typography variant="h5" fontWeight={700} fontSize={conv(25.59)}>
              {`${user.first_name || ""} ${user.last_name || ""}`}
            </Typography>
            {user.job_title && (
              <Typography
                variant="h6"
                color="text.lightblue"
                fontSize={conv(18.61)}
                alignItems={"center"}
                lineHeight={conv(28)}
                fontWeight={400}
              >
                {user.job_title}
              </Typography>
            )}
            <Grid container mt={conv(42)}>
              <Grid item md={6} sm={12} onClick={handleProfilePicUpload}>
                <FileUpload
                  style={{
                    height: conv(453),
                    width: conv(453),
                    borderRadius: "50%",
                  }}
                  icon={CameraIcon}
                  alt={user.display_name}
                  src={getImageUrl(user.avatar, imageCookie)}
                  onChange={handleProfilePicUpload}
                />
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xl={6}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                {user.display_name && (
                  <Box className={classes.box}>
                    <img src={UserLineIcon} alt="user-icon-line" />

                    <span>{user.display_name}</span>
                  </Box>
                )}

                <Box className={classes.box}>
                  <img src={EmailIcon} alt="email" />
                  {user.email.trim() && (
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  )}
                </Box>

                {/* {user?.phone_number.trim() && ( */}
                <Box display="flex">
                  <Box className={classes.mobileBox}>
                    <Box width={conv(20)} height={conv(20)}>
                      <img src={PhoneIcon} alt="phone" />
                    </Box>
                    <Box flex="1">
                      <input
                        type="tel"
                        className={classes.phoneInput}
                        value={phone}
                        onChange={handlePhoneChange}
                      ></input>
                    </Box>
                  </Box>
                  <Box className={classes.editButton} onClick={handleEditClick}>
                    {t(isEdited ? "done" : "edit")}
                  </Box>
                </Box>
                {/* )} */}

                <AlertDialog
                  open={open}
                  onClose={handleClose}
                  msg={t("valid_phone_num")}
                  title={t("error")}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        md={4}
        sm={4}
        bgcolor="background.userSettingBg"
        display="flex"
      >
        <ProfileSetting
          onLogout={handleLogout}
          onThemeChanged={handleThemeChanged}
        />
      </Grid>
    </Grid>
  );
};

export default UserProfile;
