
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  checkboxContainer: {
    "& .MuiSvgIcon-root":{
      height:"2rem !important",
      width:"2rem !important"
    }
  },
}));
