import * as React from "react";
import AppBar from "@mui/material/AppBar";
import { Stack } from "@mui/material";
import AppLogo from "../Shared/AppLogo";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import {
  userActions,
  appActions,
  portfolioActions,
  accountActions,
  feedActions,
  commentActions,
  alertActions,
  favoriteActions,
} from "../../Services/Redux/actionCreators";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getImageUrl } from "../../Utils/ImageHelper";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

const ResponsiveAppBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imageCookie = useSelector((state) => state.app.cloud_cookie);
  const userProfileLoaded = useSelector(
    (state) => state.app.user_profile_loaded
  );
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user.user);
  const [appState, setAppState] = React.useState();

  const onLogoClick = () => {
    clearRedux();
    navigate("/");
  };

  const clearRedux = () => {
    dispatch(appActions.clearReduxData());
  };

  const handleOpenUserMenu = () => {
    if (!userProfileLoaded) {
      setAppState({
        active_menu: app.active_menu,
        left_menu_open: app.left_menu_open,
        footer_menu: app.footer_menu,
        right_menu_open: app.right_menu_open,
      });
      if (app.left_menu_open) dispatch(appActions.setLeftMenuClose());
      if(app.right_menu_open)dispatch(appActions.setRightMenuClose());
      navigate("/dashboard/profile");
    } else {
      if (appState) {
        dispatch(feedActions.setSelectedFeed(null));
        if (appState.left_menu_open) {          
          dispatch(appActions.setMainMenu(appState.active_menu));
          dispatch(appActions.setLeftMenuOpen());
          dispatch(appActions.setRightMenuClose());
        }
        if (appState.right_menu_open&&appState.footer_menu) {          
          dispatch(appActions.setFooterMenu(appState.footer_menu));
          dispatch(appActions.setRightMenuOpen());
          dispatch(appActions.setLeftMenuClose());
        }
      }
      navigate(-1);
    }
  };

  return (
    <AppBar position="static" classes={{ root: classes.root }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.backgroundImg}
      >
        <div
          className={clsx(classes.box, classes.pointerCursor)}
          onClick={onLogoClick}
        >
          <AppLogo width="120px" />
        </div>
        <div className={classes.box}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            {imageCookie && user && (
              <Avatar
                className={classes.userIcon}
                sx={{
                  width: 38,
                  border: "none",
                  objectFit: "cover",
                  height: 38,
                }}
                alt={user.display_name}
                src={getImageUrl(user.avatar, imageCookie)}
              />
            )}
          </IconButton>
        </div>
      </Stack>
    </AppBar>
  );
};

export default ResponsiveAppBar;
