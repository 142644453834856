import React from "react";
import { useTheme } from "@mui/material";
import CardItem from "./CardItem";
import { KPI_CONDITION_FLAGS } from "../../Utils/Constants";
import Helpers from "../../Utils/Helper";

let percentage = "percentage";
const KPICardItem = ({ data, title, sub_title, mr, ml, onClick }) => {
  const theme = useTheme();
  if (!data)
    return (
      <CardItem title={title} value={null} mr={mr} ml={ml} onClick={onClick} />
    );

  let newtitle = "";
  if (data.name) newtitle = data.name;

  if (data.actual_value === null)
    return (
      <CardItem
        title={newtitle}
        value={null}
        mr={mr}
        ml={ml}
        onClick={onClick}
      />
    );

  let green = theme.palette.background.primaryGreen;
  let red = theme.palette.background.primaryRed;
  let grey = theme.palette.text.neutral;

  let textColor = green;
  if (data.condition_flag.toLowerCase() === KPI_CONDITION_FLAGS.DIRECT) {
    if (data.threshold_value > data.actual_value) {
      textColor = red;
    } else textColor = green;
  }
  if (data.condition_flag.toLowerCase() === KPI_CONDITION_FLAGS.INVERSE) {
    if (data.threshold_value > data.actual_value) {
      textColor = green;
    } else textColor = red;
    if (!data.actual_type) {
      textColor = grey;
    }
  }
  return (
    <CardItem
      title={newtitle}
      value={`${Helpers.FormatKPIValue(data.actual_value).replace(" ", "")}${
        data.measurement_unit === percentage ? "%" : ""
      }`}
      sub_title={sub_title}
      sub_value={data.actual_type}
      color={textColor}
      mr={mr}
      ml={ml}
      onClick={onClick}
    />
  );
};

export default KPICardItem;
