import React from "react";
import { UncheckedColor } from ".";

const Favorites = ({ checked }) => {
  return (
    <svg
      width={window.conv(26)}
      height={window.conv(23)}
      viewBox="0 0 29 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2117 2.05361L18.1305 7.92523C18.2767 8.22139 18.559 8.42808 18.8867 8.47597L25.4212 9.41991C25.6858 9.4552 25.924 9.59383 26.0866 9.80556C26.3916 10.2025 26.345 10.7646 25.9795 11.1062L21.2434 15.686C21.0027 15.9128 20.8955 16.2456 20.9586 16.5694L22.0928 23.0321C22.1722 23.5677 21.8067 24.0693 21.2711 24.1563C21.0493 24.1903 20.8225 24.155 20.6208 24.0555L14.8009 21.0044C14.5085 20.8456 14.1581 20.8456 13.8657 21.0044L8.00294 24.0719C7.5127 24.3214 6.91281 24.1361 6.64563 23.656C6.54355 23.4619 6.50826 23.2413 6.54355 23.0258L7.67779 16.5631C7.73451 16.2405 7.62738 15.9091 7.39297 15.681L2.63166 11.1024C2.24349 10.7167 2.24097 10.0891 2.62788 9.70096L2.63166 9.69592C2.79171 9.55098 2.98831 9.45394 3.2013 9.41613L9.73708 8.47219C10.0635 8.42052 10.3445 8.21635 10.4932 7.92019L13.4095 2.05361C13.5267 1.81542 13.7347 1.63268 13.9867 1.5495C14.24 1.46507 14.5173 1.48523 14.7555 1.60496C14.9508 1.702 15.1109 1.85953 15.2117 2.05361Z"
        fill={checked ? UncheckedColor : "none"}
        stroke={UncheckedColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Favorites;
