import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import { Badge } from "@mui/material";
import clsx from "clsx";
const MenuButton = ({ icon: Icon, checked, filled, onClick, badge }) => {
  const classes = useStyles();
  const handleOnClick = () => {
    onClick && onClick();
  };

  if (filled)
    return (
      <div onClick={handleOnClick}>
        <Badge  showZero={false} className={classes.badge} badgeContent={badge}>
          <Icon checked={checked} />
        </Badge>
      </div>
    );
  return (
    <div
      onClick={handleOnClick}
      className={clsx([classes.button, checked && classes.buttonChecked] )}
    >
      <Badge showZero={false}  className={classes.badge} badgeContent={badge}>
        {" "}
        <Icon checked={checked} />
      </Badge>
    </div>
  );
};
export default MenuButton;

MenuButton.propTypes = {
  icon: PropTypes.func,
  checked: PropTypes.bool,
  small: PropTypes.bool,
  filled: PropTypes.bool,
  onClick: PropTypes.func,
};

MenuButton.defaultProps = {
  icon: "",
  checked: false,
  small: false,
  filled: false,
  onClick: () => {},
};
