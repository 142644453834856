import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(MUITheme => ({
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    color: MUITheme.palette.text.default,
    alignItems: "center",
    justifyContent: "center",
    "& span":{
      color: MUITheme.palette.text.default,
      fontSize: "1rem",
      letterSpacing: "0.25px",
    }
  },

  footerLink: {
    padding: "0px 10px",
    // marginTop: "20px",
    color: MUITheme.palette.text.default,
    fontSize: "1rem",
    letterSpacing: "0.25px",
    textDecoration: "none",
    "& a": {
      color:MUITheme.palette.text.default,
      textDecoration: "none",
    },
    "& a:hover": { color: MUITheme.palette.text.default },
  },
}));
