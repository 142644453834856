import React, { lazy } from "react";
import store from "../Services/Redux/Store";
import withAppBar from "../HOC/withAppBar";
import withLoginContainer from "../HOC/withLoginContainer";
import Dashboard from "../Pages/Dashboard";

const Login = lazy(() => import("../Pages/Login"));

const ForgotPassword = lazy(() => import("../Pages/ForgotPassword"));
const ForgotPasswordConfirm = lazy(() =>
  import("../Pages/ForgotPasswordConfirm")
);

const DashboardComponent = withAppBar(Dashboard);
const Password = lazy(() => import("../Pages/Password"));
const VerifyCode = lazy(() => import("../Pages/VerifyCode"));
const ResetPassword = lazy(() => import("../Pages/ResetPassword"));
const PasswordSuccess = lazy(() => import("../Pages/PasswordSuccess"));
const VerifyEmail = lazy(() =>
  import("../Pages/VerifyEmail")
);

const LoginComponent = withLoginContainer(Login);
const PasswordComponent = withLoginContainer(Password);
const VerificationCodeComponent = withLoginContainer(VerifyCode);
const ResetPasswordComponent = withLoginContainer(ResetPassword);
const PasswordSuccessComponent = withLoginContainer(PasswordSuccess);
const VerifyEmailComponent = withLoginContainer(VerifyEmail);
const ForgotPasswordComponent = withLoginContainer(ForgotPassword);
const ForgotPasswordConfirmComponent = withLoginContainer(
  ForgotPasswordConfirm
);

const LOGIN_PATH = "/login";
const FORGOT_PASSWORD_PATH = "/forgot-password";
const VERIFY_EMAIL_PATH = "/verify-email";
const FORGOT_PASSWORD_CONFIRM_PATH = "/confirm-forgot-password";
const RESET_PASSWORD_PATH = "/reset-password";
const PASSWORD_PATH = "/password";
const VERIFY_CODE_PATH = "/verification-code";
const PASSWORD_SUCCESS_PATH = "/password-success";
const DASHBOARD_PATH = "/dashboard";

export const GlobalRoutes = {
  LOGIN: {
    name: "login",
    path: LOGIN_PATH,
    component: <LoginComponent />,
    //component: <VerifyEmailComponent />,
    // component: <VerificationCodeComponent />,
    // component:<SendVerificationCodeComponent />
    // component: <ResetPasswordComponent />,
    // component: <PasswordSuccessComponent />,
  },

  PASSWORD: {
    name: "password",
    path: PASSWORD_PATH,
    component: <PasswordComponent />,
  },
  VERIFY_CODE: {
    name: "verify code",
    path: VERIFY_CODE_PATH,
    component: <VerificationCodeComponent />,
  },
  VERIFY_EMAIL_PATH: {
    name: "verify-email",
    path: VERIFY_EMAIL_PATH,
    component: <VerifyEmailComponent />,
  },
  RESET_PASSWORD: {
    name: "reset password",
    path: RESET_PASSWORD_PATH,
    component: <ResetPasswordComponent />,
  },
  PASSWORD_SUCCESS: {
    name: "password success",
    path: PASSWORD_SUCCESS_PATH,
    component: <PasswordSuccessComponent />,
  },
  FORGOT_PASSWORD: {
    name: "forgot password",
    path: FORGOT_PASSWORD_PATH,
    component: <ForgotPasswordComponent />,
  },
  FORGOT_PASSWORD_CONFIRM: {
    name: "forgot password",
    path: FORGOT_PASSWORD_CONFIRM_PATH,
    component: <ForgotPasswordConfirmComponent />,
  },
  LANDING: {
    name: "dashboard",
    path: DASHBOARD_PATH,
    component: <DashboardComponent />,
  },
};

export const setupRouteAuthentications = () => {
  const state = store.getState();
  const { isLoggedIn } = state.user;

  const redirectPath = getRedirectPath(isLoggedIn);
  const isAllowed = isLoggedIn;

  return {
    ...GlobalRoutes,
    LANDING: { ...GlobalRoutes.LANDING, isAllowed, redirectTo: redirectPath },
  };
};

const getRedirectPath = (isLoggedIn) => {
  // if (!isLoggedIn)
  return GlobalRoutes.LOGIN;
};
