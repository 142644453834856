import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  box: {
    flex: 1,
  },
  jtpBoxContainer:{
    background:MUITheme.palette.background.jtpBoxBg,      
    height:conv(297),
    borderRadius:conv(20),
    paddingTop:conv(16),
    paddingLeft:conv(20),
    paddingBottom:conv(19),
    paddingRight:conv(20),
    marginTop:conv(16)
  },
  jtpBox:{
    display:"flex",    
    height:"100%",
    overflow:"auto",
    overflowY:"hidden",
    "-webkit-overflow-scrolling": "touch",
    "-moz-overflow-scrolling": "touch",
    "-ms-overflow-scrolling": "touch",
    "-o-overflow-scrolling": "touch",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  opDriverBox:{
    marginTop:conv(60),
    flex:1
  },
  opDriverTitle:{
    marginBottom:conv(13)+" !important"
  }
}));
