import React, { useCallback, useState, useEffect } from "react";
import { Box, Grid, Typography, Stack, IconButton } from "@mui/material";
import CircularProgress from "../Shared/CircularProgress";
import GUID from "../../Utils/Guid";
import { useTheme } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { getImageUrl } from "../../Utils/ImageHelper";
import { NavigateBefore } from "@mui/icons-material";
import { accountActions } from "../../Services/Redux/actionCreators";
import { useTranslate } from "../../Hooks/useTranslate";
import { useStyles } from "./styles";
import LoadingView from "../LoadingView";
import PhoneIcon from "../../Assets/images/phone-icon.png";
import EmailIcon from "../../Assets/images/email-icon.png";
import NoDataView from "../NoDataView";
import SidebarHeader from "../SidebarHeader";
import UIHelper from "../../Utils/UIHelper";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import { ANALYTICS_TYPE, EVENT_TYPE, FEEDTYPES, VIEW_EVENT_TYPE } from "../../Utils/Constants";
const conv = UIHelper.convertPxToRem;

const defaultData = {
  user_id: "46a4abfc-9d73-4f1a-a513-9031d15c040f",
  first_name: "Karthikeyan",
  last_name: "Balusamy",
  display_name: "Karthikeyan Balusamy",
  enterprise_id: "conga",
  phone_number: "+9876543210",
  avatar: " ",
  email: "demo_staging@ocxcognition.com",
  job_title: "Admin",
  department: "IT",
  created_time: "2021-04-29 18:45:37",
  modified_time: "2021-04-29 18:45:37",
};

function MemberDetail({ id, onClose, ...props }) {
  const dispatch = useDispatch();
  const t = useTranslate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState();
  const imageCookie = useSelector((state) => state.app.cloud_cookie);

  const loadMemberDetail = useCallback(
    async (id) => {
      setLoading(true);
      let data = await dispatch(accountActions.getUserProfileById(id));
      if (data?.data) {
        setDetail(data.data);
        if (props?.data?.source === "ACCOUNT_TEAM") {
          Pinpoint.sendEventData(EVENT_TYPE.VIEW, [
            VIEW_EVENT_TYPE.ACCOUNT_TEAM_MEMBER_PROFILE,
          ],[props.data.name,`${data.data.first_name||""} ${data.data.last_name||""}`],[props.data.portfolio_name,props.data.sfdc_id]);
        }
        else if (props?.data?.source === "ALERT_ADMIN") {
          let attr=null;
          if(props.data.feed_type===FEEDTYPES.ACCOUNT)
            attr=[props.data.portfolio_name,props.data.sfdc_id];
          Pinpoint.sendEventData(EVENT_TYPE.VIEW, [
            VIEW_EVENT_TYPE.ALERT_ADMIN_PROFILE,
          ],[props.data.name,`${data.data.first_name||""} ${data.data.last_name||""}`],attr);
        }
        else   if (props?.data?.source === "ALERT_MANAGER") {
          let attr=null;
          if(props.data.feed_type===FEEDTYPES.ACCOUNT)
            attr=[props.data.portfolio_name,props.data.sfdc_id];
          Pinpoint.sendEventData(EVENT_TYPE.VIEW, [
            VIEW_EVENT_TYPE.ALERT_MANAGER_PROFILE,
          ],[props.data.name,`${data.data.first_name||""} ${data.data.last_name||""}`],attr);
        }
        else   if (props?.data?.source === "ACTION_ADMIN") {
          // let attr=null;
          // if(props.data.feed_type===FEEDTYPES.ACCOUNT)
          //   attr=[props.data.portfolio_name,props.data.sfdc_id];
          let view_names=[];
          if(props.data.name)
            view_names.push(props.data.name);
          view_names.push(`${data.data.first_name||""} ${data.data.last_name||""}`)
          Pinpoint.sendEventData(EVENT_TYPE.VIEW, [
            VIEW_EVENT_TYPE.ACTION_ADMIN_PROFILE,
          ],view_names);
        }
      }
      setLoading(false);
    },
    [props.data]
  );

  const handleClose = () => {
    onClose && onClose();
  };
  useEffect(() => {
    if (id) loadMemberDetail(id);
  }, [id]);

  if (loading) return <LoadingView mt="15%" />;
  if (!detail) return <NoDataView mt="15%" />;

  return (
    <Box display="flex" flexDirection="column">
      <SidebarHeader title={t("profile")} onBack={handleClose} />

      <Box>
        <Box mt={conv(36)} mb={conv(27)}>
          <Avatar
            sx={{ height: conv(100), width: conv(100) }}
            alt={detail.display_name}
            src={getImageUrl(detail.avatar, imageCookie)}
          />
        </Box>
        <Typography variant="h5" fontWeight={700}>
          {`${detail.first_name || ""} ${detail.last_name || ""}`}
        </Typography>
        {detail.job_title.trim() && (
          <Typography variant="h6" color="text.lightblue" alignItems={"center"}>
            {detail.job_title}
          </Typography>
        )}
        {detail.email.trim() && (
          <Box className={classes.box} mt={conv(29)}>
            <img src={EmailIcon} alt="email" />
            <a href={`mailto:${detail.email}`}>{detail.email}</a>
          </Box>
        )}
        {detail.phone_number.trim() && (
          <Box className={classes.box}>
            <img src={PhoneIcon} alt="phone" height="18px" />
            <a href={`tel:${detail.phone_number}`}>{detail.phone_number}</a>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default React.memo(MemberDetail);
