import { getTimeSince } from "./Date";
import { FILTER_TYPE } from "./Constants";
const AbbreviateNumber = (value) => {
  let newValue =value;
  if (value === 0) {
    return "0.0";
  }
  if (value < 0) {
    newValue = -newValue;
  }
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }
  newValue = newValue.toFixed(1);
  // newValue = newValue.toString(); 
  // newValue = newValue.slice(0, (newValue.indexOf("."))+2);
  newValue += suffixes[suffixNum];
  if (value < 0) return "-" + newValue;
  return newValue;
};
const DateToMMYYYY = (dt) => {
  let date = new Date(dt.replace(/-/g, "/"));
  var strArray = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  var m = strArray[date.getMonth()];
  var y = date.getFullYear();
  return m + ", " + y;
};

const ConvertListWithDateHeader = (arr, headerKey) => {
  const res = Object.entries(
    arr.reduce((r, data) => {
      let feed_date = data[headerKey];
      let val = getTimeSince(feed_date);
      r[val] = r[val] ?? [];
      r[val].push(data);
      return r;
    }, {})
  );

  let result = [];
  res.map(([k, v]) => result.push({ type: "HEADER", [headerKey]: k }, ...v));
  return result;
};
const AddAlpha = (color, opacity) => {
  // coerce values so ti is between 0 and 1.
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

const FormatKPIValue = (value) => {
  let newValue = value;


  if (newValue === 0) return "0.0";
  if (newValue < 1) {
    return parseFloat(newValue).toFixed(3);
  } else if (newValue >= 1 && newValue < 1000) {
    return parseFloat(newValue).toFixed(1);
  }
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }
  newValue = newValue.toFixed(1);

  newValue += suffixes[suffixNum];
  return newValue;
};

function reverseArray(arr) {
  let newArr = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    newArr.push(arr[i]);
  }
  return newArr;
}
function formatFilterData(filters) {
  if (filters) {
    let filterData = {};
    Object.keys(filters).map((item) => {
      let filterItem = filters[item];
      if (filterItem.type === FILTER_TYPE.CHECKBOX)
        filterData[item] = Object.keys(filterItem.values);
      else if (filterItem.type === FILTER_TYPE.RANGE) {
        filterData[item] = {
          min: filterItem.values[0],
          max: filterItem.values[1],
        };
      } else if (filterItem.type === FILTER_TYPE.CHECKBOX_PROFILE) {
        filterData[item] = Object.keys(filterItem.values).map((acc_key) => {
          let acc_item = filterItem.values[acc_key];
          if (acc_item?.user)
            return {
              account_id: acc_item.account_id,
              user: { user_id: acc_item.user.user_id },
            };
        });
      }
    });
    return filterData;
  }
  return null;
}

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
const Helpers = {
  AbbreviateNumber,
  DateToMMYYYY,
  ConvertListWithDateHeader,
  AddAlpha,
  FormatKPIValue,
  reverseArray,
  formatFilterData,
  sleep,
};
export default Helpers;
