import React from "react";

const ArrowUp = (props) => {
  return (
    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 13L5 2M5 2L9 5.63715M5 2L1 5.63715" stroke={props.fill} strokeWidth="1.72941" strokeLinecap="round"/>
    </svg>
  );
};

export default ArrowUp;