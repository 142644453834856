import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "../styles";
import { ALERT_TYPES } from "../../../Utils/Constants";
import Animator from "../../Animator";
import MessageAlert from "../MessageAlert";
import AnalyticsAlert from "../AnalyticsAlert";
import AlertMenu from "../AlertMenu";
import { useSelector } from "react-redux";
const conv = window.conv;

const AlertCard = ({ selected, onClick, data }) => {
  const classes = useStyles();
  const { alert_type } = data;
  const [showOption, setShowOption] = React.useState(false);
  const selectedTab=useSelector(state=>state.alerts.selected_tab);

  const handleClose = () => {
    setShowOption(false);
  };

  const handleClick = (e) => {
    onClick(data);
  };

  const handleMouseEnter = (e) => {
    setShowOption(true);
  };
  const handleMouseLeave = (e) => {
    setShowOption(false);
  };

  if (!data) return;
  let component;

  if (alert_type === ALERT_TYPES.MENTION || alert_type === ALERT_TYPES.REPLY)
    component = <MessageAlert data={data} is_read={data.is_read} />;
  else if (alert_type === ALERT_TYPES.ANALYTICS_TRIGGER) {
    component = <AnalyticsAlert data={data} is_read={data.is_read} />;
  }
  return (
    <Animator animation="slideUp">
      <div
        onClick={handleClick}
        className={clsx(classes.box, selected && classes.selectedBox)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {component}
        <AlertMenu showButton={showOption} data={data} onClose={handleClose} tab={selectedTab} />
      </div>
    </Animator>
  );
};

AlertCard.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  data: PropTypes.object,
};

AlertCard.defaultProps = {
  selected: false,
  data: null,
  onClick: () => {},
};
export default React.memo(AlertCard);
