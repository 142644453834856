import * as React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import clsx from "clsx";
import Animator from "../Animator";
import Actions from "../../Assets/images/menu-icons/actions";
import { useTheme } from "@mui/styles";

const ActionItem = ({ selected, onClick, data, key }) => {
  const theme=useTheme();
  const classes = useStyles();

  const handleClick = (e) => {
    onClick(data);
  };
  if (!data) return;
 
  return (
    <>
      <Animator animation="slideUp">
        <Box
          id={key}
          className={clsx(classes.box, selected && classes.selectedBox)}
          onClick={handleClick}
       
        >
          <Box pr={"1rem"} mt="5px">
            <Actions checked={true} color={theme.palette.text.tableRowTitle}/>
          </Box>
          <Box flex="1">
            <Typography variant="body2" fontWeight="500" color={theme.palette.text.tableRowTitle}>
              {data.title}
            </Typography>
          </Box>
        
        </Box>
      </Animator>
    </>
  );
};

ActionItem.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  data: PropTypes.object,
};

ActionItem.defaultProps = {
  selected: false,
  data: null,
  onClick: () => {},
};
export default React.memo(ActionItem);
