import React, { useCallback, useState } from "react";
import { Box, Grid, Typography, Stack } from "@mui/material";
import { useStyles } from "./styles";
import Helpers from "../../Utils/Helper";
import GUID from "../../Utils/Guid";
import Rangebar from "./Rangebar";
import { useTheme } from "@mui/styles";
import store from "../../Services/Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { portfolioActions } from "../../Services/Redux/actionCreators";
import Checkbox from "../Shared/Checkbox";
import AccountMembers from "./AccountMember";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
import { CURRENCIES_SYMBOLS, FILTER_TYPE } from "../../Utils/Constants";

function FilterItem({ data, title }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { type, options } = data;

  const name = data.key;

  const paginated = data.is_paginated;
  const selected_filters = useSelector(
    (state) => state.portfolio.selected_filters
  );

  const handleCheck = (item) => {
    let filters = { ...selected_filters };
    if (filters[name]) {
      if (filters[name]["values"][item.key]) {
        delete filters[name]["values"][item.key];
        if (Object.keys(filters[name]["values"]).length == 0) {
          delete filters[name];
        }
      } else filters[name]["values"][item.key] = item.value;
    } else {
      filters = {
        ...filters,
        [name]: { title, type, values: { [item.key]: item.value } },
      };
    }

    dispatch(portfolioActions.setSelectedFilters(filters));
  };

  const handleRangebarChange = (value) => {
    let filters = { ...selected_filters };
    let symbol = CURRENCIES_SYMBOLS[data.range.label];
    let newTitle = `${symbol} ${Helpers.AbbreviateNumber(
      value[0]
    )}-${symbol} ${Helpers.AbbreviateNumber(value[1])}`;

    if (filters[name]) {
      filters[name]["title"] = newTitle;
      filters[name]["values"] = value;
    } else {
      filters = {
        ...filters,
        [name]: { title: newTitle, type, values: value },
      };
    }
    dispatch(portfolioActions.setSelectedFilters(filters));
  };

  const getValue = (key) => {
    let selectedFilter = store.getState()?.portfolio?.selected_filters;
    if (
      selectedFilter &&
      selectedFilter[name] &&
      selectedFilter[name]["values"][key]
    )
      return true;
    return false;
  };

  const getACVValue = (data) => {
    let selectedFilter = store.getState()?.portfolio?.selected_filters;
    if (selectedFilter && selectedFilter[name])
      return selectedFilter[name]["values"];
    else if (data.range) return [data.range.min, data.range.max];
    else return [];
  };

  if (!paginated) {
    if (type === FILTER_TYPE.CHECKBOX) {
      return options.map((option) => (
        <Stack
          key={GUID()}
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          mb={conv(15)}
        >
          <Typography variant="h6" color="text.neutral">
            {option.value}
          </Typography>
          <Checkbox
            checked={getValue(option.key)}
            onClick={(checked) => handleCheck(option)}
          />
        </Stack>
      ));
    } else if (type === FILTER_TYPE.RANGE) {
      if (data.range)
        return (
          <Rangebar
            min={Math.round(data.range.min)}
            max={Math.round(data.range.max)}
            label={CURRENCIES_SYMBOLS[data.range.label]}
            value={getACVValue(data)}
            onChange={(value) => handleRangebarChange(value)}
          />
        );
    }
  } else if (type === FILTER_TYPE.CHECKBOX_PROFILE) {
    return (
      <AccountMembers
        getValue={getValue}
        onChange={(value) => handleCheck(value)}
      />
    );
  }
}

export default React.memo(FilterItem);
