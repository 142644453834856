import ANIMATION_TYPES from "./AnimationTypes";
import Animated from "./Animation";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
const CustomerJourneyAnimation = ({
  value,
  duration = 500,
  trigger,
  additionalTrigger,
  ...props
}) => {
  let config = {
    from: { y: 0 },
    to: {
      y: trigger ? -(value ) : 0,
    },
    config: { tension: 280, friction: 60 },
  };

  return (
    <Animated
      trigger={ trigger}
      type={ANIMATION_TYPES.SPRING}
      config={config}
      {...props}
    />
  );
};
export default CustomerJourneyAnimation;
