import { API } from "aws-amplify";
import { fetchAuthenticatedUser } from "./userActions/loginActions";
import { APIEndpoints, APIPaths } from "../../AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../Utils/API";
import { performanceActions } from ".";
import { FEEDTYPES } from "../../../Utils/Constants";

export const SET_PORTFOLIO_DATA = "SET_PORTFOLIO_DATA";
export const SET_PORTFOLIO_ANALYTICS_LOADED = "SET_PORTFOLIO_ANALYTICS_LOADED";
export const SET_PORTFOLIO_TRENDS = "SET_PORTFOLIO_TRENDS";
export const SET_PORTFOLIO_JTP = "SET_PORTFOLIO_JTP";
export const SET_ANALYTICS_REACTIONS = "SET_ANALYTICS_REACTIONS";
export const SET_ANALYTICS_STATUS = "SET_ANALYTICS_STATUS";
export const SET_OPERATIONAL_DRIVERS = "SET_OPERATIONAL_DRIVERS";
export const UPDATE_ANALYTICS_FOLLOW_STATUS = "UPDATE_ANALYTICS_FOLLOW_STATUS";
export const UPDATE_ANALYTICS_FAVORITE_STATUS =
  "UPDATE_ANALYTICS_FAVORITE_STATUS";
export const SET_PORTFOLIO_KPI_DATA = "SET_PORTFOLIO_KPI_DATA";
export const SET_PORTFOLIO_TIMESTAMP = "SET_PORTFOLIO_TIMESTAMP";
export const SELECT_PORTFOLIO_JTP = "SELECT_PORTFOLIO_JTP";
export const SELECT_PORTFOLIO_OPERATIONAL_DRIVER =
  "SELECT_PORTFOLIO_OPERATIONAL_DRIVER";
export const SELECT_PORTFOLIO_KPI_METRIC = "SELECT_PORTFOLIO_KPI_METRIC";
export const SET_PORTFOLIO_KPI_LOADING = "SET_PORTFOLIO_KPI_LOADING";
export const SET_PORTFOLIO_JTP_LOADING = "SET_PORTFOLIO_JTP_LOADING";

export const CLEAR_PORTFOLIO_DATA = "CLEAR_PORTFOLIO_DATA";
export const CLEAR_PORTFOLIO_ALL_DATA = "CLEAR_PORTFOLIO_ALL_DATA";

export const SET_PORTFOLIO_FILTERS = "SET_PORTFOLIO_FILTERS";
export const SET_PORTFOLIO_ACCOUNT_MEMBERS = "SET_PORTFOLIO_ACCOUNT_MEMBERS";
export const CLEAR_PORTFOLIO_ACCOUNT_MEMBERS =
  "CLEAR_PORTFOLIO_ACCOUNT_MEMBERS";
export const SET_SELECTED_PORTFOLIO_FILTERS = "SET_SELECTED_PORTFOLIO_FILTERS";
export const APPLY_PORTFOLIO_FILTERS = "APPLY_PORTFOLIO_FILTERS";
export const SET_APPLY_FILTERS = "SET_APPLY_FILTERS";
export const SET_PORTFOLIO_ALERTS = "SET_PORTFOLIO_ALERTS";
export const SET_PORTFOLIO_ALERT_CONFIGURED = "SET_PORTFOLIO_ALERT_CONFIGURED";
export const SET_DEEPLINK_DATA = "SET_PORTFOLIO_DEEPLINK_DATA";

export const SET_RELOAD_FUNCTION = "SET_RELOAD_FUNCTION";

const setPortfolioData = (data) => ({
  type: SET_PORTFOLIO_DATA,
  payload: data,
});
export const setTimestamp = (data) => ({
  type: SET_PORTFOLIO_TIMESTAMP,
  payload: data,
});
export const setLoaded = (data) => ({
  type: SET_PORTFOLIO_ANALYTICS_LOADED,
  payload: data,
});
const setPortfolioTrends = (data) => ({
  type: SET_PORTFOLIO_TRENDS,
  payload: data,
});
const setPortfolioJTP = (data) => ({
  type: SET_PORTFOLIO_JTP,
  payload: data,
});
const setAnalyticsReactions = (data) => ({
  type: SET_ANALYTICS_REACTIONS,
  payload: data,
});
const setAnalyticsStatus = (data) => ({
  type: SET_ANALYTICS_STATUS,
  payload: data,
});
export const setOperationalDrivers = (data) => ({
  type: SET_OPERATIONAL_DRIVERS,
  payload: data,
});
export const selectKPI = (data) => ({
  type: SELECT_PORTFOLIO_KPI_METRIC,
  payload: data,
});

const updateFollowStatus = (data) => ({
  type: UPDATE_ANALYTICS_FOLLOW_STATUS,
  payload: data,
});

const updateFavoriteStatus = (data) => ({
  type: UPDATE_ANALYTICS_FAVORITE_STATUS,
  payload: data,
});

export const setKPIMetrics = (data) => ({
  type: SET_PORTFOLIO_KPI_DATA,
  payload: data,
});

export const setKPIMetricsLoading = (data) => ({
  type: SET_PORTFOLIO_KPI_LOADING,
  payload: data,
});

export const selectJTP = (data) => ({
  type: SELECT_PORTFOLIO_JTP,
  payload: data,
});

export const selectOperationalDriver = (data) => ({
  type: SELECT_PORTFOLIO_OPERATIONAL_DRIVER,
  payload: data,
});

export const setSelectedFilters = (data) => ({
  type: SET_SELECTED_PORTFOLIO_FILTERS,
  payload: data,
});

export const applySelectedFilters = (data) => ({
  type: APPLY_PORTFOLIO_FILTERS,
  payload: data,
});

export const setApplyFilters = (data) => ({
  type: SET_APPLY_FILTERS,
  payload: data,
});
export const setReloadFunction = (data) => ({
  type: SET_RELOAD_FUNCTION,
  payload: data,
});

const setPortfolioFilters = (data) => ({
  type: SET_PORTFOLIO_FILTERS,
  payload: data,
});

export const setAccountMembers = (data) => ({
  type: SET_PORTFOLIO_ACCOUNT_MEMBERS,
  payload: data,
});

export const clearAccountMembers = () => ({
  type: CLEAR_PORTFOLIO_ACCOUNT_MEMBERS,
});

export const setAlerts = (data) => ({
  type: SET_PORTFOLIO_ALERTS,
  payload: data,
});

const setAlertConfigured = (data) => ({
  type: SET_PORTFOLIO_ALERT_CONFIGURED,
  payload: data,
});

export const clearData = () => ({ type: CLEAR_PORTFOLIO_DATA });

export const clearAllData = () => ({ type: CLEAR_PORTFOLIO_ALL_DATA });

const setJTPLoading = (loading) => ({
  type: SET_PORTFOLIO_JTP_LOADING,
  payload: loading,
});

export const setDeeplinkData = (data) => ({
  type: SET_DEEPLINK_DATA,
  payload: data,
});

export const portfolioValue =
  (sourceId = 6, timestamp, filter) =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ANALYTICS.name;
        const apiPath = APIPaths.PORTFOLIO_VALUE_API(sourceId);
        let payload = {  };
        if(filter)
        {
          payload["filter"]=filter;
        }
        if (timestamp) {
          payload["timestamp"] = timestamp;
        }
        const apiOptions = initializeAPIOptions(token, enterprise_id, payload);
        let apiResult = await API.post(apiName, apiPath, apiOptions).catch(
          (err) => {
          // console.log("API Error",err);
          }
        );
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: `PORTFOLIO VALUE${payload.filter?"-FILTER":""}`,
            start,
            apiName,
            apiPath,
            payload: payload,
          })
        );
        dispatch(setPortfolioData(apiResult ? apiResult.data : null));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const portfolioTrend =
  (sourceId = 1, timestamp, filter) =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ANALYTICS.name;
        const apiPath = APIPaths.PORTFOLIO_TREND_API(sourceId);
        let payload = {  };
        if(filter)
        {
          payload["filter"]=filter;
        }
        if (timestamp) {
          payload["timestamp"] = timestamp;
        }
        const apiOptions = initializeAPIOptions(token, enterprise_id, payload);

        let apiResult = await API.post(apiName, apiPath, apiOptions).catch(err=>{});
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: `PORTFOLIO TREND${payload.filter?"-FILTER":""}`,
            start,
            apiName,
            apiPath,
            payload: payload,
          })
        );
        dispatch(setPortfolioTrends(apiResult?.data));

        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const portfolioJtp =
  (sourceId = 1, timestamp, filter) =>
    async (dispatch) => {
      try {
        let start = new Date();
        dispatch(setJTPLoading(true));
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ANALYTICS.name;
        const apiPath = APIPaths.PORTFOLIO_JTP_API(sourceId);
        let payload = {  };
        if(filter)
        {
          payload["filter"]=filter;
        }
        if (timestamp) {
          payload["timestamp"] = timestamp;
        }
        const apiOptions = initializeAPIOptions(token, enterprise_id, payload);
        let apiResult = await API.post(apiName, apiPath, apiOptions).catch(
          (err) => {}
        );
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: `PORTFOLIO JTP${payload.filter?"-FILTER":""}`,
            start,
            apiName,
            apiPath,
            payload: payload,
          })
        );
        if (!apiResult || apiResult.length == 0) {
          dispatch(setOperationalDrivers(null));
          dispatch(setKPIMetrics(null));
        }
        dispatch(setPortfolioJTP(apiResult ? apiResult.data : []));
        dispatch(setJTPLoading(false));
        return apiResult;
      } catch (e) {
        dispatch(setPortfolioJTP([]));
        dispatch(setJTPLoading(false));
        throw e;
      }
    };

export const analyticsReactions =
  (sourceId = 1, sourceType) =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.ANALYTICS_REACTIONS_API(sourceId, sourceType);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: "PORTFOLIO ANALYTICS REACTIONS",
            start,
            apiName,
            apiPath,
          })
        );
        dispatch(setAnalyticsReactions(apiResult.data));

        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const analyticsStatus =
  (sourceId = 1, sourceType) =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.ANALYTICS_STATUS_API(
          sourceId,
          sourceType,
          sourceId
        );
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(setAnalyticsStatus(apiResult.data));
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: "PORTFOLIO ANALYTICS STATUS",
            start,
            apiName,
            apiPath,
          })
        );
        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const setPortfolioFollow =
  (sourceId = 1, sourceType, followStatus, sourceName) =>
    async (dispatch) => {
      try {
        let start = new Date();
        dispatch(updateFollowStatus(followStatus));
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.ANALYTICS_UPDATE_STATUS_API(
          sourceId,
          sourceType,
          followStatus,
          sourceName,
          sourceId
        );
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.post(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: "PORTFOLIO FOLLOW",
            start,
            apiName,
            apiPath,
          })
        );
        dispatch(updateFollowStatus(followStatus));

        return apiResult;
      } catch (e) {
        throw e;
      }
    };
export const getIsAlertConfigured =
  (sourceId, sourceType = "PORTFOLIO") =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ALERTS.name;
        const apiPath = APIPaths.IS_ALERT_CONFIGURED(sourceId, sourceType);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: "PORTFOLIO ALERT CONFIGURED",
            start,
            apiName,
            apiPath,
          })
        );
        dispatch(setAlertConfigured(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };
export const setPortfolioFavorite =
  (sourceId = 1, sourceType, favoriteStatus, sourceName) =>
    async (dispatch) => {
      try {
        let start=new Date();
        dispatch(updateFavoriteStatus(favoriteStatus));
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.ANALYTICS_UPDATE_FAVORITE_API(
          sourceId,
          sourceType,
          favoriteStatus,
          sourceName,
          sourceId
        );
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.post(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: "PORTFOLIO FAVORITE",
            start,
            apiName,
            apiPath
          })
        );
        dispatch(updateFavoriteStatus(favoriteStatus));

        return apiResult;
      } catch (e) {
        throw e;
      }
    };
export const getOperationalDrivers =
  (sourceId = 1, jtpId, timestamp, filter) =>
    async (dispatch) => {
      try {
        let start=new Date();
        dispatch(setKPIMetrics(null));
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ANALYTICS.name;
        const apiPath = APIPaths.PORTFOLIO_OPERATIONAL_DRIVERS(
          sourceId,
          jtpId,
          // timestamp
        );
        let payload = {  };
        if(filter)
        {
          payload["filter"]=filter;
        }
        if(timestamp)
        {
          payload["timestamp"]=timestamp;
        }
        const apiOptions = initializeAPIOptions(token, enterprise_id, payload);
        let apiResult = await API.post(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: `PORTFOLIO OPERATIONAL DRIVERS${payload.filter?"-FILTER":""}`,
            start,
            apiName,
            apiPath
          })
        );
        dispatch(setOperationalDrivers(apiResult.data));

        return apiResult;
      } catch (e) {
        throw e;
      }
    };
export const getKPIMetrics =
  (sourceId = 1, jtpId, kpiId, timestamp, filter) =>
    async (dispatch) => {
      try {
        let start=new Date();
        dispatch(setKPIMetrics(null));
        dispatch(setKPIMetricsLoading(true));
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ANALYTICS.name;
        const apiPath = APIPaths.PORTFOLIO_KPI_DRILLDOWN(
          sourceId,
          jtpId,
          kpiId
        );
        let payload = {  };
        if(filter)
        {
          payload["filter"]=filter;
        }
        if(timestamp)
          payload["timestamp"]=timestamp;
        const apiOptions = initializeAPIOptions(token, enterprise_id, payload);
        let apiResult = await API.post(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: `PORTFOLIO KPI METRICS${payload.filter?"-FILTER":""}`,
            start,
            apiName,
            apiPath
          })
        );
        dispatch(setKPIMetrics(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      } finally {
        dispatch(setKPIMetricsLoading(false));
      }
    };

export const getPortfolioFilters =
  (sourceId = 1) =>
    async (dispatch) => {
      try {
        let start=new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ANALYTICS.name;
        const apiPath = APIPaths.PORTFOLIO_FILTERS(sourceId);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: "PORTFOLIO FILTERS",
            start,
            apiName,
            apiPath
          })
        );
        dispatch(setPortfolioFilters(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const getAccountTeamMembers =
  (sourceId = 1, pageNo = 1, pageSize = 10) =>
    async (dispatch) => {
      try {
        let start=new Date();
        if (pageNo === 1) {
          dispatch(clearAccountMembers());
        }
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ANALYTICS.name;
        const apiPath = APIPaths.PORTFOLIO_ACCOUNT_MEMBERS_API(
          sourceId,
          pageNo,
          pageSize
        );
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.PORTFOLIO,
            api: "PORTFOLIO ACCOUNT TEAM MEMBERS",
            start,
            apiName,
            apiPath
          })
        );
        await dispatch(setAccountMembers(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const getAlerts = (sourceId, sourceType) => async (dispatch) => {
  try {
    let start=new Date();
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.ALERTS.name;
    const apiPath = APIPaths.PORTFOLIO_ALERTS_API(sourceId, sourceType);
    const apiOptions = initializeAPIOptions(token, enterprise_id);
    let apiResult = await API.get(apiName, apiPath, apiOptions);
    dispatch(
      performanceActions.log({
        name: FEEDTYPES.PORTFOLIO,
        api: "PORTFOLIO ALERTS",
        start,
        apiName,
        apiPath
      })
    );
    dispatch(setAlerts(apiResult.data));
    return apiResult;
  } catch (e) {
    throw e;
  }
};
