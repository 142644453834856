import Animation from "./Animation";
import ANIMATION_TYPES from "./AnimationTypes";
const DetailAnimation = ({
  value,
  threshold = 0,
  trigger,
  stopAnimation,
  ...props
}) => {
  let config;
  if (stopAnimation) {
    config = {
      from: { y: 0, opacity: 1 },
      to: {
        y: 0,
        opacity: 1,
      },
      config: { tension: 280, friction: 80 },
    };

    return (
      <Animation
        type={ANIMATION_TYPES.SPRING}
        config={config}
        trigger={!isNaN(value) &&trigger}
        {...props}
      />
    );
  } else {
    config = {
      from: { y: -value, opacity: 0 },
      to: {
        y: trigger ? 0 : -value,
        opacity: trigger ? 1 : 0,
      },
      config: { tension: 280, friction: 80 },
    };
    return (
      <Animation
        type={ANIMATION_TYPES.SPRING}
        config={config}
        trigger={trigger}
        {...props}
      />
    );
  }
};
export default DetailAnimation;
