import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = MUITheme => ({
  styledSearchField: {
    border: "1px solid",
    borderRadius: conv(12),
    borderColor: MUITheme.palette.border.primarySearch,
    background: MUITheme.palette.background.searchBackground,
    padding:conv(18,24,18,24),
    "& svg": {
      height: conv(18),
      width: conv(18),
      "& path": {
        fill: MUITheme.palette.text.secondary        
      }
    },
    "& input": {
      fontSize: conv(12)+" !important",
      lineHeight:conv(13),
      fontWeight: 400,
      padding:0,
      color: MUITheme.palette.text.lightblue,
      "&::-webkit-input-placeholder": {
        fontSize: conv(12)+" !important",
        lineHeight:conv(13),
        fontWeight: 400,
        color: MUITheme.palette.text.lightblue,
        opacity: 1
      },
      "&:-ms-input-placeholder ": {
        fontSize: conv(12)+" !important",
        fontWeight: 400,
        lineHeight:conv(13),
        color: MUITheme.palette.text.lightblue,
        opacity: 1
      },
      "&::placeholder": {
        fontSize: conv(12)+" !important",
        lineHeight:conv(13),
        fontWeight: 400,
        color: MUITheme.palette.text.lightblue,
        opacity: 1
      }
    },
    "& button": {
      color: MUITheme.palette.text.secondary +"!important",
      padding: 0,
      minWidth:0,
     
    }
  }
})