import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslate } from "../../Hooks/useTranslate";
import PropTypes from "prop-types";
import clsx from "clsx";

const CardItem = ({
  title = "",
  value,
  color = "#fff",
  sub_title = "",
  sub_value = "",
  mr = 0,
  ml = 0,
  mt = "1.312rem",
  onClick,
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const handleClick = () => {
    onClick && onClick();
  };
  if (!value) {
    return (
      <Box
        className={clsx(
          classes.classfication_box,      
          classes.sm_card,
          classes.noDataBorder,
        )}
        mr={mr}
        mt={mt}
        ml={ml}
      >
        <Typography variant="h6" className={clsx(classes.card_title)} mb="0.8rem">
          {title}
        </Typography>
        <Box className={classes.card_value}>
          <Typography
            variant="h5"          
            className={clsx(classes.heading,classes.noData, classes.noDataColor)}
            color={color}
          >
            {t("no_data")}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          mt="0.625rem"
          alignItems={"center"}
          justifyContent="center"
        >
          <Typography
            variant="h6"
            className={classes.card_subtitle}
          ></Typography>
          &nbsp;
          <Typography
            variant="h6"
            className={classes.card_subvalue}
          ></Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      className={clsx(
        classes.classfication_box,
        classes.sm_card,
        onClick && "pointer"
      )}
      mr={mr}
      mt={mt}
      ml={ml}
      onClick={handleClick}
    >
      <Typography variant="h6" className={classes.card_title} mb="0.8rem">
        {title}
      </Typography>
      <Box className={classes.card_value}>
        <Typography variant="h5" className={classes.heading} color={color}>
          {value}
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        mt="0.625rem"
        alignItems={"center"}
        justifyContent="center"
      >
        <Typography variant="h6" className={classes.card_subtitle}>
          {sub_title}
        </Typography>
        &nbsp;
        <Typography variant="h6" className={classes.card_subvalue}>
          {sub_value}
        </Typography>
      </Box>
    </Box>
  );
};

CardItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
  sub_title: PropTypes.string,
  sub_value: PropTypes.string,
  mr: PropTypes.string,
  ml: PropTypes.string,
  mt: PropTypes.string,
  onClick: PropTypes.func,
};

CardItem.defaultProps = {
  title: "",
  value: null,
  color: "#fff",
  sub_title: "",
  sub_value: "",
  mr: "0",
  ml: "0",
  mt: "1.312rem",
  onClick: () => {},
};

export default CardItem;
