import * as React from "react";
import { Badge, IconButton, Stack, Box } from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./styles";
import IconImages from "../../Assets/images/menu-icons";
import ToggleButton from "./ToggleButton";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
import SidebarPanel from "./SidebarPanel";
import { useState } from "react";
import { useEffect } from "react";

const Sidebar = ({
  sideBarWidth,
  panelAlignment,
  open,
  onOpen,
  options,
  optionsAlignment,
  selectedMenu,
  onSelect,
  showToggleButton,
  onToggle,
  background,
  className,
  panelClass,
  buttonMarginBottom,
  show,
}) => {
  const classes = useStyles();
  const [menuOptions, setMenuOptions] = React.useState([]);
  let panelContent;

  const handleMenuClick = (menu) => {
    if (menu.disabled) return;
    onOpen(true);
    onSelect(menu);
  };
  const handleSideBarOpen = () => {
    onToggle(!open);
  };
  React.useEffect(() => {
    const menuOptions = [];
    options &&
      options.forEach((option) => {
        if (!option.show) return;
        let icon = option.icon;
        let panelContent;
        let onClick = handleMenuClick;
        if (option.name === selectedMenu) {
          icon = option.checkedIcon;
        }
        menuOptions.push({
          ...option,
          icon,
          hideIcon: option.hideIcon,
          panelContent,
          onClick,
        });
      });
    setMenuOptions(menuOptions);
  }, [selectedMenu, options]);

  if (!show) return;

  const sideButtonBar = (
    <div className={clsx([classes.sideBar, className])}>
      <Stack
        direction="column"
        alignItems="center"
        flex={1}
        justifyContent={optionsAlignment}
      >
        {showToggleButton && (
          <Box mb={conv(40)}>
            <ToggleButton
              onClick={handleSideBarOpen}
              checked={open}
              checkedIcon={<IconImages.DoubleArrow checked={true} />}
              unCheckedIcon={<IconImages.DoubleArrow />}
            />
          </Box>
        )}
        {options.map((option, i) => {
          if (!option.show) return;
          let icon = option.icon;
          if (option.name === selectedMenu) {
            panelContent = option.component;
            icon = option.checkedIcon;
          }
          if (option.hideIcon) return;
          return (
            <Box mb={buttonMarginBottom}    key={i}>
              <IconButton
             
                onClick={() => handleMenuClick(option)}
                sx={{ ":hover": { background: "none" } }}
              >
                {icon}
              </IconButton>
            </Box>
          );
        })}
      </Stack>
    </div>
  );

  const panel = (
    <div
      className={clsx(
        classes.sideBarPanel,
        open ? classes.sideBarExpanded : classes.sideBarCollapsed,
        panelClass
      )}
    >
      {open && panelContent}
    </div>
  );
  return (
    <Stack direction="row">
      {panelAlignment === "right" ? (
        <>
          {" "}
          {panel}
          {sideButtonBar}
        </>
      ) : (
        <>
          {sideButtonBar} {panel}
        </>
      )}
    </Stack>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  name: PropTypes.string,
  sideBarWidth: PropTypes.string,
  showToggleButton: PropTypes.bool,
  panelAlignment: PropTypes.string,
  open: PropTypes.bool,
  show: PropTypes.bool,
  onOpen: PropTypes.func,
  onSelect: PropTypes.func,
  selectedMenu: PropTypes.string,
  options: PropTypes.array,
  optionsAlignment: PropTypes.string,
  buttonMarginBottom:PropTypes.string
};

Sidebar.defaultProps = {
  name: "",
  sideBarWidth: "30px",
  showToggleButton: false,
  panelAlignment: "left",
  open: true,
  show: true,
  selectedMenu: null,
  optionsAlignment: "top",
  buttonMarginBottom:"1rem",
  onOpen: () => {},
  onSelect: () => {},
  options: [],
};
