import { API } from "aws-amplify";
import { fetchAuthenticatedUser } from "./userActions/loginActions";
import { APIEndpoints, APIPaths } from "../../AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../Utils/API";
import { performanceActions } from ".";
import { FEEDTYPES } from "../../../Utils/Constants";

export const SET_ACCOUNT_DATA = "SET_ACCOUNT_DATA";
export const SET_ACCOUNT_JTP = "SET_ACCOUNT_JTP";
export const SET_ACCOUNT_TIMESTAMP = "SET_ACCOUNT_TIMESTAMP";
export const SET_ACCOUNT_MEMBERS = "SET_ACCOUNT_MEMBERS";
export const SET_ACCOUNT_ANALYTICS_LOADED = "SET_ACCOUNT_ANALYTICS_LOADED";

export const SET_ACCOUNT_MEMBERS_LIST = "SET_ACCOUNT_MEMBERS_LIST";

export const SET_ALERT_CONFIGURED = "SET_ALERT_CONFIGURED";
export const SET_ACCOUNT_ACTIONS = "SET_ACCOUNT_ACTIONS";
export const SET_ACCOUNT_OPERATIONAL_DRIVERS =
  "SET_ACCOUNT_OPERATIONAL_DRIVERS";
export const SET_ACCOUNT_FOLLOW = "SET_ACCOUNT_FOLLOW";
export const SET_ACCOUNT_FAVORITE = "SET_ACCOUNT_FAVORITE";
export const SET_ACCOUNT_ANALYTICS_REACTIONS =
  "SET_ACCOUNT_ANALYTICS_REACTIONS";
export const SET_ACCOUNT_ANALYTICS_STATUS = "SET_ACCOUNT_ANALYTICS_STATUS";

export const SELECT_ACCOUNT_JTP = "SELECT_ACCOUNT_JTP";
export const SELECT_ACCOUNT_OPERATIONAL_DRIVER =
  "SELECT_ACCOUNT_OPERATIONAL_DRIVER";
export const SELECT_ACCOUNT_KPI_METRIC = "SELECT_ACCOUNT_KPI_METRIC";

export const SET_ACCOUNT_KPI_DATA = "SET_ACCOUNT_KPI_DATA";
export const SET_ACCOUNT_KPI_LOADING = "SET_ACCOUNT_KPI_LOADING";

export const CLEAR_ACCOUNT_DATA = "CLEAR_ACCOUNT_DATA";
export const CLEAR_ACCOUNT_ALL_DATA = "CLEAR_ACCOUNT_ALL_DATA";
export const SET_ACCOUNT_JTP_LOADING = "SET_ACCOUNT_JTP_LOADING";
export const SET_ACCOUNT_ALERTS = "SET_ACCOUNT_ALERTS";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_DEEPLINK_DATA = "SET_ACCOUNT_DEEPLINK_DATA";

const setAccountData = (data) => ({ type: SET_ACCOUNT_DATA, payload: data });
const setJTP = (data) => ({ type: SET_ACCOUNT_JTP, payload: data });
const setTeam = (data) => ({ type: SET_ACCOUNT_MEMBERS, payload: data });
const setJTPLoading = (loading) => ({
  type: SET_ACCOUNT_JTP_LOADING,
  payload: loading,
});
export const setTimestamp = (data) => ({
  type: SET_ACCOUNT_TIMESTAMP,
  payload: data,
});
export const setTeamList = (data) => ({
  type: SET_ACCOUNT_MEMBERS_LIST,
  payload: data,
});

export const setLoaded = (data) => ({
  type: SET_ACCOUNT_ANALYTICS_LOADED,
  payload: data,
});

export const clearData = () => ({ type: CLEAR_ACCOUNT_DATA });

export const clearAllData = () => ({ type: CLEAR_ACCOUNT_ALL_DATA });

const setAlertConfigured = (data) => ({
  type: SET_ALERT_CONFIGURED,
  payload: data,
});
const setAccountActions = (data) => ({
  type: SET_ACCOUNT_ACTIONS,
  payload: data,
});
export const setOperationalDrivers = (data) => ({
  type: SET_ACCOUNT_OPERATIONAL_DRIVERS,
  payload: data,
});
const updateFollowStatus = (data) => ({
  type: SET_ACCOUNT_FOLLOW,
  payload: data,
});

const updateFavoriteStatus = (data) => ({
  type: SET_ACCOUNT_FAVORITE,
  payload: data,
});
const setAnalyticsReactions = (data) => ({
  type: SET_ACCOUNT_ANALYTICS_REACTIONS,
  payload: data,
});
const setAnalyticsStatus = (data) => ({
  type: SET_ACCOUNT_ANALYTICS_STATUS,
  payload: data,
});

export const selectJTP = (data) => ({
  type: SELECT_ACCOUNT_JTP,
  payload: data,
});

export const selectKPI = (data) => ({
  type: SELECT_ACCOUNT_KPI_METRIC,
  payload: data,
});

export const selectOperationalDriver = (data) => ({
  type: SELECT_ACCOUNT_OPERATIONAL_DRIVER,
  payload: data,
});

export const setKPIMetricsLoading = (data) => ({
  type: SET_ACCOUNT_KPI_LOADING,
  payload: data,
});
export const setKPIMetrics = (data) => ({
  type: SET_ACCOUNT_KPI_DATA,
  payload: data,
});

export const setAlerts = (data) => ({
  type: SET_ACCOUNT_ALERTS,
  payload: data,
});

export const setUserProfile = (data) => ({
  type: SET_ACCOUNT_ALERTS,
  payload: data,
});

export const setDeeplinkData = (data) => ({
  type: SET_DEEPLINK_DATA,
  payload: data,
});

export const getAccountData =
  (sourceId, timeStamp, portfolioId) => async (dispatch) => {
    try {
      let start = new Date();
      const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
      const apiName = APIEndpoints.ANALYTICS.name;
      const apiPath = APIPaths.ACCOUNT_VALUE_API(
        sourceId,
        timeStamp,
        portfolioId
      );
      const apiOptions = initializeAPIOptions(token, enterprise_id);
      let apiResult = await API.get(apiName, apiPath, apiOptions).catch((err) =>
        console.log("Caught 404 error")
      );
      dispatch(
        performanceActions.log({
          name: FEEDTYPES.ACCOUNT,
          api: "ACCOUNT VALUE",
          start,
          apiName,
          apiPath,
          payload: { sourceId, timeStamp, portfolioId },
        })
      );
      dispatch(setAccountData(apiResult?.data));
      return apiResult;
    } catch (e) {
      throw e;
    }
  };

export const getAccountJTP =
  (sourceId, timeStamp, portfolioId) => async (dispatch) => {
    try {
      let start = new Date();
      dispatch(setJTPLoading(true));
      const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
      const apiName = APIEndpoints.ANALYTICS.name;
      const apiPath = APIPaths.ACCOUNT_JTP_API(
        sourceId,
        timeStamp,
        portfolioId
      );
      const apiOptions = initializeAPIOptions(token, enterprise_id);
      let apiResult = await API.get(apiName, apiPath, apiOptions).catch((err) =>
        console.log("Caught 404 error")
      );
      dispatch(
        performanceActions.log({
          name: FEEDTYPES.ACCOUNT,
          api: "ACCOUNT JTP",
          start,
          apiName,
          apiPath,
          payload: { sourceId, timeStamp, portfolioId },
        })
      );
      if (!apiResult || apiResult.length == 0) {
        dispatch(setOperationalDrivers(null));
        dispatch(setKPIMetrics(null));
      }
      dispatch(setJTP(apiResult ? apiResult.data : []));
      dispatch(setJTPLoading(false));
      return apiResult;
    } catch (e) {
      dispatch(setJTP([]));
      dispatch(setJTPLoading(false));
      throw e;
    }
  };
export const getAccountTeam =
  (sourceId, pageNo = 1, pageSize = 3) =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ORCHESTRATOR.name;
        const apiPath = APIPaths.ACCOUNT_TEAM_MEMBERS(sourceId, pageNo, pageSize);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.ACCOUNT,
            api: "ACCOUNT TEAM COUNT",
            start,
            apiName,
            apiPath,
            payload: { sourceId },
          })
        );
        dispatch(setTeam(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };
export const getAccountTeamList =
  (sourceId, pageNo = 1, pageSize = 10) =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ORCHESTRATOR.name;
        const apiPath = APIPaths.ACCOUNT_TEAM_MEMBERS(sourceId, pageNo, pageSize);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.ACCOUNT,
            api: "ACCOUNT TEAM LIST",
            start,
            apiName,
            apiPath,
            payload: { sourceId },
          })
        );
        dispatch(setTeamList(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };
export const getIsAlertConfigured =
  (sourceId, sourceType = "ACCOUNT") =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ALERTS.name;
        const apiPath = APIPaths.IS_ALERT_CONFIGURED(sourceId, sourceType);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.ACCOUNT,
            api: "ACCOUNT ALERT CONFIGURED",
            start,
            apiName,
            apiPath,
            payload: { sourceId },
          })
        );
        dispatch(setAlertConfigured(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const getActionList =
  (sourceId, sourceType = "ACCOUNT") =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ALERTS.name;
        const apiPath = APIPaths.ACTIONS(sourceId, sourceType);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.ACCOUNT,
            api: "ACTION LIST TEAM",
            start,
            apiName,
            apiPath,
            payload: { sourceId },
          })
        );
        dispatch(setAccountActions(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };
export const getOperationalDrivers =
  (sourceId = 1, jtpId, timeStamp, portfolioId) =>
    async (dispatch) => {
      try {
        let start = new Date();
        dispatch(setKPIMetrics(null));
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ANALYTICS.name;
        const apiPath = APIPaths.ACCOUNT_OPERATIONAL_DRIVERS(
          sourceId,
          jtpId,
          timeStamp,
          portfolioId
        );
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.ACCOUNT,
            api: "ACCOUNT OP DRIVERS",
            start,
            apiName,
            apiPath,
            payload: { sourceId, jtpId, timeStamp, portfolioId },
          })
        );
        dispatch(setOperationalDrivers(apiResult.data));

        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const analyticsReactions =
  (sourceId = 1, sourceType) =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.ANALYTICS_REACTIONS_API(sourceId, sourceType);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.ACCOUNT,
            api: "ACCOUNT REACTIONS",
            start,
            apiName,
            apiPath,
            payload: { sourceId },
          })
        );
        dispatch(setAnalyticsReactions(apiResult.data));

        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const analyticsStatus =
  (sourceId = 1, sourceType, portfolioId) =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.ANALYTICS_STATUS_API(
          sourceId,
          sourceType,
          portfolioId
        );
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.ACCOUNT,
            api: "ACCOUNT ANALYTICS STATUS",
            start,
            apiName,
            apiPath,
            payload: { sourceId, sourceType, portfolioId },
          })
        );
        dispatch(setAnalyticsStatus(apiResult.data));

        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const setAccountFollow =
  (sourceId = 1, sourceType, followStatus, sourceName, portfolioId) =>
    async (dispatch) => {
      try {
        let start = new Date();
        dispatch(updateFollowStatus(followStatus));
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.ANALYTICS_UPDATE_STATUS_API(
          sourceId,
          sourceType,
          followStatus,
          sourceName,
          portfolioId
        );
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.post(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.ACCOUNT,
            api: "ACCOUNT FOLLOW",
            start,
            apiName,
            apiPath,
            payload: {
              sourceId,
              sourceType,
              followStatus,
              sourceName,
              portfolioId,
            },
          })
        );
        dispatch(updateFollowStatus(followStatus));

        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const setAccountFavorite =
  (sourceId = 1, sourceType, favoriteStatus, sourceName, portfolioId) =>
    async (dispatch) => {
      try {
        let start = new Date();
        dispatch(updateFavoriteStatus(favoriteStatus));
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.ANALYTICS_UPDATE_FAVORITE_API(
          sourceId,
          sourceType,
          favoriteStatus,
          sourceName,
          portfolioId
        );
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.post(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.ACCOUNT,
            api: "ACCOUNT FAVORITE",
            start,
            apiName,
            apiPath,
            payload: {
              sourceId,
              sourceType,
              favoriteStatus,
              sourceName,
              portfolioId,
            },
          })
        );
        dispatch(updateFavoriteStatus(favoriteStatus));

        return apiResult;
      } catch (e) {
        throw e;
      }
    };
export const getKPIMetrics =
  (sourceId = 1, jtpId, kpiId, timeStamp, portfolioId) =>
    async (dispatch) => {
      try {
        let start = new Date();
        dispatch(setKPIMetrics(null));
        dispatch(setKPIMetricsLoading(true));
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ANALYTICS.name;
        const apiPath = APIPaths.ACCOUNT_KPI_DRILLDOWN(
          sourceId,
          jtpId,
          kpiId,
          timeStamp,
          portfolioId
        );
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: FEEDTYPES.ACCOUNT,
            api: "ACCOUNT KPI",
            start,
            apiName,
            apiPath,
            payload: {
              sourceId,
              jtpId,
              kpiId,
              timeStamp,
              portfolioId,
            },
          })
        );
        dispatch(setKPIMetrics(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      } finally {
        dispatch(setKPIMetricsLoading(false));
      }
    };

export const getAlerts = (sourceId, sourceType) => async (dispatch) => {
  try {
    let start = new Date();
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.ALERTS.name;
    const apiPath = APIPaths.ACCOUNT_ALERTS_API(sourceId, sourceType);
    const apiOptions = initializeAPIOptions(token, enterprise_id);
    let apiResult = await API.get(apiName, apiPath, apiOptions).catch((err) => {
      return null;
    });
    dispatch(
      performanceActions.log({
        name: FEEDTYPES.ACCOUNT,
        api: "ACCOUNT ALERTS",
        start,
        apiName,
        apiPath,
        payload: {
          sourceId,
        },
      })
    );
    dispatch(setAlerts(apiResult.data));
    return apiResult;
  } catch (e) {
    throw e;
  }
};
export const getUserProfileById = (userId) => async (dispatch) => {
  try {
    let start = new Date();
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.USER.name;
    const apiPath = APIPaths.USER_PROFILE_API(userId);
    const apiOptions = initializeAPIOptions(token, enterprise_id);
    let apiResult = await API.get(apiName, apiPath, apiOptions).catch((err) => {
      return null;
    });
    dispatch(
      performanceActions.log({
        name: FEEDTYPES.ACCOUNT,
        api: "ACCOUNT USER PROFILE BY ID",
        start,
        apiName,
        apiPath,
        payload: {
          userId,
        },
      })
    );
    return apiResult;
  } catch (e) {
    throw e;
  }
};
