import { API } from "aws-amplify";
import { fetchAuthenticatedUser } from "./userActions/loginActions";
import { APIEndpoints, APIPaths } from "../../AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../Utils/API";
import { alertActions,performanceActions } from ".";

export const SET_ALERTS = "SET_ALERTS";
export const APPEND_ALERTS="APPEND_ALERTS";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const REMOVE_FROM_LIST = "REMOVE_FROM_LIST";
export const MARK_ALERT_AS_READ = "MARK_ALERT_AS_READ";
export const DELETE_ALERT = "DELETE_ALERT";
export const SELECT_ALERT = "SELECT_ALERT";
export const SET_ALERTS_COUNT = "SET_ALERTS_COUNT";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const SHOW_DELETE_DIALOG = "SHOW_DELETE_DIALOG";
export const CLOSE_DELETE_DIALOG = "CLOSE_DELETE_DIALOG";
export const CLEAR_ALERT_DATA="CLEAR_ALERT_DATA";

export const setAlerts = (filter,data) => ({
  type: SET_ALERTS,
  payload: {data,filter}
});

export const appendAlerts = (filter,data) => ({
  type: APPEND_ALERTS,
  payload: {data,filter}
});

export const setSelectedTab = (data) => ({
  type: SET_SELECTED_TAB,
  payload: data
});

export const clearAllData = () => ({
  type: CLEAR_ALERT_DATA
});
export const clearAlerts = () => ({
  type: CLEAR_ALERTS
});

export const markAlertAsRead = (data) => ({
  type: MARK_ALERT_AS_READ,
  payload: data,
});

export const removeFromList = (alert_id,filter) => ({
  type: REMOVE_FROM_LIST,
  payload: {alert_id,filter},
});

export const selectAlert = (data) => ({
  type: SELECT_ALERT,
  payload: data,
});

export const removeAlert = (data) => ({
  type: DELETE_ALERT,
  payload: data,
});

export const setAlertsCount = (data) => ({
  type: SET_ALERTS_COUNT,
  payload: data,
});

export const showDeleteModal = (data) => ({
  type: SHOW_DELETE_DIALOG,
  payload: data,
});

export const closeDeleteModal = () => ({
  type: CLOSE_DELETE_DIALOG,
});
export const getAlerts =
  (offset = 0, limit = 1, filter) =>
    async (dispatch) => {
      try {
        let start=new Date();
     
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ALERTS.name;
        let read_unread = null;
        if (["read", "unread"].includes(filter)) {
          if (filter === "read") read_unread = true;
          if (filter === "unread") read_unread = false;
        }

        let apiPath = APIPaths.ALERTS(offset, limit, filter, read_unread);

        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions).catch(
          (err) => {}
        );
        dispatch(
          performanceActions.log({
            name: "ALERTS",
            api: "GET ALERTS",
            start,
            apiName,
            apiPath
          })
        );
        if (offset === 0) dispatch(setAlerts(filter, apiResult.data));
        else dispatch(appendAlerts(filter, apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const readAlert = (alert_id, read) => async (dispatch) => {
  try {
    let start=new Date();
    dispatch(markAlertAsRead({alert_id,read}));
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.ALERTS.name;
    const apiPath = APIPaths.READ_UNREAD_ALERT(alert_id, read);
    const apiOptions = initializeAPIOptions(token, enterprise_id, {
      is_read: read,
    });
    let apiResult = await API.post(apiName, apiPath, apiOptions).catch(err=>{});
    dispatch(
      performanceActions.log({
        name:"ALERTS",
        api: "READ ALERT",
        start,
        apiName,
        apiPath,
        payload: { alert_id,read },
      })
    );
    dispatch(getUnreadCount());
    return apiResult;
  } catch (e) {
    throw e;
  }
};

export const getUnreadCount = () => async (dispatch) => {
  try {
    let start=new Date();
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.ALERTS.name;
    const apiPath = APIPaths.GET_UNREAD_ALERTS_COUNT;
    const apiOptions = initializeAPIOptions(token, enterprise_id);
    let apiResult = await API.get(apiName, apiPath, apiOptions).catch(err=>{});
    dispatch(
      performanceActions.log({
        name:"ALERTS",
        api: "COUNT UNREAD ALERTS",
        start,
        apiName,
        apiPath
      })
    );
    if(apiResult?.data)
      dispatch(setAlertsCount(apiResult?.data.unread_count));
    return apiResult;
  } catch (e) {
    throw e;
  }
};

export const deleteAlert = (alert_id) => async (dispatch) => {
  try {
    let start=new Date();
    dispatch(removeAlert({alert_id}));
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.ALERTS.name;
    const apiPath = APIPaths.DELETE_ALERT(alert_id);
    const apiOptions = initializeAPIOptions(token, enterprise_id);
    let apiResult = await API.del(apiName, apiPath, apiOptions);
    dispatch(
      performanceActions.log({
        name:"ALERTS",
        api: "Delete Alert",
        start,
        apiName,
        apiPath,
        payload:{alert_id}
      })
    );
    dispatch(getUnreadCount());
    return apiResult;
  } catch (e) {
    throw e;
  }
};
