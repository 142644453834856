import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useStyles } from "../styles";
import { useTheme } from "@mui/styles";
import DOWN_UNREAD from "../../../Assets/images/alerts/down-effect.png";
import DOWN_READ from "../../../Assets/images/alerts/down-read.png";
import UP_UNREAD from "../../../Assets/images/alerts/up-effect.png";
import UP_READ from "../../../Assets/images/alerts/up-read.png";
import INFO_UNREAD from "../../../Assets/images/alerts/info-unread.png";
import INFO_READ from "../../../Assets/images/alerts/info-read.png";
import { ALERT_EFFECT } from "../../../Utils/Constants";

const AnalyticsAlert = ({ selected, onClick, data, is_read }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleClick = (e) => {
    onClick(data);
  };
  if (!data) return;
  let color = is_read
    ? theme.palette.text.calligraphy
    : theme.palette.text.white;

  let icon;
  let effect = data?.alert_data?.effect;
  let read = is_read;
  if (effect === ALERT_EFFECT.UP) {
    icon=read?UP_READ:UP_UNREAD;
  } else if (effect === ALERT_EFFECT.DOWN) {
    icon=read?DOWN_READ:DOWN_UNREAD;
  } else if (effect === ALERT_EFFECT.NO_CHANGE) {
    icon=read?INFO_READ:INFO_UNREAD;
  }

  return (
    <>
      <Box pr={"1rem"} pt="6px" height="24px" width="24px">
        <img  src={icon} alt="alert-icon" width="100%"/>
      </Box>
      <Box flex="1">
        <Typography variant="body2" color={color} fontWeight={read?"normal":500}>
          {data.alert_data.title}
        </Typography>
      </Box>
    </>
  );
};

AnalyticsAlert.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  data: PropTypes.object,
};

AnalyticsAlert.defaultProps = {
  selected: false,
  data: null,
  onClick: () => {},
};
export default React.memo(AnalyticsAlert);
