import Platform from "../../../Utils/Platform";
import Pinpoint from "../../AWS/Pinpoint";
import { EVENT_TYPE } from "../../../Utils/Constants";
import store from "../Store";

const prepareString = (arr) => {
  if (arr?.length) return arr.join("_");
  return "";
};

const sendEventData = (type, events, view_names, attributes) => {
  if (!type || !events?.length) return "Type is required";
  const payload = {};
  let event_type = prepareString(events);
  let view_name;
  if (view_names?.length) view_name = prepareString(view_names);
  let attribute_name;
  if (attributes?.length) attribute_name = prepareString(attributes);
  let user = store.getState()?.user?.user;

  //Add User Info
  // payload["name"] = type;

  payload["user_id"] = user ? user.user_id : "";
  payload["user_name"] = user ? user.display_name : "";
  payload["enterprise_id"] = user ? user.enterprise_id : "";

  payload["app_version"] = process.env.REACT_APP_VERSION;
  payload["os_version"] = `${Platform.OS()}-${Platform.currentBrowser()}`;
  payload["device_type"] = "WEB";
  let action_type = type == EVENT_TYPE.ACTION ? "action_type" : "view_type";

  payload[action_type] = event_type;
  if (view_name) {
    payload["view_name"] = view_name;
  }
  if (attribute_name) {
    payload["view_attributes"] = attribute_name;
  }
  Pinpoint.recordEvent(type,payload);
};
export default { sendEventData };
