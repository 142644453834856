import React from "react";
import { IconButton } from "@mui/material";

const ToggleButton = ({ checked, onClick, checkedIcon, unCheckedIcon }) => (
  <IconButton onClick={onClick}>
    {checked ? checkedIcon : unCheckedIcon}
  </IconButton>
);

export default ToggleButton;
