import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslate } from "../../Hooks/useTranslate";
import { CURRENCIES_SYMBOLS } from "../../Utils/Constants";
import Helpers from "../../Utils/Helper";
import { useTheme } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

const NpsDistribution = (props) => {
  const classes = useStyles();
  const t = useTranslate();
  const theme=useTheme
  const [distributionDetails, setDistributionDetails] = useState([]);
  const [totalCurrencyValue, setTotalCurrency] = useState(0);
  const { disCount, disDetails, colors } = props;
  const aligns = ["left", "center", "right"];

  useEffect(() => {
    let totalCurrency = 0;
    if (disDetails && disDetails.length > 0) {
      let distributionDetails = disDetails.map((item) => {
        totalCurrency = totalCurrency + item.fin_value;
        let value = Math.round((item.count / disCount) * 100);
        return { value, ...item };
      });
      setDistributionDetails(distributionDetails);
      setTotalCurrency(totalCurrency);
    }
  }, [props]);

  return (
    <>
      <Stack direction="column" className={classes.distribution_box}>
        <Typography variant="h5" color="text.subheading">
          {t("distribution")}
        </Typography>
        <Typography variant="h6" textAlign="right" className="totalCount">
          {t("total")} : {disCount}/{CURRENCIES_SYMBOLS["USD"] || ""}
          {Helpers.AbbreviateNumber(totalCurrencyValue,"dist_count")}
        </Typography>
        <Stack direction="row">
          {distributionDetails &&
            distributionDetails.map((item, index) => (
              <div
                key={index}
                style={{
                  width: `${item.value}%`,
                  minWidth: conv(100),
                  marginRight: index != 2 && conv(3),
                }}
              >
                <div
                  style={{
                    background: colors ? colors[item.nps_class] : theme.palette.text.secondary,
                    height: conv(5),
                    borderRadius: conv(5),
                    marginBottom:conv(5)
                  }}
                >
                  {" "}
                </div>
                <Stack direction="row" justifyContent={aligns[index]}>
                  <Typography
                    variant="h6"
                    display={"inline-block"}
                    textAlign={aligns[index]}
                    style={{ color: colors ? colors[item.nps_class] : theme.palette.text.secondary }}
                  >
                    {item.count}/{" "}
                  </Typography>

                  <Typography variant="h6" display={"inline-block"}>
                    {" "}
                    {CURRENCIES_SYMBOLS[item.fin_currency_type] || ""}
                    {Helpers.AbbreviateNumber(item.fin_value,"dist_count")}
                  </Typography>
                </Stack>
              </div>
            ))}
        </Stack>
      </Stack>
    </>
  );
};
export default NpsDistribution;
