import React from "react";
import { Typography } from "@mui/material";

import AmountLabel from "../../Components/AmountLabel";
import { CURRENCIES_SYMBOLS } from "../../Utils/Constants";
import Helpers from "../../Utils/Helper";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

const PortfolioTitle = ({ name, currency, value }) => {
  return (
    <Typography
      variant="h3"
      color="text.heading"
      fontWeight={700}
      lineHeight={conv(54)}
    >
      {name}
      <AmountLabel>
        <Typography
          fontSize={conv(29)}
          lineHeight={conv(36)}
          fontWeight={500}
          display="inline-block"
          variant="h4"
          color="text.neutral"
          marginLeft={conv(21)}
        >
          {CURRENCIES_SYMBOLS[currency] || ""}
          {Helpers.AbbreviateNumber(value)}
        </Typography>
      </AmountLabel>
    </Typography>
  );
};
export default React.memo(PortfolioTitle);
