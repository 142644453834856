import DoubleArrow from "./doubleArrow.js";
import Feeds from "./feeds.js";
import Accounts from "./accounts.js";
import Notification from "./notification.js";
import Alerts from "./alerts.js";
import Favorites from "./favorites.js";
import Actions from "./actions.js";
import Portfolios from "./portfolios.js";

import Comments from "./comment.js";
import Filters from "./filter.js";
import Favorites2 from "./favorites2.js";
import Follow from "./follow.js";
import Share from "./share.js";

import Next from "./next.js";
import Previous from "./previous.js"
import Chat from "./chat"
import ArrowUp from "./arrowUp.js";
export const CheckedColor = "#fff";
export const UncheckedColor = "#767E87";
const images = {
  DoubleArrow,
  Feeds,
  Accounts,
  Notification,
  Alerts,
  Portfolios,
  Actions,
  Favorites,
  Favorites2,
  Comments,
  Filters,
  Follow,
  Share,
  Next, 
  Previous,
  Chat,
  ArrowUp
};
export default images;
