import React from "react";
import { CheckedColor, UncheckedColor } from ".";

const Accounts = ({ checked, color }) => {
  return (
    <svg
      width={window.conv(24)}
      height={window.conv(24)}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29557 0C1.47548 0 0 1.47547 0 3.29556V21.3756C0 23.1957 1.47547 24.6712 3.29556 24.6712H21.3756C23.1957 24.6712 24.6712 23.1957 24.6712 21.3756V3.29557C24.6712 1.47548 23.1957 0 21.3756 0H3.29557ZM1.5 3.29556C1.5 2.3039 2.3039 1.5 3.29557 1.5H21.3756C22.3673 1.5 23.1712 2.3039 23.1712 3.29557V21.3756C23.1712 22.3673 22.3673 23.1712 21.3756 23.1712H3.29556C2.3039 23.1712 1.5 22.3673 1.5 21.3756V3.29556ZM8.45117 8.86133C8.45117 8.44711 8.11539 8.11133 7.70117 8.11133C7.28696 8.11133 6.95117 8.44711 6.95117 8.86133V16.9712C6.95117 17.3855 7.28696 17.7212 7.70117 17.7212C8.11539 17.7212 8.45117 17.3855 8.45117 16.9712V8.86133ZM12.3359 4.63281C12.7502 4.63281 13.0859 4.9686 13.0859 5.38281L13.0859 16.9684C13.0859 17.3826 12.7502 17.7184 12.3359 17.7184C11.9217 17.7184 11.5859 17.3826 11.5859 16.9684L11.5859 5.38281C11.5859 4.9686 11.9217 4.63281 12.3359 4.63281ZM17.7207 13.4941C17.7207 13.0799 17.3849 12.7441 16.9707 12.7441C16.5565 12.7441 16.2207 13.0799 16.2207 13.4941V16.9698C16.2207 17.384 16.5565 17.7198 16.9707 17.7198C17.3849 17.7198 17.7207 17.384 17.7207 16.9698V13.4941Z"
        fill={color ? color : (checked ? CheckedColor : UncheckedColor)}
      />
    </svg>
  );
};

export default Accounts;
