import { API } from "aws-amplify";
import { fetchAuthenticatedUser } from "./userActions/loginActions";
import { APIEndpoints, APIPaths } from "../../AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../Utils/API";
import { performanceActions } from ".";

export const SET_LIST_DATA = "SET_LIST_DATA";
export const SET_PF_ACC_LIST_SOURCE = "SET_PF_ACC_LIST_SOURCE";
export const CLEAR_LIST_DATA = "CLEAR_LIST_DATA";
export const APPEND_LIST_DATA = "APPEND_LIST_DATA";
export const SET_SORT_DATA="SET_SORT_DATA";
export const SET_SEARCH_TEXT="SET_SEARCH_TEXT";
export const RESET_SORT_DATA="RESET_SORT_DATA";
export const SET_CHANGED="SET_CHANGED"

export const setPfListSource = (data) => ({
  type: SET_PF_ACC_LIST_SOURCE,
  payload:data,
});
const loadListData = (data, apiName) => ({
  type: SET_LIST_DATA,
  payload: { data, apiName },
});
const appendListData = (data, apiName) => ({
  type: APPEND_LIST_DATA,
  payload: { data, apiName },
});
export const setSortData = (data, apiName) => ({
  type: SET_SORT_DATA,
  payload: { data, apiName },
});
export const setSearchText = (data, apiName) => ({
  type: SET_SEARCH_TEXT,
  payload: { data, apiName },
});
export const resetSortData = (apiName) => ({
  type: RESET_SORT_DATA,
  payload: { apiName },
});
export const setChanged = (changed,apiName) => ({
  type: SET_CHANGED,
  payload: { changed,apiName },
});

export const clearAllData = () => ({ type: CLEAR_LIST_DATA });

export const loadData =
  (
    name,
    page_number = 1,
    page_size = 15,
    sort_by = "",
    sort_order = "ASC",
    q = "",
    source,
    source_id,
    timestamp
  ) =>
    async (dispatch) => {
      try {
        let start = new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        let apiName;
        let apiResult;
        let state_key = "";
        if (name === "portfolio_accounts") {
          const apiName = APIEndpoints.ANALYTICS.name;
          const apiPath = APIPaths.PORTFOLIO_ACCOUNT_LIST(source_id);
          let payload = { page_number, page_size, sort_by, sort_order };
          if (timestamp) {
            payload["timestamp"] = timestamp;
          }
          if (q) {
            payload["q"] = q;
          }
          const apiOptions = initializeAPIOptions(token, enterprise_id, payload);
          apiResult = await API.post(apiName, apiPath, apiOptions);
          state_key = "portfolio_accounts";
        } else {
          if (name === "portfolio") {
            state_key = "portfolio";
            apiName = APIEndpoints.ANALYTICS.name;
          } else if (name === "account") {
            state_key = "account";
            apiName = APIEndpoints.ANALYTICS.name;
          } else if (name === "action") {
            state_key = "action";
            apiName = APIEndpoints.ALERTS.name;
          }
          const apiPath = APIPaths.OCX_COMMON_LIST_SEARCH_API(
            state_key,
            page_number,
            page_size,
            sort_by,
            sort_order,
            q
          );
          const apiOptions = initializeAPIOptions(token, enterprise_id);

          apiResult = await API.get(apiName, apiPath, apiOptions);
          dispatch(
            performanceActions.log({
              name: state_key.toUpperCase(),
              api: state_key.toUpperCase() + " List" + (q ? " SEARCH" : ""),
              start,
              apiName,
              apiPath,
            })
          );
        }

        if (apiResult) {
          if (page_number === 1) {
            dispatch(loadListData(apiResult.data, state_key));
          } else {
            dispatch(appendListData(apiResult.data, state_key));
          }
        }

        return apiResult;
      } catch (e) {
        throw e;
      }
    };
