import React from "react";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslate } from "../../Hooks/useTranslate";
import Button from "../Shared/Button";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles(MUITheme => ({
  button:{
    width: "200px",
    marginTop:"30px !important"
  }
}))
const PageNotFound = (props) => {
  const t = useTranslate();
  const classes=useStyles();
  const navigate=useNavigate();

  const handleGoHome=()=>{
    navigate(props.homePath,{replace:true})
  }
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent="center"
    >
      <Typography variant="h1" fontSize={"120px"} color="text.secondary">
        404
      </Typography>
      <Typography variant="h2" color="text.secondary">
        {t("page_not_found_title")}
      </Typography>
      <br />
      <Typography variant="h5" color="text.neutral">
        {t("page_not_found_desc")}
      </Typography>
      <Button
        color="primary"
        className={classes.button}
        variant="contained"
        children={t("go_home")}
        type="submit"
        onClick={handleGoHome}
      />
    </Box>
  );
};

export default PageNotFound;
