import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((MUITheme) => ({
  box: {
    flex: 1,
    marginTop:"25px" ,
  },
  header:{
    borderBottom:"1px solid "+MUITheme.palette.border.primary,
  }
}));
