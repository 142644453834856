import { API } from "aws-amplify";
import { fetchAuthenticatedUser } from "./userActions/loginActions";
import { APIEndpoints, APIPaths } from "../../AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../Utils/API";
import { performanceActions } from ".";

export const SET_SCORECARD = "SET_SCORECARD";
export const SET_SCORECARD_JTP = "SET_SCORECARD_JTP";
export const CLEAR_SCORECARD_DATA = "CLEAR_SCORECARD_DATA";
export const CLEAR_DRIVER_KPI_DATA = "CLEAR_DRIVER_KPI_DATA";
export const SET_SC_ACCOUNT_DATA="SET_SC_ACCOUNT_DATA";
const setScorecard = (data) => ({
  type: SET_SCORECARD,
  payload: data,
});
export const setSCAccountData = (data) => ({
  type: SET_SC_ACCOUNT_DATA,
  payload: data,
});
export const clearAllData = () => ({
  type: CLEAR_SCORECARD_DATA
});

export const clearDriverKPIData = () => ({
  type: CLEAR_DRIVER_KPI_DATA
});
export const setScorecardJTP = (data) => ({
  type: SET_SCORECARD_JTP,
  payload: data,
});

export const getAccountScorecard =
  (portfolioId, sourceId, jtpIds,timestamp) =>
    async (dispatch) => {
      try {
        let start=new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ANALYTICS.name;
        const apiPath = APIPaths.ACCOUNT_SCORE_CARD(portfolioId, sourceId,jtpIds,timestamp);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: "SCORECARD",
            api: "GET ACCOUNT SCORECARD",
            start,
            apiName,
            apiPath
          })
        );
        dispatch(setScorecard(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const getPortfolioScorecard =
  (sourceId,jtp_ids,timestamp,filters) =>
    async (dispatch) => {
      try { 
        let start=new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.ANALYTICS.name;
        const apiPath = APIPaths.PORTFOLIO_SCORE_CARD(
          sourceId
        );
        let payload={
          jtp_ids,
          timestamp
        }
        if(filters)
        {
          payload["filter"]=filters;
        }
        const apiOptions = initializeAPIOptions(token, enterprise_id,payload);
        let apiResult = await API.post(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name: "SCORECARD",
            api: `GET PORTFOLIO SCORECARD${payload.filter?"-FILTER":""}`,
            start,
            apiName,
            apiPath
          })
        );
        dispatch(setScorecard(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };
