import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useStyles } from "../styles";
import { useTheme } from "@mui/styles";
import Comment from "../../../Assets/images/alerts/comment";

const MessageAlert = ({ selected, onClick, data, is_read }) => {
  const classes = useStyles();
  const theme = useTheme();
  const users = useSelector((state) => state.alerts.users);
  const dispatch = useDispatch();

  const handleClick = (e) => {
    onClick(data);
  };
  if (!data) return;
  let color = is_read
    ? theme.palette.text.calligraphy
    : theme.palette.text.white;

  let title = data.alert_data.title;
  let alert_data = data?.alert_data;
  if (alert_data.meta && users) {
    let alert_users = alert_data.meta.user_id;
    alert_users.forEach((user_id) => {
      title = title.replace(`{${user_id}}`, `<b>${users[user_id]}</b>`);
    });
  }

  return (
    <>
      <Box pr={"1rem"} mt="3px">
        <Comment color={color} />
      </Box>
      <Box flex="1">
        <div className={classes.commentBox} style={{color:color}}  dangerouslySetInnerHTML={{ __html: title }}></div>
      </Box>
    </>
  );
};

MessageAlert.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  data: PropTypes.object,
};

MessageAlert.defaultProps = {
  selected: false,
  data: null,
  onClick: () => {},
};
export default React.memo(MessageAlert);
