import React from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import { CircularProgress } from "@mui/material";
import AppLogo from "../Shared/AppLogo";

const SuspenseView = () => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, classes.background)}>
      <div className={classes.logoContainer}>
        <AppLogo width={"298px"} />
        <br />
        <CircularProgress />
      </div>
    </div>
  );
};

export default SuspenseView;
