import React, { useCallback, useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "../Shared/Tabs";
import AlertList from "./AlertList";
import { useTranslate } from "../../Hooks/useTranslate";
import DeleteModal from "./DeleteModal";
import { alertActions } from "../../Services/Redux/actionCreators";

const Alerts = () => {
  const t = useTranslate();
  const [tabIndex, setTabIndex] = useState(0);

  const TabOptions = [
    {
      name: t("alert_filters.all"),
      key: "all",
      component: <AlertList filter="all" />,
    },
    {
      name: t("alert_filters.read"),
      key: "read",
      component: <AlertList filter="read" />,
    },
    {
      name: t("alert_filters.unread"),
      key: "unread",
      component: <AlertList filter="unread" />,
    },
    {
      name: t("alert_filters.analytics"),
      key: "analytics",
      component: <AlertList filter="analytics" />,
    },

    {
      name: t("alert_filters.comments"),
      key: "comments",
      component: <AlertList filter="comments" />,
    },
  ];

  const onTabChange = useCallback((tab) => {
    setTabIndex(tab);
  }, []);

  return (
    <Box position={"relative"} flex="1">
      <Box ml={window.conv(23)} mr={window.conv(12)}>
        <Typography variant="h4" mt={window.conv(45)}>
          {t("alerts")}
        </Typography>
        <Tabs
          value={tabIndex}
          data={TabOptions}
          onChange={onTabChange}
        />
     
      </Box>
      <DeleteModal />
    </Box>
  );
};

export default React.memo(Alerts);
