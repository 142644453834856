import React from "react";
import { useSelector } from "react-redux";
import Tag from "../Tag";
import { useTheme } from "@mui/styles";

const ProductLine = ({ data, selected, ...props }) => {

  const theme=useTheme();
  const is_enabled = useSelector(
    (state) => state.app.mapping_data.field_mapping.product_line.is_enabled
  );
  if (!is_enabled) return;
  return (
    <>
      <Tag
        data={data?.map((item) => ({
          text: item,
          selected: item === selected,
        }))}
      />
    </>
  );
};

export default ProductLine;
