import { API } from "aws-amplify";
import { customListActions, loaderActions, scoreCardActions } from "./";
import { fetchAuthenticatedUser } from "./userActions/loginActions";
import { APIEndpoints, APIPaths } from "../../AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../Utils/API";
import store from "../Store";
import {  portfolioActions, accountActions, feedActions, commentActions, alertActions, favoriteActions,performanceActions } from "./";


export const SET_APP_THEME = "SET_APP_THEME";
export const SET_APP_LANG = "SET_APP_LANG";
export const SET_APP_CLOUD_COOKIE = "SET_APP_CLOUD_COOKIE";
export const SET_APP_FIELD_MAPPING = "SET_APP_FIELD_MAPPING";
export const SET_APP_MAIN_MENU = "SET_APP_MAIN_MENU";
export const SET_APP_FOOTER_MENU = "SET_APP_FOOTER_MENU";
export const SET_APP_LEFT_MENU_OPEN = "SET_APP_LEFT_MENU_OPEN";
export const SET_APP_RIGHT_MENU_OPEN = "SET_APP_RIGHT_MENU_OPEN";
export const SET_USER_PROFILE_LOADED="SET_USER_PROFILE_LOADED";
export const CLEAR_APP_DATA = "CLEAR_APP_DATA";

export const setAppTheme = theme => {
  return { type: SET_APP_THEME, payload: theme };
};

export const setUserProfileLoaded = loaded => {
  return { type: SET_USER_PROFILE_LOADED, payload: loaded };
};

export const clearAllData = () => {
  return { type: CLEAR_APP_DATA };
};

export const setAppLanguage = lang => {
  return { type: SET_APP_LANG, payload: lang };
};

export const setMainMenu = menu => {
  return { type: SET_APP_MAIN_MENU, payload: menu };
};
export const setFooterMenu = menu => {
  return { type: SET_APP_FOOTER_MENU, payload: menu };
};

export const setLeftMenuOpen = () => {
  return { type: SET_APP_LEFT_MENU_OPEN, payload: true };
};
export const setLeftMenuClose = () => {
  return { type: SET_APP_LEFT_MENU_OPEN, payload: false };
};

export const setRightMenuOpen = () => {
  return { type: SET_APP_RIGHT_MENU_OPEN, payload: true };
};
export const setRightMenuClose = () => {
  return { type: SET_APP_RIGHT_MENU_OPEN, payload: false };
};

export const setCloudCookie = data => {
  return { type: SET_APP_CLOUD_COOKIE, payload: data };
};

export const setFieldMapping = data => {
  return { type: SET_APP_FIELD_MAPPING, payload: data };
};

export const getCloudCookie = () => async dispatch => {
  try {
    let start=new Date();
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.APP.name;
    const apiPath = APIPaths.CLOUD_COOKIE;
    const apiOptions = initializeAPIOptions(token, enterprise_id);
    let apiResult = await API.get(apiName, apiPath, apiOptions);
    dispatch(
      performanceActions.log({
        name:"CLOUD COOKIE",
        api: "Get Cloud Cookie",
        start,
        apiName,
        apiPath
      })
    );
    dispatch(setCloudCookie(apiResult.data));
    return apiResult;
  } catch (e) {
    dispatch(loaderActions.stopAppLoader());
    throw e;
  }
};

export const getFieldMapping = () => async dispatch => {
  try {
    let start=new Date();
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.APP.name;
    const apiPath = APIPaths.FIELD_MAPPING;
    const apiOptions = initializeAPIOptions(token, enterprise_id);
    let apiResult = await API.get(apiName, apiPath, apiOptions);
    dispatch(
      performanceActions.log({
        name:"FIELD MAPPING",
        api: "Get Field Mapping",
        start,
        apiName,
        apiPath
      })
    );
    await dispatch(setFieldMapping(apiResult.data));

    return apiResult;
  } catch (e) {
    throw e;
  }
};

export const toggleRightMenu=(menu)=>(dispatch)=>{
  const app=store.getState().app;

  if(menu===app.footer_menu)
  {
    dispatch(setRightMenuClose());
    dispatch(setLeftMenuOpen());
    dispatch(setFooterMenu(null));
  }
  else
  {
    dispatch(setFooterMenu(menu));
    dispatch(setLeftMenuClose());
    dispatch(setRightMenuOpen());
   
  }
}

export const clearReduxData=()=>dispatch=>{
  dispatch(portfolioActions.clearAllData());
  dispatch(accountActions.clearAllData());
  dispatch(feedActions.clearAllData());
  dispatch(commentActions.clearAllData());
  dispatch(alertActions.clearAllData());
  dispatch(favoriteActions.clearAllData());
  dispatch(scoreCardActions.clearAllData());
  dispatch(performanceActions.clearData());
  dispatch(customListActions.clearAllData());
}
