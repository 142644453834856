import React from "react";
import { CheckedColor, UncheckedColor } from ".";

const Actions = ({ checked, color }) => {
  let stroke = checked ? CheckedColor : UncheckedColor;
  if (color) stroke = color;
  return (
    <svg
      width={window.conv(25)}
      height={window.conv(25)}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3374 17.6901L11.3282 11.3285L17.6898 9.33765L15.699 15.6992L9.3374 17.6901Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="13.5137"
        cy="13.5137"
        r="12.0137"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Actions;
