import * as React from "react";
import { Stack, Typography, Grid, Box } from "@mui/material";
import PropTypes from "prop-types";
import Helpers from "../../../Utils/Helper";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "../../../Hooks/useTranslate";
import { portfolioActions } from "../../../Services/Redux/actionCreators";
import { useStyles } from "./styles";
import NPSGraph from "../../NPSGraph";
import { FILTER_TYPE } from "../../../Utils/Constants";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

const PortfolioDetail = (props) => {
  const { data, value, loaded } = props;
  const t = useTranslate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [color, setColor] = React.useState();
  const titles = useSelector((state) => state.app.mapping_data.title);
  const filters = useSelector((state) => state.portfolio.applied_filters);
  const deepLinkData = useSelector((state) => state.portfolio.link_data);
  const [selectedValue, setSelectedValue] = React.useState(value);
  const [trends, setTrends] = React.useState();
  const isFilterApplied = useSelector((state) => state.portfolio.apply_filters);
  if (!data) return <>No Data</>;

  React.useEffect(() => {
    const sourceData = data;
    if (sourceData) {
      let goalLine;
      if (sourceData.trends && sourceData.trends.length) {
        setTrends(sourceData.trends.reverse());
        goalLine = sourceData.target_nps_value;
        setColor(sourceData.nps_diff_color_code);
      }
    }
  }, [data]);
  React.useEffect(() => {
    dispatch(portfolioActions.setReloadFunction(updateAnalyticsData));
  }, []);

  const handleMarkerMove = React.useCallback((data) => {
    setSelectedValue(data.timestamp);
  }, []);

  const portfolioId = useSelector((state) => state.portfolio?.data?.id);

  const handleMarkerRelease = React.useCallback(
    (data) => {
      const { timestamp } = data;
      if (portfolioId && timestamp) {
        if (!deepLinkData) dispatch(portfolioActions.clearData());
        let filterData;
        if (filters) filterData = Helpers.formatFilterData(filters);
        updateAnalyticsData(portfolioId, timestamp, filterData, false);
        dispatch(portfolioActions.setTimestamp(timestamp));
      }
      setSelectedValue(data.timestamp);
    },
    [portfolioId, filters, deepLinkData]
  );

  const updateAnalyticsData = async (
    portfolioId,
    timestamp,
    filter,
    loader = true
  ) => {
    loader && props?.onLoading(true);
    dispatch(portfolioActions.portfolioValue(portfolioId, timestamp, filter));
    dispatch(portfolioActions.portfolioTrend(portfolioId, timestamp, filter));
    await dispatch(
      portfolioActions.portfolioJtp(portfolioId, timestamp, filter)
    );
    if (deepLinkData) dispatch(portfolioActions.setDeeplinkData(null));
    loader && props?.onLoading(false);
  };

  //Updating filters from the filters component
  React.useEffect(() => {
    if (isFilterApplied) {
      let filterData = null;
      if (filters && Object.keys(filters).length) {
        filterData = Helpers.formatFilterData(filters);
      }
      updateAnalyticsData(portfolioId, selectedValue, filterData);
    }
  }, [filters, isFilterApplied]);

  React.useEffect(() => {
    dispatch(portfolioActions.setTimestamp(value));
    setSelectedValue(value);
  }, [value]);

  const handleGraphLoaded = () => {
    props?.onLoaded?.();
  };

  const npsValue = data.nps_diff;

  const config = React.useMemo(() => {
    return {
      // width: "400px",
      height: conv(150),
      lineColor: color,
      shadeColor: color,
      markerColor: color,
      lineThickness: conv(5),
      markerSize: conv(10),
      markerBorderColor: "#fff",
      goalLineColor: "#767E87",
      fixedMarker: false,
      showMarker: true,
      showTooltip: true,
      tooltipStyle: classes.graphTooltip,
    };
  }, [color]);
  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        direction="row"
        // mt={conv(20)}
      >
        {loaded && (
          <>
            <Typography variant="h4">
              {titles ? titles.nps_title : ""}
            </Typography>
            {npsValue !== 0 && (
              <Typography
                variant="p"
                className={classes.npsErrorLabel}
                style={{
                  background: Helpers.AddAlpha(color, 0.3),
                  color: color,
                }}
              >
                {npsValue > 0 ? "+" + npsValue : npsValue}
              </Typography>
            )}
          </>
        )}
      </Stack>
      <Grid container mt={conv(5)}>
        <Grid item md={3} sm={4}>
          {loaded && (
            <Typography variant="h1" pt={conv(20)} className={classes.npsScore}>
              {data.nps_score}
            </Typography>
          )}
        </Grid>
        <Grid item md={9} sm={8}>
          {trends && (
            <>
              <NPSGraph
                data={trends}
                config={config}
                value={selectedValue}
                goalLine={data.target_nps_value}
                tooltipFormatter={Helpers.DateToMMYYYY}
                containerHeight={conv(120)}
                onRelease={handleMarkerRelease}
                onLoaded={handleGraphLoaded}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

PortfolioDetail.propTypes = {
  data: PropTypes.object,
};

PortfolioDetail.defaultProps = {
  data: null,
};
export default PortfolioDetail;
