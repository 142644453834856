import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

export const useStyles = makeStyles((MUITheme) => ({
  root: {
    padding: conv(5,31,22,16),
    boxSizing: "border-box",
    borderLeft: conv(3)+" solid transparent",
    borderRadius:conv(8), 
    cursor: "pointer",
    marginBottom:conv(5)
  },

  selected: {
    background: MUITheme.palette.background.opDriverBg,
    borderLeft: conv(3)+` solid ${MUITheme.palette.background.skyblue}`,
    // transition:"all ease 0.2s"
  },
  track: {
    height: conv(7),
    borderRadius: conv(10),
    marginTop:conv(3)
  },
  bar: {
    borderRadius:conv(10),
    height: conv(7),
    background: MUITheme.palette.background.mainContent,
  },
  neutralLabel: {
    // color: `${MUITheme.palette.text.neutral} `,
    fontWeight: 400,
    fontSize: conv(16),
    lineHeight: conv(24)
  },
  whiteLabel: {
    color: `${MUITheme.palette.text.secondary} !important`,   
    fontWeight: 400,
    fontSize: conv(16),
    lineHeight: conv(24),
  },
  stats: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  opDriverTitle:{
    marginBottom:conv(13)+" !important"
  },

  animatedBar: {
    // width:"100%",
    // animationDuration: "2s",
    // animationTimingFunction: "ease-in-out",
    // animationDelay: "0s",
    // animationDirection: "normal",
    // animationIterationCount: "1",
    // animationName: "$animateWidth",
    // animationFillMode: "forwards",
  },
  // "@keyframes animateWidth": {
  //   "0%": {
  //     width: "0px",
  //   },
  //   "100%": {
  //     width: "100%",
  //   },
  // },
}));
