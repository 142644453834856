import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Divider,
  ListItem,
  Avatar,
  Box,
} from "@mui/material";
import clsx from "clsx";
import Button from "../../Components/Shared/Button";
import CircularProgress from "../Shared/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANTS, FEEDTYPES } from "../../Utils/Constants";
import AddComment from "./AddComment";
import InfinitePaginatedScroll from "../Shared/InfinitePaginatedScroll";
import LoadingView from "../LoadingView";
import {
  accountActions,
  commentActions,
  portfolioActions,
} from "../../Services/Redux/actionCreators";
import { useStyles } from "./styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/styles";
import ShowMoreText from "react-show-more-text";
import Chat from "../../Assets/images/menu-icons/chat";
import Like from "../../Assets/images/menu-icons/like";
import { getImageUrl } from "../../Utils/ImageHelper";
import { getTimeSince } from "../../Utils/Date";
import { useTranslate } from "../../Hooks/useTranslate";
import GUID from "../../Utils/Guid";
import UIHelper from "../../Utils/UIHelper";
import NoDataView from "../NoDataView";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  EVENT_TYPE,
  ACTION_EVENT_TYPE,
  ACTION_EVENT_SUBTYPE,
} from "../../Utils/Constants";
const conv = UIHelper.convertPxToRem;

const CommentsView = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [commentIconClr, setCommentIconClr] = useState("text.lighterGrey");
  const [mainLikeCount, setMainLikeCount] = useState(0);
  const [mainLike, setMainLike] = useState(false);
  const [changed, setChanged] = useState(false);
  const [origLikes, setOrigLikes] = useState();
  const [isLiked, setIsLiked] = useState(false);
  const [hasMorePage, setHasMorePage] = useState(true);
  const dispatch = useDispatch();
  const commentsData = useSelector((state) => state.comments);
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  const commentsMeta = commentsData.meta;

  const userDetails = commentsData.user_details;
  const imageCookie = useSelector((state) => state.app).cloud_cookie;
  const [replyComment, setReplyComment] = useState({
    showReplyComment: false,
    commentId: 0,
  });
  const t = useTranslate();

  const portfolioAnalyticsReactions = useSelector(
    (state) => state.portfolio.analyticsReactions
  );

  const accountAnalyticsReactions = useSelector(
    (state) => state.account.analyticsReactions
  );
  let accountName = useSelector((state) => state.account.data?.name);
  let accPortfolioName= useSelector((state) => state.account.data?.portfolio_name)
  let accountSFDC= useSelector((state) => state.account.data?.external_account_id)

  let portfolioName= useSelector((state) => state.portfolio.data?.name)

  const loadComments = async () => {
    setListLoading(true);
    const offset = commentsData.comments.length;
    if (offset === 0) setLoading(true);
    await dispatch(
      commentActions.loadComments(
        selectedFeed.feed_type,
        selectedFeed.source_id,
        offset,
        15
      )
    );
    if (offset === 0) setLoading(false);
    setListLoading(false);
  };

  const handleNext = () => {
    if (page == commentsMeta.page_count) {
      setHasMorePage(false);
    }
    setPage(page + 1);
  };

  useEffect(() => {
    if (changed) return;
    let data;
    if (
      selectedFeed.feed_type == FEEDTYPES.PORTFOLIO &&
      portfolioAnalyticsReactions
    ) {
      data = portfolioAnalyticsReactions;
    }
    if (
      selectedFeed.feed_type == FEEDTYPES.ACCOUNT &&
      accountAnalyticsReactions
    ) {
      data = accountAnalyticsReactions;
    }
    if (data) {
      setMainLike(data.user_reaction);
      setIsLiked(data.user_reaction);
      setMainLikeCount(data.total_reactions);
      setOrigLikes(data.total_reactions);
    }
  }, [portfolioAnalyticsReactions, accountAnalyticsReactions, changed]);

  useEffect(() => {
    if (commentsData.comments.length > 0) loadComments();
  }, [page]);

  useEffect(() => {
    if (commentsData.comments.length == 0) loadComments();
  }, []);

  

  const messageFormatRegex = /{(.*?)}/g;
  const formatMessage = (message) => {
    var split_string = message.split(messageFormatRegex).filter(Boolean);
    if (split_string.length == 1) {
      return [message];
    } else {
      split_string.forEach((str, index) => {
        if (userDetails[str]) {
          split_string[
            index
          ] = `<span style="font-weight:400;color:${getStyleForMenionedUser()}">${
            userDetails[str].display_name
          }</span>`;
        }
      });
      return split_string;
    }
  };
  const formatMessageForShow = (message) => {
    var split_string = message.split(messageFormatRegex).filter(Boolean);
    if (split_string.length == 1) {
      return [message];
    } else {
      split_string.forEach((str, index) => {
        if (userDetails[str]) {
          split_string[
            index
          ] = `<span style="font-weight:400;color:${getStyleForMenionedUser()};">${
            userDetails[str].display_name
          }</span>`;
        }
      });
      return split_string;
    }
  };

  const getStyleForMenionedUser = () => {
    return `${theme.palette.text.default} !important`;
  };

  const getAvatar = (item) => {
    return getImageUrl(userDetails[item.from].avatar, imageCookie);
  };

  const getDisplayName = (item) => {
    return userDetails[item.from].display_name;
  };

  const replyToComment = (item) => {
    let showReply;
    if (item.id == replyComment.commentId) {
      showReply = !replyComment.showReplyComment;
    } else {
      showReply = true;
    }
    setReplyComment({ showReplyComment: showReply, commentId: item.id });
  };

  const getLikeFillColor = () => {
    return mainLike
      ? theme.palette.text.secondary
      : theme.palette.text.lighterGrey;
  };

  const getCommentStrokeColor = () => {
    if (commentsData.meta && commentsData.meta.total_records) {
      return theme.palette.text.secondary;
    }
    return theme.palette.text.lighterGrey;
  };

  useEffect(() => {
    if (commentsData?.meta?.total_records) {
      setCommentIconClr(theme.palette.text.secondary);
    } else {
      setCommentIconClr(theme.palette.text.lighterGrey);
    }
  }, [commentsData?.meta?.total_records]);

  const toggleLikeOnClick = async () => {
    try {
      let nextLikeState = !mainLike;
      if (isLiked) {
        setMainLikeCount(nextLikeState ? origLikes : origLikes - 1);
      } else {
        setMainLikeCount(nextLikeState ? origLikes + 1 : origLikes);
      }
      setMainLike(nextLikeState);
      setChanged(true);
      dispatch(
        commentActions.toggleLike(
          selectedFeed.feed_type,
          selectedFeed.source_id.toString(),
          nextLikeState
        )
      );
      if (selectedFeed.feed_type == FEEDTYPES.ACCOUNT) {
        dispatch(
          accountActions.analyticsReactions(
            selectedFeed.source_id,
            selectedFeed.feed_type
          )
        );
        Pinpoint.sendEventData(EVENT_TYPE.ACTION,[ACTION_EVENT_TYPE.ACCOUNT,nextLikeState?ACTION_EVENT_SUBTYPE.LIKE:ACTION_EVENT_SUBTYPE.UNLIKE],[accountName],[accPortfolioName,accountSFDC])
   
       
      } else {
        dispatch(
          portfolioActions.analyticsReactions(
            selectedFeed.source_id,
            selectedFeed.feed_type
          )
        );
        Pinpoint.sendEventData(EVENT_TYPE.ACTION,[ACTION_EVENT_TYPE.PORTFOLIO,nextLikeState?ACTION_EVENT_SUBTYPE.LIKE:ACTION_EVENT_SUBTYPE.UNLIKE],[portfolioName])
  
      }
    } catch (error) {
      console.log(error, "state not loaded");
    }
  };

  const toggleCommentLikeOnClick = async (item, index) => {
    await dispatch(
      commentActions.toggleLike(
        CONSTANTS.COMMENT,
        item.id,
        !item.user_likes,
        index
      )
    ); 
    if (selectedFeed.feed_type == FEEDTYPES.ACCOUNT) {
      Pinpoint.sendEventData(EVENT_TYPE.ACTION,[ACTION_EVENT_TYPE.ACCOUNT,ACTION_EVENT_TYPE.COMMENT,item.user_likes?ACTION_EVENT_SUBTYPE.LIKE:ACTION_EVENT_SUBTYPE.UNLIKE],[accountName],[accPortfolioName,accountSFDC])
 
     
    } else if (selectedFeed.feed_type == FEEDTYPES.PORTFOLIO) { 
      Pinpoint.sendEventData(EVENT_TYPE.ACTION,[ACTION_EVENT_TYPE.PORTFOLIO,ACTION_EVENT_TYPE.COMMENT,item.user_likes?ACTION_EVENT_SUBTYPE.LIKE:ACTION_EVENT_SUBTYPE.UNLIKE],[portfolioName])

    }  
  };

  const getLikeFillColorForComments = (comment) => {
    return comment.user_likes
      ? theme.palette.text.secondary
      : theme.palette.text.lighterGrey;
  };

  const getView = () => {
    if (loading) return <LoadingView />;
    else if (
      !loading &&
      (!commentsData || commentsData.meta.total_records === 0)
    ) {
      return <NoDataView />;
    } else
      return (
        <InfinitePaginatedScroll
          clsx={clsx(classes.negativeMargin)}
          onNext={handleNext}
          loader={<CircularProgress show={listLoading} />}
          totalRecords={commentsData.meta.total_records}
          height="calc(100vh - 340px)"
        >
          {commentsData.comments.map((item, commentIndex) => (
            <Box key={item.id} pr={conv(20)}>
              <ListItem
                alignItems="flex-start"
                className={clsx(
                  item.parent_id == null && classes.parentItems,
                  item.parent_id != null && classes.childComment
                )}
              >
                <Stack spacing={0} sx={{ width: "100%" }}>
                  <Stack
                    direction="row"
                    spacing={0}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row">
                      <Avatar
                        alt={getDisplayName(item)}
                        src={getAvatar(item)}
                        sx={{ width: 27, height: 27 }}
                      />
                      <Typography
                        component="span"
                        variant="body2"
                        className={clsx(classes.displayName)}
                      >
                        {getDisplayName(item)}
                      </Typography>
                    </Stack>
                    <Typography
                      component="span"
                      variant="body2"
                      className={clsx(classes.commentTime)}
                    >
                      {getTimeSince(item.created_time)} {t("ago")}
                    </Typography>
                  </Stack>

                  <Typography
                    sx={{ display: "inline", position: "relative" }}
                    component="div"
                    variant="body2"
                    className={clsx(classes.commentText)}
                  >
                    <ShowMoreText
                      lines={3}
                      more={`${t("more")}...`}
                      less={t("less")}
                      truncatedEndingComponent={"..."}
                    >
                      {formatMessage(item.message.text).map((str) => {
                        return (
                          <span key={GUID(new Date().getTime())}>
                            <span
                              dangerouslySetInnerHTML={{ __html: str }}
                            ></span>
                          </span>
                        );
                      })}
                    </ShowMoreText>
                    <Box
                      style={{
                        position: "absolute",
                        top: 0,
                        background: item.parent_id
                          ? theme.palette.background.replyCommentBg
                          : theme.palette.background.secondary,
                      }}
                      className={clsx(classes.lineClamp)}
                    >
                      {formatMessageForShow(item.message.text).map((str) => {
                        return (
                          <span key={GUID(new Date().getTime())}>
                            <span
                              dangerouslySetInnerHTML={{ __html: str }}
                            ></span>
                          </span>
                        );
                      })}
                    </Box>
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={0}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={() =>
                        toggleCommentLikeOnClick(item, commentIndex)
                      }
                    >
                      <Like fill={getLikeFillColorForComments(item)} />
                    </IconButton>
                    <Typography
                      component="span"
                      variant="body2"
                      className={clsx(
                        classes.commentsLight,
                        classes.likesCount
                      )}
                    >
                      {item.likes_count.total_reactions}
                    </Typography>
                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={() => replyToComment(item)}
                    >
                      <Chat
                        width={conv(15)}
                        height={conv(15)}
                        stroke={theme.palette.text.lighterGrey}
                      />
                    </IconButton>
                    <Typography
                      component="span"
                      variant="body2"
                      className={clsx(
                        classes.commentsLight,
                        classes.likesCount,
                        classes.commentsCount
                      )}
                    >
                      {item.reply_count}
                    </Typography>
                  </Stack>
                </Stack>
              </ListItem>
              {replyComment.showReplyComment &&
                replyComment.commentId == item.id && (
                <ListItem
                  key={`add-comment-${item.id}`}
                  alignItems="flex-start"
                  sx={{ padding: "0 !important" }}
                >
                  <Stack spacing={0} sx={{ width: "100%" }}>
                    <AddComment
                      setReplyComment={setReplyComment}
                      index={commentIndex}
                      parentId={item.id}
                      from={item.from}
                      displayName={getDisplayName(item)}
                    />
                  </Stack>
                </ListItem>
              )}
            </Box>
          ))}
        </InfinitePaginatedScroll>
      );
  };

  return (
    <>
      <Box p={conv(28)}>
        <Stack
          direction="row"
          spacing={0}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            spacing={0}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Button
              className={clsx(classes.likeButton)}
              onClick={() => toggleLikeOnClick()}
            >
              <Like
                fill={getLikeFillColor()}
                style={{ width: conv(21), height: conv(21) }}
              />
            </Button>
            <Typography
              component="span"
              variant="body2"
              className={clsx(classes.commentsLight, classes.likeCommentAction)}
            >
              {t("likes")}
            </Typography>
          </Stack>
          <Typography
            component="span"
            variant="body2"
            className={clsx(
              classes.commentsLight,
              classes.likeCommentAction,
              classes.likeCommentActionCount
            )}
          >
            {mainLikeCount}
          </Typography>
        </Stack>
        <Divider className={clsx(classes.likeCommentDivider)} />
        <Stack
          mt={conv(6)}
          direction="row"
          spacing={0}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            spacing={0}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Chat
              stroke={commentIconClr}
              style={{ width: conv(21), height: conv(21) }}
            />
            <Typography
              component="span"
              variant="body2"
              className={clsx(classes.commentsLight, classes.likeCommentAction)}
            >
              {t("alert_filters.comments")}
            </Typography>
          </Stack>
          <Typography
            component="span"
            variant="body2"
            className={clsx(
              classes.commentsLight,
              classes.likeCommentAction,
              classes.likeCommentActionCount
            )}
          >
            {commentsData.meta.total_records}
          </Typography>
        </Stack>
        <Divider className={clsx(classes.likeCommentDivider)} />

        <AddComment index={-1} />
        {getView()}
      </Box>
    </>
  );
};

export default CommentsView;
