import * as React from "react";
import { Stack, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import { useStyles } from "../styles";
import AccountSlider from "../../AccountSlider";
import Helpers from "../../../Utils/Helper";
import { useSelector } from "react-redux";
import AmountLabel from "../../AmountLabel";
import { useTheme } from "@mui/styles";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

const AccountSliderView = (props) => {
  const {
    title,
    nrrLabel,
    npsLabel,
    date,
    data,
    value,
    text,
    currency,
    amount,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const npsColors = useSelector((state) => state.app.mapping_data.nps_color);
  const nrrColors = useSelector(
    (state) => state.app.mapping_data.nrr_state_color
  );
  const [accountData, setAccountData] = React.useState([]);
  const [currentItem, setCurrentItem] = React.useState();

  let show_nrr = false;

  React.useEffect(() => {
    const trends = [...data.trends];
    let accData = [];
    if (trends.length === 0 && npsColors) {
      setCurrentItem({ text: text, color: npsColors[text.toLowerCase()] });
    }
    if (trends && trends.length) {
      trends.reverse().forEach((item) => {
        let text, color;
        if (item.show_nrr) {
          text = item.nrr_state;
          color = nrrColors[text.toLowerCase()];
        } else {
          text = item.value;
          color = npsColors[text.toLowerCase()];
        }
        let resultItem = { ...item, text: text, color };
        if (item.timestamp === value) {
          setCurrentItem(resultItem);
        }
        accData.push(resultItem);
      });
      setAccountData(accData);
    }
  }, [data, npsColors]);

  if (currentItem) show_nrr = currentItem.show_nrr;
  return (
    <>
      <Typography variant="h4" className={classes.account_title}>
        {title}
        <AmountLabel>
          <Typography variant="span" className={classes.finLabel}>
            {currency}
            {amount}
          </Typography>
        </AmountLabel>
      </Typography>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        mt="1.25rem"
      >
        {currentItem && (
          <Typography variant="h6">
            {show_nrr ? nrrLabel : npsLabel}
            {show_nrr && currentItem.nrr_percentage > 0 && (
              <Typography
                variant="a"
                className={classes.npsErrorLabel}
                style={{
                  background: Helpers.AddAlpha(currentItem.color, 0.3),
                  color: currentItem.color,
                }}
              >
                {Math.round(currentItem.nrr_percentage)}%
              </Typography>
            )}
          </Typography>
        )}
        <Typography variant="neutral" pr={0} className={classes.neutralLabel}>
          {accountData?.length ? date : ""}
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        mt="5px"
      >
        {currentItem && (
          <Typography
            variant="h4"
            pr="10px"
            className={classes.account_status}
            style={{ color: currentItem.color }}
          >
            {show_nrr ? currentItem.nrr_state : currentItem.text}
          </Typography>
        )}
        <Box flex="1">
          <AccountSlider
            data={{
              trends: accountData,
              total_trend_month: data.total_trend_month,
              available_trend_month: data.available_trend_month,
          
            }}
            value={currentItem}
            config={{
              readOnly: true,
              height: conv(8),
              trackStyle: {
                background: theme.palette.background[props.selected?"accSliderSelectedBg":"accSliderBg"],
                marginTop: conv(4),
              },
              markerStyle: {
                top: conv(-4),
                height: conv(9),
                width: conv(9),
              },
            }}
          />
        </Box>
      </Stack>
    </>
  );
};

AccountSliderView.propTypes = {
  title: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.object,
  nrrLabel: PropTypes.string,
  npsLabel: PropTypes.string,
  date: PropTypes.string,
};

AccountSliderView.defaultProps = {
  title: "Alpha Upsilon Furniture & Cabinetry",
  currency: "$",
  data: {},
  nrrLabel: "NRR",
  npsLabel: "NPS",
  date: "May, 2022",
};
export default AccountSliderView;
