import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles(MUITheme => ({
  buttonChecked: {
    backgroundColor: MUITheme.palette.background.skyblue +" !important",
  },
  button: {
    backgroundColor: "transparent",
    height:conv(52),
    width:conv(52),
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    borderRadius: "14px",
  },
  badge: {
    color: "#fff",
    "& .BaseBadge-badge": {
      backgroundColor: MUITheme.palette.background.red,
    },
  }
}));
