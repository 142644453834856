import React, { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../Hooks/useTranslate";
import DeleteAlert from "../Shared/DeleteAlert";
import { useNavigate } from "react-router-dom";
import {
  accountActions,
  alertActions,
  feedActions,
  portfolioActions,
} from "../../Services/Redux/actionCreators";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  EVENT_TYPE,ACTION_EVENT_TYPE,ACTION_EVENT_SUBTYPE
} from "../../Utils/Constants";

const DeleteModal = () => {
  const t = useTranslate();
  const navigate=useNavigate();
  const show_delete_modal = useSelector(
    (state) => state.alerts.show_delete_modal
  );
  const selected = useSelector((state) => state.alerts.selected);
  const dispatch = useDispatch();

  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  const handleDeleteClick = useCallback(() => {
    dispatch(alertActions.deleteAlert(selected));
    Pinpoint.sendEventData(EVENT_TYPE.ACTION,[ACTION_EVENT_TYPE.ALERTS,ACTION_EVENT_SUBTYPE.DELETE])
    if (selectedFeed.feed_id === selected) {
      dispatch(feedActions.setSelectedFeed(null));
      dispatch(accountActions.clearAllData());
      dispatch(portfolioActions.clearAllData());
      navigate("/dashboard");
    }
  }, [selected,selectedFeed]);

  const handleCloseClick = useCallback(() => {
    dispatch(alertActions.closeDeleteModal());
  }, []);

  useEffect(() => {
    return () => dispatch(alertActions.closeDeleteModal());
  }, []);

  return (
    <DeleteAlert
      open={show_delete_modal}
      title={t("are_you_sure")}
      description={t("alert_delete_desc")}
      onCancel={handleCloseClick}
      onSuccess={handleDeleteClick}
    />
  );
};

export default React.memo(DeleteModal);
