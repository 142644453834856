import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element: Element, ...props }) => {
  if (props.isAllowed) {
    return <Element {...props} />;
  }
  return <Navigate to={props.redirectTo?.path} replace />;
};

export default PrivateRoute;
