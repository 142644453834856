import React from "react";
import FooterLink from "./FooterLink";
import { useStyles } from "./styles";
import { Typography, Box } from "@mui/material";
import { useTranslate } from "../../../Hooks/useTranslate";
const Footer = ({ forProfile = false }) => {
  const t = useTranslate();
  const classes = useStyles();
  if (forProfile)
    return (
      <div>
        <Box textAlign={"center"}>
          {" "}
          <Typography color="text.default" variant="span" fontSize="1rem">
            {process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
        <div className={classes.footer}>
          <FooterLink url={process.env.REACT_APP_PRIVACY_POLICY}>
            {t("privacy_policy")}
          </FooterLink>
          |
          <FooterLink url={process.env.REACT_APP_TERMS_OF_USE}>
            {t("terms_of_use")}
          </FooterLink>
          |
          <FooterLink url={process.env.REACT_APP_DISCLOSURES}>
            {t("disclosures")}
          </FooterLink>
        </div>
      </div>
    );
  else
    return (
      <div className={classes.footer}>
        <FooterLink>{process.env.REACT_APP_VERSION}</FooterLink> |
        <FooterLink url={process.env.REACT_APP_PRIVACY_POLICY}>
          {t("privacy_policy")}
        </FooterLink>
        |
        <FooterLink url={process.env.REACT_APP_TERMS_OF_USE}>
          {t("terms_of_use")}
        </FooterLink>
      </div>
    );
};

export default Footer;
