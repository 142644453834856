import * as React from "react";
import Button from "../Shared/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Box, Typography, Stack } from "@mui/material";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
import PropTypes from "prop-types";

import { useStyles } from "./styles";

const AlertDialog = ({
  open,
  icon,
  title,
  description,
  onClose,
  onConfirm,
  buttonText = "Retry",
}) => {
  const classes = useStyles();
  const handleConfirm = () => {
    onConfirm && onConfirm();
  };
  return (
    <Dialog open={open} onClose={onClose} className={classes.container}>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent="center"
        padding={conv(0, 20)}
      >
        {icon && <img src={icon} alt="no-internet-icon" />}
        <Typography variant="h4" mt="1rem">
          {title}
        </Typography>
      </Box>
      <Typography
        variant="h6"
        textAlign={"center"}
        mt="1rem"
        fontWeight={"normal"}
      >
        {description}
      </Typography>
      <DialogActions
        className={classes.content}
        style={{ justifyContent: "center" }}
      >
        <Box width="200px" mt={"1rem"}>
          <Button
            variant="contained"
            className={classes.buttonNext}
            onClick={handleConfirm}
            children={buttonText}
            type="submit"
          ></Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
export default AlertDialog;
