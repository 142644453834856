import * as React from "react";
import { Grid, Stack, Box, Typography, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import KPIDrillDownView from "./KPIDrillDownView";
import { useStyles } from "./styles";
import GUID from "../../Utils/Guid";
import LoadingView from "../LoadingView";
import Images from "../../Assets/images/menu-icons";
import Animator from "../Animator";
import { useTranslate } from "../../Hooks/useTranslate";
import NoDataView from "../NoDataView";
import UIHelper from "../../Utils/UIHelper";
import { useTheme } from "@mui/styles";
const conv = UIHelper.convertPxToRem;
const NAV_BUTTONS = { PREVIOUS: "PREVIOUS", NEXT: "NEXT" };

const KPIDrillDownBars = (props) => {
  const classes = useStyles();
  const t = useTranslate();
  const theme=useTheme();
  const [index, setIndex] = React.useState(-1);
  const [length, setLength] = React.useState(0);
  const [selected, setSelected] = React.useState();

  const { drivers, selectedDriver, onNavigate, data, loading } = props;

  const handlePaginate = React.useCallback(
    (button) => {
      if (button === NAV_BUTTONS.NEXT) {
        if (index < length - 1) {
          setIndex(index + 1);
        }
      } else if (button === NAV_BUTTONS.PREVIOUS) {
        if (index > 0) {
          setIndex(index - 1);
        }
      }
    },
    [index, length]
  );

  React.useEffect(() => {
    if (drivers?.length&&selectedDriver) {
      {
        setLength(drivers.length);
        let ind = drivers.findIndex((item) => item.id === selectedDriver);

        setIndex(ind);
        setSelected(drivers[ind]);
      }
    }
  }, [drivers, selectedDriver]);

  React.useEffect(() => {
    if (drivers?.length && index >= 0)
      onNavigate && onNavigate(drivers[index]?.id);
  }, [index]);

  let titleColor = selected?.color_code;
  if (selected?.impact < 0) {
    titleColor = "text.neutral";
  }
  return (
    <>
      <Animator  animation="fadeInUp">
        <Box mr={"1.75rem"} className={classes.root}>
          <Grid container>
            <Grid item md={9} sm={9}>
              <Typography
                variant="h5"
                className={classes.capability_label}
                color={titleColor}
              >
                {selected?.display_name}
              </Typography>
            </Grid>
            <Grid item md={3} sm={3} textAlign="right">
              <Box className={classes.box_header}>
                <IconButton sx={{":hover":{background:"none"}}}
                  disabled={index == 0}
                  onClick={() => handlePaginate(NAV_BUTTONS.PREVIOUS)}
                >
                  <Images.Previous />
                </IconButton>
                <IconButton  sx={{":hover":{background:"none"}}}
                  disabled={index === length - 1}
                  onClick={() => handlePaginate(NAV_BUTTONS.NEXT)}
                >
                  <Images.Next />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Box mt={conv(34)} mb={conv(32)}>
            <Typography
              variant="h6"
              className={classes.impact_label}
              display="inline-block"
            >
              <b>{t("kpi")}</b> /{" "}
              <Typography variant="neutral">{t("impact")}</Typography>
            </Typography>
          </Box>
          <Stack>
            {loading ? (
              <LoadingView />
            ) : data && data.length > 0 ? (
              data.map((item) => (
                <Animator key={GUID("kpi_view")} animation="bounceInUp" >
                  <KPIDrillDownView data={item} />
                </Animator>
              ))
            ) : (
              <NoDataView />
            )}
          </Stack>
        </Box>
      </Animator>
    </>
  );
};

KPIDrillDownBars.propTypes = {
  data: PropTypes.array,
  trackColor: PropTypes.string,
};

KPIDrillDownBars.defaultProps = {
  data: [],
  trackColor: "background.mainContent",
};
export default React.memo(KPIDrillDownBars);
