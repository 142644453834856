import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import Pinpoint from "../pinpointActions";
import {
  EVENT_TYPE,
  ACTION_EVENT_TYPE,
  ACTION_EVENT_SUBTYPE,
} from "../../../../Utils/Constants";
import { ERROR_CODES } from "../../../../Utils/ErrorCodes";

import { loaderActions } from "../";
import { LoaderContent } from "../../../../Utils/Loader";
import { getCurrentUTCEpoch } from "../../../../Utils/Date";
import { APIEndpoints, APIPaths } from "../../../AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../../Utils/API";
import { parseError } from "../../../../Utils/ErrorHandling";
import { appActions, performanceActions } from "../";

export const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_NICKNAME = "SET_USER_NICKNAME";
export const SET_APP_INITIALIZED = "SET_APP_INITIALIZED";
export const RESET_USER_ON_SIGNOUT = "RESET_USER_ON_SIGNOUT";
export const SET_JWT_EXP = "SET_JWT_EXP";
export const SET_USER_ATTRIBUTES = "SET_USER_ATTRIBUTES";
export const SET_USER_DATA = "SET_USER_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
const setUserLoggedIn = (isLoggedin) => ({
  type: SET_USER_LOGGED_IN,
  payload: isLoggedin,
});

export const setUserEmail = (email) => ({
  type: SET_USER_EMAIL,
  payload: email,
});

export const setUserNickname = (nickname) => ({
  type: SET_USER_NICKNAME,
  payload: nickname,
});
export const setUserDetailData = (data) => ({
  type: SET_USER_DATA,
  payload: data,
});

export const updateUserDetailData = (data) => ({
  type: UPDATE_USER_DATA,
  payload: data,
});

const setAppInitialized = (isInitialized) => ({
  type: SET_APP_INITIALIZED,
  payload: isInitialized,
});

const resetUserOnSignout = () => ({ type: RESET_USER_ON_SIGNOUT });

const setJWTExp = (exp) => ({ type: SET_JWT_EXP, payload: exp });

export const fetchAuthenticatedUser = () => async (dispatch, getState) => {
  let start = new Date();
  let bypassCache = false;

  const { exp } = getState().user.jwt;
  const currentEpochInUTC = getCurrentUTCEpoch();
  if (!exp || currentEpochInUTC >= Number(exp)) {
    bypassCache = true;
  }

  const currentUser = await Auth.currentAuthenticatedUser({ bypassCache });
  dispatch(
    performanceActions.log({
      name: "USER",
      api: "FETCH AUTHENTICATED USER",
      start,
    })
  );
  const newExp = currentUser.signInUserSession.idToken.payload.exp;
  dispatch(setJWTExp(newExp));
  const userDetails =
    currentUser.signInUserSession.idToken.payload.user_details;
  const userDetailsArr = JSON.parse(userDetails);
  let enterprise_id;
  if (userDetailsArr.length) enterprise_id = userDetailsArr[0].enterprise_id;

  return {
    email: currentUser.attributes.email,
    isEmailVerified: currentUser.attributes.email_verified,
    token: currentUser.signInUserSession.idToken.jwtToken,
    enterprise_id: enterprise_id,
  };
};

const getCurrentAuthenticatedUser = () => async (dispatch, getState) => {
  let bypassCache = false;

  const { exp } = getState().user.jwt;
  const currentEpochInUTC = getCurrentUTCEpoch();
  if (!exp || currentEpochInUTC >= Number(exp)) {
    bypassCache = true;
  }

  const currentUser = await Auth.currentAuthenticatedUser({ bypassCache });
  const newExp = currentUser.signInUserSession.idToken.payload.exp;
  dispatch(setJWTExp(newExp));
  return currentUser;
};

export const initializeApplication = async (dispatch) => {
  try {
    let start = new Date();
    const userAttributes = await dispatch(fetchAuthenticatedUser());
    let userData = await dispatch(getUser());
    if (userData?.data?.length)
      dispatch(
        setUserAttributes({
          ...userAttributes,
          user: userData.data[0],
          isLoggedIn: true,
        })
      );
    let allResult = await Promise.allSettled([
      dispatch(appActions.getCloudCookie()),
      dispatch(appActions.getFieldMapping()),
      dispatch(setAppInitialized(true)),
    ]);

    dispatch(
      performanceActions.log({
        name: "APP",
        api: "INIT APP",
        start,
      })
    );
    return allResult;
  } catch (error) {
    dispatch(setAppInitialized(true));
  }
};

const loginSucess = (loginResponse) => async (dispatch) => {
  const userAttributes = {
    isLoggedIn: true,
    email: loginResponse.attributes.email,
    isEmailVerified: loginResponse.attributes.email_verified,
  };
  let userData = await dispatch(getUser());
  if (userData?.data?.length)
    dispatch(
      setUserAttributes({
        ...userAttributes,
        user: userData.data[0],
        isLoggedIn: true,
      })
    );

  let result = await Promise.all([
    dispatch(appActions.getCloudCookie()),
    dispatch(appActions.getFieldMapping()),
    dispatch(setAppInitialized(true)),
  ]);
  dispatch(loaderActions.stopAppLoader());
  Pinpoint.sendEventData(EVENT_TYPE.ACTION, [ACTION_EVENT_SUBTYPE.LOGIN]);
  return result;
};

const verifyEmailSuccess = (verifyResponse) => async (dispatch) => {
  const userAttributes = {
    email: verifyResponse.email,
    enabled: verifyResponse.data.enabled,
    userStatus: verifyResponse.data.user_status,
  };

  return await Promise.all([
    dispatch(setUserAttributes(userAttributes)),
    dispatch(loaderActions.stopAppLoader()),
  ]);
};

const setUserData = (data) => async (dispatch) => {
  if (data.data) {
    const userAttributes = { ...data.data[0] };
    return await dispatch(setUserDetailData(userAttributes));
  }
};

const handleUserNotConfirmed = (email) => async (dispatch) => {
  return await Promise.all([
    dispatch(setUserLoggedIn(true)),
    dispatch(setUserEmail(email)),
  ]);
};
export const verifyEmail = (email) => async (dispatch) => {
  try {
    dispatch(loaderActions.startAppLoader(LoaderContent.LOGIN));

    const apiName = APIEndpoints.USER.name;
    const apiPath = APIPaths.VERIFY_EMAIL(email);
    const apiOptions = initializeAPIOptions(null, null, null, null, {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    });
    let apiResult = await API.get(apiName, apiPath, apiOptions).then(response => response).catch(err=>{
      dispatch(loaderActions.stopAppLoader());
      throw err;
    });

    await dispatch(verifyEmailSuccess({ email, ...apiResult }));
    return apiResult;
  } catch (error) {
    dispatch(loaderActions.stopAppLoader());
    throw error;
  }
};

export const sendVerificationCodeThroughAPI = (email) => async (dispatch) => {
  try {
    dispatch(loaderActions.startAppLoader(LoaderContent.LOGIN));

    const apiName = APIEndpoints.USER.name;
    const apiPath = APIPaths.SEND_VERIFICATION_CODE;
    const apiOptions = initializeAPIOptions(
      null,
      null,
      { email: email },
      null,
      {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      }
    );
    let apiResult = await API.post(apiName, apiPath, apiOptions).then(response => response).catch(err=>{
      dispatch(loaderActions.stopAppLoader());
      throw err;
    });
    dispatch(loaderActions.stopAppLoader());
    return apiResult;
  } catch (error) {
    // let err = parseError(error);
    dispatch(loaderActions.stopAppLoader());
    throw error;
  }
};

export const setPasswordThroughAPI =
  (email, password, verificatonCode) => async (dispatch) => {
    try {
      dispatch(loaderActions.startAppLoader(LoaderContent.LOGIN));

      const apiName = APIEndpoints.USER.name;
      const apiPath = APIPaths.RESET_PASSWORD;
      const apiOptions = initializeAPIOptions(
        null,
        null,
        {
          email: email,
          password: password,
          verification_code: verificatonCode,
        },
        null,
        {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        }
      );
      let apiResult = await API.post(apiName, apiPath, apiOptions).then(response => response).catch(err=>{
        dispatch(loaderActions.stopAppLoader());
        throw err;
      });

      dispatch(loaderActions.stopAppLoader());
      return apiResult;
    } catch (error) {
      dispatch(loaderActions.stopAppLoader());
      throw error;
    }
  };

export const getUser = () => async (dispatch) => {
  try {
    let start = new Date();
    const apiName = APIEndpoints.USER.name;
    const apiPath = APIPaths.GET_USER;
    const { token } = await dispatch(fetchAuthenticatedUser());
    const apiOptions = initializeAPIOptions(token);
    let apiResult = await API.get(apiName, apiPath, apiOptions);
    dispatch(
      performanceActions.log({
        name: "USER",
        api: "GET USER",
        start,
      })
    );
    return apiResult;
  } catch (error) {
    throw error;
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch(loaderActions.startAppLoader(LoaderContent.LOGIN));
    const loginResponse = await Auth.signIn(email, password);
    await dispatch(loginSucess(loginResponse));
    return;
  } catch (error) {
    let err = parseError(error);
    dispatch(loaderActions.stopAppLoader());
    throw err;
  }
};

export const signout = () => async (dispatch) => {
  dispatch(loaderActions.startAppLoader(LoaderContent.SIGN_OUT));
  await Auth.signOut();
  await dispatch(resetUserOnSignout());
  dispatch(loaderActions.stopAppLoader());
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch(loaderActions.startAppLoader(LoaderContent.FORGOT_PASSWORD));
    let response = await Auth.forgotPassword(email).then(response => response).catch(err=>{
      dispatch(loaderActions.stopAppLoader());
      throw err;
    });
    dispatch(loaderActions.stopAppLoader());
  } catch (error) {
    dispatch(loaderActions.stopAppLoader());
    let err = parseError(error);
    throw err;
  }
};

export const forgotPasswordSubmit =
  (email, code, password) => async (dispatch) => {
    try {
      dispatch(
        loaderActions.startAppLoader(LoaderContent.FORGOT_PASSWORD_SUBMIT)
      );
      let result= await Auth.forgotPasswordSubmit(email, code, password).then(response => response).catch(err=>{
        dispatch(loaderActions.stopAppLoader());
        throw err;
      });
    
      dispatch(loaderActions.stopAppLoader());
    } catch (error) {
      console.log("Forget Password Res===",error);
      dispatch(loaderActions.stopAppLoader());
      throw error;
    }
  };

export const setUserAttributes = (userAttributes) => (dispatch) => {
  dispatch({ type: SET_USER_ATTRIBUTES, payload: userAttributes });
};

export const updateUser = (data) => async (dispatch) => {
  try {
    let start = new Date();
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.USER.name;
    const apiPath = APIPaths.UPDATE_USER_PROFILE_API;
    const apiOptions = initializeAPIOptions(token, enterprise_id, data);
    let apiResult = await API.put(apiName, apiPath, apiOptions);
    dispatch(
      performanceActions.log({
        name: "USER",
        api: "UPDATE USER",
        start,
      })
    );
    if (apiResult?.data) dispatch(updateUserDetailData(apiResult.data));
    return apiResult;
  } catch (error) {
    let err = parseError(error);
    throw err;
  }
};
export const updateProfilePhoto = (data) => async (dispatch) => {
  try {
    let start = new Date();
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.USER.name;
    const apiPath = APIPaths.UPDATE_USER_PROFILE_PIC_API;
    const apiOptions = initializeAPIOptions(token, enterprise_id, data, null, {
      "content-type": "image/jpeg",
    });
    let apiResult = await API.post(apiName, apiPath, apiOptions);
    dispatch(
      performanceActions.log({
        name: "USER",
        api: "UPLOAD PROFILE PHOTO",
        start,
      })
    );
    if (apiResult?.data?.url) {
      dispatch(updateUserDetailData({ avatar: apiResult.data.url }));
    }
    return apiResult;
  } catch (error) {
    let err = parseError(error);
    throw err;
  }
};
