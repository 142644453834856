import { makeStyles } from "@mui/styles";
import Background from "../../Assets/images/header-bg.png";
export const useStyles = makeStyles(MUITheme => ({
  root:{
    height:"60px",
    background:`${MUITheme.palette.background.black} !important`
  },
  backgroundImg: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize: "contain",
    backgroundImage: `url(${Background})`,
    // backgroundColor: MUITheme.palette.background.black,
  },
  box: {
    padding: "10px 20px !important",
  },
  pointerCursor:{
    cursor:"pointer"
  },
  userIcon: {
    border: `2px solid ${MUITheme.palette.background.white} !important`,
  },
}));
