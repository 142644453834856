
const pxToREM = (px) => {
  if (typeof px === "string") {
    let ind = px.indexOf("px");
    if (ind > -1) {
      px = px.substring(0, ind);
    }
    px=parseFloat(px);
  }
  // let currentRemVal = parseFloat(
  //   getComputedStyle(document.documentElement).fontSize
  // );
  // console.log("Current REM Value",currentRemVal);
  let currentRemVal=16;
  return `${px / currentRemVal}rem `;
};

function convertPxToRem(...px){
  let res="";
  px.forEach(item=>{
    res=res+pxToREM(item);
  })
  return res;
}
export default { convertPxToRem };
