import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../Services/Redux/actionCreators";
import SidebarHeader from "../SidebarHeader";
import { useTranslate } from "../../Hooks/useTranslate";
import Button from "../Shared/Button";
import LoadingView from "../LoadingView";
import { FEEDTYPES } from "../../Utils/Constants";
import UIHelper from "../../Utils/UIHelper";
import CloseButton from "../CloseButton";
const conv = UIHelper.convertPxToRem;

function FavoriteStatus({ onUpdate }) {
  const classes = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [isFavorite, setFavorite] = useState(false);
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  const selectedAccount = useSelector((state) => state.account.data);
  const selectedPortfolio = useSelector((state) => state.portfolio.data);

  const portfolioAnalyticsStatus = useSelector(
    (state) => state.portfolio.analyticsStatus
  );

  const accountAnalyticsStatus = useSelector(
    (state) => state.account.analyticsStatus
  );
  const updateFavoriteStatus = () => {
    onUpdate && onUpdate();
  };

  useEffect(() => {
    if (selectedFeed) {
      let statuses;
      if (selectedFeed.feed_type === FEEDTYPES.PORTFOLIO) {
        statuses = portfolioAnalyticsStatus;
        setName(selectedPortfolio.name);
      } else if (selectedFeed.feed_type === FEEDTYPES.ACCOUNT) {
        statuses = accountAnalyticsStatus;
        setName(selectedAccount.name);
      }
      if (statuses) {
        setFavorite(statuses.favorite_status);
      }
    }
  }, [
    selectedFeed,
    portfolioAnalyticsStatus?.favorite_status,
    accountAnalyticsStatus?.favorite_status,
  ]);

  if (loading) {
    return <LoadingView mt={"20%"} />;
  }

  return (
    <Box display={"flex"} p={conv(13, 28)} flex={1} flexDirection={"column"}>
      <Box display="flex" flex={1} flexDirection="column">
        <SidebarHeader
          title={t("title_favorite")}
          mb={conv(29)}
        />
        {isFavorite ? (
          <Typography variant="body2">
            {t("you_have_added")}{" "}
            <Typography variant="body2" fontWeight={700} component="span">
              {name}
            </Typography>{" "}
            {t("to_your_fav")}
          </Typography>
        ) : (
          <Typography variant="body2">
            {t("fav_not_added")}{" "}
            <Typography variant="body2" fontWeight={700} component="span">
              {name}
            </Typography>{" "}
            {t("as_a_fav")}
          </Typography>
        )}
      </Box>

      <Box m={conv(39, 42)}>
        <Button
          color="primary"
          className={classes.buttonApply}
          variant="contained"
          onClick={updateFavoriteStatus}
          disableRipple
          children={isFavorite ? t("remove_favorite") : t("add_favorite")}
          type="submit"
        />
      </Box>
    </Box>
  );
}

export default React.memo(FavoriteStatus);
