import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(MUITheme => ({
  root: {
    overflowY:"auto",
    width:"100%",
    height:"100%",
    "& .infinite-scroll-component ":{
      overflow: "initial !important"
    }
  }
}));
