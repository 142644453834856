import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((MUITheme) => ({
  root: {
    "& .MuiAvatar-root.MuiAvatar-circular": {
      border: `1.5px solid ${MUITheme.palette.border.avatarBorder}`,
      fontSize: "1.2rem",
    },
    "& .MuiAvatar-root.MuiAvatar-circular:nth-of-type(1)": {
      fontSize: "1.2rem",
      marginTop:"2px",
      background:"none",
      border:"none",
      marginLeft:"-3px",
      fontWeight:400,
      color:MUITheme.palette.text.primary,
      height:"40px",
      width:"40px",
    },
  },
}));
