import React from "react";
import { UncheckedColor } from ".";
import { useTheme } from "@mui/styles";

const Comments = ({ checked }) => {
  const theme=useTheme();
  return (
    <svg
      width={window.conv(25)}
      height={window.conv(24)}
      viewBox="0 0 29 28"
      fill={checked ? UncheckedColor : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.69268 14.8107L1.74304 14.6276L1.72276 14.4388C1.69414 14.1722 1.67938 13.9005 1.67938 13.6246V8.43923C1.67938 4.60524 4.48795 1.66113 7.75953 1.66113H21.9198C25.1914 1.66113 28 4.60524 28 8.43923V13.6246C28 17.4586 25.1914 20.4027 21.9198 20.4027H9.85027C9.00974 20.4027 8.20093 20.7236 7.589 21.2998L1.94066 26.6185C1.89939 26.6574 1.87593 26.6602 1.8719 26.6607L1.87163 26.6607C1.86339 26.6618 1.84435 26.6617 1.81614 26.6475C1.76413 26.6212 1.67822 26.5417 1.67822 26.3623V14.921C1.67822 14.8803 1.68367 14.8434 1.69268 14.8107Z"
        stroke={UncheckedColor}
        strokeWidth="2"
      />
      <circle
        cx="10.1707"
        cy="12.1536"
        r="1.14925"
        fill={checked ? theme.palette.background.iconFill : UncheckedColor}
      />
      <circle
        cx="14.7677"
        cy="12.1536"
        r="1.14925"
        fill={checked ? theme.palette.background.iconFill : UncheckedColor}
      />
      <circle
        cx="19.3648"
        cy="12.1536"
        r="1.14925"
        fill={checked ? theme.palette.background.iconFill : UncheckedColor}
      />
    </svg>
  );
};

export default Comments;
