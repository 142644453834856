import * as React from "react";
import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import { useEffect } from "react";
import { useState } from "react";

export default function FileUpload({ name, alt, src, onChange, style, icon }) {
  const classes = useStyles();
  const [source, setSource] = useState();
  useEffect(() => {
    setSource(src);
  }, [src]);
  const handleFileSelected = (ev) => {
    try {
      if (ev.target.files && ev.target.files[0]) {
        ev.persist();
        let reader = new FileReader();
        reader.onload = (e) => {
          let image = e.target.result;
          setSource(image);
          let buffer = image.substring(image.indexOf(",") + 1);
          onChange && onChange({ target: { name, value: buffer } });
        };
        reader.readAsDataURL(ev.target.files[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          ...style,
        }}
      >
        <Avatar
          sx={{ ...style, height: "100%", width: "100%" }}
          alt={alt}
          src={source}
        />
        <div className={classes.container}>
          <div className={classes.box}>
            <input
              type="file"
              accept="image/png, image/jpeg"
              title="Select Profile Image"
              onChange={handleFileSelected}
              className={classes.input}
            />
            <div className={classes.iconBox}>
              {icon && <img src={icon} alt="Select Profile" />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
FileUpload.propTypes = {
  name: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};
