import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslate } from "../../Hooks/useTranslate";
import { useStyles } from "./styles";
import SidebarHeader from "../SidebarHeader";
import GUID from "../../Utils/Guid";
import Button from "../Shared/Button";
import MemberDetail from "../AccountTeam/MemberDetail";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

const OPERATORS = {
  ANY: "ANY",
  ">=": "\u{2265}",
  ">": "\u{003E}",
  "<": "\u{003C}",
  "<=": "\u{2264}",
  "=": "",
  "<>": "\u{2260}",
};

function AlertDetail({ data, onClose,...props }) {
  const t = useTranslate();
  const classes = useStyles();

  const [managerId, setManagerId] = useState();
  const handleClose = () => {
    onClose && onClose();
  };
  useEffect(() => {
    if (!data) handleClose();
  }, [data]);

  const handleContactAlertManager = () => {
    if (data.alert_manager) {
      setManagerId(data.alert_manager);
    }
  };

  const handleCloseAlertManagerDetail = () => {
    setManagerId(null);
  };

  let configs = data?.alert_config;

  if (managerId)
    return (
      <Box display={"flex"} flex="1" flexDirection={"column"}>
        <MemberDetail id={managerId} onClose={handleCloseAlertManagerDetail} data={props.pinPointData}/>
      </Box>
    );
  return (
    <>
      <SidebarHeader title={t("alert_conditions")} onBack={handleClose} />
      <Typography variant="body2" mt="30px">
        {t("alert_condition_desc")}
      </Typography>
      <Box mr="20px" mt="20px">
        <Typography variant="h6" fontWeight={700}>
          {data.alert_title}
        </Typography>
      </Box>
      <div
        style={{
          display: "flex",
          flex: "1 auto",
          flexDirection: "column",
          overflowY: "auto",
          height: 0,
          paddingRight: "0.875rem",
        }}
      >
        {configs?.map((item, index) => {
          let conditions = item?.conditions;
          return (
            <>
              <Box
                className={classes.conditions}
                key={GUID("alert_con")}
                borderBottom="1px solid"
                borderColor="border.light"
              >
                {conditions?.map((condition, cInd) => (
                  <Box key={GUID("alert_condition")}>
                    <Box pt="25px" pb="25px">
                      <Typography variant="h6" fontWeight={"500"}>
                        {condition.key}
                      </Typography>
                      {condition.operator !== OPERATORS.ANY && (
                        <Typography
                          variant="neutral"
                          component="p"
                          color="text.neutral"
                          mt="5px"
                        >
                          {OPERATORS[condition.operator]} {condition.value}
                        </Typography>
                      )}
                    </Box>
                    {conditions.length > 1 && cInd < conditions.length - 1 && (
                      <Box
                        borderBottom="1px solid"
                        borderTop="1px solid"
                        borderColor="border.light"
                        pt="25px"
                        pb="25px"
                      >
                        <Typography
                          variant="h6"
                          className={classes.rounded_label}
                        >
                          {t("or")}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              {configs.length > 1 && index < configs.length - 1 && (
                <Box
                  key={GUID("alert_con")}
                  borderBottom="1px solid"
                  borderColor="border.light"
                  pt="25px"
                  pb="25px"
                >
                  <Typography variant="h6" className={classes.rounded_label}>
                    {t("and")}
                  </Typography>
                </Box>
              )}
            </>
          );
        })}
      </div>
      <Box m={conv(39, 42)}>
        <Button
          color="primary"
          className={classes.buttonApply}
          onClick={handleContactAlertManager}
          disableRipple
          variant="contained"
          children={t("contact_alert_manager")}
          type="submit"
        />
      </Box>
    </>
  );
}

export default React.memo(AlertDetail);
