export const ERROR_CODES = {
  USER_NOT_FOUND: "UserNotFoundException",
  NOT_AUTHORIZED: "NotAuthorizedException",
  PASSWORD_RESET_REQUIRED: "PasswordResetRequiredException",
  ERROR_IN_PASSWORD_RESET:"ERROR_IN_PASSWORD_RESET",
  CODEMISMATCHEXCEPTION:"CodeMismatchException",
  CODE_MISMATCH_EXCEPTION:"CODE_MISMATCH_EXCEPTION",
  VERIFICATION_CODE_EXPIRED:"VERIFICATION_CODE_EXPIRED",
  LIMIT_EXCEEDED_EXCEPTION:"LimitExceededException"
};
