import { BorderColor } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { textTransform } from "@mui/system";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  parentItems: {
    backgroundColor: MUITheme.palette.background.secondary,
    flex: 1,
    borderRadius: conv(20),
    padding: conv(15) + " !important",
    cursor: "pointer",
    margin: conv(10, 0),
    // padding:conv(20),
    flexDirection: "column",
    "& + li > div": {
      borderTop: "none !important",
    },
  },
  addCommentWithBackground: {
    backgroundColor: MUITheme.palette.background.secondary,
  },
  displayName: {
    color: MUITheme.palette.text.secondary + " !important",
    fontSize: "1rem !important",
    marginLeft: conv(10) + " !important",
    fontWeight: 500 + " !important",
    lineHeight: conv(21) + " !important",
  },
  likeButton: {
    width: conv(21),
    height: conv(21),
    minWidth: conv(21) + " !important",
    padding: "0px !important",
  },
  commentText: {
    color: MUITheme.palette.text.body2 + " !important",
    fontSize: conv(14) + " !important",
    marginLeft: conv(45) + " !important",
    marginTop: -conv(10) + " !important",
    display: "block !important",
    "& a": {
      color: MUITheme.palette.text.secondary,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  lineClamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  commentsLight: {
    color: `${MUITheme.palette.text.lighterGrey} !important`,
    textTransform: "uppercase",
    fontWeight: "600 !important",
    fontSize: conv(11) + " !important",
    lineHeight: conv(13) + " !important",
  },
  likeCommentAction: {
    color: `${MUITheme.palette.text.white} !important`,
    fontSize: conv(24) + " !important",
    lineHeight: conv(35) + " !important",
    textTransform: "capitalize",
    marginLeft: conv(10) + " !important",
  },
  likeCommentActionCount: {
    fontWeight: "400 !important",
    color: `${MUITheme.palette.text.default} !important`,
    marginRight: conv(10) + " !important",
  },
  likeCommentDivider: {
    borderBottomWidth: conv(2) + " !important",
    borderColor: `${MUITheme.palette.border.light} !important`,
    margin: conv(14) + " 0 !important",
  },
  selectedBox: {
    backgroundColor: MUITheme.palette.background.container,
  },
  childComment: {
    flex: 1,
    borderRadius: 0,
    paddingLeft: conv(52) + " !important",
    cursor: "pointer",
    margin: "0px 0",
    flexDirection: "column",
    "& > div": {
      paddingTop: conv(10),
      borderTop: `1px solid ${MUITheme.palette.border.lightGrey}`,
    },
  },
  addCommentInput: {
    background: "transparent",
    resize: "none",
    outline: "none",
    border: "none",
    color: MUITheme.palette.text.body2,
    marginLeft: conv(35),
    paddingRight: "0",
    // marginTop: conv(5)+" !important",
    flex: 1,
    "& *": {
      color: MUITheme.palette.text.body2,
      fontSize: conv(14),
      lineHeight: conv(21),
      whiteSpace: "pre-wrap",
      "&:focus-visible": {
        outline: "none",
        border: "none",
      },
    },
    "& textarea": {
      outline: "none",
      border: "none",
      maxHeight: conv(66) + " !important",
      overflowY: "auto !important",
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
      },
    },
    "& ul": {
      backgroundColor: MUITheme.palette.background.secondary,
    },
    "&__highlighter .mentions__mention": {
      color: MUITheme.palette.text.default,
      background: MUITheme.palette.background.mentionBox,
    },
    "&__highlighter": {
      border: conv(2) + " solid transparent !important",
      maxHeight: conv(66) + " !important",
      overflowY: "auto !important",
    },
    "&__suggestions__item--focused": {
      background: MUITheme.palette.text.lighterGrey,
    },
    "&__suggestions__item": {
      padding: conv(5),
    },
    "&__suggestions": {
      width: conv(242),
      background: `${MUITheme.palette.background.mentionBox} !important`,
      borderRadius: conv(15),
      height: conv(218),
      overflow: "auto",
      fontSize: conv(14),
      fontWeight: 500,
      color: `${MUITheme.palette.text.white} !important`,
      padding: conv(15, 0, 5, 0),
      border: `1px solid ${MUITheme.palette.border.primary}`,
      "& b": {
        fontWeight: 500,
      },
      "& h2": {
        fontWeight: "400",
        lineHeight: conv(21),
        color: MUITheme.palette.text.lightblue,
        marginBlockStart: 0,
        marginBlockEnd: 0,
        fontSize: conv(14),
        paddingLeft: conv(15),
        paddingBottom: conv(15),
      },
      "& li": {
        padding: conv(10, 15),
        cursor: "pointer",
        "& div div:last-child": {
          marginLeft: "0px !important",
          fontWeight: 500,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: conv(237),
          fontSize: conv(14),
          paddingLeft: conv(10) + " !important",
        },
        "&.focused": {
          background: MUITheme.palette.background.suggestionHover,
        },
      },
    },
  },
  addComment: {
    minHeight: conv(48),
    borderRadius: conv(20),
    border: `1px solid ${MUITheme.palette.background.secondary}`,
    background: MUITheme.palette.background.mainContent,
    padding: conv(20),
  },
  likesCount: {
    fontSize: conv(14) + " !important",
    padding: conv(0, 20, 0, 5),
  },
  commentsCount: {
    padding: conv(0, 0, 0, 5),
  },
  negativeMargin: {
    margin: -conv(10),
    width: "calc(100% + 15px)",
  },
  commentTime: {
    color: `${MUITheme.palette.text.lighterGrey} !important`,
    textTransform: "uppercase",
    fontWeight: "600 !important",
    fontSize: conv(11) + " !important",
    lineHeight: conv(13) + " !important",
    marginTop: conv(-10) + " !important",
  },
  sendButton: {
    
    "& svg path": {
      stroke: `${MUITheme.palette.text.white}`,
    },
    "&.Mui-disabled svg path": {
      stroke: `${MUITheme.palette.text.navButtonDisabled} !important`,
    },
  },
}));
