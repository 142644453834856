import React from "react";
const FavAccount = ({color}) => {
  let stroke = "#F6F7F9";
  if(color) stroke=color;
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.52007 10.0391V17.5228"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0284 6.45801V17.5228"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4463 13.9932V17.5223"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5141 1.0918H7.45278C3.59704 1.0918 1.18018 3.61407 1.18018 7.1847V16.8171C1.18018 20.3877 3.5858 22.91 7.45278 22.91H18.5141C22.3811 22.91 24.7867 20.3877 24.7867 16.8171V7.1847C24.7867 3.61407 22.3811 1.0918 18.5141 1.0918Z"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default FavAccount;
