import * as React from "react";
import { Box, Typography, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import DeleteIcon from "../../../Assets/images/alerts/delete.png";
import Button from "../../../Components/Shared/Button";
import { useTranslate } from "../../../Hooks/useTranslate";
import clsx from "clsx";

const DeleteAlert = (props) => {
  const classes = useStyles();
  let {
    open,
    icon,
    title,
    description,
    successText,
    cancelText,
    onCancel,
    onSuccess,
  } = props;
  const t = useTranslate();
  if (!open) return "";
  if (!title) title = t("no_data_title");
  if (!description) description = t("no_data_description");
  return (
    <Box className={classes.container}>
      <Box className={classes.box}>
        <Box className={classes.iconBox}>
          {icon ? icon : <img src={DeleteIcon} alt="delete-icon" />}
        </Box>
        <Typography variant="h4" mt="1rem">
          {title}
        </Typography>
        <Typography variant="neutral" mt="0.625rem">
          {description}
        </Typography>
        <Grid container flex={1} mt="1.875rem" spacing={0}>
          <Grid item sm={6} md={6}>
            <Button
              className={classes.transparentBtn}
              disableRipple
              variant="contained"
              children={successText}
              onClick={onSuccess}
            />
          </Grid>

          <Grid item sm={6} md={6}>
            <Button
              className={clsx(
                classes.transparentBtn,
                classes.transparentCancelBtn
              )}
              disableRipple
              variant="contained"
              children={cancelText}
              onClick={onCancel}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

DeleteAlert.propTypes = {
  open: PropTypes.bool,
  icon: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  successText: PropTypes.string,
  cancelText: PropTypes.string,
};

DeleteAlert.defaultProps = {
  open: false,
  icon: null,
  title: "Alert Title",
  description: "",
  successText: "Remove",
  cancelText: "Cancel",
  onSuccess: () => {},
  onCancel: () => {},
};
export default React.memo(DeleteAlert);
