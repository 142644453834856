import { accountActions } from "../actionCreators";

const initialState = {
  data: null,
  jtpData: null,
  team:null,
  actions: null,
  alert_action_config:{
    "action_status": false,
    "alert_status": false},
  operationalDrivers:null,
  analyticsStatus: null,
  analyticsReactions:null,
  selected_jtp:null,
  selected_op_driver:null,
  selected_kpi:null,
  kpi_metrics:null,
  timestamp:null,
  loading_jtp:false,
  alerts:null,
  user_profile:null,
  link_data:null,
  loading_kpi:false,
  loaded:false
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
  case accountActions.SET_ACCOUNT_DATA: {
    return {
      ...state,
      data: action.payload,
    };
  }  
  case accountActions.SET_ACCOUNT_JTP: {
    return { ...state, jtpData: action.payload };
  } 
  case accountActions.SET_ACCOUNT_MEMBERS: {
    return { ...state, team: action.payload };
  } 
  case accountActions.SET_ACCOUNT_MEMBERS_LIST: {
    let new_members;
    if(action.payload)
    {
      if (state.team_list?.items)
        new_members = {
          items: [...state.team_list.items, ...action.payload.items],
          meta: action.payload.meta,
        };
      else 
        new_members = {
          items: action.payload.items,
          meta: action.payload.meta,
        };
    }
    return { ...state, team_list: new_members };
  } 
  case accountActions.SET_ACCOUNT_ACTIONS:{
    return { ...state, actions: action.payload };
  }
  case accountActions.SET_ALERT_CONFIGURED:{
    return { ...state, alert_action_config: action.payload };
  }
  case accountActions.SET_ACCOUNT_OPERATIONAL_DRIVERS: {
    return { ...state, operationalDrivers: action.payload };
  }
  case accountActions.SELECT_ACCOUNT_KPI_METRIC: {
    return { ...state, selected_kpi: action.payload };
  }
  case accountActions.SET_ACCOUNT_ANALYTICS_REACTIONS: {
    return { ...state, analyticsReactions: action.payload };
  }
  case accountActions.SET_ACCOUNT_ANALYTICS_STATUS: {
    return { ...state, analyticsStatus: action.payload };
  }
  case accountActions.SET_ACCOUNT_FOLLOW: {
    return { ...state, analyticsStatus: {...state.analyticsStatus, follow_status:action.payload} };
  }
  case accountActions.SET_ACCOUNT_FAVORITE: {
    return { ...state, analyticsStatus: {...state.analyticsStatus, favorite_status:action.payload} };
  }
  case accountActions.SELECT_ACCOUNT_JTP: {
    return { ...state, selected_jtp: action.payload,selected_op_driver:null,kpi_metrics:null};
  }

  case accountActions.SELECT_ACCOUNT_OPERATIONAL_DRIVER: {
    return { ...state, selected_op_driver: action.payload};
  }
  case accountActions.SET_ACCOUNT_KPI_DATA: {
    return { ...state, kpi_metrics: action.payload };
  }
  case accountActions.SET_ACCOUNT_KPI_LOADING: {
    return { ...state, loading_kpi: action.payload };
  }
  case accountActions.SET_ACCOUNT_TIMESTAMP: {
    return { ...state, timestamp: action.payload };
  }
  case accountActions.SET_ACCOUNT_JTP_LOADING: {
    return { ...state, loading_jtp: action.payload };
  }
  case accountActions.CLEAR_ACCOUNT_DATA: {
    return { ...state,
      jtpData: [],
      operationalDrivers:null,
      selected_jtp:null,
      selected_op_driver:null,
      kpi_metrics:null 
    };
  }
  case accountActions.CLEAR_ACCOUNT_ALL_DATA: {
    return {...initialState,link_data:state.link_data};
  }

  case accountActions.SET_ACCOUNT_ALERTS: {
    return { ...state, alerts: action.payload };
  }
  case accountActions.SET_USER_PROFILE: {
    return { ...state, user_profile: action.payload };
  }
  case accountActions.SET_DEEPLINK_DATA: {
    return { ...state, link_data: action.payload };
  }
  case accountActions.SET_ACCOUNT_ANALYTICS_LOADED:{
    return {...state,loaded:action.payload}
  }
  default:
    return state;
  }
};

export default accountReducer;
