import React, { useEffect, useRef } from "react";
import { Typography, Box } from "@mui/material";
import clsx from "clsx";
import Animator from "../../Animator";
import { useStyles } from "./styles";

const CustomizedProgressBar = (props) => {
  let {
    id,
    label,
    value,
    color,
    canClick,
    clickable,
    onClick,
    trackColor,
    lg,
    selected,
  } = props;
  const divRef = useRef();

  useEffect(() => {
    if ( selected && divRef?.current) {
      let div = divRef.current;
      let parent = div?.parentElement?.parentElement;
      if (div?.parentElement.offsetWidth < div?.offsetLeft + div?.offsetWidth)
        parent?.scrollTo(div?.offsetLeft, 0);
    }
  }, [selected, divRef?.current]);

  const classes = useStyles();
  const handleClick = () => {
    if (value >= 0 && canClick && onClick) onClick(id);
  };
  return (
    <div className={classes.root} ref={divRef} style={{width:(lg?"70px":"60px")}}>
      <div className={classes.stats}>
        <Box
          className={clsx([classes.track, lg && classes.large])}
          bgcolor={trackColor}
          onClick={handleClick}
        >
          <div
            className={clsx([classes.animatedBar, classes.bar])}
            style={
              canClick
                ? { maxHeight: `${value}%`, background: color }
                : { maxHeight: `${value}%` }
            }
          ></div>
        </Box>
        {label && (
          <Animator animation="slideUp">
            <Typography
              color={clickable ? "text.secondary" : "text.neutral"}
              variant="p"
              marginTop="10px"
              className={classes.neutralLabel}
              // maxWidth={lg ? "70px" : "55px"}
            >
              {label}
            </Typography>
          </Animator>
        )}
      </div>
    </div>
  );
};

export default CustomizedProgressBar;
