import { useSelector } from "react-redux";
import { FILTER_TYPE } from "../../Utils/Constants";

export default function FilterCount() {
  const selected_filters = useSelector(
    (state) => state.portfolio.applied_filters
  );
  let total = 0;
  if (selected_filters) {
    Object.keys(selected_filters).forEach((element) => {
      if (selected_filters[element].type === FILTER_TYPE.CHECKBOX||selected_filters[element].type === FILTER_TYPE.CHECKBOX_PROFILE) {
        total = total + Object.keys(selected_filters[element].values).length;
      } else if (selected_filters[element].type === FILTER_TYPE.RANGE) {
        total = total + 1;
      }
    });
  }
  
  return total;
}
