import React from "react";
import { Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import UIHelper from "../../Utils/UIHelper";
const conv=UIHelper.convertPxToRem;
function AlertSettingItem({ data, title, onClick }) {
  const handleClick = () => {
    onClick && onClick(data);
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      pt={conv(21)}
      pb={conv(22)}
      borderBottom="1px solid"
      borderColor="border.light"
      sx={{ cursor: "pointer" }}
      onClick={handleClick}
    >
      <Typography variant="h6">{title}</Typography>

      <Typography variant="h6" mt="3px">
        <ArrowForwardIosIcon />
      </Typography>
    </Box>
  );
}

export default AlertSettingItem;
