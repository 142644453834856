import React from "react";
const NoAlerts = ({fill="#171F26"}) => {
  return (
    <svg
      width="58"
      height="56"
      viewBox="0 0 58 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3607 19.8958C40.2727 21.4097 40.5529 22.9639 41.3562 24.25C49.5415 37.3547 37.8747 39.3125 26.0612 39.3125C13.314 39.3125 1.36146 37.2694 9.64932 24.1614C10.4289 22.9284 10.7338 21.4435 10.6594 19.9866C10.2661 12.2811 15.5082 4.25 25.4795 4.25C35.3734 4.25 40.7927 12.4612 40.3607 19.8958Z"
        stroke="#4B545B"
        strokeWidth="2"
      />
      <path
        d="M20.7188 44.625C20.7188 44.625 22.5453 46.75 25.4575 46.75C28.3697 46.75 30.2813 44.625 30.2813 44.625"
        stroke="#4B545B"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="41" cy="39" r="13" fill={fill} />
      <path
        d="M27.2375 39C27.2375 31.4116 33.4116 25.2375 41 25.2375C48.5884 25.2375 54.7625 31.4116 54.7625 39C54.7625 46.5884 48.5884 52.7625 41 52.7625C33.4116 52.7625 27.2375 46.5884 27.2375 39ZM52.7375 39C52.7375 32.5061 47.4939 27.2625 41 27.2625C34.5061 27.2625 29.2625 32.5061 29.2625 39C29.2625 45.4939 34.5061 50.7375 41 50.7375C47.4939 50.7375 52.7375 45.4939 52.7375 39ZM42.0125 34.7H39.9875V32.675H42.0125V34.7ZM42.0125 45.325H39.9875V36.925H42.0125V45.325Z"
        fill="#4B545B"
        stroke="#171F26"
        strokeWidth="0.1"
      />
    </svg>
  );
};
export default NoAlerts;
