import { appActions } from "../actionCreators";

const initialState = {
  theme: "dark",
  lang: "en",
  active_menu: "feeds",
  cloud_cookie: null,
  mapping_data: {
    field_mapping: {
      external_account_id: {
        is_enabled: false,
      },
      customer_lifecycle: {
        is_enabled: false,
      },
      product_line: {
        is_enabled: false,
      },
      account_team: {
        is_enabled: false,
      },
      fin_value: {
        is_enabled: false,
      },
      customer_market_factor: {
        is_enabled: false,
      },
      training: {
        is_enabled: false,
      },
      action: {
        is_enabled: false,
      },
    },
  },
  left_menu_open: true,
  right_menu_open: false,
  user_profile_loaded:false
};

function parseFieldMappingData(data) {
  let result = {};
  if (!data) return result;
  if (data.field_mapping) {
    let fieldmapping = {};
    data.field_mapping.forEach(field => {
      fieldmapping[field.field_key] = {
        is_enabled: field.is_enabled,
        display_value: field.display_value,
      };
    });
    result["field_mapping"] = fieldmapping;
  }
  if (data.nps_color) {
    let nps_color = {};
    data.nps_color.forEach(field => {
      nps_color[field.field_name.toLowerCase()] = field.color_code;
    });
    result["nps_color"] = nps_color;
  }
  if (data.nrr_state_color) {
    let nrr_state_color = {};
    data.nrr_state_color.forEach(field => {
      nrr_state_color[field.field_name.toLowerCase()] = field.color_code;
    });
    result["nrr_state_color"] = nrr_state_color;
  }
  if (data.title) {
    let title = {};
    data.title.forEach(field => {
      title[field.field_key] = field.display_value;
    });
    result["title"] = title;
  }
  if (data.nps_class_change_color) {
    let nps_class_change_color = {};
    data.nps_class_change_color.forEach(field => {
      nps_class_change_color[field.field_name] = field.color_code;
    });
    result["nps_class_change_color"] = nps_class_change_color;
  }
  if (data.nps_score_change) {
    let nps_score_change = {};
    data.nps_score_change.forEach(field => {
      nps_score_change[field.field_name] = field.color_code;
    });
    result["nps_score_change"] = nps_score_change;
  }
  return result;
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
  case appActions.SET_APP_THEME: {
    return { ...state, theme: action.payload || initialState.theme };
  }

  case appActions.SET_APP_LANG: {
    return { ...state, lang: action.payload || initialState.lang };
  }

  case appActions.SET_APP_CLOUD_COOKIE: {
    return { ...state, cloud_cookie: action.payload };
  }

  case appActions.SET_APP_FIELD_MAPPING: {
    return { ...state, mapping_data:  parseFieldMappingData(action.payload) };
  }

  case appActions.SET_APP_MAIN_MENU: {
    return { ...state, active_menu: action.payload };
  }

  case appActions.SET_APP_FOOTER_MENU: {
    return { ...state, footer_menu: action.payload };
  }
  case appActions.SET_USER_PROFILE_LOADED: {
    return { ...state, user_profile_loaded: action.payload };
  }

  case appActions.SET_APP_LEFT_MENU_OPEN: {
    return {
      ...state,
      left_menu_open: action.payload,
      // right_menu_open: false,
    };
  }

  case appActions.SET_APP_RIGHT_MENU_OPEN: {
    return {
      ...state,
      right_menu_open: action.payload,
      // left_menu_open: false,
    };
  }

  case appActions.CLEAR_APP_DATA:{
    return initialState;
  }

  default:
    return state;
  }
};

export default appReducer;
