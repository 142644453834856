import Animation from "./Animation";
import ANIMATION_TYPES from "./AnimationTypes";
const ScoreCardAnimation = ({trigger,...props}) => {
  const config2 = {
    from: { opacity: 0.5,y:-1000 },
    enter: { opacity: 1,y:0 },
    leave: { opacity: 0.5,y:-1000},
    config:{tension:280,friction:80}
  }
  return (
    <Animation type={ANIMATION_TYPES.TRANSITION} config={config2} trigger={trigger} {...props}/>
  );
};
export default ScoreCardAnimation;