import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((MUITheme) => ({
  root: {
    backgroundColor: MUITheme.palette.background.container,
    flex: 1,
  },
  container: {
    backgroundColor: MUITheme.palette.background.container,
    borderLeft: "1px solid " + MUITheme.palette.border.transparent,
    marginRight: "5px",
    width: "100%",
    display: "flex",
    height: "calc(100vh - 60px)",
  },
  leftSideBarButtonChecked: {
    backgroundColor: MUITheme.palette.background.skyblue,
  },
  rightMenuBar: {
    backgroundColor: "transparent",
  },
  rightMenuPanel: {
    backgroundColor: MUITheme.palette.background.rightPanelBg+" !important",
    transition: "width ease-in-out .2s",
    overflowX:"auto"
  },
  customBadge: {
    backgroundColor: MUITheme.palette.background.red,
    color: MUITheme.palette.text.white,
  },
}));
