import React from "react";
import { useSelector } from "react-redux";
import Tag from "../Tag";
import { useTheme } from "@mui/styles";

const CustomerLifecycle = ({ data, selected,  }) => {

  const theme=useTheme();
  const is_enabled = useSelector(
    (state) => state.app.mapping_data.field_mapping.customer_lifecycle.is_enabled
  );
  if (!is_enabled) return;
  return (
    <>
      <Tag
        data={data?.map((item) => ({
          text: item,
          color: theme.palette.text.default,
          selected: item === selected,
        }))}
      />
    </>
  );
};

export default CustomerLifecycle;
