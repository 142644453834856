import { makeStyles } from "@mui/styles";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  root: {
    "& .MuiAccordion-root": {
      backgroundColor: "transparent",
      borderBottom: `1px solid ${MUITheme.palette.border.primary}`,
      paddingLeft: 0,
      boxShadow: "none",
      "& .MuiAccordionSummary-root": {
        padding: conv(30, 0),
        // paddingTop:conv(30),
        // paddingBottom:conv(30),
      },
      "& .MuiAccordionDetails-root ": {
        padding: 0,
        // paddingBottom:"10px"
      },
    },
    "& .MuiAccordion-root:last-of-type": {
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px  !important",
    },
    "& .MuiAccordionSummary-expandIconWrapper svg": {
      color: MUITheme.palette.text.white,
      fontSize: "2rem",
    },
    "& .MuiAccordionDetails-root p": {
      color: MUITheme.palette.text.secondary,
      fontSize: conv(14),
      fontWeight: 400,
      lineHeight: conv(21),
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
      padding: "0px",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0 + " !important",
    },
    "& .MuiAccordionSummary-content p": {
      fontSize: conv(22),
      lineHeight: conv(33),
      fontWeight: 600,
      color: MUITheme.palette.text.white,
    },
    "& .MuiAccordionDetails-root": {
      // backgroundColor: MUITheme.palette.background.container,
    },
  },
}));
