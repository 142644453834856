import { alertActions } from "../actionCreators";

const initialState = {
  alerts: {},
  users: {},
  selected: null,
  show_delete_modal: false,
  unread_alerts: null,
  selected_tab: "all",
};

function formatUsers(source, users) {
  let data = { ...source };
  if (users && users.length) {
    users.forEach((element) => {
      if (!data[element.user_id]) data[element.user_id] = element.display_name;
    });
  }
  return data;
}

function markReadUnread(alert_id,read,source){
  let alerts={...source};
  Object.keys(source).forEach(item=>{
    let list=source[item].list;
    let ind = list.findIndex((item) => item.id === alert_id);
    if (ind > -1) {
      list[ind].is_read = read; 
      alerts[item]={...alerts[item],list:list};    
    }
   
  })  
  return alerts;
}
function removeFromList(alert_id,source){
  let alerts={...source};
  Object.keys(source).forEach(item=>{
    let list=[...source[item].list];
    let ind = list.findIndex((item) => item.id === alert_id);
    if (ind > -1) {
      list.splice(ind, 1);
      alerts[item]={...alerts[item],list:list};    
    }
   
  })  
  return alerts;
}

function removeReadUnreadItem(alert_id,filter,source){
  let alerts={...source};
  let list=[...source[filter].list];
  let ind = list.findIndex((item) => item.id === alert_id);
  if(ind>-1)
  {
    let alert=list[ind];
    list.splice(ind, 1);
  
    if(filter==="read")
    {
      let unread_list=source["unread"]?.list;
      if(unread_list)
      { 
        alert.is_read=false;
        alerts["unread"]= {...alerts["unread"],list:[alert].concat(unread_list)};
      }
      alerts["read"]={...alerts["read"],list:list}; 
    }
    else if(filter==="unread")
    {
      let read_list=source["read"]?.list;
      if(read_list)
      {
        alert.is_read=true;
        alerts["read"]= {...alerts["read"],list:[alert].concat(read_list)};
      }
      alerts["unread"]={...alerts["unread"],list:list}; 
    }
  }
  return alerts;
}

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
  case alertActions.SET_ALERTS: {
    return {
      ...state,
      alerts: {
        ...state.alerts,
        [action.payload.filter]: {
          list: action.payload.data.items,
          meta: action.payload.data.meta,
        },
      },
      users: formatUsers(state.users, action.payload.data.user_details),
    };
  }
  case alertActions.APPEND_ALERTS: {
    return {
      ...state,
      alerts: {
        ...state.alerts,
        [action.payload.filter]: {
          list: [
            ...state.alerts[action.payload.filter].list,
            ...action.payload.data.items,
          ],
          meta: action.payload.data.meta,
        },
      },
      users: formatUsers(state.users, action.payload.data.user_details),
    };
  }

  case alertActions.MARK_ALERT_AS_READ: {
    return {...state,alerts:markReadUnread(action.payload.alert_id,action.payload.read,state.alerts)}
  }
  case alertActions.REMOVE_FROM_LIST: {
    return { ...state,  alerts:removeReadUnreadItem(action.payload.alert_id,action.payload.filter,state.alerts)  };
  }
  case alertActions.DELETE_ALERT: {
    return {
      ...state,
      alerts: removeFromList(action.payload.alert_id,state.alerts),
      selected: null,
      show_delete_modal: false,
    };
  }

  case alertActions.CLEAR_ALERTS:
    return { ...state, alerts: {}, users: {} };

  case alertActions.SHOW_DELETE_DIALOG:
    return { ...state, selected: action.payload, show_delete_modal: true };
  case alertActions.CLOSE_DELETE_DIALOG:
    return { ...state, show_delete_modal: false };
  case alertActions.SET_ALERTS_COUNT:
    return { ...state, unread_alerts: action.payload };
  case alertActions.SET_SELECTED_TAB:
    return { ...state, selected_tab: action.payload };
  case alertActions.SELECT_ALERT:
    return { ...state, selected: action.payload };
  case alertActions.CLEAR_ALERT_DATA: {
    return initialState;
    // return { ...initialState, unread_alerts: state.unread_alerts };
  }
  default:
    return state;
  }
};

export default alertReducer;
