import React from "react";

import AppBar from "../Components/AppBar";

const withAppBar = Component => {
  return props => (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <AppBar />
      <Component {...props} />
    </div>
  );
};

export default withAppBar;
