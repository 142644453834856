import React from "react";
import AccountList from ".";
import { useTranslate } from "../../Hooks/useTranslate";
import { useSelector } from "react-redux";

const CustomListMain = (props) => {
  const t = useTranslate();
  const fieldMapping = useSelector((state) => state.app.mapping_data.field_mapping);
  // const fieldMapping={fin_value:{is_enabled:false}}
  const headers = [
    {
      field: "name",
      is_enabled: true,
      display_value: t(props.type),
      sort_direction: "ASC",
      size: fieldMapping.fin_value.is_enabled ? 5 : 7,
    },
    {
      ...fieldMapping.fin_value,
      display_value:fieldMapping.fin_value.display_value||t("acv"),
      field: "fin_value",
      sort_direction: "",
      size: 3,
    },
    {
      field: "nps_score",
      is_enabled: true,
      display_value: t("str_nps"),
      sort_direction: "",
      size: fieldMapping.fin_value.is_enabled?3:4,
    },
    {
      field: "nps_score_change",
      is_enabled: true,
      display_value: t("header_chg"),
      sort_direction: "",
      size: 1,
    },
  ];
  return <AccountList {...props} headers={headers} />;
};

export default CustomListMain;
