import { makeStyles } from "@mui/styles";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

export const useStyles = makeStyles(MUITheme => ({


  npsErrorLabel: {
    background: MUITheme.palette.background.primaryRed,
    color: "#F45252",
    fontSize:"1rem",
    lineHeight:conv(22),
    display:"inline-block",
    padding: conv(3,8),
    borderRadius: conv(5),
    marginLeft:conv(10)+ " !important",
  }, 
  npsScore:{
    color: MUITheme.palette.text.secondary,
    fontWeight:800 +"!important",
  },
  autoHeight:{
    height: "calc(100vh - 205px)",
  },
  graphTooltip:{
    position: "absolute",
    // height: "15px",
    top: "-30px",
    minWidth: conv(80),
    padding: conv(6,8),
    lineHeight: 1,
    textAlign: "center",
    borderRadius: conv(6),
    color: MUITheme.palette.text.lightblue,
    fontSize:"1rem",
    fontWeight:500,
    backgroundColor: MUITheme.palette.background.lightgrey
  }
}));
