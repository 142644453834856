import * as React from "react";
import { Stack, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import NoDataView from "../NoDataView";
import clsx from "clsx";
import { useStyles } from "./styles";
import { useTheme } from "@mui/styles";
import {
  animated,
  useSprings,
  useSpringRef,
  useTransition,
  useChain,
} from "react-spring";

const ODBars = (props) => {
  const { title, jtp, value, data, trackColor, onClick, onAnimationEnd } =
    props;
  const [open, setOpen] = React.useState(false);
  const theme=useTheme();

  const handleClick = (id) => {
    onClick && onClick(id);
  };
  const classes = useStyles();

  // React.useEffect(() => {
  //   if (!value && data && data.length > 0) {
  //     let id = data[0].id;
  //     handleClick(id, true);
  //   }
  // }, [data]);

  React.useEffect(() => {
    setOpen(!open);
  }, [jtp]);

  const springRef = useSpringRef();
  const delayValue = 400;
  const transition = useTransition(open, {
    ref: springRef,
    from: { transform: "translate3d(0,1000px,0)" },
    enter: { transform: "translate3d(0,0px,0)" },
    leave: {
      transform: "translate3d(0,1000px,0)",
      onRest: () => {
        onAnimationEnd && onAnimationEnd();
      },
    },
    config: { tension: 280, friction: 60 },
  });

  const springsRef = useSpringRef();

  const springs = useSprings(
    data && data.length,
    data &&
      data.length &&
      data.map((item, i) => ({
        ref: springsRef,
        item,
        delay: open
          ? (i + 1) * delayValue
          : data.length * delayValue - i * delayValue,
        opacity: open ? 1 : 0,
        width: open
          ? (item.impact < 0 ? 0 : (item.impact + 1) * 33.3) + "%"
          : "0%",
        from: {
          opacity: 0,
        },
      }))
  );

  useChain(open ? [springRef, springsRef] : [springsRef, springRef]);

  if (jtp && (!data || data.length === 0)) {
    return (
      <>
        <Typography
          variant="h5"
          color="text.subheading"
          className={classes.opDriverTitle}
        >
          {title}
        </Typography>
        <NoDataView show_icon={false} />
      </>
    );
  }
  return (
    <>
      {" "}
      {transition(
        (style, item) =>
          item && (
            <animated.div style={style}>
              {jtp && (
                <Typography
                  variant="h5"
                  color="text.subheading"
                  className={classes.opDriverTitle}
                >
                  {title}
                </Typography>
              )}
              {springs?.map(({ x, ...props }, i) => {
                const item = data[i];
                const color =
                  item.impact < 0 ? theme.palette.text.neutral : item.color_code;
                return (
                  <div
                    key={i}
                    className={clsx(
                      classes.root,
                      value === item.id && classes.selected
                    )}
                    onClick={() => handleClick(item.id)}
                  >
                    {item.display_name && (
                      <Typography
                        variant="h6"
                        pl="0px"
                        className={classes.neutralLabel}
                        style={{ color: color }}
                      >
                        {item.display_name}
                      </Typography>
                    )}
                    <div>
                      <Box className={classes.track} bgcolor={trackColor}>
                        <animated.div
                          style={{
                            ...props,
                          }}
                        >
                          <div
                            className={classes.bar}
                            style={{ background: color }}
                          ></div>
                        </animated.div>
                      </Box>
                    </div>
                  </div>
                );
              })}
            </animated.div>
          )
      )}
    </>
  );
};

ODBars.propTypes = {
  data: PropTypes.array,
  trackColor: PropTypes.string,
};

ODBars.defaultProps = {
  data: [
    {
      id: 14,
      display_name: "Effectiveness",
      impact: 0,
      color_flag: "GREEN",
      color_code: "#71C782",
    },
    {
      id: 11,
      display_name: "Effort",
      impact: 1,
      color_flag: "RED",
      color_code: "#F45252",
    },
    {
      id: 16,
      display_name: "Performance & Stability",
      impact: 0,
      color_flag: "GREEN",
      color_code: "#71C782",
    },
    {
      id: 17,
      display_name: "Resolution Time",
      impact: 0,
      color_flag: "GREEN",
      color_code: "#71C782",
    },
    {
      id: 24,
      display_name: "Response Time",
      impact: 0,
      color_flag: "GREEN",
      color_code: "#71C782",
    },
    {
      id: 12,
      display_name: "Support Team",
      impact: 1,
      color_flag: "RED",
      color_code: "#F45252",
    },
  ],
  trackColor: "background.mainContent",
};
export default ODBars;
