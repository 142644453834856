import React from "react";
const Delete = ({ color}) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3248 8.46875C17.3248 8.46875 16.7818 15.2037 16.4668 18.0407C16.3168 19.3957 15.4798 20.1898 14.1088 20.2148C11.4998 20.2618 8.88779 20.2648 6.27979 20.2098C4.96079 20.1828 4.13779 19.3788 3.99079 18.0478C3.67379 15.1858 3.13379 8.46875 3.13379 8.46875"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.708 5.23828H1.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4406 5.239C14.6556 5.239 13.9796 4.684 13.8256 3.915L13.5826 2.699C13.4326 2.138 12.9246 1.75 12.3456 1.75H8.11258C7.53358 1.75 7.02558 2.138 6.87558 2.699L6.63258 3.915C6.47858 4.684 5.80258 5.239 5.01758 5.239"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Delete;
