import React from "react";
import { CheckedColor, UncheckedColor } from ".";

const Next = ({color}) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0004 1L1.40039 9.33156L11.0004 17.6631"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Next;
