import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import clsx from "clsx";
import { useStyles } from "./styles";
import GUID from "../../Utils/Guid";
import { useTheme } from "@mui/styles";
import { SORT_ORDER } from "../../Utils/Constants";

export default function CustomListHeader(props) {
  const theme = useTheme();
  const classes = useStyles();
  const columns = props.headers.filter((col) => col.is_enabled);
  const { sort_order, sort_by } = props.sortOptions;
  const arrowIcons = {
    ASC: <ArrowDropUpIcon style={{ color: theme.palette.sortIcon.primary }} />,
    DESC: (
      <ArrowDropDownIcon style={{ color: theme.palette.sortIcon.primary }} />
    ),
  };

  const onSort = (field, sort_direction) => {
    props.onSortData(field, sort_direction);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container className={classes.tableHeaderBorder}>
        {columns.map((col, index) => {
          let is_current = sort_by == col.field;
          return (
            <Grid key={GUID()} item xs={col.size}>
              <Box
                className={clsx(classes.tableHeader)}
                justifyContent={
                  index === columns.length - 1 ? "center" : "flext-start"
                }
                onClick={() =>
                  onSort(
                    col.field,
                    is_current ?
                      (sort_order === SORT_ORDER.ASCENDING
                        ? SORT_ORDER.DESCENDING
                        : SORT_ORDER.ASCENDING):SORT_ORDER.ASCENDING
                  )
                }
              >
                {col.display_value}
                {is_current && arrowIcons[sort_order]}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
