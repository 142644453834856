import React from "react";
import PropTypes from "prop-types";
const animations = {
  slideRight: "slideInRight",
  slideLeft: "slideInLeft",
  slideUp: "slideInUp",
  slideDown: "slideInDown",
  bounceInUp: "bounceInUp",
  fadeOutUp:"fadeOutUp",
  fadeInUp:"fadeInUp"
};
const Animator = (props) => {
  return (
    <div
      className={`animate__animated animate__${animations[props.animation]} ${props.animation==="bounceInUp"&&"animate__slow"} ${props.speed} `}
    >
      {props.children}
    </div>
  );
};

export default Animator;
