import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((MUITheme) => ({

 
 
  close:{
    color:MUITheme.palette.text.secondary+" !important"
  },
 
}));
