import React from "react";
import { useSelector } from "react-redux";

const AmountLabel = ( props ) => {
  const is_enabled = useSelector(
    state => state.app.mapping_data.field_mapping.fin_value.is_enabled
  );
  return is_enabled && props.children;
};

export default AmountLabel;
