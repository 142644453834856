import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { useStyles } from "./styles";
import { CloseOutlined } from "@mui/icons-material";

function CloseButton({ onClick }) {
  const classes = useStyles();
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <IconButton onClick={handleClick} className={classes.close}>
      <CloseOutlined />
    </IconButton>
  );
}

export default CloseButton;
