/* eslint-disable */
import React, { useRef, useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Divider,
  List,
  ListItem,
  TextareaAutosize,
  Avatar,
} from "@mui/material";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import IconButton from "@mui/material/IconButton";
import ClearInput from "../../Assets/images/menu-icons/clearInput";
import Send from "../../Assets/images/menu-icons/send";
import { getImageUrl } from "../../Utils/ImageHelper";
import MentionTextArea from "./Mentions/MentionTextArea";
import { fetchAuthenticatedUser } from "../../Services/Redux/actionCreators/userActions/loginActions";
import { commentActions } from "../../Services/Redux/actionCreators";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
import { useTheme } from "@mui/styles";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  FEEDTYPES,
  EVENT_TYPE,
  ACTION_EVENT_TYPE,
  ACTION_EVENT_SUBTYPE,
} from "../../Utils/Constants";

const AddComment = (props) => {
  const user = useSelector((state) => state.user.user);
  const imageCookie = useSelector((state) => state.app).cloud_cookie;
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [value, setValue] = useState();
  const [showComment, setShowComment] = useState(true);
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const [enterprise_id, setEnterpriseId] = useState("");
  const theme = useTheme();

  let accountName = useSelector((state) => state.account.data?.name);
  let accPortfolioName= useSelector((state) => state.account.data?.portfolio_name)
  let accountSFDC= useSelector((state) => state.account.data?.external_account_id)

  let portfolioName= useSelector((state) => state.portfolio.data?.name)

  useEffect(() => {
    // This is similar to componentDidMount
    // Call back-end api here
    async function fetchEnterpriseId() {
      const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
      setToken(token);
      setEnterpriseId(enterprise_id);
    }
    fetchEnterpriseId();
  }, []);

  useEffect(() => {
    setValue(props.from ? `{${props.from}} ` : "");
  }, [ props.from]);

  const handleValueChange = (value) => {
    setValue(value);
  };

  const handleCloseClick = () => {
    setValue(props.from ? `{${props.from}} ` : "");
    setComment("");
    clearComment();
  };
  const postComment = async () => {
    const body = {
      source_type: selectedFeed.feed_type,
      source_id: parseInt(selectedFeed.source_id),
      portfolio_id: parseInt(
        selectedFeed.portfolio_id || selectedFeed.source_id
      ),
      message: {
        text: comment,
        mentioned_users: mentionedUsers.map((u) => u.id),
      },
      parent_id: props.parentId,
    };
    setComment("");
    setValue("");
    const commentResponse = await dispatch(commentActions.addComment(body));
    if (selectedFeed.feed_type == FEEDTYPES.ACCOUNT) {
      let arr=[ACTION_EVENT_TYPE.ACCOUNT,ACTION_EVENT_SUBTYPE.COMMENT]
     if(props.parentId)
     {
      arr.push(ACTION_EVENT_SUBTYPE.REPLY)
     }
    Pinpoint.sendEventData(EVENT_TYPE.ACTION,arr,[accountName],[accPortfolioName,accountSFDC])
    }
    else if(selectedFeed.feed_type == FEEDTYPES.PORTFOLIO)
    {
      let arr=[ACTION_EVENT_TYPE.PORTFOLIO,ACTION_EVENT_SUBTYPE.COMMENT]
      if(props.parentId)
      {
       arr.push(ACTION_EVENT_SUBTYPE.REPLY)
      }
      Pinpoint.sendEventData(EVENT_TYPE.ACTION,arr,[portfolioName])
  
    }
    await dispatch(
      commentActions.getCommentById(
        commentResponse.data.id,
        selectedFeed.feed_type,
        selectedFeed.source_id,
        props.index
      )
    );
    if (props.setReplyComment) {
      props.setReplyComment({ showReplyComment: false, commentId: 0 });
    } else {
      clearComment();
    }
  };

  const clearComment = () => {
    setShowComment(false);
    setTimeout(() => {
      setShowComment(true); //workaroud as the pros are working from hook to class component
    }, 10);
  };

  return (
    <Stack style={{ margin: conv(24, 0) }}>
      <Stack
        className={clsx(
          classes.addComment,
          props.parentId ? classes.addCommentWithBackground : ""
        )}
      >
        <Stack
          direction="row"
          spacing={0}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row">
            <Avatar
              alt={user.display_name}
              src={getImageUrl(user.avatar, imageCookie)}
              sx={{ width: conv(28), height: conv(28) }}
            />
            <Typography
              component="span"
              variant="body2"
              className={clsx(classes.displayName)}
            >
              {user.display_name}
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ width: "100%" }} direction="row">
          {showComment && (
            <MentionTextArea
              replyUser={{ id: props.from, display: props.displayName }}
              value={value}
              onValueChange={handleValueChange}
              setComment={setComment}
              setMentionedUsers={setMentionedUsers}
              imageCookie={imageCookie}
              token={token}
              enterprise_id={enterprise_id}
              className={clsx(classes.addCommentInput)}
            />
          )}
          {comment.length > 0 && (
            <IconButton
              sx={{ padding: "0" }}
              aria-label="delete"
              color="primary"
              onClick={handleCloseClick}
            >
              <ClearInput />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        justifyContent="flex-end"
        mt={1}
        mb={1}
      >
        <IconButton
          onClick={() => postComment()}
          disabled={!comment}
          className={classes.sendButton}
          sx={{ padding: "0" }}
          aria-label="delete"
          color="primary"
        >
          <Send color={theme.palette.text.white} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default AddComment;
