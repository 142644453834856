import React, { useCallback, useEffect, useState } from "react";
import InfinitePaginatedScroll from "../Shared/InfinitePaginatedScroll";
import GUID from "../../Utils/Guid";
import CircularProgress from "../Shared/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import Helpers from "../../Utils/Helper";
import {
  accountActions,
  alertActions,
  appActions,
  feedActions,
  portfolioActions,
} from "../../Services/Redux/actionCreators";
import AlertCard from "./AlertCard";
import { FEEDTYPES, RIGHTMENUS } from "../../Utils/Constants";
import { Typography, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import LoadingView from "../LoadingView";
import NoDataView from "../NoDataView";
import { PAGE_SIZE } from "../../Utils/Constants";
import { useTranslate } from "../../Hooks/useTranslate";
import { ALERT_TYPES } from "../../Utils/Constants";
import NoAlertsIcon from "../../Assets/images/alerts/NoAlerts";
import { useTheme } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  EVENT_TYPE,
  VIEW_EVENT_TYPE,
  ALERT_EVENT_SUBTYPE,
} from "../../Utils/Constants";
const conv = UIHelper.convertPxToRem;

const AlertList = ({ filter }) => {
  const [offset, setOffset] = useState(0);
  const t = useTranslate();
  const [loading, setLoading] = useState(false);
  const [commentSelected,setCommentSelected]=useState(false);
  const [listLoading, setListLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const portfolioLoaded = useSelector((state) => state.portfolio.loaded);
  const accountLoaded = useSelector((state) => state.account.loaded);
  let alertData = useSelector((state) => state.alerts?.alerts);
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);

  let alertList = alertData?.[filter]?.list;
  const alertMeta = alertData?.[filter]?.meta;

  useEffect(() => {
    Pinpoint.sendEventData(EVENT_TYPE.VIEW, [
      VIEW_EVENT_TYPE.ALERTS,
      ALERT_EVENT_SUBTYPE[filter.toUpperCase()],
    ]);
  }, [filter]);

  useEffect(()=>{
    if (commentSelected&&
      selectedFeed &&
      ((selectedFeed.feed_type === FEEDTYPES.PORTFOLIO && portfolioLoaded) ||
        (selectedFeed.feed_type === FEEDTYPES.ACCOUNT && accountLoaded))
    )
    {
      dispatch(appActions.setLeftMenuClose());
      dispatch(appActions.setRightMenuOpen());

      dispatch(appActions.setFooterMenu(RIGHTMENUS.COMMENTS));
    }
  },[portfolioLoaded,accountLoaded,selectedFeed,commentSelected])

  const loadAlerts = async (offset) => {
    if (offset === 0) {
      setLoading(true);
      await dispatch(alertActions.getAlerts(offset, PAGE_SIZE, filter));
      setLoading(false);
      return;
    }
    setListLoading(true);
    await dispatch(alertActions.getAlerts(offset, PAGE_SIZE, filter));
    setListLoading(false);
  };

  useEffect(() => {
    if (!alertMeta) loadAlerts(0);
  }, [alertMeta]);

  useEffect(() => {
    if (offset) {
      loadAlerts(offset);
    }
  }, [offset]);

  useEffect(() => {
    dispatch(alertActions.setSelectedTab(filter));
  }, [filter]);

  const selectedAlert = useSelector((state) => state.alerts.selected);

  const handleAlertClick = useCallback(
    (data) => {
      if (!data) return;
      dispatch(alertActions.selectAlert(data.id));
      const { alert_type, source_type } = data;
      let basepath;
      let jtp_id;
      let kpi_corelation_id; //Operational Driver ID
      let kpi_metric_id; //KPI Metrics ID
      let timestamp;
      let feedPayload = {
        feed_id: data.id,
        feed_type: source_type,
        source_id: data.source_id,
      };
      if (source_type === FEEDTYPES.PORTFOLIO) {
        basepath = "portfolio";
      } else if (source_type === FEEDTYPES.ACCOUNT) {
        basepath = "account";
        if (data.portfolio_id) feedPayload["portfolio_id"] = data.portfolio_id;
      }
      dispatch(feedActions.setSelectedFeed(feedPayload));
      setCommentSelected(false);
      if ([ALERT_TYPES.MENTION, ALERT_TYPES.REPLY].includes(data.alert_type)) {      
        setCommentSelected(true);
      } else if (alert_type === ALERT_TYPES.ANALYTICS_TRIGGER) {
        const alert_data = data.alert_data;
        if (alert_data.jtp_id) {
          jtp_id = alert_data.jtp_id;
        }
        if (alert_data.data_updated_at) {
          timestamp = alert_data.data_updated_at;
        }
      }

      if (basepath) {
        let navPath = `${basepath}/${feedPayload.feed_id}/${feedPayload.source_id}`;
        if (timestamp) {
          navPath = `${navPath}/${timestamp}`;
        } else {
          navPath = `${navPath}/date`;
        }

        if (feedPayload.portfolio_id) {
          navPath = `${navPath}/${feedPayload.portfolio_id}`;
        }
        if (!(filter === "read"))
          dispatch(alertActions.readAlert(data.id, true));
        navigate(navPath, {
          state: {
            jtp: { id: jtp_id },
            kpi_corelation_id: kpi_corelation_id,
            kpi_metric_id: kpi_metric_id,
          },
        });
      }
    },
    [filter]
  );

  const handleNext = () => {
    if (alertMeta && alertMeta.offset + PAGE_SIZE < alertMeta.total_records) {
      setOffset(alertMeta.offset + PAGE_SIZE);
    }
  };

  if (loading)
    return (
      <Box mt="50%" flex="1">
        <LoadingView />
      </Box>
    );

  if (!loading&&(alertList && alertList.length === 0) || !alertMeta)
    return (
      <Box mt="50%" flex="1">
        <NoDataView
          text={t("no_alerts_have_set")}
          description={t("please_contact_ur_admin")}
          icon={<NoAlertsIcon fill={theme.palette.background.noDataFill} />}
        />
      </Box>
    );
  let dataLength = alertList?.length;
  alertList =
    alertList && Helpers.ConvertListWithDateHeader(alertList, "created_at");

  return (
    <InfinitePaginatedScroll
      totalRecords={alertMeta.total_records}
      dataLength={dataLength}
      onNext={handleNext}
      height="calc(100vh - 195px)"
      loader={<CircularProgress show={listLoading} />}
    >
      {alertList &&
        alertList.map((alert, i) => {
          let isSelected = false;
          if (selectedAlert && selectedAlert === alert.id) {
            isSelected = true;
          }
          if (alert.type === "HEADER")
            return (
              <Box key={GUID("alert")} mt={conv(36)}>
                <Typography variant="neutral">{alert.created_at}</Typography>
              </Box>
            );
          return (
            <AlertCard
              key={alert.id + i}
              data={alert}
              selected={isSelected}
              onClick={handleAlertClick}
            />
          );
        })}
    </InfinitePaginatedScroll>
  );
};

export default React.memo(AlertList);
