export const getCurrentUTCEpoch = () => {
  const currentDate = new Date();
  const currentUTCDate = new Date(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate(),
    currentDate.getUTCHours(),
    currentDate.getUTCMinutes(),
    currentDate.getUTCSeconds()
  );
  const currentUTCEpoch = Math.floor(currentUTCDate.getTime() / 1000);
  return currentUTCEpoch;
};

const getUTCDate = (date) => {
  const currentDate = new Date(date);
  const currentUTCDate = new Date(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate(),
    currentDate.getUTCHours(),
    currentDate.getUTCMinutes(),
    currentDate.getUTCSeconds()
  );
  return currentUTCDate;
};

export const parseDateFromAPIResponse = (dateString) => {
  if (!dateString) {
    return null;
  }
  const localDate = new Date(dateString);
  const intlDateOptions = { month: "short", year: "numeric", day: "2-digit" };
  // Date format - "MMM DD, YYYY"
  const formattedDateString = new Intl.DateTimeFormat(
    "en-US",
    intlDateOptions
  ).format(localDate);
  return formattedDateString;
};

export const getTimeSince = (date) => {
  var now = new Date();
  let startDate = new Date(date.replace(/-/g, "/"));
  let endDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000); //CONVERTING IT TO UTC TIMEZONE

  let months = 0;
  let years = 0;
  let weeks = 0;
  let yearDays = 365;
  let monthDays = 30;
  let weekDays = 7;
  let days = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
  if (days >= yearDays) {
    years = Math.floor(days / yearDays);
    if (years > 0) return years + "Y";
    days = days - years * yearDays;
  }
  if (days >= monthDays) {
    months = Math.floor(days / monthDays);
    if (months > 0) return months + "M";
    days = days - monthDays * months;
  }
  if (days >= weekDays) {
    weeks = Math.floor(days / weekDays);
    if (weeks > 0) return weeks + "W";
    days = days - weekDays * weeks;
  
  }
  if(days>0)
    return days + "D";
  let hours = Math.floor(((endDate - startDate) / (1000 * 60 * 60)) % 24);
  if (hours >= 0) return hours + "H";
};
let MONTHS=["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
export const getFullDate = (date,show_day=true) => {
  let dateObj = new Date(date.replace(/-/g, "/"));
  let month = dateObj.getMonth() ;
  let day = dateObj.getDate();
  let year = dateObj.getFullYear();
  if(show_day)
    return `${MONTHS[month]} ${day}, ${year}`
  return `${MONTHS[month].substring(0,3).toUpperCase()} ${year} `
};
