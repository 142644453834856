import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslate } from "../../Hooks/useTranslate";
import CardItem from "./CardItem";
import KPICardItem from "./KPICardItem";
import MainCard from "./MainCard";
import { FEEDTYPES } from "../../Utils/Constants";
import GUID from "../../Utils/Guid";
import {
  accountActions,
  portfolioActions,
  scoreCardActions,
} from "../../Services/Redux/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import Helpers from "../../Utils/Helper";
import LoadingView from "../LoadingView";
import { useParams } from "react-router-dom";
import ScoreCardAnimation from "../CustomAnimations/ScoreCardAnimation";
import NoData from "./NoData";
const IMPACTS = ["low", "medium", "high"];
const impactConverter = (value) => {
  if (value < IMPACTS.length) return IMPACTS[value];
};

const ScoreCard = ({ data,loadingJTP, animationTrigger,...props }) => {
  const classes = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [mainWidth, setMainWidth] = useState();
  const filters = useSelector((state) => state.portfolio.applied_filters);
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  const acc_timestamp = useSelector((state) => state.account.timestamp);
  const loadingAccJTP = useSelector((state) => state.account.loading_jtp);
  const loadingPfJTP = useSelector((state) => state.portfolio.loading_jtp);
  const scorecard = useSelector((state) => state.scorecard);
  const port_timestamp = useSelector((state) => state.portfolio.timestamp);

  const loadData = async (ids) => {
    // const { portfolio_id, source_id } = selectedFeed;

    const { id, date, portfolio_id } = params;
    const source_id = id;
    setLoading(true);
    if (
      selectedFeed.feed_type === FEEDTYPES.ACCOUNT &&
      portfolio_id &&
      acc_timestamp
    ) {
      let jtp_ids = ids.join(",");
      await dispatch(
        scoreCardActions.getAccountScorecard(
          portfolio_id,
          source_id,
          jtp_ids,
          acc_timestamp
        )
      );
    } else if (
      selectedFeed.feed_type === FEEDTYPES.PORTFOLIO &&
      port_timestamp
    ) {
      let filterData;
      if (filters) {
        filterData = Helpers.formatFilterData(filters);
      }
      await dispatch(
        scoreCardActions.getPortfolioScorecard(
          source_id,
          ids,
          port_timestamp,
          filterData
        )
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data?.length > 0 && (acc_timestamp || port_timestamp) && selectedFeed) {
      let filtered = data.filter((item) => item.display_scorecard == true);
      if (filtered?.length) {
        let sorted = filtered?.sort(
          (item1, item2) => item1.scorecard_order - item2.scorecard_order
        );
        if (sorted?.length) {
          let ids = sorted?.map((item) => item.id);
          dispatch(scoreCardActions.setScorecardJTP(sorted));
          loadData(ids);
        }
      } else {
        dispatch(scoreCardActions.clearAllData());
      }
    } else if (data?.length === 0) {
      // console.log("DataLength",data.length)
      dispatch(scoreCardActions.clearDriverKPIData());
    }
  }, [
    data,
    acc_timestamp,
    port_timestamp,
    filters,
    params.feed_id,
    params.id,
    params.date,
    params.portfolio_id,
  ]);

  const handleJTPClick = (data) => {
    if (!data) return;
    if (selectedFeed.feed_type === FEEDTYPES.ACCOUNT) {
      dispatch(accountActions.selectJTP(data.id));
    } else if (selectedFeed.feed_type === FEEDTYPES.PORTFOLIO) {
      dispatch(portfolioActions.selectJTP(data.id));
    }
  };
  const handleOPDriverClick = (data) => {
    if (!data) return;
    if (selectedFeed.feed_type === FEEDTYPES.ACCOUNT) {
      dispatch(accountActions.selectJTP(data.jtp_id));
      dispatch(accountActions.selectOperationalDriver(data.id));
    } else if (selectedFeed.feed_type === FEEDTYPES.PORTFOLIO) {
      dispatch(portfolioActions.selectJTP(data.jtp_id));
      dispatch(portfolioActions.selectOperationalDriver(data.id));
    }
  };
  const handleKPIClick = (data) => {
    if (!data) return;
    if (selectedFeed.feed_type === FEEDTYPES.ACCOUNT) {
      dispatch(accountActions.selectJTP(data.jtp_id));
      dispatch(accountActions.selectOperationalDriver(data.kpi_corelation_id));
      dispatch(accountActions.selectKPI(data.id));
    } else if (selectedFeed.feed_type === FEEDTYPES.PORTFOLIO) {
      dispatch(portfolioActions.selectJTP(data.jtp_id));
      dispatch(
        portfolioActions.selectOperationalDriver(data.kpi_corelation_id)
      );
      dispatch(portfolioActions.selectKPI(data.id));
    }
  };
  const handleWidthUpdate = (val) => {
    setMainWidth(val);
  };
  if (animationTrigger&&(loading || loadingAccJTP || loadingPfJTP)) {
    return <LoadingView mt={"20%"} />;
  }

  const op_drivers = scorecard?.data?.kpi_detail;
  const kpi_metrics = scorecard?.data?.kpi_metrics;
  const jtps = scorecard.jtps;

  let hSpacing = "1.374rem";

  if(loadingAccJTP&&props.type===FEEDTYPES.ACCOUNT)
  {return <></>}
  if(loadingPfJTP&&props.type===FEEDTYPES.PORTFOLIO)
  {return <></>}

  return (
    <ScoreCardAnimation trigger={animationTrigger}>
      <Box className={classes.root}>
        <Typography variant="body1" className={classes.main_title}>
          {t("scorecard")}
        </Typography>
        {!jtps||jtps?.length===0 ? (
          <NoData />
        ) : (
          <>
            <MainCard onWidthUpdate={handleWidthUpdate} />
            <Box
              display="flex"
              overflow={"auto"}
              flexDirection="column"
              pb=".5rem"
            >
              <Box display="flex" flexDirection="row">
                {mainWidth &&
                  jtps.map((jtp, i) => {
                    let k1 = GUID("sc-1");
                    return (
                      <Box
                        key={k1}
                        flex={
                          jtps.length === 1
                            ? 1
                            : `0 0 calc(${mainWidth / 2}px - 0.8rem)`
                        }
                        display="flex"
                        flexDirection="column"
                        ml={i > 0 && i < jtps.length ? hSpacing : 0}
                      >
                        <CardItem
                          title={t("customer_journey")}
                          value={jtp.name}
                          sub_title={t("impact")}
                          sub_value={t(impactConverter(jtp.sat_score_value))}
                          color={jtp.color_code}
                          onClick={() => handleJTPClick(jtp)}
                        />
                      </Box>
                    );
                  })}
              </Box>
              <Box display="flex" flexDirection="row" pb=".5rem">
                {mainWidth &&
                  jtps.map((jtp, i) => {
                    let opdriver = op_drivers?.[i];
                    let kpi = kpi_metrics?.[i];
                    let flex=`0 0 calc(${mainWidth / 2}px - 0.8rem)`;
                    if(jtps.length===1)
                      flex="1";
                    let column = (
                      <Box
                        flex={flex}
                        display="flex"
                        flexDirection="column"
                        ml={i > 0 && i < jtps.length ? hSpacing : 0}
                      >
                        <CardItem
                          title={t("operational_driver")}
                          value={opdriver?.display_name}
                          sub_title={t("impact")}
                          sub_value={
                            opdriver && t(impactConverter(opdriver.impact))
                          }
                          color={opdriver?.color_code}
                          onClick={() => handleOPDriverClick(opdriver)}
                        />

                        <KPICardItem
                          data={kpi}
                          title={t("kpi")}
                          sub_title={t("impact")}
                          onClick={() => handleKPIClick(kpi)}
                        />
                      </Box>
                    );
                    // let empty_column = (
                    //   <Box
                    //     flex={`0 0 calc(${mainWidth / 2}px - 0.8rem)`}
                    //     display="flex"
                    //     flexDirection="column"
                    //     ml={hSpacing}
                    //   >
                    //     <CardItem
                    //       title={t("operational_driver")}
                    //       value={null}
                    //       sub_title={t("impact")}
                    //       sub_value={null}
                    //       color={null}
                    //       onClick={() => handleOPDriverClick(opdriver)}
                    //     />

                    //     <KPICardItem
                    //       data={null}
                    //       title={t("kpi")}
                    //       sub_title={t("impact")}
                    //       onClick={() => handleKPIClick(kpi)}
                    //     />
                    //   </Box>
                    // );
                    return (
                      <React.Fragment key={GUID("sc-2")}>
                        {column}
                        {/* {jtps.length === 1 && empty_column} */}
                      </React.Fragment>
                    );
                  })}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </ScoreCardAnimation>
  );
};
export default ScoreCard;
