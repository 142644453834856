export const MAINMENUS = {
  FEEDS: "feeds",
  FAVORITES: "favorites",
  NOTIFICATIONS: "notifications",
  PORTFOLIOS: "portfolios",
  ACCOUNTS: "accounts",
  HELP: "help",
  ACTIONS: "actions",
};


export const RIGHTMENUS = {
  FOLLOW: "FOLLOW",
  FAVORITES: "FAVORITES",
  COMMENTS: "COMMENTS",
  ALERTS:"ALERTS",
  SHARE: "SHARE",
  FILTER: "FILTER",
  TEAM:"TEAM",
  ACCOUNTS:"ACCOUNTS"
};

export const FEEDTYPES = {
  PORTFOLIO: "PORTFOLIO",
  ACCOUNT: "ACCOUNT",
};

export const FEEDSUBTYPES = {
  JTP: "JTP",
};

export const CURRENCIES_SYMBOLS = {
  USD: "$",
  INR: "₹",
};

export const COLOR_CODES={
  GREY:"GREY"
}

export const KPI_CONDITION_FLAGS={
  DIRECT:"direct",
  INVERSE:"inverse"
}
export const FILTER_TYPE = {
  CHECKBOX: "checkbox",
  CHECKBOX_PROFILE: "checkbox_profile",
  RANGE: "range",
};

export const REACTIONS = {
  REACTION_ID: 1
}

export const CONSTANTS = {
  COMMENT: "COMMENT"
}

export const PAGE_SIZE=15

export const ALERT_TYPES={
  ANALYTICS_TRIGGER:"ANALYTICS_TRIGGER",
  MENTION:"MENTION",
  REPLY:"REPLY"
}
export const ALERT_EFFECT={
  UP:"UP",
  DOWN:"DOWN",
  NO_CHANGE:"NO_CHANGE"
}
export const SORT_ORDER={
  ASCENDING:"ASC",
  DESCENDING:"DESC"
}

export const ANALYTICS_TYPE={
  PORTFOLIO:"portfolio",
  ACCOUNT:"account"
}

export const USER_STATUS={
  CONFIRMED:"CONFIRMED",
  UNCONFIRMED:"UNCONFIRMED"
}

//PINPOINT CONSTANTS
export const EVENT_TYPE={
  VIEW:"view",
  ACTION:"action"
}

export const VIEW_EVENT_TYPE={
  LOGIN:"Login",
  RESET_PASSWORD:"ResetPassword",
  FEED:"Feed",
  ALERTS:"Alerts",
  FAVORITES:"Favorites",
  PORTFOLIO_ANALYTICS:"PortfolioAnalytics",
  ACCOUNT_ANALYTICS:"AccountAnalytics",
  PORTFOLIO_LIST:"PortfolioList",
  ACCOUNT_LIST:"AccountList",
  SETTINGS:"Settings",
  ACCOUNT_TEAM_LIST:"AccountTeamList",
  ACCOUNT_TEAM_MEMBER_PROFILE:"AccountTeamMemberProfile",
  ALERT_ADMIN_PROFILE:"AlertAdminProfile",
  ALERT_MANAGER_PROFILE:"AlertManagerProfile",
  ACTION_ADMIN_PROFILE:"ActionAdminProfile"

}

export const FEED_EVENT_SUBTYPE={
  ALL:"All",
  MY_FOLLOW:"My_Follows",
  EXPERIENCE:"Experiences",
  CHANGES:"Changes"
}

export const ALERT_EVENT_SUBTYPE={
  ALL:"All",
  READ:"Read",
  UNREAD:"Unread",
  ANALYTICS:"Analytics",
  COMMENTS:"Comments"
}

export const ANALYTICS_EVENT_SUBTYPE={
  JOURNEY:"Journey",
  OPDriverKPIs:"OPDriverKPIs",
  PORTFOLIO:"Portfolio",
  ACCOUNT:"Account"
}

export const ACTION_EVENT_TYPE={
  ALERTS:"Alerts",
  PORTFOLIO:"Portfolio",
  ACCOUNT:"Account",
  COMMENT:"Comment"
}

export const ACTION_EVENT_SUBTYPE={
  LOGIN:"Login",
  LOGOUT:"Logout",
  FAVORITE:"Favorite",
  UNFAVORITE:"Unfavorite",
  FOLLOW:"Follow",
  UNFOLLOW:"Unfollow",
  LIKE:"Like",
  UNLIKE:"Unlike",
  SHARE:"Share",
  DELETE:"Delete",
  SEARCH:"Search",
  COMMENT:"Comment",
  REPLY:"Reply",
  MENU:"Menu",
  LIGHT_MODE_TOGGLE:"LightToggle"
}

//PINPOINT END