import React, { useEffect, useRef, useState, useCallback, useId } from "react";
import PropTypes from "prop-types";
import { useWindowResize } from "../../Hooks/useWindowResize";
import GraphJS from "./MyGraph";
import { CircularProgress } from "@mui/material";
import GUID from "../../Utils/Guid";
import Helpers from "../../Utils/Helper";

import clsx from "clsx";
import "./index.css";

const Chart = (props) => {
  const gradId = useId();
  let {
    lineColor,
    markerColor,
    fixedMarker,
    goalLineColor,
    showTooltip,
    markerSize,
    markerBorderColor,
    shadeColor,
    lineThickness,
    tooltipStyle,
  } = props.config;
  // const width = props.width;
  let width;
  const { data } = props;
  const [options, setOptions] = useState({
    yMin: -100,
    yMax: 130,
    xMin: -20,
    xMax: 475,
  });
  const [loaded, setLoaded] = useState();
  const [loading,setLoading]=useState(false);
  const containerRef = React.useRef();
  const resizing = useWindowResize();
  const linePathRef = React.useRef();
  const handleRef = React.useRef();
  const [circles, setCircles] = useState();
  const [allPoints, setAllPoints] = useState([]);
  const [positionPoints, setPositionPoints] = useState([]);
  const [lineCurveData, setLineCurveData] = useState();
  const [shadeCurveData, setShadeCurveData] = useState();
  const [goalLinePath, setGoalLinePath] = useState();
  const tooltipRef = useRef();
  const svgRef = useRef();
  const [drag, setDrag] = useState(false);
  const [points, setPoints] = useState();
  const [points2, setPoints2] = useState();
  const [selectedPoint, setSelectedPoint] = useState(0);
  const [pointData, setPointData] = useState();
  const [position, setPosition] = useState();
  const [totalWidth, setTotalWidth] = useState(width);
  const [totalHeight, setTotalHeight] = useState();
  const [marginLeft, setMarginLeft] = useState();

  const svgCircles = (points) =>
    points.map((element, i) => {
      return (
        <circle
          key={i}
          cx={element[0]}
          cy={element[1]}
          r="2.5"
          className="svg-circles"
          v-for="p in pointsPositions"
        ></circle>
      );
    });

  const resize = async(_) => {
    if(fixedMarker)
      await Helpers.sleep(200);
   
    let container = containerRef.current;
    const w = width || container.getBoundingClientRect().width;
    const h = container.getBoundingClientRect().height;

    setTotalWidth(w);
    setTotalHeight(h);
    let minYValue = data.reduce((acc, loc) =>
      acc.value < loc.value ? acc : loc
    ).value;
    let maxYValue = data.reduce((acc, loc) =>
      acc.value > loc.value ? acc : loc
    ).value;
    let { goalLine } = props;
    if (goalLine < minYValue) {
      minYValue = goalLine;
    } else if (goalLine > maxYValue) {
      maxYValue = goalLine;
    }
    let newOptions = {
      xMin:-20,
      xMax: w + 18,
      yMin: minYValue - 25,
      yMax: maxYValue + 20,
    };

    setOptions(newOptions);
    if (points.length == 1) {
      const pointsPositions = GraphJS.pointsPositionsCalc(
        points,
        w,
        h,
        newOptions
      );
      setPositionPoints(pointsPositions);
      setSelectedPoint(0);
      let newX = newOptions.xMax - (fixedMarker?35:30);
      setPosition({ x: newX, y: pointsPositions[0][1] });
      handleRef.current.setAttribute("cx", newX);
      handleRef.current.setAttribute("cy", pointsPositions[0][1]);
      handleRef.current.setAttribute("stroke-width", 0);
      setLoaded(true);
      props?.onLoaded?.();
    } else if (points.length >= 2) {
      const pointsPositions = GraphJS.pointsPositionsCalc(
        points,
        w,
        h,
        newOptions
      );
      setPositionPoints(pointsPositions);
      let lineCurveData = GraphJS.getSVGDataFromPoints(
        points,
        w,
        h,
        newOptions
      );
      setLineCurveData(lineCurveData);
      const circles = svgCircles(pointsPositions);
      setCircles(circles);

      let shadeCurveData = GraphJS.getSVGDataFromPoints(
        points2,
        w,
        h,
        newOptions,
        true
      );
      setShadeCurveData(shadeCurveData);
     
    }
    if (goalLine!==null && points?.length) {
      let goalPoint = GraphJS.pointsPositionsCalc(
        [[0, goalLine]],
        w,
        h,
        newOptions
      );
      if (goalPoint)
        setGoalLinePath(
          `M ${15},${Math.round(
            goalPoint[0][1]
          )} L ${w-5},${Math.round(
            goalPoint[0][1]
          )}`
        );
    }
  };

  ////////////////// ALL USE EFFECT HOOKS//////////////////////////

  useEffect(() => {
    if (containerRef?.current) {
      const data = props.data;
      let w = width || containerRef?.current?.offsetWidth;
      let incr = w / (data.length-1);
      let seed = Math.round(incr);

      let points = data.map((item, i) => {
        return [seed * i, item.value];
      });
      // setOptions({ ...options, xMin:  - 20 });
      let points2 = [];
      points.map((item, index) => {
        if (index == 0) {
          points2.push([item[0], options.yMin]);
          points2.push([item[0], item[1]]);
          points2.push([item[0], item[1]]);
          points2.push([item[0], item[1]]);
        } else points2.push([item[0], item[1]]);
      });
      points2.push([
        points[points.length - 1][0],
        points[points.length - 1][1],
      ]);
      points2.push([
        points[points.length - 1][0],
        points[points.length - 1][1],
      ]);
      points2.push([points[points.length - 1][0], options.yMin]);

      setPoints2(points2);
      setPoints(points);
    }
  }, [containerRef?.current?.offsetWidth]);

  useEffect(() => {
    if (containerRef && points) {
      resize();
    }
  }, [points]);

  useEffect(() => {
    if (fixedMarker) {
      setLoaded(true);
      props?.onLoaded?.();
    }
    if (!fixedMarker && linePathRef?.current && lineCurveData) {
      const path = linePathRef.current;
      let pathLength = path.getTotalLength() || 0;
      let allPointsOnCurve = [];
      for (let i = 0; i < pathLength; i+=5) {
        let startPoint = path.getPointAtLength(i);
        allPointsOnCurve.push(startPoint);
      }
      setAllPoints(allPointsOnCurve);
    }

  }, [linePathRef, lineCurveData]);

  const onGraphLoad = async () => {
    setLoaded(true);
    props?.onLoaded?.();
  };

  useEffect(() => {
    if (allPoints.length > 0) {
      if (!loaded) onGraphLoad();
    }
  }, [allPoints, loaded]);

  useEffect(() => {
    if (handleRef && points && points.length>1) {
      const value = props.value;
      let container = containerRef.current;
      const w = width || container.offsetWidth;
      const h = container.offsetHeight;

      let actualIndex = props.data.findIndex(
        (item) => item.timestamp === value
      );
      if (actualIndex < 0) return;
      let positionPoint = points[actualIndex];
      let goalPoint = GraphJS.pointsPositionsCalc(
        [positionPoint],
        w,
        h,
        options
      );
      if (positionPoint) {
        setSelectedPoint(actualIndex);
        setPosition({ x: goalPoint[0][0], y: goalPoint[0][1] });
        handleRef.current.setAttribute("cx", goalPoint[0][0]);
        handleRef.current.setAttribute("cy", goalPoint[0][1]);
      }
    }
  }, [props.value, props.data, handleRef, points, positionPoints]);

  useEffect(() => {
    if (pointData) {
      const { index, point } = pointData;

      if (point) {
        setSelectedPoint(index);
      }
    }
  }, [pointData]);

  useEffect(() => {
    if (tooltipRef?.current && position && points?.length) {
      let tWidth = tooltipRef.current.offsetWidth;
      let hfWidth = tWidth / 2;
      let posX = position.x;
      let tooltipPos = posX - hfWidth;
      let containerWidth = width || containerRef?.current?.offsetWidth;
      if (posX < hfWidth) {
        tooltipPos = 0;
      }
      if (posX > containerWidth - hfWidth) {
        tooltipPos = containerWidth - tWidth;
      }
      tooltipRef.current.style.left = `${tooltipPos}px`;
      // props?.onMove?.(data[selectedPoint])
    }
  }, [position]);
  ///////////////////////////////////////////////////////////

  //////////////////////////////// MOUSE EVENTS ///////////////////////////
  const handleMouseMove = (e) => {
    if (fixedMarker) return;
    if (drag) updateMarker(e);
  };

  const handleMouseUp = (e) => {
    if (fixedMarker) return;

    if (pointData) {
      const { index, point } = pointData;
      if (point) {
        handleRef.current.setAttribute("cx", point[0]);
        handleRef.current.setAttribute("cy", point[1]);
        if (drag) props?.onRelease(data[selectedPoint]);
        setDrag(false);
      }
    }
  };

  const handleMouseClick = (e) => {
    updateMarker(e);
  };

  const handleDragStart = useCallback(() => {
    setDrag(true);
  }, []);

  const handleDragEnd = useCallback(() => {
    setDrag(false);
  }, []);

  const updateMarker = (e) => {
    if (handleRef) {
      let x = e.clientX;
      let y = e.clientY;

      if (svgRef?.current) {
        let point = svgRef.current.createSVGPoint();
        point.x = x; // 316
        point.y = y; // 165
        point = point.matrixTransform(svgRef.current.getScreenCTM().inverse());

        const currentPosition = allPoints.findIndex(
          (item) => Math.round(item.x) == Math.round(point.x)
        );
        const pp = allPoints[currentPosition];

        if (pp) {
          let position = { x: point.x, y: pp.y };
          let dataPointIndex = positionPoints.findIndex(
            (item) => Math.round(item[0]) - Math.round(position.x) == 0
          );
          if (dataPointIndex == -1) dataPointIndex = selectedPoint;

          handleRef.current.setAttribute("cx", position.x);
          handleRef.current.setAttribute("cy", position.y);

          let p1, p2, p0;
          if (currentPosition >= 0 && currentPosition < allPoints.length) {
            p1 = [Math.round(pp.x), Math.round(pp.y)];
            if (dataPointIndex == 0) {
              p0 = 0;
              p2 = 1;
            }
            if (
              dataPointIndex > 0 &&
              dataPointIndex < positionPoints.length - 1
            ) {
              if (position.x < positionPoints[dataPointIndex][0]) {
                p0 = dataPointIndex - 1;
                p2 = dataPointIndex;
              } else {
                p0 = dataPointIndex;
                p2 = dataPointIndex + 1;
              }
            }

            if (dataPointIndex == positionPoints.length - 1) {
              let len = positionPoints.length;
              p0 = len - 2;
              p2 = len - 1;
            }
            let { index, point } = GraphJS.calculateNextPoint(
              p0,
              p1,
              p2,
              positionPoints
            );
            setPointData({ index, point });
            setPosition(position);
            setSelectedPoint(dataPointIndex);
          }
        }
      }
    }
  };

  /////////////////////////////////////////////////////////////////////////
  return (
    <div
      ref={containerRef}
      className="container"
      style={{
        height: props.containerHeight,
        position: "relative",
      }}
      onMouseLeave={handleMouseUp}
    >
      <svg
        viewBox={`0 0 ${totalWidth || 100} ${totalHeight || 100}`}
        height={totalHeight}
        width={totalWidth}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <defs>
          <linearGradient
            id={gradId}
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" stopColor={shadeColor} stopOpacity="100%" />
            <stop offset="90%" stopColor={shadeColor} stopOpacity="0%" />
          </linearGradient>
        </defs>
        <path
          ref={linePathRef}
          d={lineCurveData}
          stroke={lineColor}
          strokeWidth={lineThickness}
          fill="none"
          strokeLinecap="round"
        />
        <path d={shadeCurveData} fillOpacity="0.7" fill={`url(#${gradId})`} />
        {goalLinePath && (
          <path
            d={goalLinePath}
            stroke={goalLineColor}
            strokeWidth="2"
            strokeDasharray="3"
          />
        )}
        {/* {circles} */}
        <circle
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          ref={handleRef}
          r={markerSize}
          strokeWidth={lineThickness}
          stroke={fixedMarker ? markerColor : markerBorderColor}
          fill={markerColor}
          cursor="pointer"
          display={loaded ? "block" : "none"}
          v-for="p in pointsPositions"
        />
      </svg>
      <div
        className={clsx(tooltipStyle)}
        style={{ display: showTooltip ? "block" : "none" }}
        ref={tooltipRef}
      >
        {selectedPoint > -1 &&
          selectedPoint < data.length &&
          props?.tooltipFormatter(data[selectedPoint].timestamp)}
      </div>
    </div>
  );
};
export default Chart;

Chart.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  height: PropTypes.string,
  containerHeight: PropTypes.string,
  onClick: PropTypes.func,
  showMarker: PropTypes.bool,
};

Chart.defaultProps = {
  config: {
    width: "600px",
    height: "400px",
    lineColor: "#71C782",
    lineThickness: 3,
    markerColor: "#71C782",
    shadeColor: "#71C782",
    markerBorderColor: "#fff",
    fixedMarker: false,
    goalLineColor: "#767E87",
    showTooltip: true,
    markerSize: 10,
    tooltipStyle: {
      position: "absolute",
      height: "15px",
      padding: "10px 15px",
      width: "100px",
      background: "red",
      top: "10px",
    },
  },
  data: [],
  containerHeight: "200px",
  showMarker: false,
  onClick: () => {
    return;
  },
};
