
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  circularProgressContainer: {
    marginTop:"6px",
    textAlign: "center",
    "& .MuiCircularProgress-root":{
      color:theme.palette.border.lightgrey
    }
  },
  // circularProgress: { display: "inline-block" },
}));
