import React from "react";
import { CheckedColor, UncheckedColor } from ".";

const DoubleArrow = ({ checked }) => {
  if (checked)
    return (
      <svg
        width={window.conv(25)}
        height={window.conv(23)}
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 17L19 9L11 1"
          stroke={UncheckedColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 17L9 9L1 1"
          stroke={UncheckedColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  return (
    <svg
      width={window.conv(25)}
      height={window.conv(23)}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1L1 9L9 17"
        stroke={UncheckedColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 1L11 9L19 17"
        stroke={UncheckedColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoubleArrow;
