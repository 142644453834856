import React, { useCallback, useEffect, useState } from "react";
import InfinitePaginatedScroll from "../Shared/InfinitePaginatedScroll";
import GUID from "../../Utils/Guid";
import CircularProgress from "../Shared/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import Helpers from "../../Utils/Helper";
import {
  accountActions,
  feedActions,
  portfolioActions,
} from "../../Services/Redux/actionCreators";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  EVENT_TYPE,
  VIEW_EVENT_TYPE,
  FEED_EVENT_SUBTYPE,
} from "../../Utils/Constants";
import FeedCard from "./FeedCard";
import { FEEDTYPES, FEEDSUBTYPES } from "../../Utils/Constants";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingView from "../LoadingView";
import NoDataView from "../NoDataView";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

const FeedList = ({ filter }) => {
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  let fData = useSelector((state) => state.feed.feeds);
  let feedData = fData?.[filter]?.list;
  const feedMeta = fData?.[filter]?.meta;
  let page = 0;
  if (feedMeta?.page_number) page = feedMeta.page_number;
  let dataLength = feedData?.length;
  feedData =
    feedData && Helpers.ConvertListWithDateHeader(feedData, "feed_date");

  useEffect(() => {
    loadFirstPage();
  }, [page, filter]);

  const loadFirstPage=async()=>{
    if (page === 0) {
      setLoading(true)
      await loadFeeds(1);
      setLoading(false)
      Pinpoint.sendEventData(EVENT_TYPE.VIEW, [
        VIEW_EVENT_TYPE.FEED,
        FEED_EVENT_SUBTYPE[filter.toUpperCase()],
      ]);
    }
  }

  const loadFeeds = async (page) => {
    setListLoading(true);
    await dispatch(feedActions.loadFeeds(page, filter));
    setListLoading(false);
  };

  const handleFeedClick = useCallback(
    (data) => {
      let sourceId = data.feed_data.source_id;
      let sourceData = data.feed_data.source_data;
      let selectedJourney;
      let basepath;

      if (data.feed_type === FEEDTYPES.PORTFOLIO) {
        basepath = "portfolio";
      } else if (data.feed_type === FEEDTYPES.ACCOUNT) {
        basepath = "account";
      }

      if (
        selectedFeed &&
        selectedFeed.feed_id == data.feed_id &&
        selectedFeed.source_id == sourceId
      ) {
        dispatch(feedActions.setSelectedFeed(null));
        if (basepath === "portfolio") {
          dispatch(portfolioActions.clearAllData());
        }
        if (basepath === "account") {
          dispatch(accountActions.clearAllData());
        }
        // dispatch(scoreCardActions.clearAllData());
        navigate("/dashboard");
        return;
      }

      let feedPayload = {
        feed_id: data.feed_id,
        feed_type: data.feed_type,
        source_id: sourceId,
        source_name: sourceData.name,
      };
      if (data.feed_type === FEEDTYPES.ACCOUNT) {
        if (sourceData.portfolio_id) {
          feedPayload["portfolio_id"] = sourceData.portfolio_id;
        }
      }
      dispatch(feedActions.setSelectedFeed(feedPayload));

      if (data.feed_sub_type === FEEDSUBTYPES.JTP) {
        let journeys = data?.feed_data?.source_data?.journey_score;
        if (journeys) {
          selectedJourney = journeys.find((item) => item.clickable);
        }
      }
      if (basepath) {
        let navPath = `${basepath}/${data.feed_id}/${data.feed_data.source_id}/${data.feed_data.source_data.last_updated_dt}`;
        if (data.feed_type === FEEDTYPES.ACCOUNT) {
          if (sourceData.portfolio_id) {
            navPath = `${navPath}/${sourceData.portfolio_id}`;
          }
        }
        navigate(navPath, { state: { jtp: selectedJourney } });
      }
    },
    [selectedFeed]
  );

  const handleNext = () => {
    if (feedMeta && feedMeta.page_number < feedMeta.page_count) {
      loadFeeds(feedMeta.page_number + 1);
    }
  };

  if (loading)
    return (
      <Box mt="50%" flex="1">
        <LoadingView />
      </Box>
    );

  if (!loading && (!feedMeta||feedData?.length===0))
    return (
      <Box mt="50%" flex="1">
        <NoDataView />
      </Box>
    );
  
  const checkIsSelected=(feed_id)=>{
    if(!selectedFeed)
    {
      return false;
    }
    return selectedFeed.feed_id === feed_id;    
  }  

  return (
    <InfinitePaginatedScroll
      totalRecords={feedMeta.total_records}
      dataLength={dataLength}
      onNext={handleNext}
      height="calc(100vh - 180px)"
      loader={<CircularProgress show={listLoading} />}
    >
      {feedData &&
        feedData.map((feed) => {         
          if (feed.type === "HEADER")
            return (
              <Box key={GUID("feed")} mt={conv(14)}>
                <Typography variant="neutral">{feed.feed_date}</Typography>
              </Box>
            );
          return (
            <FeedCard
              key={feed.feed_id}
              id={feed.feed_id}
              selected={checkIsSelected(feed.feed_id)}
              filter={filter}
              data={feed}
              loaded={feed.loaded}
              onClick={handleFeedClick}
            />
          );
        })}
    </InfinitePaginatedScroll>
  );
};

export default React.memo(FeedList);
