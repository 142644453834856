import React from "react";
const FavPortfolio = ({color}) => {
  let stroke="#F6F7F9";
  if(color) stroke=color;
  return (    
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.964844 16.1719C0.964844 16.1719 1.13524 18.2575 1.17484 18.9151C1.22764 19.7971 1.56844 20.7823 2.13724 21.4663C2.94004 22.4359 3.88564 22.7779 5.14804 22.7803C6.63244 22.7827 17.4264 22.7827 18.9108 22.7803C20.1732 22.7779 21.1188 22.4359 21.9216 21.4663C22.4904 20.7823 22.8312 19.7971 22.8852 18.9151C22.9236 18.2575 23.094 16.1719 23.094 16.1719"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.79541 3.99519V3.54999C7.79541 2.08599 8.98101 0.900391 10.445 0.900391H13.5434C15.0062 0.900391 16.193 2.08599 16.193 3.54999L16.1942 3.99519"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.994 17.6133V16.0605"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.899902 7.66667V11.8271C3.2015 13.3451 5.9591 14.4083 8.9855 14.8295C9.3479 13.5083 10.5395 12.5399 11.9879 12.5399C13.4135 12.5399 14.6291 13.5083 14.9675 14.8415C18.0059 14.4203 20.7743 13.3571 23.0879 11.8271V7.66667C23.0879 5.63387 21.4523 3.99707 19.4195 3.99707H4.5803C2.5475 3.99707 0.899902 5.63387 0.899902 7.66667Z"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default FavPortfolio;
