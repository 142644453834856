import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((MUITheme) => ({

 
  box_header: {
    flex:"1",
    marginTop: "10px !important",
    marginBottom: "10px !important",
  },
  buttonApply: {
    marginTop: 15,
    lineHeight:"2.3rem !important" ,
    fontSize:"0.925rem !important",
    textAlign:"right",
    marginLeft:"20%",
    marginRight:"20%",
    marginBottom:"20px",
    fontWeight:600
  },
  title:{}
}));
