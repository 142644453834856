import React from "react";
import { Typography } from "@mui/material";
import { useStyles } from "./styles";

const FooterLink = ({ index, url, children }) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.footerLink}
      variant="span"
      key={index}
      
    >
      {url ? (
        <a href={url} target="_blank" rel="noreferrer">
          {" "}
          {children}
        </a>
      ) : (
        children
      )}
    </Typography>
  );
};
export default FooterLink;
