import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
 
  root:{
    "& svg path":{
      stroke: `${MUITheme.palette.text.navButton}`
    },
    "& .Mui-disabled svg path":{
      stroke: `${MUITheme.palette.text.navButtonDisabled} !important`
    },
    
    "& h6":{
      fontSize:"0.875rem",
    }
  },
  capability_label: {
    color: MUITheme.palette.text.secondary,
    fontSize:conv(18),
    lineHeight:conv(27)   
  },
  impact_label: {
    color: MUITheme.palette.text.secondary,
    background: MUITheme.palette.background.secondary,
    borderRadius: conv(4), 
    padding: `${conv(2)} ${conv(19)}`,
    lineHeight:conv(21)+" !important",
    fontWeight:700+" !important",
    fontSize:conv(14)+" !important"
  },
  rounded_label: {
    color: MUITheme.palette.text.lightblue,
    background: MUITheme.palette.background.secondary,
    borderRadius: conv(4),
    padding: conv(2,16),
    fontSize:conv(14)+" !important"
  },
  drilldownBoxContainer: {
    background: MUITheme.palette.background.kpiMetricBg,
    borderRadius: conv(8),
    cursor: "pointer",
    padding: conv(7,14,25,17) ,
    width: "100%",
    boxSizing: "border-box",
    marginBottom:conv(16)
  },
  drilldownBox: {
    marginTop: conv(40),
    width: "100%",
   
  },
  tooltipBottomText: {
    position: "absolute",
    top: conv(10),
    textAlign:"center",
    left:conv(-10)
  },
  boundValue: {
    width:"auto",
    display:"block",
    flex:1,
    maxWidth:conv(44),
  
    textAlign:"left",
    "& p":{
      fontSize:"0.875rem",
      lineHeight:"1.4rem",
    }
  },
  textRight:{
    textAlign:"right"
  },
  neutral_label: {
    fontSize:"0.875rem",
    color: MUITheme.palette.text.kpi_bound+" !important",
  },
  barContainer:{
    flex:1,
    width:"100%",
    position: "relative",
    "& .tooltipContainer": {
      position: "absolute",
      left: "0px",
      top: "0px",
      // width:"100%",
      "& .tooltipBox": {
        width: conv(8),
        height:conv(8),
        zIndex:100,
        display: "inline-block",
        marginBottom: conv(3),
        background: "#fff",
        boxShadow:"0px 1px 2px rgba(0, 0, 0, 0.15)",
        borderRadius:conv(5),
        position: "relative",
       
      },
    },
  },
  tooltipTextBox: {
    position: "absolute",
    top: conv(-30),
    // border:"1px solid red",
    // width:"100%",
    // minWidth: conv(110),
    fontSize:"0.875rem"
  },
  metricsBar: {
    display: "inline-block",
    height: conv(8),
    background: MUITheme.palette.background.primaryGreen,
    borderRadius: conv(5),
    marginBottom:conv(3)
  },
  leftBar: { width: "50%", borderRadius:conv(5,0,0,5)  },
  rightBar: {
    width: "50%",
    position:"relative",
    borderRadius:conv(0,5,5,0)
  },
  border:{borderRadius:conv(5)},
  greenBackground: { background: MUITheme.palette.background.primaryGreen },
  redBackground: { background: MUITheme.palette.background.primaryRed },
  grayBackground: { background: MUITheme.palette.background.secondary},
  greenText: { color: MUITheme.palette.background.primaryGreen },
  redText: { color: MUITheme.palette.background.primaryRed },
  greyText: { color: MUITheme.palette.text.neutral },
}));
