import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((MUITheme) => ({
  buttonApply: {
    marginTop: 15,
    lineHeight: "2.3rem !important",
    fontSize: "0.925rem !important",
    textAlign: "right",
    marginLeft: "20%",
    marginRight: "20%",
    marginBottom: "20px",
    fontWeight: 600,
  },
  box: {
    backgroundColor: MUITheme.palette.background.listItem,
    flex: 1,
    borderRadius: 15,
    padding: window.conv(24,21),    marginTop:window.conv(15),
    cursor: "pointer",
    display:"flex",
    marginRight:window.conv(16),
    alignItems:"center"
  },
  selectedBox: {
    backgroundColor: MUITheme.palette.background.listItemSelected,
  },
}));
