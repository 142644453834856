// Array.prototype.findLastIndex = function (el) {
//   for (let i = this.length - 1; i >= 0; i--) {
//     if (this[i] !== el) {
//       continue;
//     }
//     return i;
//   }
//   return -1;
// };

export const findLastIndex = (source, value) => {
  for (let i = source.length - 1; i >= 0; i--) {
    if (source[i].value !== value) {
      continue;
    }
    return i;
  }
  return -1;
};
