import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv=UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  box: {
    background: MUITheme.palette.background.secondary,
    padding: conv(18,16),
    color: MUITheme.palette.text.lightblue,
    borderRadius: conv(12),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: conv(20),
    marginBottom: conv(20),
    "& h6": {
      color: MUITheme.palette.text.lightblue,
      marginLeft: conv(10),
      textOverflow:"ellipsis",
      overflow:"hidden",
      "-webkit-line-clamp": 1,
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "overflowWrap":"anywhere"
    },
    "& a": {
      color: MUITheme.palette.text.lightblue,
      marginLeft: conv(10),
      textDecoration:"none",
      textOverflow:"ellipsis",
      overflow:"hidden",
      "-webkit-line-clamp": 1,
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "overflowWrap":"anywhere"
    },
  },
}));
