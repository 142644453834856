import * as React from "react";
import { Stack } from "@mui/material";
import Sidebar from "../../Components/Sidebar";
import { useStyles } from "./styles";
import { useLeftBarOptions } from "./LeftBarOptions";
import { useRightBarOptions } from "./RightBarOptions";
import { appActions } from "../../Services/Redux/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import LoadingView from "../../Components/LoadingView";
import { FEEDTYPES, MAINMENUS, RIGHTMENUS } from "../../Utils/Constants";
import { Outlet, useNavigate } from "react-router-dom";
import { localStorageKeys, useLocalStorage } from "../../Hooks/useLocalStorage";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const leftBarOptions = useLeftBarOptions();
  const rightBarOptions = useRightBarOptions();
  const [loading, setLoading] = React.useState(false);
  const {
    left_menu_open,
    active_menu,
    footer_menu,
    right_menu_open,
    user_profile_loaded,
  } = useSelector((state) => state.app);
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  const portfolioLoaded = useSelector((state) => state.portfolio.loaded);
  const portfolioData=useSelector((state)=>state.portfolio.data);
  const accountLoaded = useSelector((state) => state.account.loaded);
  const accountData=useSelector((state)=>state.account.data);

  const [theme] = useLocalStorage(localStorageKeys.APP_THEME, "dark");
  React.useEffect(() => {
    dispatch(appActions.setAppTheme(theme));
  }, [dispatch, theme]);

  const handleLeftSideBarOpen = React.useCallback((flag) => {
    if (flag) {
      dispatch(appActions.setLeftMenuOpen());
      dispatch(appActions.setRightMenuClose());
    } else {
      dispatch(appActions.setLeftMenuClose());
      dispatch(appActions.setRightMenuOpen());
    }
  }, []);

  const handleRightSideBarOpen = React.useCallback((flag) => {
    if (flag) {
      dispatch(appActions.setRightMenuOpen());
      dispatch(appActions.setLeftMenuClose());
    } else {
      dispatch(appActions.setRightMenuClose());
      dispatch(appActions.setLeftMenuOpen());
    }
  }, []);
  const handleLeftMenuSelect = React.useCallback(
    (menu) => {
      dispatch(appActions.setMainMenu(menu.name));

      dispatch(appActions.setFooterMenu(null));
      if (user_profile_loaded) navigate("/dashboard");
    },
    [user_profile_loaded]
  );

  const handleRightMenuSelect = React.useCallback(
    (menu) => {
      dispatch(appActions.toggleRightMenu(menu.name));
    },
    [footer_menu]
  );

  const handleToggle = (flag) => {
    if (flag) {
      if (!active_menu) {
        dispatch(appActions.setMainMenu(MAINMENUS.FEEDS));
      }
      dispatch(appActions.setLeftMenuOpen());
      dispatch(appActions.setRightMenuClose());
      dispatch(appActions.setFooterMenu(null));
      if (user_profile_loaded) navigate("/dashboard");
    } else {
      dispatch(appActions.setLeftMenuClose());
      if (!footer_menu) dispatch(appActions.setFooterMenu(RIGHTMENUS.FOLLOW));
      dispatch(appActions.setRightMenuOpen());
    }
  };

  function checkVisibility()
  {
    if(user_profile_loaded)
      return false;
    if(selectedFeed)  
    {
      if(selectedFeed.feed_type === FEEDTYPES.ACCOUNT)
      {
        if(!accountData)
          return false;
        return accountLoaded;
      }
      if(selectedFeed.feed_type === FEEDTYPES.PORTFOLIO)
      {
        // if(!portfolioData)
        //   return false;
        return portfolioLoaded;
      }
    }
    return false;    
  }

  if (loading) return <LoadingView />;

  return (
    <Stack direction="row" className={classes.root}>
      <Sidebar
        sideBarWidth="70px"
        panelWidth="420px"
        name="leftbar"
        buttonMarginBottom={conv(30)}
        showToggleButton={true}
        panelAlignment="left"
        open={left_menu_open}
        options={leftBarOptions}
        selectedMenu={active_menu}
        onToggle={handleToggle}
        onOpen={handleLeftSideBarOpen}
        onSelect={handleLeftMenuSelect}
      />
      <div className={classes.container}>
        <Outlet />
      </div>
      <Sidebar
        sideBarWidth="55px"
        panelWidth="350px"
        name="rightbar"
        buttonMarginBottom={conv(15)}
        panelAlignment="right"
        optionsAlignment="center"
        className={classes.rightMenuBar}
        panelClass={classes.rightMenuPanel}
        show={
          checkVisibility()
        }
        open={right_menu_open}
        selectedMenu={footer_menu}
        options={rightBarOptions}
        onOpen={handleRightSideBarOpen}
        onSelect={handleRightMenuSelect}
      />
    </Stack>
  );
};

export default Dashboard;
