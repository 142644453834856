import * as React from "react";
import { Box, Typography, Menu, MenuItem, Popover } from "@mui/material";
import Fade from "@mui/material/Fade";
import { MoreHoriz } from "@mui/icons-material";
import Images from "../../../Assets/images/alerts";
import { useStyles } from "../styles";
import { useTheme } from "@mui/styles";
import { useTranslate } from "../../../Hooks/useTranslate";
import {
  alertActions,
  favoriteActions,
} from "../../../Services/Redux/actionCreators";
import { useId } from "react";
import { useDispatch } from "react-redux";

const AlertMenu = ({
  data,
  onClose,
  showButton,
  isMarkRead,
  isFromFav = false,
  tab
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const id = useId();
  const t = useTranslate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMenu, setShowMenu] = React.useState(false);

  React.useEffect(() => {
    if (!showButton) {
      setShowMenu(false);
    }
  }, [showButton]);

  const handleClose = (e) => {
    e?.stopPropagation();
    setShowMenu(false);
    setAnchorEl(null);
    onClose && onClose();
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setShowMenu(true);
    setAnchorEl(e.target);
  };

  const handleReadUnreadClick = (e) => {
    e.stopPropagation();
    dispatch(alertActions.readAlert(data.id, !data.is_read));
    if(tab==="read"||tab==="unread")
      dispatch(alertActions.removeFromList(data.id,tab));
    handleClose(e);
  };
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    if (isFromFav) {
      dispatch(favoriteActions.showDeleteModal(data));
    } else {
      dispatch(alertActions.showDeleteModal(data.id));
    }
    handleClose(e);
  };
  if (!showButton) return <></>;
  return (
    <>
      <Box
        className={classes.optionButton}
        onClick={handleOpen}
        name="option-button"
        aria-describedby={id}
      >
        <MoreHoriz color={theme.palette.text.secondary} />
      </Box>

      <Menu
        className={classes.menuBox}
        open={showMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        TransitionComponent={Fade}
      >
        {!isMarkRead && (
          <MenuItem onClick={handleReadUnreadClick}>
            <Box pr={"1rem"}>
              {data.is_read ? (
                <Images.Unread color={theme.palette.text.white} />
              ) : (
                <Images.Read color={theme.palette.text.white} />
              )}
            </Box>
            <Box flex="1">
              <Typography variant="body2">
                {data.is_read ? t("mark_as_unread") : t("mark_as_read")}
              </Typography>
            </Box>
          </MenuItem>
        )}
        <MenuItem onClick={handleDeleteClick}>
          <Box pr={"1.5rem"} mt="5px">
            <Images.Delete color={theme.palette.text.white} />
          </Box>
          <Box flex="1">
            <Typography variant="body2">{t("delete")}</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default React.memo(AlertMenu);
