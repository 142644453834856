import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "./TabPanel";
import GUID from "../../../Utils/Guid";
import { useStyles } from "./styles";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function BasicTabs({ data,value, margin,onChange }) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };
  const panels = [];
  return (
    <>
      <Box className={classes.border} margin={margin}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.tabs}
          aria-label="scrollable auto tabs"
        >
          {data.map((tab, i) => {
            panels.push(
              <TabPanel key={GUID("tab-panel")} value={value} index={i}>
                {tab.component}
              </TabPanel>
            );
            return (
              <Tab key={GUID("tab")} label={tab.name} {...a11yProps(i)}></Tab>
            );
          })}
        </Tabs>
      </Box>
      {panels}
    </>
  );
}
