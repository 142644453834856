import { makeStyles } from "@mui/styles";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  root: {
    minHeight: conv(126),
    display: "flex",
    height: "100%",
    minWidth:conv(55),
    flexDirection:"row",
    justifyContent:"center"
  },
  track: {
    height: "100%",
    width: conv(22),
    borderRadius: conv(34),
    display: "flex",
    alignItems: "flex-end",
    cursor: "pointer"
  },
  large: {    
    width: conv(30),
    borderRadius: conv(34),
  },
  bar: {
    borderRadius: conv(34),
    width: "100%",
    background: MUITheme.palette.background.disabled.gray,
    transition:"all ease 0.2s"
  },
  
  neutralLabel: {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: conv(24),
    padding: "0px 5px",
    textOverflow:"ellipsis",
    overflow:"hidden",
    "-webkit-line-clamp": 1,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "overflowWrap":"anywhere"
    // width:"60px"
  
  },
  stats: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  normalHeight:{height:"100%"},
  animatedBar: {
    animationDuration: "1s",
    animationTimingFunction: "ease-in-out",
    animationDelay: "0s",
    animationDirection: "normal",
    animationIterationCount: "1",
    animationName: "$animateHeight",
    animationFillMode: "forwards",
  },
  "@keyframes animateHeight": {
    "0%": {
      height: "0px",
    },
    "100%": {
      height: "100%",
    },
  },
}));
