import * as React from "react";
import { Stack, Typography, Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import AccountSlider from "../../AccountSlider";
import { useSelector, useDispatch } from "react-redux";
import Helpers from "../../../Utils/Helper";
import { useTranslate } from "../../../Hooks/useTranslate";
import {
  accountActions,
  scoreCardActions,
} from "../../../Services/Redux/actionCreators";
import UIHelper from "../../../Utils/UIHelper";
import { useTheme } from "@mui/styles";
import { useMemo } from "react";
const conv = UIHelper.convertPxToRem;

const AccountDetail = (props) => {
  const { data } = props;
  const t = useTranslate();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = React.useState();
  const [releasedValue, setReleasedValue] = React.useState();
  const [accountData, setAccountData] = React.useState([]);
  const [currentItem, setCurrentItem] = React.useState(null);
  const npsColors = useSelector((state) => state.app.mapping_data.nps_color);
  const nrrColors = useSelector(
    (state) => state.app.mapping_data.nrr_state_color
  );
  const titles = useSelector((state) => state.app.mapping_data.title);
  const deepLinkData = useSelector((state) => state.account.link_data);
  if (!data) return <>No Data</>;

  React.useEffect(() => {
    let accountData = [];

    let lastUpdatedVal;
    let trends = data?.nps_class_trends?.trends;
    if (trends) {
      if (trends.length === 0 && npsColors) {
        let text = data.nps_class;
        setCurrentItem({ text: text, color: npsColors[text.toLowerCase()] });
      } else if (trends.length > 0) {
        [...trends].reverse().forEach((item) => {
          let text, color;
          if (item.show_nrr) {
            text = item.nrr_state;
            color = nrrColors[text.toLowerCase()];
          } else {
            text = item.value;
            color = npsColors[text.toLowerCase()];
          }
          if (item.timestamp === data.last_updated_dt) {
            lastUpdatedVal = item;
          }
          accountData.push({ ...item, text: text, color });
        });
        setAccountData(accountData);
        dispatch(accountActions.setTimestamp(data.last_updated_dt));
        if (!sliderValue) setSliderValue(lastUpdatedVal);
      }
    }
  }, [data]);

  let colors = npsColors;
  let show_nrr = false;
  if (currentItem) show_nrr = currentItem.show_nrr;

  React.useEffect(() => {
    if (accountData && sliderValue) {
      setCurrentItem(sliderValue);
      dispatch(scoreCardActions.setSCAccountData(sliderValue));
    }
  }, [sliderValue]);

  const handleOnLoad=()=>{
    props.onLoad&&props.onLoad();
  }

  const accountId = useSelector((state) => state.account)?.data?.id;
  const portfolioId = useSelector((state) => state.account)?.data?.portfolio_id;
  const handleMarkerRelease = React.useCallback(
    (data) => {
      let timestamp = data.value;
      if (accountId && timestamp) {
        
        if (!deepLinkData) {
          dispatch(accountActions.clearData());
        }
        setReleasedValue(data);
        dispatch(
          accountActions.getAccountData(accountId, timestamp, portfolioId)
        );
        dispatch(
          accountActions.getAccountJTP(accountId, timestamp, portfolioId)
        );
        dispatch(accountActions.setTimestamp(timestamp));
        if (deepLinkData) dispatch(accountActions.setDeeplinkData(0));
      }
    },
    [accountId, portfolioId, deepLinkData]
  );

  const dataConfig = useMemo(() => {
    return {
      trends: accountData,
      total_trend_month: data.nps_class_trends.total_trend_month,
      available_trend_month: data.nps_class_trends.available_trend_month,
    };
  }, [accountData, data]);

  const handleMarkerMove = React.useCallback((data) => {
    if (data) setSliderValue(data);
  }, []);

  if (!currentItem) return "";
  return (
    <Box display="flex" flexDirection="row">
      <Box flex="1" maxWidth={conv(200)}>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          mb="1rem"
        >
          <Typography variant="h4" position={"relative"}>
            {titles && (show_nrr ? titles.nrr_state_title : titles.nps_title)}
            {show_nrr && currentItem.nrr_percentage >= 0 && (
              <Typography
                variant="p"
                className={classes.npsErrorLabel}
                style={{
                  background: Helpers.AddAlpha(currentItem.color, 0.3),
                  color: currentItem.color,
                }}
              >
                {Math.round(currentItem.nrr_percentage)}%
              </Typography>
            )}
          </Typography>
        </Stack>
        <Typography
          variant="h3"
          fontWeight={800}
          pr={conv(10)}
          color={currentItem.color}
          className={classes.valueLabel}
        >
          {show_nrr ? currentItem.nrr_state : currentItem.text}
        </Typography>
        <Typography variant="h6" mt="1rem" className={classes.neutralLabel}>
          {t("model_accuracy")}&nbsp;
          {currentItem.model_accuracy && currentItem.model_accuracy + "%"}
        </Typography>
      </Box>
      <Box flex="1" display="flex" >
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <AccountSlider
            data={dataConfig}
            onLoad={handleOnLoad}
            config={{
              height: conv(15),
              onChange: handleMarkerMove,
              onRelease: handleMarkerRelease,
              trackStyle: {
                background: theme.palette.background.accSliderBg,
                marginTop: conv(12),
              },
              markerStyle: {
                top: conv(-3),
                height: conv(13),
                width: conv(13),
              },
              showTooltip: true,
              tooltipFormatter: Helpers.DateToMMYYYY,
              tooltipStyle: classes.tooltipStyle,
              colors: colors,
            }}
            value={sliderValue}
            releasedValue={releasedValue}
          />
        </Box>
      </Box>
    </Box>
  );
};

AccountDetail.propTypes = {
  data: PropTypes.object,
};

AccountDetail.defaultProps = {
  data: null,
};
export default AccountDetail;
