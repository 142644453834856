import React from "react";
import { CheckedColor, UncheckedColor } from ".";

const Next = ({color}) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999636 17.6641L10.5996 9.3325L0.999637 1.00094"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Next;
