import { API } from "aws-amplify";
import { fetchAuthenticatedUser } from "./userActions/loginActions";
import { APIEndpoints, APIPaths } from "../../AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../Utils/API";
import { performanceActions } from ".";

export const SET_FAVORITES = "SET_FAVORITES";
export const SET_FAV_SEARCH_TEXT="SET_FAV_SEARCH_TEXT";
export const SET_PAGE="SET_FAVORITE_PAGE";
export const CLEAR_FAVORITES = "CLEAR_FAVORITES";
export const DELETE_FAVORITE = "DELETE_FAVORITE";
export const SHOW_DELETE_DIALOG = "SHOW_FAV_DIALOG";
export const CLOSE_DELETE_DIALOG = "CLOSE_FAV_DIALOG";
export const CLEAR_FAVORITE_DATA="CLEAR_ALERT_DATA";

export const setFavorites = (data) => {
  return { type: SET_FAVORITES, payload: data };
};
export const setSearchText = (data) => {
  return { type: SET_FAV_SEARCH_TEXT, payload: data };
};
export const clearFavorites = () => ({
  type: CLEAR_FAVORITES,
});
export const clearAllData = () => ({
  type: CLEAR_FAVORITE_DATA,
});
export const removeFavorite = (data) => ({
  type: DELETE_FAVORITE,
  payload: data,
});

export const showDeleteModal = (data) => ({
  type: SHOW_DELETE_DIALOG,
  payload: data,
});

export const closeDeleteModal = () => ({
  type: CLOSE_DELETE_DIALOG,
});
export const getFavorites = (offset, limit, query) => async (dispatch) => {
  try {
    let start=new Date();
    if (offset === 0) {
      dispatch(clearFavorites());
    }
    const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
    const apiName = APIEndpoints.FEEDS.name;
    const apiPath = APIPaths.GET_FAVORITES_LIST(offset, limit, query);
    const apiOptions = initializeAPIOptions(token, enterprise_id);
    let apiResult = await API.get(apiName, apiPath, apiOptions);
    dispatch(
      performanceActions.log({
        name: "FAVORITES",
        api: "GET FAVORITES"+(query?" SEARCH":""),
        start,
        apiName,
        apiPath
      })
    );
    dispatch(setFavorites(apiResult.data));
    return apiResult;
  } catch (e) {
    throw e;
  }
};

export const deleteFavoriteItem =
  (sourceId, sourceName, sourceType, favStatus, portfolioId) =>
    async (dispatch) => {
      try {
        let start=new Date();
        dispatch(removeFavorite({sourceId, portfolioId}));
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.DELETE_FAVORITE_ITEM(
          sourceId,
          sourceName,
          sourceType,
          favStatus,
          portfolioId
        );
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.post(apiName, apiPath, apiOptions).catch(
          (err) => {}
        );
        dispatch(
          performanceActions.log({
            name: "FAVORITES",
            api: "DELETE FAVORITE",
            start,
            apiName,
            apiPath
          })
        );
        return apiResult;
      } catch (e) {
        throw e;
      }
    };
