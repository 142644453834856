import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    height: window.conv(44),
    width: window.conv(44),
    bottom: window.conv(37),
    right: window.conv(48),
    cursor: "pointer",
  },
  box:{
    position: "relative", width: "100%", height: "100%"
  },
  input:{
    position: "absolute",
    height: "100%",
    width: "100%",
    opacity: 0,
    cursor: "pointer",
    zIndex: 100,
  },
  iconBox:{
    position: "absolute",
    height: "100%",
    width: "100%",
    opacity: 1,
    cursor: "pointer",
    background: theme.palette.background.camIconBg,
    border: "2px solid "+theme.palette.border.camIconBorder,
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
    borderRadius:"50%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },

 
}));
