import { makeStyles } from "@mui/styles";
import Background from "../../Assets/images/web-bg.png";
export const useStyles = makeStyles((MUITheme) => ({
  container: {
    backgroundColor: MUITheme.palette.background.mainContent,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  background: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize:"70%"
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
