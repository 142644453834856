export const APIEndpoints = {
  APP: {
    name: "App",
    endpoint: `${process.env.REACT_APP_SERVER_ENDPOINT}`,
  },
  USER: {
    name: "User",
    endpoint: `${process.env.REACT_APP_SERVER_ENDPOINT}/user/v1`,
  },
  FEEDS: {
    name: "Feed",
    endpoint: `${process.env.REACT_APP_SERVER_ENDPOINT}/feeds/v1`,
  },
  ANALYTICS: {
    name: "Analytics",
    endpoint: `${process.env.REACT_APP_SERVER_ENDPOINT}/analytics/v1`,
  },
  ORCHESTRATOR: {
    name: "ORCHESTRATOR",
    endpoint: `${process.env.REACT_APP_SERVER_ENDPOINT}/orchestrator/v1`,
  },
  ALERTS: {
    name: "Alerts",
    endpoint: `${process.env.REACT_APP_SERVER_ENDPOINT}/alerts/v1`,
  },
  UTILITY: {
    name: "Utility",
    endpoint: `${process.env.REACT_APP_SERVER_ENDPOINT}/utility/v1`,
  },
};

export const APIPaths = {
  VERIFY_EMAIL: (email) => `/user/status?email=${email}`,
  GET_USER: "/user",
  CLOUD_COOKIE: "/utility/v1/cloudfront_cookie",
  FIELD_MAPPING: "/analytics/v1/helper",
  SEND_VERIFICATION_CODE: "/user/send_verification_code",
  RESET_PASSWORD: "/user/verify",
  FEEDS: (page_number, page_size, filter_type) =>
    `/feed?page_number=${page_number}&page_size=${page_size}&filter_type=${filter_type}`,

  //////////////////////PORTFOLIO API LIST//////////////////////////////
  PORTFOLIO_VALUE_API: (source_id) => `/portfolio/${source_id}/value`,
  PORTFOLIO_JTP_API: (source_id) => `/portfolio/${source_id}/jtp`,
  PORTFOLIO_TREND_API: (source_id) => `/portfolio/${source_id}/trend`,
  PORTFOLIO_OPERATIONAL_DRIVERS: (source_id, jtp_id, timestamp) => {
    let url = `/portfolio/${source_id}/jtp/${jtp_id}`;
    if (timestamp) return `${url}?timestamp=${timestamp}`;
    return url;
  },
  PORTFOLIO_KPI_DRILLDOWN: (source_id, jtp_id, op_id) => {
    let url = `/portfolio/${source_id}/jtp/${jtp_id}/kpi/${op_id}`;
    return url;
  },
  PORTFOLIO_FILTERS: (source_id) => `/portfolio/${source_id}/filter`,
  PORTFOLIO_ALERTS_API: (source_id, source_type) =>
    `/alert-settings?source_type=${source_type}&source_id=${source_id}`,
  PORTFOLIO_ACCOUNT_MEMBERS_API: (source_id, page_no, page_size) =>
    `/portfolio/${source_id}/filter-key/account_member?page_number=${page_no}&page_size=${page_size}`,
  PORTFOLIO_ACCOUNT_LIST: (source_id) => `/portfolio/${source_id}/account`,
  //////////////////////////////////////////////////////////////////////

  ////////////////////////////////ACCOUNT API LIST//////////////////////////////////////
  ACCOUNT_VALUE_API: (source_id, timestamp, portfolio_id) => {
    let url = `/portfolio/${portfolio_id}/account/${source_id}/value`;
    if (timestamp) return `${url}?timestamp=${timestamp}`;
    return url;
  },
  ACCOUNT_JTP_API: (source_id, timestamp, portfolio_id) => {
    let url = `/portfolio/${portfolio_id}/account/${source_id}/jtp`;
    if (timestamp) return `${url}?timestamp=${timestamp}`;
    return url;
  },
  ACCOUNT_TEAM_MEMBERS: (source_id, page_no, page_size) =>
    `/account-team-and-followers?account_id=${source_id}&page_number=${page_no}&page_size=${page_size}`,
  ACCOUNT_OPERATIONAL_DRIVERS: (source_id, jtp_id, timestamp, portfolio_id) => {
    let url = `/portfolio/${portfolio_id}/account/${source_id}/jtp/${jtp_id}`;
    if (timestamp) return `${url}?timestamp=${timestamp}`;
    return url;
  },
  ACCOUNT_KPI_DRILLDOWN: (
    source_id,
    jtp_id,
    op_id,
    timestamp,
    portfolio_id
  ) => {
    let url = `/portfolio/${portfolio_id}/account/${source_id}/jtp/${jtp_id}/kpi/${op_id}`;
    if (timestamp) return `${url}?timestamp=${timestamp}`;
    return url;
  },
  ACCOUNT_ALERTS_API: (source_id, source_type) =>
    `/alert-settings?source_type=${source_type}&source_id=${source_id}`,
  USER_PROFILE_API: (user_id) => `/user/${user_id}`,
  UPDATE_USER_PROFILE_API: "/user",
  UPDATE_USER_PROFILE_PIC_API: "/user/profile_picture",

  //////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////COMMON API LIST FOR PORTFOLIO AND ACCOUNT//////////////////////////////////////////
  IS_ALERT_CONFIGURED: (source_id, source_type) =>
    `/alerts_orchestrator/alert_action_status?source_id=${source_id}&source_type=${source_type}`,
  ACTIONS: (source_id, source_type) =>
    `/action?source_id=${source_id}&source_type=${source_type}`,
  ACTION_DETAIL: (id) => `/action/${id}`,

  ANALYTICS_REACTIONS_API: (source_id, source_type) =>
    `/reactions?source_id=${source_id}&source_type=${source_type}`,
  ANALYTICS_STATUS_API: (source_id, source_type, portfolio_id) => {
    let basepath = `/favorite-follow/status?source_id=${source_id}&source_type=${source_type}`;
    if (portfolio_id) basepath = `${basepath}&portfolio_id=${portfolio_id}`;
    return basepath;
  },
  ANALYTICS_UPDATE_STATUS_API: (
    source_id,
    source_type,
    follow_status,
    source_name,
    portfolio_id
  ) => {
    let basepath = `/follow/status?source_id=${source_id}&source_type=${source_type}&follow=${follow_status}&source_name=${source_name}`;
    if (portfolio_id) basepath = `${basepath}&portfolio_id=${portfolio_id}`;
    return basepath;
  },
  ANALYTICS_UPDATE_FAVORITE_API: (
    source_id,
    source_type,
    favorite,
    source_name,
    portfolio_id
  ) => {
    let basepath = `/favorite/status?source_id=${source_id}&source_type=${source_type}&favorite=${favorite}&source_name=${source_name}`;
    if (portfolio_id) basepath = `${basepath}&portfolio_id=${portfolio_id}`;
    return basepath;
  },
  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////SHARE API /////////////////
  SHARE_API: () => "/spectrum_link",
  ///////////////////////////////////////////////////////////

  /////////////////////////////////COMMENTS API /////////////////
  COMMENTS_LIST_API: (source_type, source_id, offset, limit) =>
    `/comments?source_type=${source_type}&source_id=${source_id}&offset=${offset}&limit=${limit}`,
  GET_COMMENT_BY_ID: (commentId, source_type, source_id) =>
    `/comments/${commentId}?source_type=${source_type}&source_id=${source_id}`,
  ADD_COMMENTS_API: "/comments",
  REACTIONS_API: "/reactions",
  MENTIONS_API: (query, pageNumber, page_size) =>
    `/user/search?q=${query}&page_number=${pageNumber}&page_size=${page_size}`,
  /////////////////////////////////COMMENTS ENDS HERE API /////////////////

  ////////////////////////////////ALERTS APIS////////////////////
  ALERTS: (offset, limit, alert_type = "all", is_read) => {
    if (is_read != null) {
      return `/alerts?is_read=${is_read}&offset=${offset}&limit=${limit}`;
    }
    return `/alerts?offset=${offset}&limit=${limit}&alert_type=${alert_type}`;
  },
  GET_UNREAD_ALERTS_COUNT: "/alerts/count",
  READ_UNREAD_ALERT: (alert_id) => `/alerts/${alert_id}/mark`,
  DELETE_ALERT: (alert_id) => `/alerts/${alert_id}`,

  ///////////////////////////ALERTS API ENDS HERE////////////////
  /////////////////////////////// FAVORITES API  //////////////////////////
  GET_FAVORITES_LIST: (offset, limit, query) => {
    if (query) {
      return `/favorite/search?offset=${offset}&limit=${limit}&q=${query}`;
    } else {
      return `/favorite/search?offset=${offset}&limit=${limit}`;
    }
  },

  DELETE_FAVORITE_ITEM: (
    sourceId,
    sourceName,
    sourceType,
    favStatus,
    portfolioId
  ) =>
    `/favorite/status?source_id=${sourceId}&source_name=${sourceName}&source_type=${sourceType}&favorite=${favStatus}&portfolio_id=${portfolioId}`,
  //////////////////////////////  FAVORITES END //////////////////////////

  /////////////////// SCORE CARD APIs ////////////////////////
  PORTFOLIO_SCORE_CARD: (sourceId) =>
    `/portfolio/${sourceId}/scorecard`,
  ACCOUNT_SCORE_CARD: (portfolioId,accountId,jtpIds,timestamp) =>
    `/portfolio/${portfolioId}/account/${accountId}/scorecard?jtp_ids=${jtpIds}&timestamp=${timestamp}`,

  //////////////////  SCORE CARD END /////////////////////////
  /////////////////////////////// ACCOUNT/PORTFOLIO API  //////////////////////////

  /////////////////////////////// ACCOUNT/PORTFOLIO API  //////////////////////////

  OCX_COMMON_LIST_SEARCH_API: (
    api_name,
    page_number = 1,
    page_size = 15,
    sort_by = "",
    sort_order = "ASC",
    q = ""
  ) => {
    const queryParams = {
      api_name,
      page_number,
      page_size,
      sort_by,
      sort_order,
      q,
    };
    let prepareParams = [];
    for (let key in queryParams) {
      if (queryParams[key]) {
        prepareParams.push(`${key}=${queryParams[key]}`);
      }
    }
    return `/${api_name}/search?${prepareParams.join("&")}`;
  },

  /////////////////////////////// ACCOUNT/PORTFOLIO API ENDS HERE //////////////////////////

  ACTION_LIST_SEARCH_API: (
    api_name,
    page_number = 1,
    page_size = 15,
    sort_by = "",
    sort_order = "ASC",
    q = ""
  ) => {
    const queryParams = {
      api_name,
      page_number,
      page_size,
      sort_by,
      sort_order,
      q,
    };
    let prepareParams = [];
    for (let key in queryParams) {
      if (queryParams[key]) {
        prepareParams.push(`${key}=${queryParams[key]}`);
      }
    }
    return `/${api_name}/search?${prepareParams.join("&")}`;
  },
};
