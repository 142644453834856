import { scoreCardActions } from "../actionCreators";

const initialState = {
  data: null,
  jtps: null,
  account_data: null,
};

const scorecardReducer = (state = initialState, action) => {
  switch (action.type) {
  case scoreCardActions.SET_SCORECARD: {
    let jtps = state.jtps;
    let scData = action.payload;

    let ops = scData?.kpi_detail;
    let kpis = scData?.kpi_metrics;
    let newOps = [];
    let newKpis = [];
    if (jtps)
      for (let i = 0; i < jtps.length; i++) {
        let jtp = jtps[i];

        let checkOp = ops.find((item) => item.jtp_id === jtp.id);

        newOps.push(checkOp);

        let checkKpi = kpis.find((item) => item.jtp_id === jtp.id);
        newKpis.push(checkKpi);
      }

    return { ...state, data: { kpi_detail: newOps, kpi_metrics: newKpis } };
  }
  case scoreCardActions.SET_SCORECARD_JTP:
    return { ...state, jtps: action.payload };
  case scoreCardActions.SET_SC_ACCOUNT_DATA:
    return { ...state, account_data: action.payload };
  case scoreCardActions.CLEAR_DRIVER_KPI_DATA:
    return { ...state, jtps: null, data: null };
  case scoreCardActions.CLEAR_SCORECARD_DATA:
    return initialState;
  default:
    return state;
  }
};

export default scorecardReducer;
