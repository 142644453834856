import React, { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../Hooks/useTranslate";
import DeleteAlert from "../Shared/DeleteAlert";
import {
  favoriteActions,
  feedActions,
  accountActions,
  portfolioActions,
} from "../../Services/Redux/actionCreators";
import { useNavigate } from "react-router-dom";
import { FEEDTYPES } from "../../Utils/Constants";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  EVENT_TYPE,
  ACTION_EVENT_TYPE,
  ACTION_EVENT_SUBTYPE,
} from "../../Utils/Constants";

const DeleteModal = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const show_delete_modal = useSelector(
    (state) => state.favorites.show_delete_modal
  );
  const selected = useSelector((state) => state.favorites.selected);
  const dispatch = useDispatch();
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);

  let portfolioName = useSelector((state) => state.portfolio.data?.name);
  const handleDeleteClick = useCallback(() => {
    const { id, favorite_type, name, portfolio_id } = selected;
    dispatch(
      favoriteActions.deleteFavoriteItem(
        id,
        name,
        favorite_type,
        false,
        portfolio_id
      )
    );

    if (favorite_type === FEEDTYPES.PORTFOLIO) {
      Pinpoint.sendEventData(
        EVENT_TYPE.ACTION,
        [ACTION_EVENT_TYPE.PORTFOLIO, ACTION_EVENT_SUBTYPE.UNFAVORITE],
        [name]
      );
    }
    else if (favorite_type === FEEDTYPES.ACCOUNT) {
      Pinpoint.sendEventData(
        EVENT_TYPE.ACTION,
        [ACTION_EVENT_TYPE.ACCOUNT, ACTION_EVENT_SUBTYPE.UNFAVORITE],
        [name],
      );
    }

    if (selectedFeed.feed_id === id) {
      dispatch(feedActions.setSelectedFeed(null));
      if (favorite_type === FEEDTYPES.PORTFOLIO) {
        dispatch(portfolioActions.clearAllData());
      }
      if (favorite_type === FEEDTYPES.ACCOUNT) {
        dispatch(accountActions.clearAllData());
      }
      navigate("/dashboard");
      return;
    }
  }, [selected]);

  const handleCloseClick = useCallback(() => {
    dispatch(favoriteActions.closeDeleteModal());
  }, []);

  useEffect(() => {
    return () => dispatch(favoriteActions.closeDeleteModal());
  }, []);

  return (
    <DeleteAlert
      open={show_delete_modal}
      title={t("are_you_sure")}
      description={t("delete_desc_favorite")}
      onCancel={handleCloseClick}
      onSuccess={handleDeleteClick}
    />
  );
};

export default React.memo(DeleteModal);
