import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  selectedBox: {
    backgroundColor: MUITheme.palette.background.secondary,
  },
  title: {
    fontSize: conv(22.52) + " !important",
    // fontWeight: 600,
    lineHeight: conv(34) + " !important",
  },
  account_title: {
    fontSize: conv(16) + " !important",
    // fontWeight: 600,
    lineHeight: conv(34) + " !important",
  },
  finLabel: {
    color: `${MUITheme.palette.text.neutral} !important`,
    fontWeight: 500,
    padding: "0px 5px",
    fontSize:conv(12) +"!important",
    lineHeight:conv(18) +"!important",
  },
  neutralLabel: {
    color: `${MUITheme.palette.text.neutral} !important`,
    fontWeight: 500,
    padding: "0px 5px",
  },
  npsErrorLabel: {
    background: "#573C3C ",
    color: "#F45252",
    fontSize: ".875rem !important",
    lineHeight: "21px",
    padding: "2px 5px",
    borderRadius: "5px",
    marginLeft: "5px !important",
  },
  countLabel: {
    color: `${MUITheme.palette.text.secondary} !important`,
    // paddingRight: conv(4),
    // textOverflow:"ellipsis",
    // overflow:"hidden",
    // "-webkit-line-clamp": 1,
    // display: "-webkit-box",
    // "-webkit-box-orient": "vertical",
    // "overflowWrap":"anywhere"
  },
  jtpBox: {
    whiteSpace: "nowrap",
    overflowY: "hidden",
    overflowX: "scroll",
    "-webkit-overflow-scrolling": "touch",
    "-moz-overflow-scrolling": "touch",
    "-ms-overflow-scrolling": "touch",
    "-o-overflow-scrolling": "touch",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  autoHeight: {
    height: "calc(100vh - 205px)",
  },
  account_status: {
    fontWeight: 700+" !important",
    fontSize: conv(24)+" !important",
    lineHeight: conv(36)+" !important",
  },
}));
