import React from "react";
import { UncheckedColor } from ".";

const Filters = ({ checked }) => {
  return (
    <svg
      width={window.conv(22)}
      height={window.conv(22)}
      viewBox="0 0 25 24"
      fill={checked ? UncheckedColor : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1346 21.998L14.9109 19.7499V13.8584L22.4006 6.26261C22.6829 5.97799 22.8413 5.58737 22.8413 5.17832V3.51559C22.8413 2.67673 22.8413 1.99805 22.8413 1.99805H2.84002C2.83741 1.99805 2.84126 2.67673 2.84126 3.51559V5.21634C2.84126 5.60235 2.98296 5.97339 3.23845 6.25454L10.1346 13.8584V21.998Z"
        stroke={UncheckedColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Filters;
