import * as React from "react";
import { Stack, Typography, Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import AmountLabel from "../../AmountLabel";
import Helpers from "../../../Utils/Helper";
import { useStyles } from "../styles";
import NPSGraph from "../../NPSGraph";
import { useTheme } from "@mui/styles";

import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

const AreaGraphCard = (props) => {
  const {
    title,
    currency,
    amount,
    npsLabel,
    npsValue,
    color,
    date,
    npsScore,
    data,
    goalLineValue,
  } = props;
  const theme = useTheme();

  const classes = useStyles();

  const config = React.useMemo(() => {
    return {
      height: conv(90),
      lineColor: color,
      shadeColor: color,
      markerColor: color,
      lineThickness: conv(4),
      markerSize: conv(6),
      markerBorderColor: theme.palette.background.white,
      goalLineColor: "#767E87",
      fixedMarker: true,
      showMarker: true,
      showTooltip: false,
      tooltipStyle: classes.graphTooltip,
    };
  }, [color]);

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        {title}
        <AmountLabel>
          <Typography variant="span" className={classes.finLabel}>
            {currency}
            {amount}
          </Typography>
        </AmountLabel>
      </Typography>

      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        mt="1rem"
      >
        <Typography variant="h6">
          {npsLabel}
          {npsValue === 0 ? (
            ""
          ) : (
            <Typography
              variant="a"
              className={classes.npsErrorLabel}
              style={{ background: Helpers.AddAlpha(color, 0.3), color: color }}
            >
              {npsValue > 0 ? "+" + npsValue : npsValue}
            </Typography>
          )}
        </Typography>
        <Typography variant="neutral" className={classes.neutralLabel}>
          {date}
        </Typography>
      </Stack>
      <Grid container>
        <Grid item md={3} sm={3}>
          <Typography variant="h2" className={classes.countLabel} mt={conv(15)}>
            {npsScore}
          </Typography>
        </Grid>
        <Grid item md={9} sm={9}>
          {data && (
            <NPSGraph
              data={Helpers.reverseArray(data)}
              config={config}
              value={props.value}
              goalLine={goalLineValue}
              tooltipFormatter={Helpers.DateToMMYYYY}
              containerHeight={conv(80)}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

AreaGraphCard.propTypes = {
  title: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.array,
  amount: PropTypes.string,
  goalLineValue: PropTypes.number,
  npsLabel: PropTypes.string,
  npsValue: PropTypes.number,
  color: PropTypes.string,
  date: PropTypes.string,
  npsScore: PropTypes.number,
  showTitle: PropTypes.bool,
};

AreaGraphCard.defaultProps = {
  title: "Alpha Upsilon Furniture & Cabinetry",
  currency: "$",
  data: [],
  amount: 46.66,
  goalLineValue: 40,
  npsLabel: "NPS",
  npsValue: -5,
  color: "transparent",
  date: "May, 2022",
  npsScore: 555,
  showTitle: true,
};
export default React.memo(AreaGraphCard);

const useRefDimensions = (ref) => {
  const [dimensions, setDimensions] = React.useState({ width: 1, height: 2 });
  React.useEffect(() => {
    if (ref.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect();
      const { width, height } = boundingRect;
      setDimensions({ width: Math.round(width), height: Math.round(height) });
    }
  }, [ref, ref?.current?.getBoundingClientRect()]);
  return dimensions;
};
