import { API } from "aws-amplify";
import { fetchAuthenticatedUser } from "./userActions/loginActions";
import { APIEndpoints, APIPaths } from "../../AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../Utils/API";
import { CONSTANTS, REACTIONS } from "../../../Utils/Constants";
import { performanceActions } from ".";

export const SET_COMMENTS = "SET_COMMENTS";
export const APPEND_COMMENTS = "APPEND_COMMENTS";
export const FORMAT_USERS = "FORMAT_USERS";
export const APPEND_USERS = "APPEND_USERS";
export const POST_COMMENT = "POST_COMMENT";
export const SET_COMMENT_BY_ID = "SET_COMMENT_BY_ID";
export const CLEAR_COMMENTS_DATA="CLEAR_COMMENTS_DATA";
export const COMMENT_TOGGLE_LIKE="COMMENT_TOGGLE_LIKE";

const converUsersAsObj = (users) => {
  let userObj = {};
  users.forEach(user => {
    userObj[user.user_id] = user
  });
  return userObj;
}


const setComments = (comments) => ({ type: SET_COMMENTS, payload:{comments}});
const formatUsers = (users) => ({ type: FORMAT_USERS, payload:{users: converUsersAsObj(users)}});
const appendUsers = (users) => ({ type: APPEND_USERS, payload:{users: converUsersAsObj(users)}});
const postComment = (comment) => ({type: POST_COMMENT, payload: {comment}});
const setCommentById = (comment, index) => ({type: SET_COMMENT_BY_ID, payload: {comment}, index});

const appendComments = (comments) => ({ type: APPEND_COMMENTS, payload:{comments}});

const setLikeComment = (index) => ({ type: COMMENT_TOGGLE_LIKE, index});

export const clearAllData=()=>({type:CLEAR_COMMENTS_DATA});


export const loadComments =
  (sourceType, sourceId, offset=0, limit=15) =>
    async dispatch => {
      try {
        let start=new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.COMMENTS_LIST_API(sourceType, sourceId, offset, limit);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name:"COMMENTS",
            api: "LOAD COMMENTS",
            start,
            apiName,
            apiPath
          })
        );
        if (offset === 0) {
          dispatch(setComments(apiResult.data));
          dispatch(formatUsers(apiResult.data.user_details));
        } else {
          dispatch(appendComments(apiResult.data));
          dispatch(appendUsers(apiResult.data.user_details));
        }
        
        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const addComment =
  (body) =>
    async dispatch => {
      try {
        let start=new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.ADD_COMMENTS_API;
        const apiOptions = initializeAPIOptions(token, enterprise_id, body);
        let apiResult = await API.post(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name:"COMMENTS",
            api: "ADD COMMENT",
            start,
            apiName,
            apiPath
          })
        );
        dispatch(postComment(apiResult.data));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const getCommentById =
  (commentId, sourceType, sourceId, index) =>
    async (dispatch) => {
      try {
        let start=new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.GET_COMMENT_BY_ID(commentId, sourceType, sourceId);
        const apiOptions = initializeAPIOptions(token, enterprise_id);
        let apiResult = await API.get(apiName, apiPath, apiOptions);
        dispatch(
          performanceActions.log({
            name:"COMMENTS",
            api: "GET COMMENT BY ID",
            start,
            apiName,
            apiPath
          })
        );
        dispatch(setCommentById(apiResult.data, index));
        dispatch(appendUsers(apiResult.data.user_details));
        return apiResult;
      } catch (e) {
        throw e;
      }
    };

export const toggleLike =
  (source_type, source_id, reaction, index=0) =>
    async dispatch => {
      try {
        let start=new Date();
        const { token, enterprise_id } = await dispatch(fetchAuthenticatedUser());
        const apiName = APIEndpoints.FEEDS.name;
        const apiPath = APIPaths.REACTIONS_API;
        const req = {source_type,source_id, reaction,"reaction_id": REACTIONS.REACTION_ID}
        const apiOptions = initializeAPIOptions(token, enterprise_id, req);
        let apiResult;
        if(source_type == CONSTANTS.COMMENT) {
          dispatch(setLikeComment(index));
          apiResult = await API.post(apiName, apiPath, apiOptions);
        } else {
          apiResult = await API.post(apiName, apiPath, apiOptions);
        }      
        dispatch(
          performanceActions.log({
            name:"COMMENTS",
            api: "LIKE/DISLIKE COMMENT",
            start,
            apiName,
            apiPath
          })
        );                  
        return apiResult;
      } catch (e) {
        if(source_type ==CONSTANTS.COMMENT) {
          dispatch(setLikeComment(index));
        }           
        throw e;
      }
    };    