import React, { useRef, useEffect, useState } from "react";
import { Typography, Box, Stack, Button } from "@mui/material";
import { useTranslate } from "../../Hooks/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import SidebarHeader from "../SidebarHeader";
import { useStyles } from "./styles";
import { useTheme } from "@mui/styles";
import Mail from "../../Assets/images/mail.png";
import Slack from "../../Assets/images/slack.png";
import Teams from "../../Assets/images/teams.png";
import ShareLink from "../../Assets/images/share-icons/shareLink";
import store from "../../Services/Redux/Store";
import { ANALYTICS_EVENT_SUBTYPE, FEEDTYPES, MAINMENUS } from "../../Utils/Constants";
import { appActions, feedActions } from "../../Services/Redux/actionCreators";
import { FILTER_TYPE } from "../../Utils/Constants";
import UIHelper from "../../Utils/UIHelper";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  EVENT_TYPE,
  ACTION_EVENT_TYPE,
  ACTION_EVENT_SUBTYPE,
} from "../../Utils/Constants";
const conv = UIHelper.convertPxToRem;

const Share = () => {
  const t = useTranslate();
  const linkRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
  const [inititalVal, setInitialVal] = useState(null);
  const shareLink = useSelector((state) => state.feed.share_link);
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  let acc_jtps = useSelector((state) => state.account.jtpData);
  let pf_jtps = useSelector((state) => state.portfolio.jtpData);
  let acc_jtp = useSelector((state) => state.account?.selected_jtp);
  let pf_jtp = useSelector((state) => state.portfolio?.selected_jtp);
  let acc_driver = useSelector((state) => state.account?.selected_op_driver);
  let pf_driver = useSelector((state) => state.portfolio?.selected_op_driver);
  let acc_timestamp = useSelector((state) => state.account?.timestamp);
  let pf_timestamp = useSelector((state) => state.portfolio?.timestamp);
  let app_theme = useSelector((state) => state.app?.theme);
  let main_menu = useSelector((state) => state.app.main_menu);

  let accountName = useSelector((state) => state.account.data?.name);
  let accPortfolioName = useSelector(
    (state) => state.account.data?.portfolio_name
  );
  let accountSFDC = useSelector(
    (state) => state.account.data?.external_account_id
  );

  let portfolioName = useSelector((state) => state.portfolio.data?.name);

  const appliedFilters = useSelector(
    (state) => state.portfolio.applied_filters
  );

  const dispatch = useDispatch();
  const generateShareLink = () => {
    if (selectedFeed) {
      let payload = {};
      let description = "";
      let feedType = selectedFeed.feed_type;
      let data;
      if (feedType === FEEDTYPES.ACCOUNT) {
        data = store.getState().account;
        description = t("account_share_description");
        payload["portfolio_id"] = data?.data?.portfolio_id;
      } else if (feedType === FEEDTYPES.PORTFOLIO) {
        data = store.getState().portfolio;
        description = t("portfolio_share_description");
      }
      if (data?.data) {
        payload["source_id"] = parseInt(data.data.id);
        payload["source_name"] = data?.data?.name.replace(/ /g, "_");
        payload["source_type"] = feedType;
        payload["timestamp"] = data.timestamp;
        payload["jtp_id"] = data.selected_jtp;
        payload["op_id"] = data.selected_op_driver;
        payload["theme"] = app_theme.toUpperCase();
        let selectedKpi;
        if (data.selected_op_driver && data.operationalDrivers) {
          selectedKpi = data.operationalDrivers.find(
            (item) => item.id === data.selected_op_driver
          );
          if (selectedKpi) {
            payload["op_name"] = selectedKpi.display_name;
            payload["op_color"] = selectedKpi.color_code;
            description = t("kpi_share_description");
          }
        }
        payload["description"] = description;
        let filters = formatShareFilters(appliedFilters);
        if (filters) {
          payload["filter"] = filters;
        }
        recordShareEvents(feedType,data,selectedKpi?.display_name)
        dispatch(feedActions.generateShareLink(payload));
      }
    }
  };

  function recordShareEvents(feed_type, data,op_name) {
    let event_types = [];
    let view_names = [];
    let attributes = null;
    let jtps;
    if (feed_type === FEEDTYPES.PORTFOLIO) {
      if (portfolioName === null) return;
      event_types = [ACTION_EVENT_TYPE.PORTFOLIO];
      view_names = [portfolioName];
      jtps=pf_jtps;
    } else {
      if (accountName === null) return;
      event_types = [ACTION_EVENT_TYPE.ACCOUNT];
      view_names = [accountName];
      attributes = [accPortfolioName, accountSFDC];
      jtps=acc_jtps;
    }
    if(data.selected_jtp&&jtps?.length)
    {
    
      let selectedJTP = jtps?.find((item) => item.id === data.selected_jtp);
      if(selectedJTP&&selectedJTP.name!==null)
      {
        event_types.push(ANALYTICS_EVENT_SUBTYPE.JOURNEY);
        view_names.push(selectedJTP.name);
        
      }
      if(data.selected_op_driver&&op_name)
      {
        event_types.push(ANALYTICS_EVENT_SUBTYPE.OPDriverKPIs);
        view_names.push(op_name);
      }
     
    }
   
    event_types.push(ACTION_EVENT_SUBTYPE.SHARE);
    Pinpoint.sendEventData(EVENT_TYPE.ACTION, event_types,view_names,attributes);
  }

  function formatShareFilters(appliedFilters) {
    if (appliedFilters) {
      let filterData = {};
      let sharePayload = [];
      Object.keys(appliedFilters).map((item) => {
        let filterItem = appliedFilters[item];
        if (filterItem.type === FILTER_TYPE.CHECKBOX) {
          Object.keys(filterItem.values).map((subFilterItem) => {
            let option = {};
            option["groupName"] = filterItem.title;
            option["type"] = filterItem.type;
            option["data"] = subFilterItem;
            option["key"] = item;
            option["text"] = filterItem.values[subFilterItem];
            sharePayload.push(option);
          });
        } else if (filterItem.type === FILTER_TYPE.RANGE) {
          let rangeValues = {
            min: filterItem.values[0],
            max: filterItem.values[1],
          };
          let option = {};
          option["groupName"] = filterItem.group_name || "ACV";
          option["type"] = filterItem.type;
          option["data"] = rangeValues;
          option["key"] = item;
          option["text"] = filterItem.title;
          sharePayload.push(option);
        } else if (filterItem.type === FILTER_TYPE.CHECKBOX_PROFILE) {
          filterData[item] = Object.keys(filterItem.values).map((acc_key) => {
            let acc_item = filterItem.values[acc_key];
            if (acc_item?.user) {
              let option = {};
              option["groupName"] = filterItem.title;
              option["type"] = filterItem.type;
              option["key"] = item;
              option["data"] = {
                account_id: acc_item.account_id,
                user: { user_id: acc_item.user.user_id },
              };
              option["text"] = acc_item?.user.display_name;
              sharePayload.push(option);
            }
          });
        }
      });
      return sharePayload;
    }
    return null;
  }
  useEffect(() => {
    generateShareLink();
  }, []);

  useEffect(() => {
    if (!inititalVal) {
      setInitialVal({
        acc_jtp,
        pf_jtp,
        acc_driver,
        pf_driver,
        acc_timestamp,
        pf_timestamp,
      });
      return;
    }

    dispatch(appActions.setRightMenuClose());
    if (!main_menu) dispatch(appActions.setMainMenu(MAINMENUS.FEEDS));
    dispatch(appActions.setLeftMenuOpen());
    dispatch(appActions.setFooterMenu(null));
  }, [
    acc_jtp,
    pf_jtp,
    acc_driver,
    pf_driver,
    acc_timestamp,
    pf_timestamp,
    main_menu,
  ]);

  const getSubject = () => {
    let data, description;
    if (selectedFeed.feed_type === FEEDTYPES.ACCOUNT) {
      data = store.getState().account;
      description = t("account_share_description");
    } else if (selectedFeed.feed_type === FEEDTYPES.PORTFOLIO) {
      data = store.getState().portfolio;
      description = t("portfolio_share_description");
    }
    return [data?.data?.name, description];
  };

  const getMailTo = () => {
    const subject = getSubject();
    return `mailto:?subject=${subject[0]}&body=${subject[1]} ${shareLink?.url}`;
  };

  const copyToClipboard = (e) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(shareLink && shareLink.url)
        .then(function () {
          //toastr can be impletented later
        });
    }
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://teams.microsoft.com/share/launcher.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [shareLink]);

  const buttons = {
    teams: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="teams-share-button"
          data-href={shareLink && shareLink.url}
          style={{ height: "100%", width: "100%", zIndex: 100, opacity: 0 }}
        ></div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          }}
        >
          <img
            src={Teams}
            style={{ width: conv(46), height: conv(46) }}
            alt="teams-icon"
          />
        </div>
      </div>
    ),

    // slack: (
    //   <div>
    //     <img
    //       src={Slack}
    //       style={{ width: conv(46), height: conv(46) }}
    //       alt="slack-icon"
    //     />
    //   </div>
    // ),

    mail: (
      <div>
        <a href={getMailTo()}>
          <img
            src={Mail}
            style={{ width: conv(46), height: conv(46), marginTop: conv(5) }}
            alt="mail-icon"
          />
        </a>
      </div>
    ),
  };

  return (
    <Box
      display={"flex"}
      p={conv(13, 28)}
      flex={1}
      flexDirection={"column"}
      className={classes.container}
    >
      <SidebarHeader title={t("share")} mr={0} mb={"45px"} />
      <Box
        flex="1"
        display="flex"
        flexDirection={"column"}
        style={{ pointerEvents: !shareLink?.url && "none" }}
      >
        <Stack direction="row" justifyContent="left">
          {Object.keys(buttons).map((key) => (
            <Box
              key={key}
              display={"flex"}
              width={conv(80)}
              height={conv(80)}
              mr={conv(50)}
              className={classes.shareItem}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {buttons[key]}
            </Box>
          ))}
        </Stack>

        <Typography
          variant="h6"
          className={classes.copyLink}
          mt={conv(53)}
          mb={conv(26)}
        >
          {t("copy_link")}
        </Typography>

        <Stack
          direction={"row"}
          alignItems="center"
          padding={conv(18, 21)}
          className={classes.linkBorder}
        >
          <Box pr={conv(10)} height={conv(24)} width={conv(24)}>
            <ShareLink color={theme.palette.text.lightblue} />
          </Box>
          <Typography
            variant="p"
            textOverflow={"ellipsis"}
            overflow="hidden"
            whiteSpace={"nowrap"}
            color="text.lightblue"
            ref={linkRef}
          >
            {shareLink ? shareLink.url : t("generating_link")}
          </Typography>
        </Stack>

        <Button
          color="primary"
          className={classes.buttonApply}
          variant="contained"
          children={t("copy_link")}
          onClick={copyToClipboard}
        />
      </Box>
    </Box>
  );
};

export default React.memo(Share);
