import React from "react";
import { UncheckedColor } from ".";

const Share = ({ checked }) => {
  return (
    <svg
      width={window.conv(21)}
      height={window.conv(22)}
      viewBox="0 0 28 29"
      fill={checked ? UncheckedColor : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4204_74290)">
        <path
          d="M5.63851 8.04034H5.72295C3.15829 8.04034 2.33911 10.1198 2.33911 12.6844L2.33911 22.6091C2.33911 25.1725 4.41856 27.252 6.98322 27.252H21.0101C23.5747 27.252 25.6542 25.1725 25.6542 22.6091V12.6718C25.6542 10.1147 24.8413 8.04034 22.2842 8.04034H22.356"
          stroke={UncheckedColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9954 1.99938V15.9141"
          stroke={UncheckedColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3235 5.68945L13.9972 1.99937L17.6721 5.68945"
          stroke={UncheckedColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4204_74290">
          <rect
            width="29"
            height="27"
            fill="white"
            transform="translate(0.339111 29) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Share;
