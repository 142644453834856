import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((theme) => ({

  container: {
    "& .MuiPaper-root": {
      padding: conv(36,0)+" !important",
      borderRadius:conv(12),
      width: conv(600),
      backgroundColor: `${theme.palette.background.alertBoxBg} !important`,

      "& p": {
        color: `${theme.palette.text.lightGrey} !important`,
        fontSize: conv(16),
        lineHeight:conv(22),
        fontWeight: 400,
        textAlign:"center"
      },

      "& button": {
        color: `${theme.palette.background.white} !important`,
        fontSize: "1.125rem !important",
        fontWeight: 500,
      },
    },
 
  },
}));
