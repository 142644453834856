import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((MUITheme) => ({
  tableHeaderBorder: {
    borderBottom: `1.12px solid ${MUITheme.palette.border.tableHeaderBorder}`,
  },
  tableHeader: {
    color: `${MUITheme.palette.text.tableHeader} !important`,
    fontSize: window.conv(13.44) + " !important",
    fontWeight: "600 !important",
    display: "flex",
    textTransform: "uppercase",
    cursor: "pointer",
    paddingBottom: window.conv(14),
    "& svg": {
      color: MUITheme.palette.text.secondary,
      padding: 0,
      fontSize: "1.5rem",
    },
  },
  box: {
    marginBottom:"2px",
    marginTop:"2px",
    "&:hover": {
      backgroundColor: MUITheme.palette.background.tableRowSelected,
      borderRadius: window.conv(20),
      boxShadow: MUITheme.palette.boxShadow.searchBox,
    },
  },

  tableRow: {
    fontSize: window.conv(13.44) + " !important",
   
    alignItems: "center",
    cursor: "pointer",
    paddingTop: window.conv(23),
    paddingBottom: window.conv(25),
    "& h6": {
      fontSize: window.conv(13.44) + " !important",
      lineHeight: window.conv(20),
      fontWeight: 500 + " !important",
    },
    borderBottom: `1.12px solid ${MUITheme.palette.border.tableRowBorder}`,
    "&:hover": {
      borderBottom: "1.12px solid transparent",
    },
  },

  tableRowBackground: {
    background: MUITheme.palette.background.container,
  },
  selectedBox: {
    backgroundColor: MUITheme.palette.background.tableRowSelected,
    borderRadius: window.conv(20),
    boxShadow: MUITheme.palette.boxShadow.searchBox,
  },
  noBorder: {
    borderBottom: "1.12px solid transparent",
  },
}));
