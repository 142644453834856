import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  box: {
    color: MUITheme.palette.text.default,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: conv(21),
    "& h6, span, a ": {
      color: MUITheme.palette.text.default,
      textDecoration: "none",
      fontSize: "1rem",
      lineHeight: conv(24),
      fontWeight: 400,
    },
    "& img": {
      marginRight: conv(15),
    },
  },
  mobileBox: {
    marginTop: conv(5),
    display: "flex",
    minWidth:conv(350),
    background: MUITheme.palette.background.phoneInputBg,
    border: "1px solid "+MUITheme.palette.border.primary,
    borderRadius: conv(12),
    padding:conv(22,14,22,13),
    "& img": {
      width:"100%",
      marginTop:conv(3)
    },
  },
  phoneInput: {
    background: "transparent",
    lineHeight:conv(21),
    fontWeight:400,
    fontSize:conv(14),
    border: "none",
    outline: "none",
    padding: "0px 5px",
    color: MUITheme.palette.text.lightblue,
    fontFamily: "inherit",
    width: "80%",
  },
  pointer: {
    cursor: "pointer",
  },
  editButton: {
    cursor: "pointer",
    width:conv(100),
    color: MUITheme.palette.text.lightblue,
    fontSize:conv(14),
    lineHeight:conv(21),
    fontWeight:400,
    paddingLeft:conv(15),
    display:"flex",
    alignItems:"center"
  },
}));
