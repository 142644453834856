import React, { useState, useEffect } from "react";
import Images from "../../Assets/images/menu-icons";
import MenuButton from "../../Components/MenuButton";
import CommentsView from "../../Components/CommentsView";
import PortfolioFilters from "../../Components/PortfolioFilters";
import AccountTeam from "../../Components/AccountTeam";
import { RIGHTMENUS, FEEDTYPES } from "../../Utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { FILTER_TYPE } from "../../Utils/Constants";
import { useParams } from "react-router-dom";
import {
  accountActions,
  portfolioActions,
  appActions,
} from "../../Services/Redux/actionCreators";
import { commentActions } from "../../Services/Redux/actionCreators";
import Share from "../../Components/Share";
import FollowStatus from "../../Components/FollowStatus";
import AlertSettings from "../../Components/AlertSettings";
import FavoriteStatus from "../../Components/FavoriteStatus";
import AccountList from "../../Components/AccountList";
import Pinpoint from "../../Services/Redux/actionCreators/pinpointActions";
import {
  EVENT_TYPE,
  ACTION_EVENT_TYPE,
  ACTION_EVENT_SUBTYPE,
} from "../../Utils/Constants";

export const useRightBarOptions = () => {
  const classes = useStyles();
  const params = useParams();
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  const { footer_menu } = useSelector((state) => state.app);

  const appliedFilters = useSelector(
    (state) => state.portfolio.applied_filters
  );
  const alertActionConf = useSelector(
    (state) => state.account.alert_action_config
  );

  const portfolioLoaded = useSelector((state) => state.portfolio.loaded);
  const accountLoaded = useSelector((state) => state.account.loaded);

  const pf_alertActionConf = useSelector(
    (state) => state.portfolio.alert_action_config
  );

  const dispatch = useDispatch();

  const portfolioAnalyticsStatus = useSelector(
    (state) => state.portfolio.analyticsStatus
  );

  const portfolioAnalyticsReactions = useSelector(
    (state) => state.portfolio.analyticsReactions
  );
  const portfolioFinValue = useSelector(
    (state) => state.portfolio?.data?.fin_value
  );

  const accountAnalyticsStatus = useSelector(
    (state) => state.account.analyticsStatus
  );

  const accountAnalyticsReactions = useSelector(
    (state) => state.account.analyticsReactions
  );

  const portfolioIsLatest = useSelector(
    (state) => state.portfolio?.data?.is_latest
  );
  const accountIsLatest = useSelector(
    (state) => state.account?.data?.is_latest
  );

  const accountFinValue = useSelector(
    (state) => state.account?.data?.fin_value
  );

  const accountTeam = useSelector(
    (state) => state.app.mapping_data.field_mapping.account_team
  );
  let accountName = useSelector((state) => state.account.data?.name);
  let accPortfolioName= useSelector((state) => state.account.data?.portfolio_name)
  let accountSFDC= useSelector((state) => state.account.data?.external_account_id)

  let portfolioName= useSelector((state) => state.portfolio.data?.name)

  const [data, setData] = useState([]);

  const updateFollowStatus = () => {
    if (selectedFeed) {
      let isFollowed = false;
      switch (selectedFeed.feed_type) {
      case FEEDTYPES.ACCOUNT:
        isFollowed = accountAnalyticsStatus.follow_status;
        dispatch(
          accountActions.setAccountFollow(
            selectedFeed.source_id,
            FEEDTYPES.ACCOUNT,
            !isFollowed,
            selectedFeed.source_name,
            params.portfolio_id
          )
        );
        Pinpoint.sendEventData(EVENT_TYPE.ACTION,[ACTION_EVENT_TYPE.ACCOUNT,!isFollowed?ACTION_EVENT_SUBTYPE.FOLLOW:ACTION_EVENT_SUBTYPE.UNFOLLOW],[accountName],[accPortfolioName,accountSFDC])
        break;
      case FEEDTYPES.PORTFOLIO:
        isFollowed = portfolioAnalyticsStatus.follow_status;
        dispatch(
          portfolioActions.setPortfolioFollow(
            selectedFeed.source_id,
            FEEDTYPES.PORTFOLIO,
            !isFollowed,
            selectedFeed.source_name
          )
        );
        Pinpoint.sendEventData(EVENT_TYPE.ACTION,[ACTION_EVENT_TYPE.PORTFOLIO,!isFollowed?ACTION_EVENT_SUBTYPE.FOLLOW:ACTION_EVENT_SUBTYPE.UNFOLLOW],[portfolioName])
        break;
      }
    }
  };
  const updateFavoriteStatus = () => {
    if (selectedFeed) {
      let isFavorite = false;
      switch (selectedFeed.feed_type) {
      case FEEDTYPES.ACCOUNT:
        isFavorite = accountAnalyticsStatus.favorite_status;

        dispatch(
          accountActions.setAccountFavorite(
            selectedFeed.source_id,
            FEEDTYPES.ACCOUNT,
            !isFavorite,
            selectedFeed.source_name,
            params.portfolio_id
          )
        );
        Pinpoint.sendEventData(EVENT_TYPE.ACTION,[ACTION_EVENT_TYPE.ACCOUNT,!isFavorite?ACTION_EVENT_SUBTYPE.FAVORITE:ACTION_EVENT_SUBTYPE.UNFAVORITE],[accountName],[accPortfolioName,accountSFDC])
        break;
      case FEEDTYPES.PORTFOLIO:
        isFavorite = portfolioAnalyticsStatus.favorite_status;
        dispatch(
          portfolioActions.setPortfolioFavorite(
            selectedFeed.source_id,
            FEEDTYPES.PORTFOLIO,
            !isFavorite,
            selectedFeed.source_name
          )
        );
        Pinpoint.sendEventData(EVENT_TYPE.ACTION,[ACTION_EVENT_TYPE.PORTFOLIO,!isFavorite?ACTION_EVENT_SUBTYPE.FAVORITE:ACTION_EVENT_SUBTYPE.UNFAVORITE],[portfolioName])
        break;
      }
    }
  };

  const onCommentClick = async () => {
    dispatch(commentActions.clearAllData());
    await dispatch(
      commentActions.loadComments(
        selectedFeed.feed_type,
        selectedFeed.source_id,
        0,
        15
      )
    );
  };

  let isFilterApplied = useSelector((state) => state.portfolio.apply_filters);
  function getFilterCount() {
    let selectedFilterCount = 0;
    if (!isFilterApplied) return 0;
    if (appliedFilters) {
      Object.keys(appliedFilters).forEach((element) => {
        if (
          appliedFilters[element].type === FILTER_TYPE.CHECKBOX ||
          appliedFilters[element].type === FILTER_TYPE.CHECKBOX_PROFILE
        ) {
          selectedFilterCount =
            selectedFilterCount +
            Object.keys(appliedFilters[element].values).length;
        } else if (appliedFilters[element].type === FILTER_TYPE.RANGE) {
          selectedFilterCount = selectedFilterCount + 1;
        }
      });
    }
    return selectedFilterCount;
  }
  useEffect(() => {
    let isFollowed = false;
    let isFavorite = false;
    let isAlertSet = false;
    let isCommented = false;
    let showCommentButton = false;
    let disableShare = false;
    if (selectedFeed) {
      let statuses;
      let reactions;
      if (selectedFeed.feed_type === FEEDTYPES.PORTFOLIO) {
        statuses = portfolioAnalyticsStatus;
        let reactions = portfolioAnalyticsReactions;
        if (
          reactions &&
          (reactions.total_comments > 0 || reactions.total_reactions > 0)
        )
          isCommented = true;

        if (pf_alertActionConf) {
          isAlertSet = pf_alertActionConf.alert_status;
        }
        showCommentButton = portfolioIsLatest;
        if (portfolioFinValue===null) disableShare = true;
      } else if (selectedFeed.feed_type === FEEDTYPES.ACCOUNT) {
        statuses = accountAnalyticsStatus;
        let reactions = accountAnalyticsReactions;
        if (
          reactions &&
          (reactions.total_comments > 0 || reactions.total_reactions > 0)
        )
          isCommented = true;
        if (alertActionConf) {
          isAlertSet = alertActionConf.alert_status;
        }
        showCommentButton = accountIsLatest;
        if (accountFinValue===null) disableShare = true;
      }
      if (showCommentButton === false && footer_menu === RIGHTMENUS.COMMENTS) {
        dispatch(appActions.setLeftMenuOpen());
        dispatch(appActions.setRightMenuClose());
      }
      if (statuses) {
        isFollowed = statuses.follow_status;
        isFavorite = statuses.favorite_status;
      }
    }
    const defaultData = [
      {
        name: RIGHTMENUS.FOLLOW,
        icon: (
          <MenuButton
            small
            filled
            checked={isFollowed}
            icon={Images.Follow}
            // onClick={updateFollowStatus}
          />
        ),
        checkedIcon: (
          <MenuButton
            small
            filled
            checked={isFollowed}
            icon={Images.Follow}
            // onClick={updateFollowStatus}
          />
        ),
        component: <FollowStatus onUpdate={updateFollowStatus} />,
        show: true,
      },
      {
        name: RIGHTMENUS.FAVORITES,
        icon: (
          <MenuButton
            small
            filled
            checked={isFavorite}
            icon={Images.Favorites2}
            // onClick={updateFavoriteStatus}
          />
        ),
        checkedIcon: (
          <MenuButton
            small
            filled
            checked={isFavorite}
            icon={Images.Favorites2}
            // onClick={updateFavoriteStatus}
          />
        ),
        component: <FavoriteStatus onUpdate={updateFavoriteStatus} />,
        show: true,
      },
      {
        name: RIGHTMENUS.COMMENTS,
        icon: (
          <MenuButton
            small
            filled
            checked={isCommented}
            icon={Images.Comments}
            onClick={onCommentClick}
          />
        ),
        checkedIcon: (
          <MenuButton
            small
            filled
            checked
            icon={Images.Comments}
            onClick={onCommentClick}
          />
        ),
        component: <CommentsView />,
        show: showCommentButton,
      },
      {
        name: RIGHTMENUS.ALERTS,
        icon: (
          <MenuButton small filled checked={isAlertSet} icon={Images.Alerts} />
        ),
        checkedIcon: (
          <MenuButton small filled checked={isAlertSet} icon={Images.Alerts} />
        ),
        component: <AlertSettings />,
        disabled: !isAlertSet,
        show: true,
      },
      {
        name: RIGHTMENUS.SHARE,
        icon: <MenuButton small filled icon={Images.Share} />,
        checkedIcon: <MenuButton small filled icon={Images.Share} />,
        disabled: disableShare,
        component: <Share />,
        show: true,
      },
      {
        name: RIGHTMENUS.FILTER,
        icon: (
          <MenuButton
            small
            filled
            icon={Images.Filters}
            badge={getFilterCount()}
          />
        ),
        checkedIcon: (
          <MenuButton
            small
            filled
            checked
            icon={Images.Filters}
            badge={getFilterCount()}
          />
        ),
        component: <PortfolioFilters />,
        show: selectedFeed
          ? selectedFeed.feed_type === FEEDTYPES.PORTFOLIO
          : true,
      },
      {
        name: RIGHTMENUS.TEAM,
        icon: <MenuButton small filled icon={Images.Filters} />,
        checkedIcon: <MenuButton small filled checked icon={Images.Filters} />,
        hideIcon: true,
        component: <AccountTeam />,
        show:
          accountTeam?.is_enabled &&
          (selectedFeed ? selectedFeed.feed_type === FEEDTYPES.ACCOUNT : true),
      },
      {
        name: RIGHTMENUS.ACCOUNTS,
        icon: <MenuButton small filled icon={Images.Filters} />,
        checkedIcon: <MenuButton small filled checked icon={Images.Filters} />,
        hideIcon: true,
        component: (
          <AccountList
            type="portfolio_accounts"
            source_id={selectedFeed?.source_id}
            name={selectedFeed?.source_name}
          />
        ),
        show: true,
      },
    ];
    setData(defaultData);
  }, [
    alertActionConf,
    portfolioAnalyticsStatus,
    portfolioAnalyticsReactions,
    accountAnalyticsReactions,
    accountAnalyticsStatus,
    selectedFeed,
    isFilterApplied,
    appliedFilters,
    portfolioIsLatest,
    accountIsLatest,
    footer_menu,
    accountTeam,
    portfolioFinValue,
    accountFinValue,
  ]);
  if (
    selectedFeed &&
    ((selectedFeed.feed_type === FEEDTYPES.PORTFOLIO && portfolioLoaded) ||
      (selectedFeed.feed_type === FEEDTYPES.ACCOUNT && accountLoaded))
  )
    return data;
  return [];
};
