import { userActions } from "../actionCreators";

const initialState = {
  entity: "",
  isInitialized: false,
  isLoggedIn: false,
  email: undefined,
  isEmailVerified: false,
  jwt: {
    exp: "",
  },
  user:null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
  case userActions.loginActions.SET_USER_LOGGED_IN: {
    return { ...state, isLoggedIn: action.payload };
  }
  case userActions.loginActions.SET_USER_EMAIL: {
    return { ...state, email: action.payload };
  }
  case userActions.loginActions.SET_APP_INITIALIZED: {
    return { ...state, isInitialized: action.payload };
  }
  case userActions.loginActions.RESET_USER_ON_SIGNOUT: {
    return { ...initialState, isInitialized: true };
  }
  case userActions.loginActions.SET_JWT_EXP: {
    return { ...state, jwt: { ...state.jwt, exp: action.payload } };
  }

  case userActions.loginActions.SET_USER_ATTRIBUTES: {
    return { ...state, ...action.payload };
  }
  case userActions.loginActions.SET_USER_DATA: {
    return { ...state, user:action.payload };
  }
  case userActions.loginActions.UPDATE_USER_DATA: {
    return { ...state, user:{...state.user,...action.payload} };
  }
  default:
    return state;
  }
};

export default userReducer;
