export const ADD_PERFORMANCE_DATA = "ADD_PERFORMANCE_DATA";
export const CLEAR_PERFORMANCE_DATA = "CLEAR_PERFORMANCE_DATA";
import moment from "moment";

export const log = (data) => {
  const { name, api } = data;
  let start = moment(data.start);
  let end = moment(data.end || new Date());
  let diff;
  if (start && end) diff = end.diff(start, "milliseconds");
  return {
    type: ADD_PERFORMANCE_DATA,
    payload: {
      name,
      api,
      start_time: start.format("DD/MM/YY HH:mm:ss.SSS"),
      end_time: end.format("DD/MM/YY HH:mm:ss.SSS"),
      time_taken: diff && diff / 1000,
      ...data,
    },
  };
};

export const clearData = () => {
  return { type: CLEAR_PERFORMANCE_DATA };
};
