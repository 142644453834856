import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    background: theme.palette.background.alertContainerBg,
    backdropFilter: "blur(6px)",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  box:{
    background: theme.palette.background.alertBoxBg,
    display:"flex",
    flexDirection:"column",
    flex:"1",
    alignItems:"center",
    justifyContent:"center",
    textAlign:"center",
    margin:window.conv(40),
    padding:window.conv(25),
    borderRadius:window.conv(14)
  },
  iconBox:{
    alignItems:"center",
    justifyContent:"center",
    textAlign:"center",
    background:theme.palette.background.deleteIconBg,
    padding:window.conv(36,40),
    borderRadius:"50%",
    "& img":{
      width:"100%"
    }
  },

  transparentBtn: {
    lineHeight: "1.687rem !important",
    fontSize: "1.125rem !important",
    textAlign: "left",
    background: "transparent !important",
    boxShadow: "none !important",
    justifyContent: "left !important",
    fontWeight: 300 +"!important",
    paddingBottom: "0px !important",
    color:theme.palette.text.white +" !important",
    "&:hover": {
      boxShadow: "none !important",
    },
  },
  transparentCancelBtn: {
    justifyContent: "right !important",
    fontWeight: 600 + "!important",
    color:theme.palette.text.white +" !important",
  },
}));
