import React, { useCallback, useEffect, useState } from "react";
import { Typography, Box, Stack } from "@mui/material";
import InfinitePaginatedScroll from "../Shared/InfinitePaginatedScroll";
import { useTranslate } from "../../Hooks/useTranslate";
import SearchInput from "../Shared/SearchInput";
import { PAGE_SIZE } from "../../Utils/Constants";
import { customListActions } from "../../Services/Redux/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import LoadingView from "../LoadingView";
import NoDataView from "../NoDataView";
import CircularProgress from "../Shared/CircularProgress";
import { useNavigate } from "react-router-dom";
import ActionItem from "./ActionItem";
const ActionList = (props) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const listData = useSelector(
    (state) => state.customListData.customListData[props.type]
  );
  const listMetaData = useSelector(
    (state) => state.customListData.customListMetaData[props.type]
  );
  const searchText = useSelector(
    (state) => state.customListData.searchData[props.type]
  );
  // let page = 0;
  // if (listMetaData?.page_number) page = listMetaData.page_number;
 
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);

  const [query, setQuery] = useState(null);
  const [selectedId, setSelectedId] = useState("");

  const loadFirstPage=async(page)=>{
    if (page === 0) {
      setLoading(true)
      await loadActions(1);
      setLoading(false)
    }
  }
  
  const loadActions = async function (page) {   
    setListLoading(true);
    await dispatch(
      customListActions.loadData(props.type, page, PAGE_SIZE, "", "", query)
    );
    setListLoading(false);
  };

  useEffect(()=>{
    if(!listMetaData)
      loadFirstPage(0)
  },[listMetaData])

  useEffect(()=>{
    if(searchText)
    {
      loadFirstPage(0);
      dispatch(customListActions.setSearchText("", props.type));
    }
  },[])

  useEffect(() => {   
    if(query!=null)
      loadFirstPage(0);
  }, [query]);

  const onSearchData = (q) => {
    dispatch(customListActions.setSearchText(q, props.type));
    setQuery(q);
  };
  const onClearSearch = (q) => {
    dispatch(customListActions.setSearchText("", props.type));
    setQuery("");
  };

  const handleNext = () => {
    if (listMetaData && listMetaData.page_number < listMetaData.page_count) {
      loadActions(listMetaData.page_number + 1);
    }
  };

  const navigateOnClick = async (data) => {
    let basepath = "action";
    setSelectedId(data.id);
    let navPath = `${basepath}/${data.id}`;
    navigate(navPath);
  };

  const getView = () => {
    let component = <></>;
    if (loading)
      component = (
        <Box
          height={"100%"}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <LoadingView />
        </Box>
      );
    else if (!loading && (listData && listData.length === 0)||!listMetaData) {
      component = (
        <Box
          height={"100%"}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <NoDataView />
        </Box>
      );
    } else
      component = (
        <InfinitePaginatedScroll
          totalRecords={listMetaData.total_records}
          onNext={handleNext}
          loader={<CircularProgress show={listLoading} />}
        >
          {listData &&
            listData.map((item, index) => {
              return (
                <ActionItem
                  key={index}
                  data={item}
                  onClick={navigateOnClick}
                  selected={selectedId === item.id}
                />
              );
            })}
        </InfinitePaginatedScroll>
      );
    return component;
  };

  return (
    <>
      <Box ml={window.conv(23)} mr={window.conv(12)}>
        <Typography variant="h4" color="text.heading" mt={window.conv(45)}>
          {t(props.type + "s")}
        </Typography>
        <Box mt={window.conv(26)} mb={window.conv(45)} mr={window.conv(24)}>
          <SearchInput  disabled={!listMetaData}
            onKeyUp={onSearchData}
            onClear={onClearSearch}
            placeholder={t("search_by_name")}
          />
        </Box>
        <Box height={`calc(100vh - ${window.conv(310)})`}>{getView()}</Box>
      </Box>
    </>
  );
};

export default React.memo(ActionList);
