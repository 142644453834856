import React from "react";
import PlayStoreLogo from "../../Assets/images/playstore.png";
import AppStoreLogo from "../../Assets/images/appstore.png";
import { useStyles } from "./styles";

const DownloadButton = ({ os }) => {
  const classes = useStyles();
  const data = {
    androidOS: {
      image: (
        <img
          className={classes.storeLogo}
          src={PlayStoreLogo}
          alt="Download Spectrum AI"
        />
      ),
      link: process.env.REACT_APP_PLAYSTORE_URL,
    },
    iOS: {
      image: (
        <img
          className={classes.storeLogo}
          src={AppStoreLogo}
          alt="Download Spectrum AI"
        />
      ),
      link: process.env.REACT_APP_APPSTORE_URL,
    },
  };

  const currentOS = data[os];
  if (!currentOS) return os;
  return <a href={currentOS.link}>{currentOS.image}</a>;
};

export default DownloadButton;
DownloadButton.defaultProps = {
  os: "androidOS",
};
