import React from "react";
const FavNoData = () => {
  return (
    <svg
      width="60"
      height="58"
      viewBox="0 0 60 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.301 6.4752C23.4845 3.50826 27.5155 3.50827 28.699 6.47521L32.0186 14.7972C32.5176 16.048 33.6457 16.9026 34.9408 17.0108L43.5573 17.7311C46.6293 17.9879 47.8749 21.9852 45.5344 24.0756L38.9695 29.9392C37.9828 30.8204 37.5519 32.2032 37.8533 33.5209L39.859 42.2881C40.5741 45.4137 37.313 47.8841 34.6829 46.2092L27.306 41.5111C26.1972 40.8049 24.8028 40.8049 23.694 41.5111L16.3171 46.2092C13.687 47.8841 10.4259 45.4137 11.141 42.2881L13.1467 33.5209C13.4481 32.2032 13.0172 30.8204 12.0305 29.9392L5.46561 24.0756C3.1251 21.9852 4.37074 17.9879 7.4427 17.7311L16.0593 17.0108C17.3543 16.9026 18.4824 16.048 18.9814 14.7972L22.301 6.4752Z"
        stroke="#4B545B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle
        cx="43"
        cy="41"
        r="13"
        transform="rotate(-180 43 41)"
        fill="#171F26"
      />
      <path
        d="M56.7625 41C56.7625 48.5884 50.5884 54.7625 43 54.7625C35.4116 54.7625 29.2375 48.5884 29.2375 41C29.2375 33.4116 35.4117 27.2375 43 27.2375C50.5884 27.2375 56.7625 33.4116 56.7625 41ZM31.2625 41C31.2625 47.4939 36.5061 52.7375 43 52.7375C49.4939 52.7375 54.7375 47.4939 54.7375 41C54.7375 34.5061 49.4939 29.2625 43 29.2625C36.5061 29.2625 31.2625 34.5061 31.2625 41ZM41.9875 45.3L44.0125 45.3L44.0125 47.325L41.9875 47.325L41.9875 45.3ZM41.9875 34.675L44.0125 34.675L44.0125 43.075L41.9875 43.075L41.9875 34.675Z"
        fill="#4B545B"
        stroke="#171F26"
        strokeWidth="0.1"
      />
    </svg>
  );
};
export default FavNoData;
