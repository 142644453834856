import React from "react";
import { useSelector } from "react-redux";
import Tag from "../Tag";
import { useTheme } from "@mui/styles";
import { COLOR_CODES } from "../../Utils/Constants";
import { Typography } from "@mui/material";
import { useTranslate } from "../../Hooks/useTranslate";

const CustomerMarketFactors = ({ resource, data }) => {
  const theme = useTheme();
  const t = useTranslate();
  const is_enabled = useSelector(
    (state) =>
      state.app.mapping_data.field_mapping.customer_market_factor.is_enabled
  );
  if (!is_enabled || data?.length == 0) return;

  return (
    <>
      <Typography variant="h5" color="text.subheading">
        {t("customer_market_factors")}
      </Typography>
      <Tag rows={2}
        marginTop={"10px"}
        data={data?.map((item) => {
          let fItem = resource?.find((res) => res.name === item);

          let color =
            !fItem || !fItem.color_flag || fItem.color_flag === COLOR_CODES.GRAY
              ? theme.palette.text.default
              : fItem.color_code;
          return {
            text: item,
            color,
          };
        })}
      />
    </>
  );
};

// const resource = [
//   {
//     name: "Competitive Position",
//     color_flag: "GREEN",
//     color_code: "#71C782",
//   },
//   {
//     name: "Price Position",
//     color_flag: null,
//     color_code: "#80878F",
//   },
//   {
//     name: "Product Fit",
//     color_flag: "GREEN",
//     color_code: "#71C782",
//   },
//   {
//     name: "Sponsorship",
//     color_flag: null,
//     color_code: "#80878F",
//   },
//   {
//     name: "Switching Costs",
//     color_flag: "RED",
//     color_code: "#F45252",
//   },
//   {
//     name: "Competitive Position",
//     color_flag: "GREEN",
//     color_code: "#71C782",
//   },
//   {
//     name: "Price Position",
//     color_flag: null,
//     color_code: "#80878F",
//   },
//   {
//     name: "Product Fit",
//     color_flag: "GREEN",
//     color_code: "#71C782",
//   },
//   {
//     name: "Sponsorship",
//     color_flag: null,
//     color_code: "#80878F",
//   },
//   {
//     name: "Switching Costs",
//     color_flag: "RED",
//     color_code: "#F45252",
//   },
//   {
//     name: "Competitive Position",
//     color_flag: "GREEN",
//     color_code: "#71C782",
//   },
//   {
//     name: "Price Position",
//     color_flag: null,
//     color_code: "#80878F",
//   },
//   {
//     name: "Product Fit",
//     color_flag: "GREEN",
//     color_code: "#71C782",
//   },
//   {
//     name: "Sponsorship",
//     color_flag: null,
//     color_code: "#80878F",
//   },
//   {
//     name: "Switching Costs",
//     color_flag: "RED",
//     color_code: "#F45252",
//   },
// ];
// const data = [
//   "Competitive Position",
//   "Price Position",
//   "Product Fit",
//   "Sponsorship",
//   "Switching Costs",
//   "Competitive Position",
//   "Price Position",
//   "Product Fit",
//   "Sponsorship",
//   "Switching Costs",
//   "Competitive Position",
//   "Price Position",
//   "Product Fit",
//   "Sponsorship",
//   "Switching Costs",
// ];

export default CustomerMarketFactors;
