import { makeStyles } from "@mui/styles";
import Background from "../../../Assets/images/web-bg.png";
import UIHelper from "../../../Utils/UIHelper";
const conv=UIHelper.convertPxToRem;
export const useStyles = makeStyles((MUITheme) => ({
  container: {
    backgroundColor: MUITheme.palette.background.mainContent,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    backgroundSize: "75%",
  },
  logoContainer: {
    marginTop:conv(143),
    marginBottom:conv(288),
    textAlign:"center"
    // display:"flex",
    // alignItems:"center",
    // justifyContent:"center",
    // textAlign:"center",
    // height:conv(465)
  },
  footerContainer: {
    margin: conv(26,0),
  },
  children: {
    flex: 1,
  },
}));
