import { performanceActions } from "../actionCreators";

const initialState = {
  data: [],
};

const performaceReducer = (state = initialState, action) => {
  switch (action.type) {
  case performanceActions.ADD_PERFORMANCE_DATA: {
   
    let data = [...state.data];
    let apiName = action.payload.api;

    let ind = data.findIndex((item) => item.api == apiName);
    if (ind > -1) {
      data[ind] = action.payload;
    } else data.push(action.payload);
    return { ...state, data };
  }
  case performanceActions.CLEAR_PERFORMANCE_DATA:
    return initialState;
  default:
    return state;
  }
};

export default performaceReducer;
