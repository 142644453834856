import React from "react";
import { Provider as ReactReduxProvider } from "react-redux";
import { Amplify } from "aws-amplify";
import store from "./Services/Redux/Store";
import GlobalRouter from "./GlobalRouter";
import GlobalLoader from "./Components/GlobalLoader";
import ThemeProvider from "./Components/ThemeProvider";
import MobileView from "./Components/MobileView";
import DefaultHelmet from "./Services/Helmet";
import { HelmetProvider } from "react-helmet-async";
import { aws_config } from "./Services/AWS/aws_config";
import ErrorBoundary from "./ErrorBoundary";
import NoInternet from "./Components/NoInternet";

Amplify.configure(aws_config);
function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <ReactReduxProvider store={store}>
          <ThemeProvider>
            <MobileView>
              <NoInternet/>
              <DefaultHelmet />
              <GlobalRouter />
              <GlobalLoader />
            </MobileView>
          </ThemeProvider>
        </ReactReduxProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;
