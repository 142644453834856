import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslate } from "../../Hooks/useTranslate";
import Button from "../Shared/Button";
import LoadingView from "../LoadingView";
import { FEEDTYPES } from "../../Utils/Constants";
import SidebarHeader from "../SidebarHeader";
import UIHelper from "../../Utils/UIHelper";
import CloseButton from "../CloseButton";
import {appActions} from "../../Services/Redux/actionCreators"
const conv = UIHelper.convertPxToRem;

function FollowStatus({ onUpdate }) {
  const classes = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [isFollow, setFollow] = useState(false);
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  const selectedAccount = useSelector((state) => state.account.data);
  const selectedPortfolio = useSelector((state) => state.portfolio.data);

  const portfolioAnalyticsStatus = useSelector(
    (state) => state.portfolio.analyticsStatus
  );

  const accountAnalyticsStatus = useSelector(
    (state) => state.account.analyticsStatus
  );
  const updateFollowStatus = () => {
    onUpdate && onUpdate();
  };

  useEffect(() => {
    if (selectedFeed) {
      let statuses;
      if (selectedFeed.feed_type === FEEDTYPES.PORTFOLIO && selectedPortfolio) {
        statuses = portfolioAnalyticsStatus;
        setName(selectedPortfolio.name);
      } else if (
        selectedFeed.feed_type === FEEDTYPES.ACCOUNT &&
        selectedAccount
      ) {
        statuses = accountAnalyticsStatus;
        setName(selectedAccount.name);
      }
      if (statuses) {
        setFollow(statuses.follow_status);
      }
    }
  }, [
    selectedFeed,
    portfolioAnalyticsStatus?.follow_status,
    accountAnalyticsStatus?.follow_status,
  ]);

  if (loading) {
    return <LoadingView mt={"20%"} />;
  }
  
  return (
    <Box display={"flex"} p={conv(13, 28)} flex={1} flexDirection={"column"}>
      <Box display="flex" flex={1} flexDirection="column">
        <SidebarHeader
          title={t("title_follow")}
          mb={conv(29)}
        />

        {isFollow ? (
          <Typography variant="body2">
            {t("you_have_added")}{" "}
            <Typography variant="body2" fontWeight={700} component="span">
              {name}{" "}
            </Typography>{" "}
            {t("follow_ur_feed")}
          </Typography>
        ) : (
          <Typography variant="body2">
            {t("ur_not_following")}&nbsp;
            <Typography variant="body2" fontWeight={700} component="span">
              {name}
            </Typography>
            &nbsp;.
          </Typography>
        )}
      </Box>

      <Box m={conv(39, 42)}>
        <Button
          color="primary"
          className={classes.buttonApply}
          variant="contained"
          disableRipple
          onClick={updateFollowStatus}
          children={isFollow ? t("title_unfollow") : t("title_follow")}
          type="submit"
        />
      </Box>
    </Box>
  );
}

export default React.memo(FollowStatus);
