import React from "react";

const ClearInput = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4631_27249)">
        <path d="M10.4277 1L0.999645 10.4281" stroke="#767E87" strokeWidth="2" strokeLinecap="round"/>
        <path d="M1.26172 1L10.6898 10.4281" stroke="#767E87" strokeWidth="2" strokeLinecap="round"/>
      </g>
      <defs>
        <clipPath id="clip0_4631_27249">
          <rect width="12" height="11.2" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClearInput;
