import {favoriteActions } from "../actionCreators";

const initialState = {
  data: null,
  selected:null,
  show_delete_modal: false,
  search_text:null
};

const favoriteReducer  = (state = initialState, action) => {
  switch (action.type) { 

  case favoriteActions.SET_FAVORITES: {
    let new_data = null;
    if (action.payload) {
      if (action.payload.meta.offset === 0) {
        new_data = {
          items: action.payload.items,
          meta: action.payload.meta,
        };
      } else
        new_data = {
          items: [...state.data.items, ...action.payload.items],
          meta: action.payload.meta,
        };
    }

    return {
      ...state,
      data: new_data,
    };
  }

  case favoriteActions.CLEAR_FAVORITES:
    return { ...state, data: null };
  case favoriteActions.SET_FAV_SEARCH_TEXT:
    return { ...state, search_text: action.payload };

  case favoriteActions.SHOW_DELETE_DIALOG:
    return { ...state, selected: action.payload, show_delete_modal: true };
  case favoriteActions.CLOSE_DELETE_DIALOG:
    return { ...state, show_delete_modal: false };
  
  case favoriteActions.DELETE_FAVORITE: {
    let list = [...state.data.items];
    let ind = list.findIndex((item) => (item.id === action.payload.sourceId && item.portfolio_id === action.payload.portfolioId));
    list.splice(ind, 1);
    return {
      ...state,
      data: { ...state.data, items: list },
      selected: null,
      show_delete_modal: false,
    };
  }
  case favoriteActions.CLEAR_FAVORITE_DATA:{
    return initialState;
  }

  default:
    return state;
  }
};

export default favoriteReducer;

