import React from "react";
import { useSelector } from "react-redux";

const SFDCLabel = ({ value }) => {
  const sfdc = useSelector(
    state => state.app.mapping_data.field_mapping.external_account_id
  );
  if(sfdc&&sfdc.is_enabled)
    return `${sfdc.display_value}: ${value}`;
};

export default SFDCLabel;
