import MuiButton from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import UIHelper from "../../../Utils/UIHelper";
const conv=UIHelper.convertPxToRem;
const Button = withStyles(MUITheme => ({
  root: props => {
    const rootStyles = {
      fontWeight: 600 +"!important",
      fontSize: conv(14),
      letterSpacing: 1.25,
      padding: conv(16,0)+" !important",
      lineHeight: conv(21),
      color: MUITheme.palette.text.white,
      backgroundColor: MUITheme.palette.primary.main,
      boxShadow:"none !important",
      width: "100%",
      textTransform: "none !important",
      borderRadius: conv(12)+" !important",
      "&:hover": {
        backgroundColor: MUITheme.palette.secondary.main,
      },
    };
    return rootStyles;
  },
  containedSecondary: { color: MUITheme.palette.text.white },
}))(MuiButton);

export default Button;
