import { makeStyles } from "@mui/styles";
import UIHelper from "../../Utils/UIHelper";
const conv=UIHelper.convertPxToRem;
export const useStyles = makeStyles(MUITheme => ({
  box: {
    padding: `${conv(10,20)} !important`,
  },
  sideBar: {
    backgroundColor: MUITheme.palette.background.secondary,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingTop: conv(42),//"15px",
    width: conv(79),   
  },
  sideBarPanel: {
    transition: "width ease-in-out .2s",
    backgroundColor: MUITheme.palette.background.mainContent,
    // overflowX:"auto"
  },
  sideBarExpanded: {
    width: conv(418),
    display: "flex",
    flexDirection: "column"
  },
  sideBarCollapsed: {
    width: "0px",
  },

  userIcon: {
    border: `2px solid ${MUITheme.palette.background.white} !important`,
  },
  badge: {
    color: MUITheme.palette.text.white,
    "& .BaseBadge-badge": {
      backgroundColor: MUITheme.palette.background.red,
    },
  },
  arrowIcon: {
    margin: conv(10)//"10px",
  },
}));
