import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { useStyles } from "./styles";

const Loader = ({ isLoading, title, content }) => {
  const classes = useStyles();

  return (
    <Modal disablebackdropclick="true" open={isLoading}>
      <Card className={classes.card}>
        <CardHeader title={<Typography variant="h2">{title}</Typography>} />
        <div className={classes.circularProgressContainer}>
          <CircularProgress className={classes.circularProgress} />
        </div>
        <CardContent>
          <Typography variant="body2" component="p">
            {content}
          </Typography>
        </CardContent>
      </Card>
    </Modal>
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default Loader;
