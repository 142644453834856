import React, {  forwardRef } from "react";
const Marker = forwardRef((props, ref) => {
  const {
    currentItem,
    position,
    style,
    onMouseDown,
    onMouseUp,
    border
  } = props;

  return (
    <div
      ref={ref}
      style={{
        borderRadius: "50%",
        position: "absolute",
        background: currentItem ? currentItem.color : "orange",
        cursor: "pointer",
        left: position + "%",
        textAlign: "center",
        border:border,
        ...style,
      }}
      onMouseDown={onMouseDown && onMouseDown}
      onMouseUp={onMouseUp && onMouseUp}
    ></div>
  );
});
export default Marker;