import React from "react";
import { UncheckedColor } from ".";
import { useTheme } from "@mui/styles";

const Follow = ({ checked }) => {
  const theme=useTheme();
  return (
    <svg
      width={window.conv(25)}
      height={window.conv(26)}
      viewBox="0 0 28 29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4204_74296)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3471 1.99805H8.53657C4.41967 1.99805 1.83911 4.91191 1.83911 9.03687V20.1647C1.83911 24.2897 4.40767 27.2035 8.53657 27.2035H20.3471C24.476 27.2035 27.0446 24.2897 27.0446 20.1647V9.03687C27.0446 4.91191 24.476 1.99805 20.3471 1.99805Z"
          fill={checked ? UncheckedColor : "none"}
          stroke={UncheckedColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4412 9.97266V19.2059V9.97266Z"
          fill={checked ? UncheckedColor : "none"}
        />
        <path
          d="M14.4412 9.97266V19.2059"
          stroke={checked ? theme.palette.background.iconFill : UncheckedColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0655 14.5879H9.82349H19.0655Z"
          fill={checked ? UncheckedColor : "none"}
        />
        <path
          d="M19.0655 14.5879H9.82349"
          stroke={checked ? theme.palette.background.iconFill : UncheckedColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4204_74296">
          <rect
            width="27"
            height="28"
            fill="white"
            transform="translate(0.839111 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Follow;
