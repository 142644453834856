import React from "react";
const Unread = ({ color }) => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 10V14.3117L23 12.9626V11.3613L21.8685 10H18.6077L16.0213 8.25543C16.3106 8.09277 16.6445 8 17 8H20.2062L17.7127 5H16V3H18.1818H18.651L18.9509 3.3608L22.8068 8H23C24.1046 8 25 8.89543 25 10ZM17.2037 16L20.1688 18H17C15.8954 18 15 17.1046 15 16V14.5136L17 15.8626V16H17.2037ZM13 13.1646V16C13 17.1046 12.1046 18 11 18H5C3.89543 18 3 17.1046 3 16V10C3 8.89543 3.89543 8 5 8H5.19318L5.24707 7.93517L8.30831 10H6.13149L5 11.3613L5 16H11V11.8156L13 13.1646ZM12 5H11.1949L8.94696 3.48374L9.04914 3.3608L9.34903 3H9.81818H12V5Z"
        fill={color}
      />
      <line
        x1="0.663197"
        y1="1.47174"
        x2="26.4568"
        y2="18.8697"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
export default Unread;
