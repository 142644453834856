import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import TextField from "../../Shared/TextField";
import { useTheme } from "@mui/styles";

const NumberInput = (props) => {
  const theme=useTheme();
  const { name, defaultValue, value, onChange } = props;
  const handleChange = (e) => {
    var regex= /^\d*[.]?\d*$/;
    if(regex.test( e.target.value))
    {
      const onlyNums = e.target.value;
      onChange && onChange({target:{name,value:onlyNums}});
    }
  };

  const handleClearClick = () => {
    handleChange({ target: { name: name, value: defaultValue } });
  };
  return (
    <TextField
      onChange={handleChange}
      variant="outlined"
      defaultValue={defaultValue}
      value={value}
      name={name}
      border="0px !important"
      autoComplete="off"
      InputProps={{
        endAdornment: (
          <IconButton
            sx={{ visibility: value !== defaultValue ? "visible" : "hidden" }}
            onClick={handleClearClick}
          >
            <Close color="text.primary" />
          </IconButton>
        ),
      }}
      sx={{
        "& .MuiIconButton-root": { color: "text.primary" },
        "& .MuiOutlinedInput-root": {
          border:"0px !important",
          paddingRight:0+" !important"
        },
        "& .MuiOutlinedInput-input":{
          padding:window.conv(29,18),
          paddingRight:0+" !important",
          color:theme.palette.text.secondary+" !important",
          background:theme.palette.background.mainContent+" !important"
        },
        "& .MuiInputBase-root":{
          background:theme.palette.background.mainContent+" !important"
        }
      }}
    />
  );
};
export default NumberInput;
