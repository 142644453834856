import { combineReducers } from "redux";

import userReducer from "./userReducer";
import loaderReducer from "./loaderReducer";
import feedReducer from "./feedReducer";
import appReducer from "./appReducer";
import portfolioReducer from "./portfolioReducer";
import accountReducer from "./accountReducer";
import commentReducer from "./commentReducer";
import customListReducer from "./customListReducer";
import alertReducer from "./alertReducer";
import favoriteReducer from "./favoriteReducer";
import scorecardReducer from "./scorecardReducer";
import performaceReducer from "./performanceReducer";

const rootReducer = combineReducers({
  user: userReducer,
  loader: loaderReducer,
  feed: feedReducer,
  app: appReducer,
  portfolio:portfolioReducer,
  account:accountReducer,
  comments: commentReducer,
  customListData: customListReducer,
  alerts: alertReducer,
  favorites: favoriteReducer,
  scorecard: scorecardReducer,
  performance:performaceReducer
});

export default rootReducer;
