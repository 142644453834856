
import React, { Component } from "react";
import { ListItem, Avatar, Typography, Stack } from "@mui/material";
import { MentionsInput, Mention } from "react-mentions";
import "./mention-style.css";
import CircularProgress from "../../Shared/CircularProgress";
import { API } from "aws-amplify";
import { APIEndpoints, APIPaths } from "../../../Services/AWS/APIEndpoints";
import { initializeAPIOptions } from "../../../Utils/API";
import { getImageUrl } from "../../../Utils/ImageHelper";
import InfiniteScroll from "react-infinite-scroll-component";
import { withTranslation } from "react-i18next";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;

let container;
class MentionTextArea extends Component {
  constructor(props) {
    super();
    this.textInput = React.createRef();
    let selectedUserObj = {}
    if(props.replyUser.id) {
      selectedUserObj[props.replyUser.id] = props.replyUser.display;
    }
    this.state = {
      name: "React",
      // value: (props.replyUser.id ? `{${props.replyUser.id}} ` : ""),
      mentionData: null,
      users: (props.replyUser ? [props.replyUser] : []),
      hasMore: true,
      loading: true,
      pageNumber: 1,
      callback: null,
      query: "",
      isReply: (props.replyUser.id ? true : false),
      replyNameLength: (props.replyUser.id ? props.replyUser.display.length : 0),
      selectedUserObj: selectedUserObj
    };
  } 

  handleChange = (event, newValue, newPlainTextValue, mentions) => {  
    let selectedUserObj = {}
    console.log(this.state.selectedUserObj);
    if(mentions[mentions.length-1] && !selectedUserObj[mentions[mentions.length-1].id]) {
      if(mentions[mentions.length-1].display.trim().length>0) selectedUserObj[mentions[mentions.length-1].id] = mentions[mentions.length-1].display;
    }

    this.setState({
      mentionData: {newValue, newPlainTextValue, mentions},
      selectedUserObj: {...this.state.selectedUserObj, ...selectedUserObj}
    });
    this.props?.onValueChange(newValue);
    this.props.setComment(newValue);
    this.props.setMentionedUsers(mentions);
    if(this.state.isReply && this.state.users[0] && newPlainTextValue.length<=this.state.users[0].display.length) {
      this.props.setComment("");
    }
  }

  fetchUsers = async (query, callback, paginate = false) => {
    if (!query || query.length < 4) return
    this.setState({callback: callback, query: query, loading: true});
    const pageNumber = paginate ? this.state.pageNumber : 1;
    this.setState({pageNumber: pageNumber});
    const apiName = APIEndpoints.USER.name;
    const apiPath = APIPaths.MENTIONS_API(query, pageNumber, 15);
    const apiOptions = initializeAPIOptions(this.props.token, this.props.enterprise_id);
    API.get(apiName, apiPath, apiOptions)
      .then(res => {
        this.setState({loading: false});
        if(!paginate) {
          const firstPageUsers = res.data.items.map(user => ({ display: user.display_name, id: user.user_id }));
          this.setState({users: firstPageUsers});
          return firstPageUsers;
        } else {
          const paginatedUsers = [...this.state.users, ...res.data.items.map(user => ({ display: user.display_name, id: user.user_id }))];
          this.setState({users: paginatedUsers});
          return paginatedUsers;
        }
      })
      .then(callback)    
  }

  fetchUsersPagination(query, callback) {
    const pageNUm = this.state.pageNumber+1;
    this.setState({pageNumber: pageNUm}, () => {
      this.fetchUsers(query, callback, true);
    });
  }

  getAvatar = (item) => {  
    return getImageUrl(item.avatar, this.props.imageCookie);
  }  

  getUsernameById = (id) => {
    if(this.state.selectedUserObj[id]) return this.state.selectedUserObj[id];
    if(this.state.users.filter(u=>u.id==id)[0]) {
      const display = this.state.users.filter(u=>u.id==id)[0].display;
      if(display.trim().length==0) {        
        return this.state.selectedUserObj[id].display;
      } else {
        return display;
      }      
    }      
    else
      return "  ";
  }

  render() {
    const { t,value } = this.props;

    return (
      <div style={{width: "100%"}} ref={el => {
        container = el
      }}>
        <MentionsInput
          value={value}
          autoFocus
          onChange={this.handleChange}
          onKeyDown={(event)=>{
            if(this.state.isReply ) {
              if(event.target.selectionStart <= this.state.replyNameLength && (event.code === "Delete" || event.code === "Backspace")) {
                event.target.selectionStart=this.state.replyNameLength+1;
                event.preventDefault();   
              } else if(event.target.selectionStart < this.state.replyNameLength) {
                event.target.selectionStart=this.state.replyNameLength+1;
                event.preventDefault(); 
              }
            }
          }}
          placeholder={t("add_a_comment")}
          className={this.props.className + " mentions"}
          allowSuggestionsAboveCursor={true}
          suggestionsPortalHost={container}
          allowSpaceInQuery={true}
          customSuggestionsContainer={(children)=>{          
            return <div><span style={{fontWeight: "bold"}}><h2>{t("suggestions")}</h2></span>
              <InfiniteScroll
                dataLength={this.state.users.length}
                next={() => {this.fetchUsersPagination(this.state.query, this.state.callback, 2)}}
                hasMore={this.state.hasMore}
                loader={<CircularProgress show={this.state.loading} />}
                height={conv(218)}
              >
                {
                  children.map((c, index)=>{
                    return (
                      <ListItem className={c.props.focused ? "focused" : ""} alignItems="flex-start" onClick={c.props.onClick} onMouseEnter={c.props.onMouseEnter} key={index}>
                        <Stack  direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={1}
                          width="100%"
                        >
                          <Avatar src={this.getAvatar(c)} sx={{ width: conv(28), height: conv(28) }}/>
                          <Typography
                            component="div"
                            variant="body2"
                            sx={{ width: conv(237), height: conv(27) }}
                          >
                            {c.props.suggestion.display}
                          </Typography>
                        </Stack>                
                      </ListItem>
                    )
                  })
                }    
              </InfiniteScroll>          
            </div>
          }
          }          
        >
          <Mention
            type="user"
            trigger="@"
            data={this.fetchUsers}
            markup="{__id__}"
            displayTransform={id => this.getUsernameById(id)}
            className="mentions__mention"
          />
        </MentionsInput>      
      </div>
    );
  }
}

export default withTranslation()(MentionTextArea);