import React, { useEffect, useRef, useState, forwardRef } from "react";
import Marker from "./Marker";
import Tooltip from "./Tooltip";
import { findLastIndex } from "../../Utils/ArrayPolyfill";
import Helpers from "../../Utils/Helper";

function getBounds(item) {
  return item?.current?.getBoundingClientRect();
}

function getWidth(ref) {
  return ref?.current?.offsetWidth;
}

const Mark = ({ data }) => {
  const { width, color, borderRadius = 0 } = data;

  return (
    <div
      className="slider-mark"
      style={{
        height: "100%",
        background: color,
        borderRadius: borderRadius,
        display: "flex",
        flex: 1
      }}
    ></div>
  );
};
function AccountSlider({ data, value,onLoad, releasedValue, config = {} }) {
  const {
    height = "15px",
    onChange,
    onRelease,
    readOnly = false,
    markerSize = "15px",
    borderRadius = "20px",
    markerStyle,
    trackStyle,
    showTooltip,
    tooltipFormatter,
    tooltipStyle,
  } = config;
  const [drag, setDrag] = useState(false);
  let [position, setPosition] = useState();
  let [percPosition, setPercPosition] = useState();
  const [marks, setMarks] = useState([]);
  const [currentItem, setCurrentItem] = useState();
  const [dragged, setDragged] = useState(false);
  const [totalWidth, setTotalWidth] = useState();
  const [outerWidth, setOuterWidth] = useState();
  const [margLeft, setMargLeft] = useState(0);
  const [tooltipPos, setTooltipPos] = useState();
  const [loaded, setLoaded] = useState(false);
  const markerRef = useRef();
  const containerRef = useRef();
  const tooltipRef = useRef();
  const outerRef = useRef();

  //#region not impacting

  const handleOnLoad=()=>{
    onLoad&&onLoad();
  }

  const handleMouseDown = (e) => {
    if (readOnly) return;
    setDrag(true);
    setDragged(true);
  };
  const handleMouseUp = () => {
    if (readOnly) return;
    setDrag(false);
  };

  const handleMouseMove = (e) => {
    if (drag) {
      setMarkerPosition(e.clientX);
    }
  };
  const calculateMargin = async () => {
    await Helpers.sleep(200);
    let noDataMonths = data.total_trend_month - data.available_trend_month;
    if (noDataMonths === 0 && outerWidth) {
      setMargLeft(0);
    } else {
      let margin;
      let singleMonthWidth = outerWidth / data.total_trend_month;
      let dynamicWidth = singleMonthWidth * noDataMonths;
      let noDataStaticWidth = outerWidth / 50;
      let staticWidth = noDataStaticWidth * noDataMonths;
      if (data.trends.length > 1) {
        let width = Math.min(staticWidth, dynamicWidth);
        margin = width;
      } else {
        margin = dynamicWidth;
      }
      if (!isNaN(margin)) {
        setMargLeft(margin);
      }
    }
    setLoaded(true);
  };
  const createMarks = async () => {
    let totalWidth = containerRef?.current?.clientWidth;
    let markWidth;
    let dataLength = data?.trends.length;

    if (dataLength > 0) {
      markWidth = parseFloat((totalWidth / dataLength).toPrecision(4));

      
      let marksData = data.trends.map((item, ind) => {
        let start = parseFloat(ind * markWidth).toFixed(2);
        start = parseFloat(start);
        let end = parseFloat(parseFloat(start + markWidth).toFixed(2));
        let borderRadius = "0px";
        let radius = markerSize;
        if (ind === 0) borderRadius = `${radius} 0px 0px ${radius}`;
        if (ind === data.trends.length - 1)
          borderRadius = `0px ${radius} ${radius} 0px`;
        return {
          ...item,
          index: ind,
          value: item.timestamp,
          start: start,
          end: end,
          width: markWidth,
          height: "100%",
          color: item.color,
          text: item.text,
          borderRadius,
        };
      });
      setMarks(marksData);
      if (value?.index) {
        setCurrentItem(marksData[value?.index]);
      }
    }
  };

  const setMarkerPosition = (mouseX) => {
    let totalWidth = getWidth(containerRef);
    if (markerRef?.current && totalWidth) {
      let markerBounds = getBounds(markerRef);
      let bounds = getBounds(containerRef);
      let positionX = mouseX - bounds.x;
      if (bounds && markerBounds) {
        let left = positionX;
        let markerWidth = markerBounds.width;
        let markerHfWidth = markerWidth / 2;
        if (positionX <= markerHfWidth) {
          left = 0;
        } else if (positionX >= totalWidth - markerHfWidth) {
          left = totalWidth - markerWidth;
        } else {
          left = positionX - markerHfWidth;
        }
        setPosition(left);
      }
    }
  };

  const convertPerc = (val) => {
    return (val / totalWidth) * 100;
  };


  useEffect(() => {
    setPercPosition(convertPerc(position));
  }, [position]);

  const handleChange = (val) => {
    if (data?.trends?.length > 1) {
      onChange && onChange(val);
    }
  };
  const handleRelease = (val) => {
    if (data?.trends?.length > 1) onRelease && onRelease(val);
  };

  const setTooltip = () => {
    let totalWidth = getWidth(containerRef);
    if (tooltipRef?.current && showTooltip && totalWidth) {
      let tWidth = getBounds(tooltipRef).width;
      let markerBounds = getBounds(markerRef);
      let hfWidth = tWidth / 2;

      if (data.trends.length === 1) {
        setTooltipPos(`${convertPerc(totalWidth - tWidth - 5)}%`);
        return;
      }

      if (markerBounds) {
        let posX = markerRef?.current?.offsetLeft;
        let markerWidth = markerBounds.width;
        let markerHfWidth = markerWidth / 2;
        let tooltipPos;
        let containerWidth = totalWidth;
        if (posX + margLeft < hfWidth) {
          tooltipPos = 0;
        } else if (posX > containerWidth - hfWidth - markerWidth) {
          tooltipPos = containerWidth - tWidth - 5;
        } else {
          tooltipPos = posX - hfWidth + markerHfWidth;
        }
        setTooltipPos(`${convertPerc(tooltipPos)}%`);
      }
    }
  };

  useEffect(() => {
    setTotalWidth(getBounds(containerRef)?.width);
  }, [containerRef?.current?.offsetWidth, margLeft]);

  useEffect(() => {
    setOuterWidth(getBounds(outerRef)?.width);
  }, [outerRef?.current?.offsetWidth]);

  useEffect(() => {
    if (data) {
      calculateMargin();
    }
  }, [data, outerWidth]);

  useEffect(() => {
    if (loaded) {
      createMarks();
    }
  }, [loaded, totalWidth]);

  const handleResize = () => {
    if (outerRef) setOuterWidth(getBounds(outerRef)?.width);
    if (containerRef) setTotalWidth(getBounds(containerRef)?.width);
    setTooltip();
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //#endregion
  
  const updateMarkerPos = () => {
    let selectedVal;
    if (value) {
      let ind = findLastIndex(marks, value.timestamp);
    

      if (ind > -1) selectedVal = marks[ind];

      if (selectedVal) {
        if (ind === marks.length - 1) {
          setPosition(
            containerRef?.current?.offsetWidth - getBounds(markerRef).width
          );
        } else {
          setPosition(selectedVal?.start);
        }
      }
      return selectedVal;
    }
  };


  useEffect(() => {
    if (marks && marks.length && markerRef.current && drag) {
      let markerBounds = getBounds(markerRef);
      if (markerBounds) {
        let markerWidth = markerBounds.width;
        let markerHfWidth = markerWidth / 2;
        let posX = markerRef?.current?.offsetLeft+markerHfWidth;
        // let pos = position + markerBounds.width / 2;
        if (posX === markerHfWidth) {
          setCurrentItem(marks[0]);
        } else if (
          Math.round(markerRef?.current?.offsetLeft + markerWidth) ===
          Math.round(marks[marks.length - 1].end)
        ) {
          setCurrentItem(marks[marks.length - 1]);
        } else {
          let currItem = marks.find(
            (item) => posX >= item.start && posX <= item.end
          );
          if(currItem)
            setCurrentItem(currItem);
        }
      }
    }
  }, [position, drag, markerRef, marks]);
  useEffect(() => {
    if (
      marks &&
      marks.length &&
      value?.timestamp &&
      !value["index"] &&
      !drag &&
      loaded
    ) {
      let selectedVal = updateMarkerPos();

      let isChanged = JSON.stringify(value) !== JSON.stringify(selectedVal);
      if (selectedVal) {
        setCurrentItem(selectedVal);
        isChanged && onChange && onChange(selectedVal);
        handleOnLoad();
      }
    
    }
  }, [marks, value, drag, loaded]);

  useEffect(() => {
    if (dragged && currentItem) {
      if (drag) {
        let isChanged = JSON.stringify(value) !== JSON.stringify(currentItem);
        isChanged && handleChange(currentItem);
      } else {
        if (currentItem.index === data.trends.length - 1) {
          setPosition(currentItem.end - getBounds(markerRef).width);
        } else {
          if (position !== currentItem.start)
            setPosition(currentItem.start);
        }

        if (JSON.stringify(releasedValue) !== JSON.stringify(currentItem)) {
          handleRelease(currentItem);
        }
      }
    }
  }, [drag, dragged, currentItem, markerRef?.current?.offsetWidth, value]);

  useEffect(() => {
    setTooltip();
  }, [
    currentItem,
    position,
    tooltipRef?.current?.offsetWidth,
    markerRef?.current?.offsetLeft,
    totalWidth,
    data,
  ]);

  //#region ZERO DATA SLIDER
  const zeroDataSlider = () => (
    <div
      style={{
        width: "100%",
        height: height,
        borderRadius: borderRadius,
        ...trackStyle,
      }}
    ></div>
  );

  if (data?.trends?.length === 0) return zeroDataSlider();
  //#endregion

  //#region ONE DATA SLIDER
  const oneDataSlider = () => (
    <>
      <div
        style={{
          width: "100%",
          borderRadius: borderRadius,
          ...trackStyle,
        }}
      >
        <div
          ref={outerRef}
          style={{
            height: height,
          }}
        >
          <div
            ref={containerRef}
            style={{
              height: "100%",
              position: "relative",
            }}
          >
            <Tooltip
              ref={tooltipRef}
              className={tooltipStyle}
              show={showTooltip}
              position={tooltipPos}
              value={
                currentItem &&
                (tooltipFormatter
                  ? tooltipFormatter(currentItem?.value)
                  : currentItem?.value)
              }
            />

            <Marker
              ref={markerRef}
              readOnly={true}
              currentItem={currentItem}
              position={convertPerc(totalWidth - getBounds(markerRef)?.width)}
              style={markerStyle}
              markerSize={markerSize}
              border={currentItem && "3px solid " + currentItem.color}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            />
          </div>
        </div>
      </div>
    </>
  );

  if (data?.trends?.length === 1) return oneDataSlider();
  //#endregion

  return (
    <div
      style={{
        width: "100%",
        height: height,
        borderRadius: borderRadius,
        ...trackStyle,
      }}
      onMouseLeave={handleMouseUp}
    >
      <div
        ref={outerRef}
        style={{
          paddingLeft: !isNaN(margLeft) ? margLeft : 0,
          height: height,
          borderRadius: borderRadius,
        }}
      >
        <div
          ref={containerRef}
          style={{
            height: height,
            position: "relative",
            display: "flex",
            flex: 1,
          }}
          onMouseMove={handleMouseMove}
        >
          <Tooltip
            ref={tooltipRef}
            className={tooltipStyle}
            position={tooltipPos}
            show={showTooltip}
            value={
              currentItem &&
              (tooltipFormatter
                ? tooltipFormatter(currentItem?.value)
                : currentItem?.value)
            }
          />
          {marks && marks.map((item, i) => <Mark data={item} key={i} />)}
          <Marker
            ref={markerRef}
            currentItem={currentItem}
            position={percPosition}
            markerSize={markerSize}
            style={markerStyle}
            border={`3px solid ${readOnly ? currentItem?.color : "#fff"}`}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(AccountSlider);
