import React, { useCallback, useState, useEffect } from "react";
import { Box, Grid, Typography, Stack, IconButton } from "@mui/material";
import { NavigateBefore } from "@mui/icons-material";

function SideHeader({ title, onBack, mr = 0, rightComponent, mb = "0" }) {
  const handleClose = () => {
    onBack && onBack();
  };

  return (
    <Box
      display="flex"
      alignItems={"center"}
      borderBottom={"2px solid"}
      borderColor="border.light"
      pb={window.conv(15)}
      mr={mr}
      mb={mb}
    >
      {onBack && (
        <IconButton
          onClick={handleClose}
          sx={{
            color: "text.secondary",
            paddingLeft: 0,
            paddingTop: 0,
            marginLeft: window.conv(-10),
            height:window.conv(30),
            marginTop:"5px",
            ":hover":{background:"none"}
          }}
        >
          <NavigateBefore sx={{ fontSize: window.conv(40)}} />
        </IconButton>
      )}
      <Typography
        flex="1"
        display="flex"
        variant="h4"
        color="text.heading"
        justifyContent={onBack ? "center" : "left"}
        ml={onBack ? window.conv(-50) : "0px"}
      >
        {title}
      </Typography>
      {rightComponent && rightComponent}
    </Box>
  );
}

export default React.memo(SideHeader);
