import React from "react";
const Comment = ({color}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.30361 13.5182C6.60024 13.5182 6.02881 12.9468 6.02881 12.2434C6.02881 11.5421 6.60024 10.9707 7.30361 10.9707C8.00699 10.9707 8.57842 11.5421 8.57842 12.2434C8.57842 12.9468 8.00699 13.5182 7.30361 13.5182ZM12.2595 13.5182C11.5561 13.5182 10.9847 12.9468 10.9847 12.2434C10.9847 11.5421 11.5561 10.9707 12.2595 10.9707C12.9628 10.9707 13.5343 11.5421 13.5343 12.2434C13.5343 12.9468 12.9628 13.5182 12.2595 13.5182ZM15.9406 12.2434C15.9406 12.9468 16.512 13.5182 17.2154 13.5182C17.9188 13.5182 18.4902 12.9468 18.4902 12.2434C18.4902 11.5421 17.9188 10.9707 17.2154 10.9707C16.512 10.9707 15.9406 11.5421 15.9406 12.2434Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0217 1.0918C5.68355 1.0918 1.09082 6.26938 1.09082 12.0173C1.09082 13.8528 1.62537 15.7418 2.56355 17.468C2.73809 17.7532 2.75991 18.1126 2.63991 18.4524L1.909 20.8997C1.74537 21.4897 2.24718 21.9256 2.80355 21.7508L5.00718 21.0963C5.60718 20.8997 6.07627 21.1499 6.63264 21.4897C8.22537 22.4282 10.2108 22.91 11.9999 22.91C17.4108 22.91 22.909 18.7255 22.909 11.9845C22.909 6.17105 18.2181 1.0918 12.0217 1.0918Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Comment;