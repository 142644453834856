import React from "react";
import { Checkbox } from "@mui/material";
import { useStyles } from "./styles";
import { useTheme } from "@mui/styles";

export default function MyCheckbox({ checked = false, onClick }) {
  const theme = useTheme();
  const classes = useStyles();
  const handleClick = () => {
    onClick && onClick(!checked);
  };
  return (
    <div className={classes.checkboxContainer}>
      <Checkbox
        checked={checked}
        onClick={handleClick}
        inputstyle={{ color: theme.palette.text.secondary }}
        style={{ color: theme.palette.text.secondary, marginRight: "0px",padding:0 }}
      />
    </div>
  );
}
