import React from "react";
const Search = () => {
  return (
    <svg
      width={window.conv(63)}
      height={window.conv(61)}
      viewBox="0 0 63 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      
      <ellipse
        rx="21.6103"
        ry="21.6088"
        transform="matrix(0.715074 0.699049 -0.70181 0.712364 31.5099 30.5)"
        stroke="#4B545B"
        strokeWidth="2"
      />
      <line
        x1="1"
        y1="-1"
        x2="15.9235"
        y2="-1"
        transform="matrix(0.677144 0.73585 -0.738455 0.674303 45.5039 46.5312)"
        stroke="#4B545B"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
    
  );
};
export default Search;
