import React from "react";

const Dragger = ({fill="red",stroke}) => {
  return (
    <svg
      width={window.conv(35)}
      height={window.conv(35)}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13.6923"
        cy="13.6923"
        r="12.4231"
        fill={fill}
        stroke={stroke}
        strokeWidth="1.46154"
      />
      <rect
        x="10.7703"
        y="18.0762"
        width="1.46154"
        height="8.76923"
        transform="rotate(-180 10.7703 18.0762)"
        fill={stroke}
      />
      <rect
        x="18.0781"
        y="18.0762"
        width="1.46154"
        height="8.76923"
        transform="rotate(-180 18.0781 18.0762)"
        fill={stroke}
        // fill="#F6F7F9"
      />
    </svg>
  );
};

export default Dragger;
