import React from "react";
import NoData from "../../Assets/images/no-data";
import { Stack, Typography, Box } from "@mui/material";
import { useTranslate } from "../../Hooks/useTranslate";
import { useTheme } from "@mui/styles";
const NoDataView = ({
  text,
  description,
  show_icon = true,
  icon,
  headingFontSize,
}) => {
  const theme=useTheme();
  const t = useTranslate();
  if (!text) text = t("no_data_title");
  if (!description) description = t("no_data_description");
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      flex="1"
      alignItems="center"
      justifyContent="center"
      textAlign={"center"}
      mt="20px"
    >
      {show_icon && (
        <Box display={"inline-block"}>
          {icon ? (
            <Box
              display="flex"
              height="8.125rem"
              width="8.125rem"
              alignItems={"center"}
              justifyContent="center"
              borderRadius={"50%"}
              bgcolor="background.loaderBg"
            >
              {icon}
            </Box>
          ) : (
            <NoData fill={theme.palette.background.noDataFill}/>
          )}
        </Box>
      )}
      {headingFontSize ? (
        <Typography variant="h4" mt="1rem" fontSize={headingFontSize}>
          {text}
        </Typography>
      ) : (
        <Typography variant="h4" mt="1rem">
          {text}
        </Typography>
      )}
      <Typography variant="neutral">{description}</Typography>
    </Box>
  );
};

export default NoDataView;
