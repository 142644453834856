import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslate } from "../../Hooks/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import { FEEDTYPES } from "../../Utils/Constants";
import AmountLabel from "../../Components/AmountLabel";
import { CURRENCIES_SYMBOLS } from "../../Utils/Constants";
import { useResizeDetector } from "react-resize-detector";

import Helpers from "../../Utils/Helper";
import { getFullDate } from "../../Utils/Date";

const MainCard = ({ data, onWidthUpdate }) => {
  const classes = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();
  const divRef = useRef();
  const resizer = useResizeDetector();
  const selectedFeed = useSelector((state) => state.feed.selectedFeed);
  const accountData = useSelector((state) => state.account.data);
  const portfolioTrendData = useSelector((state) => state.portfolio.trendData);
  const accountCurrentData = useSelector(
    (state) => state.scorecard.account_data
  );
  const portfolioData = useSelector((state) => state.portfolio.data);

  const titles = useSelector((state) => state.app.mapping_data.title);

  useEffect(() => {
    if (resizer?.width) {
      onWidthUpdate(resizer.width);
    } else
      resizer.ref.current && onWidthUpdate(resizer.ref.current.clientWidth);
  }, [resizer]);

  let title = titles ? titles.nps_title : "";
  let value = "";
  let amount = 0;
  let currency = "";
  let color = "text.secondary";
  let subtextLabel = "";
  let subtextValue = "";
  if (!selectedFeed) return "";
  if (
    selectedFeed.feed_type === FEEDTYPES.ACCOUNT &&
    accountCurrentData &&
    accountData
  ) {
    if (titles) {
      title = accountCurrentData.show_nrr
        ? titles.nrr_state_title
        : titles.nps_title + " " + t("classification");
    }
    value = accountCurrentData.show_nrr
      ? accountCurrentData.nrr_state
      : accountCurrentData.text;
    currency = accountData.fin_currency_type;
    amount = accountData.fin_value;
    color = accountCurrentData.color;
    subtextLabel = t("renewal");
    if (!accountData.renewal_date || accountData.renewal_date === "")
      subtextValue = ": " + t("not_available");
    else {
      subtextValue = getFullDate(accountData.renewal_date, false);
    }
  }
  if (selectedFeed.feed_type === FEEDTYPES.PORTFOLIO && portfolioData) {
    value = portfolioTrendData.nps_score;
    currency = portfolioData.fin_currency_type;
    amount = portfolioData.fin_value;
    color = portfolioTrendData.nps_diff_color_code;
    subtextLabel = t("total_accounts");
    subtextValue = portfolioTrendData.distribution_count;
  }

  return (
    <div ref={resizer.ref}>
      <Box
        className={classes.classfication_box}
        mt={"2.312rem"}
        mb="2.312rem"
        p={"0.875rem"}
      >
        <Typography variant="h6" className={classes.card_title}>
          {title}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          mt="1rem"
          className={classes.card_value}
          justifyContent="center"
          width="100%"
        >
          <Typography variant="h5" className={classes.heading} color={color}>
            {value}
          </Typography>

          <AmountLabel>
            <Typography
              ml="1rem"
              variant="h5"
              className={classes.heading_finance}
              color="text.secondary"
            >
              {(currency && CURRENCIES_SYMBOLS[currency]) || ""}
              {amount && Helpers.AbbreviateNumber(amount)}
            </Typography>
          </AmountLabel>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          mt="1rem"
          justifyContent={"center"}
        >
          <Typography variant="h6" className={classes.card_subtitle}>
            {subtextLabel}
          </Typography>{" "}
          &nbsp;
          <Typography variant="h6" className={classes.card_subvalue}>
            {subtextValue}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
export default MainCard;
