import { commentActions } from "../actionCreators";

const initialState = {
  comments: [],
  meta: {},
  user_details: {},
  comment: {}
};

const commentReducer = (state = initialState, action) => {
  switch (action.type) {
  case commentActions.SET_COMMENTS: {
    return {
      ...state,
      comments: [...action.payload.comments.items],
      meta: {...action.payload.comments.meta}
    };
  }
  case commentActions.APPEND_COMMENTS: {
    return {
      ...state,
      comments: [
        ...state.comments,
        ...action.payload.comments.items
      ]
    }
  }
  case commentActions.FORMAT_USERS: {
    return {
      ...state,
      user_details: {...action.payload.users}
    };
  }
  case commentActions.APPEND_USERS: {    
    return {
      ...state,
      user_details: {...state.user_details,...action.payload.users}
    };
  }
  case commentActions.POST_COMMENT: {
    return {
      ...state,
      ...action.payload
    }
  }
  case commentActions.SET_COMMENT_BY_ID: {
    let comments = [...state.comments];
    comments.splice(action.index+1, 0, action.payload.comment.item);
    let meta = {...state.meta};
    meta.total_records = meta.total_records+1;
    return {
      ...state,
      comments,
      meta
    }
  }
  case commentActions.COMMENT_TOGGLE_LIKE: {
    let comments = [...state.comments]
    comments[action.index].user_likes = !comments[action.index].user_likes;
    if(comments[action.index].user_likes) {
      comments[action.index].likes_count.total_reactions = comments[action.index].likes_count.total_reactions+1;
    } else {
      comments[action.index].likes_count.total_reactions = comments[action.index].likes_count.total_reactions-1;
    }
    return {
      ...state,
      comments: comments
    }    
  }  
  case commentActions.CLEAR_COMMENTS_DATA: {
    return initialState;
  }  
  default:
    return state;
  }
};

export default commentReducer;
