import { makeStyles } from "@mui/styles";
import UIHelper from "../../../Utils/UIHelper";
const conv = UIHelper.convertPxToRem;
export const useStyles = makeStyles(MUITheme => ({
  box: {
    backgroundColor: MUITheme.palette.background.listItem,
    flex: 1,
    borderRadius: conv(25),
    padding: `${conv(18)} ${conv(25)}`,    
    cursor: "pointer",
  },
  p0:{
    paddingBottom:0
  },
  selectedBox: {
    backgroundColor: MUITheme.palette.background.listItemSelected,
  },
}));
