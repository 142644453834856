import { portfolioActions } from "../actionCreators";

const initialState = {
  data: null,
  trendData: null,
  jtpData: null,
  analyticsReactions: null,
  analyticsStatus: null,
  operationalDrivers: null,
  kpiMetrics: null,
  selected_jtp: null,
  selected_op_driver: null,
  selected_kpi: null,
  kpi_metrics: null,
  timestamp: null,
  filters: { filter_page: null },
  selected_filters: {},
  loading_jtp: false,
  account_members: null,
  apply_filters: false,
  applied_filters: {},
  alerts: null,
  link_data: null,
  loading_kpi: false,
  loaded: false,
  reload:false
};

const portfolioReducer = (state = initialState, action) => {
  switch (action.type) {
  case portfolioActions.SET_PORTFOLIO_DATA: {
    return {
      ...state,
      data: action.payload,
    };
  }
  case portfolioActions.SET_PORTFOLIO_TRENDS: {
    return {
      ...state,
      trendData: action.payload,
    };
  }

  case portfolioActions.SET_PORTFOLIO_JTP: {
    return { ...state, jtpData: action.payload };
  }
  case portfolioActions.SET_ANALYTICS_REACTIONS: {
    return { ...state, analyticsReactions: action.payload };
  }
  case portfolioActions.SET_ANALYTICS_STATUS: {
    return { ...state, analyticsStatus: action.payload };
  }
  case portfolioActions.SET_OPERATIONAL_DRIVERS: {
    return { ...state, operationalDrivers: action.payload };
  }
  case portfolioActions.UPDATE_ANALYTICS_FOLLOW_STATUS: {
    return {
      ...state,
      analyticsStatus: {
        ...state.analyticsStatus,
        follow_status: action.payload,
      },
    };
  }
  case portfolioActions.UPDATE_ANALYTICS_FAVORITE_STATUS: {
    return {
      ...state,
      analyticsStatus: {
        ...state.analyticsStatus,
        favorite_status: action.payload,
      },
    };
  }

  case portfolioActions.SET_PORTFOLIO_KPI_DATA: {
    return { ...state, kpi_metrics: action.payload };
  }

  case portfolioActions.SELECT_PORTFOLIO_JTP: {
    return {
      ...state,
      selected_jtp: action.payload,
      selected_op_driver: null,
      kpi_metrics: null,
    };
  }

  case portfolioActions.SELECT_PORTFOLIO_OPERATIONAL_DRIVER: {
    return { ...state, selected_op_driver: action.payload };
  }
  case portfolioActions.SELECT_PORTFOLIO_KPI_METRIC: {
    return { ...state, selected_kpi: action.payload };
  }
  case portfolioActions.SET_PORTFOLIO_KPI_LOADING: {
    return { ...state, loading_kpi: action.payload };
  }
  case portfolioActions.SET_PORTFOLIO_TIMESTAMP: {
    return { ...state, timestamp: action.payload };
  }
  case portfolioActions.SET_PORTFOLIO_ALERT_CONFIGURED: {
    return { ...state, alert_action_config: action.payload };
  }

  case portfolioActions.SET_PORTFOLIO_JTP_LOADING: {
    return { ...state, loading_jtp: action.payload };
  }

  case portfolioActions.SET_PORTFOLIO_FILTERS: {
    return { ...state, filters: action.payload };
  }
  case portfolioActions.SET_APPLY_FILTERS: {
    let result_filters = action.payload?state.selected_filters:{};
  
    return {
      ...state,
      apply_filters: action.payload,
      applied_filters: result_filters,
      selected_filters: result_filters,
    };
  }
  case portfolioActions.SET_SELECTED_PORTFOLIO_FILTERS: {
    return { ...state, selected_filters: action.payload };
  }
  case portfolioActions.SET_PORTFOLIO_ALERTS: {
    return { ...state, alerts: action.payload };
  }
  case portfolioActions.SET_RELOAD_FUNCTION: {
    return { ...state, reload: action.payload };
  }
  case portfolioActions.CLEAR_PORTFOLIO_DATA: {
    return {
      ...state,
      jtpData: [],
      operationalDrivers: null,
      kpiMetrics: null,
      selected_jtp: null,
      selected_op_driver: null,
      kpi_metrics: null,
      // share_link: null,
      // filters: {filter_page:null},
      // selected_filters:null,
      // applied_filters:null,
      // account_members:null
    };
  }
  case portfolioActions.CLEAR_PORTFOLIO_ALL_DATA: {
    return { ...initialState, link_data: state.link_data };
  }

  case portfolioActions.SET_PORTFOLIO_ACCOUNT_MEMBERS: {
    let new_members = null;
    if (action.payload) {
      if (action.payload.meta.page_number === 1) {
        new_members = {
          items: action.payload.items,
          meta: action.payload.meta,
        };
      } else
        new_members = {
          items: [...state.account_members.items, ...action.payload.items],
          meta: action.payload.meta,
        };
    }

    return {
      ...state,
      account_members: new_members,
    };
  }

  case portfolioActions.CLEAR_PORTFOLIO_ACCOUNT_MEMBERS:
    return { ...state, account_members: null };

  case portfolioActions.SET_DEEPLINK_DATA:
    return { ...state, link_data: action.payload };

  case portfolioActions.SET_PORTFOLIO_ANALYTICS_LOADED:
    return { ...state, loaded: action.payload };

  default:
    return state;
  }
};

export default portfolioReducer;
